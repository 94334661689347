import { ContentCut } from "@mui/icons-material";
import { Button, Stack, ToggleButton, useTheme } from "@mui/material";
import { EventDetails } from "src/models/EventDetails";
import { useOpenState } from "src/utils/useOpenState";
import { ClipViewerExportDialogMobile } from "./ClipViewerExportDialogMobile/ClipViewerExportDialogMobile";

type ClipViewFooterMobileProps = {
  event: EventDetails;
  onEdit?: () => void;
  createReport: () => void;
  handleDownload: () => void;
  showShareDialog: () => void;
};

export function ClipViewFooterMobile({
  event,
  onEdit,
  createReport,
  handleDownload,
  showShareDialog,
}: ClipViewFooterMobileProps) {
  const { palette } = useTheme();

  const exportDialog = useOpenState();

  const editClip = onEdit && (
    <ToggleButton
      value={true}
      size="small"
      onClick={onEdit}
      disabled={!onEdit}
      sx={{ height: 42, width: 42 }}
      children={
        <ContentCut sx={{ transform: "rotate(180deg)" }} color="primary" />
      }
    />
  );

  return (
    <Stack
      px={2}
      py={1}
      borderTop={`1px solid ${palette.divider}`}
      flexDirection="row"
      justifyContent="space-between"
      gap={2}
    >
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={exportDialog.show}
        children="Export"
        sx={{
          flex: 1,
        }}
      />
      <Button
        variant="contained"
        color="secondary"
        size="large"
        children="Share"
        onClick={showShareDialog}
        sx={{
          flex: 1,
        }}
      />

      {editClip}

      <ClipViewerExportDialogMobile
        event={event}
        open={exportDialog.isOpen}
        onClose={exportDialog.hide}
        createReport={createReport}
        handleDownload={handleDownload}
      />
    </Stack>
  );
}
