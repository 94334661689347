import { ChangeEvent, useState } from "react";
import { Add } from "@mui/icons-material";
import {
  alpha,
  Stack,
  Switch,
  ToggleButton,
  Typography,
  useTheme,
} from "@mui/material";
import { ScheduledAlertCardView } from "src/components/ScheduledAlertPopover/ScheduledAlertCardView/ScheduledAlertCardView";
import { ScheduledAlertDeleteDialog } from "src/components/ScheduledAlertPopover/ScheduledAlertDeleteDialog/ScheduledAlertDeleteDialog";
import { getScheduledAlertType } from "src/components/ScheduledAlertPopover/ScheduledAlertPopover.utils";
import { OriginType } from "src/api/useWatchQueryAlertCreate";
import { useWatchQueryUpdate } from "src/api/useWatchQueryUpdate";
import { useWatchQuery } from "src/api/useWatchQuery";
import { UserQueryDTO } from "src/models/UserQueryDTO";
import { AlertType } from "src/models/AlertType";

export function ScheduledAlertsSection({
  isScheduledAlertsAvailable,
  alerts,
  origin,
  queryId,
  open,
  onModeChange,
  onEdit,
}: {
  isScheduledAlertsAvailable: boolean;
  alerts: UserQueryDTO["alerts"];
  origin: OriginType;
  queryId: string;
  open?: boolean;
  onModeChange: () => void;
  onEdit: (rule: AlertType) => void;
}) {
  const { palette } = useTheme();
  const darkMode = palette.mode === "dark";

  const themeBackground = darkMode
    ? palette.background.default
    : palette.background.paper;

  const [showAlertDeleteDialog, setShowAlertDeleteDialog] = useState(false);
  const [alertDeleteData, setAlertDeleteData] = useState<AlertType | null>(
    null
  );

  const currentQueryData = useWatchQuery({
    enabled: !!queryId && !!open && origin !== "edit",
    path: {
      queryId: queryId ?? "",
    },
  });

  const watchQueryUpdate = useWatchQueryUpdate({
    options: {
      origin,
    },
  });
  const handleScheduledAlertChange = (event: ChangeEvent<HTMLInputElement>) => {
    const data = currentQueryData.data;
    if (!data) return;

    const updatedAlerts = alerts.map((v) => {
      if (v.type === "realtime") return v;
      return { ...v, enabled: event.target.checked };
    });

    watchQueryUpdate.mutate({
      ...data,
      alerts: updatedAlerts,
    });
  };

  return (
    <>
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        mx={3}
        mt={2.5}
        mb={1.25}
      >
        <Stack flexDirection="row" alignItems="center" gap={1}>
          <Switch
            disabled={alerts.length === 0}
            checked={isScheduledAlertsAvailable}
            onChange={handleScheduledAlertChange}
          />
          <Typography variant="subtitle1">Scheduled alerts</Typography>
        </Stack>

        <ToggleButton
          value
          onClick={onModeChange}
          sx={{ width: 48, background: themeBackground }}
        >
          <Add
            sx={{
              color: palette.mode === "dark" ? "primary.main" : "text.primary",
            }}
          />
        </ToggleButton>
      </Stack>

      <Stack
        mx={3}
        mb={3}
        sx={{
          borderWidth: 1,
          borderStyle: "solid",
          borderRadius: 2,
          borderColor:
            palette.mode === "light"
              ? alpha(palette.common.black, 0.23)
              : alpha(palette.common.white, 0.12),
        }}
      >
        {alerts.map((alert) => (
          <ScheduledAlertCardView
            key={alert.id}
            type={getScheduledAlertType(alert)}
            value={alert.schedule || []}
            enabled={alert.enabled}
            onDelete={() => {
              setAlertDeleteData(alert);
              setShowAlertDeleteDialog(true);
            }}
            onEdit={() => onEdit(alert)}
            sx={{
              borderRadius: "unset",
              "&:first-of-type": {
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
              },
              "&:last-of-type": {
                borderBottomRightRadius: 8,
                borderBottomLeftRadius: 8,
              },
            }}
          />
        ))}

        <ScheduledAlertDeleteDialog
          open={showAlertDeleteDialog}
          onClose={() => setShowAlertDeleteDialog(false)}
          queryId={queryId}
          alert={alertDeleteData}
          origin={origin}
        />
      </Stack>
    </>
  );
}
