import { Fragment, useState } from "react";
import { MoreVertOutlined } from "@mui/icons-material";
import { ButtonProps, IconButton } from "@mui/material";
import { WatchListUserQueryDTO } from "src/models/WatchListUserQueryDTO";
import { WatchQueryDeleteDialog } from "src/components/WatchQueryDeleteDialog/WatchQueryDeleteDialog";
import { WatchQueryItemMenu } from "./WatchQueryItemMenu";

export function WatchQueryItemMenuButton({
  query,
  canCreateQuery,
}: {
  query: WatchListUserQueryDTO;
  canCreateQuery: boolean;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const buttonId = `query-table-row-${query.id}-button`;
  const menuId = `query-table-row-${query.id}-menu`;

  const handleClick: ButtonProps["onClick"] = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  return (
    <Fragment>
      <IconButton
        id={buttonId}
        aria-controls={!!anchorEl ? menuId : undefined}
        aria-expanded={!!anchorEl ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        size="large"
      >
        <MoreVertOutlined />
      </IconButton>
      <WatchQueryItemMenu
        id={menuId}
        setShowDeleteModal={setShowDeleteModal}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        canCreateQuery={canCreateQuery}
        MenuListProps={{
          "aria-labelledby": buttonId,
        }}
        query={{
          id: query.id,
          title: query.title,
        }}
      />
      <WatchQueryDeleteDialog
        open={showDeleteModal}
        query={query}
        onClose={() => setShowDeleteModal(false)}
      />
    </Fragment>
  );
}
