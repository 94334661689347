import { Paper, Stack } from "@mui/material";
import { AccountsManagementNewAccountForm } from "../AccountsManagementNewAccountForm/AccountsManagementNewAccountForm";
import { AccountsManagementUsersForm } from "../AccountsManagementNewAccountForm/AccountsManagementUsersForm";

export function AccountsManagementGeneralTab() {
  return (
    <Stack direction={"column"} gap={2}>
      <Stack component={Paper} flex={1} px={4}>
        <AccountsManagementNewAccountForm />
      </Stack>
      <Stack component={Paper} flex={1} py={4}>
        <AccountsManagementUsersForm />
      </Stack>
    </Stack>
  );
}
