import {
  Fragment,
  HTMLAttributes,
  forwardRef,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useElementSize } from "src/utils/useElementSize";
import { AppAutocomplete } from "../AppAutocomplete/AppAutocomplete";
import { OptionCycler } from "../OptionCycler/OptionCycler";
import { TagPickerLogic, TagPickerProps } from "./TagPicker.model";
import { TagPickerList } from "./TagPickerList";
import { TagPickerListCollapseGroup } from "./TagPickerListCollapseGroup";
import { TagPickerListGroup } from "./TagPickerListGroup";
import { TagPickerListItem } from "./TagPickerListItem";

/**
 * A responsive Autocomplete multi-picker input
 * - provides custom List with row/column layout switching for narrow screens
 * - provides option presets in the List Footer
 * - renders custom option groups with additional info
 */
export function TagPicker<T>({
  value,
  presets,
  dropDownBoundsEl,
  dropDownLayout = "auto",
  hideOperator = false,
  getGroupLabel,
  getGroupTotal,
  onChange,
  renderOption,
  renderGroup,
  renderStartAdornment,
  renderDropDownHeader,
  ...props
}: TagPickerProps<T>) {
  const rootRef = useRef<HTMLElement>(null);
  const rootSize = useElementSize(rootRef);
  const size = useElementSize(dropDownBoundsEl);
  const dropDownWidth = size?.width || rootSize?.width;
  const autoDropDownLayout =
    !dropDownWidth || dropDownWidth < 600
      ? "column" //
      : "row";

  const layout =
    dropDownLayout === "auto" ? autoDropDownLayout : dropDownLayout;

  const onClear = useCallback(() => {
    if (onChange) {
      onChange({
        list: [],
        logic: "or",
      });
    }
  }, [onChange]);

  const onTagPickerListChange = useCallback(
    (list: T[]) => {
      onChange({ list, logic: value.logic });
    },
    [onChange, value.logic]
  );

  const renderStartAdornmentWrapper: typeof renderStartAdornment = (
    inherited
  ) => {
    const adornment = (
      <Fragment>
        {inherited}
        {value.list.length > 1 && !hideOperator && (
          // OptionCycler was used instead of OptionPicker because of
          // conflict between AppAutocomplete Dropdown and Menu of the Picker
          <OptionCycler<TagPickerLogic>
            onChange={(logic) => onChange({ list: value.list, logic })}
            value={value.logic}
            options={["and", "or"]}
            getOptionLabel={(opt) => opt.toUpperCase()}
            disableAutoFocusItem
            onClick={(e) => {
              // to prevent Autocomplete focus and dropdown opening
              e.stopPropagation();
              e.preventDefault();
            }}
          />
        )}
      </Fragment>
    );

    return renderStartAdornment ? renderStartAdornment(adornment) : adornment;
  };

  const ListBoxComponent = useMemo(() => {
    return forwardRef<HTMLUListElement, HTMLAttributes<HTMLElement>>(
      (listBoxProps, ref) => {
        return (
          <TagPickerList
            {...listBoxProps}
            listRef={ref}
            presets={presets}
            layout={layout}
            onChange={onTagPickerListChange}
            onClear={onClear}
          />
        );
      }
    );
  }, [presets, layout, onTagPickerListChange, onClear]);

  const renderOptionWrapper: typeof renderOption = (
    listItemProps,
    option,
    state,
    ownerState
  ) => {
    if (renderOption) {
      return renderOption(listItemProps, option, state, ownerState);
    }

    const key = JSON.stringify(option);
    const primary =
      typeof option === "string"
        ? option
        : props.getOptionLabel
        ? props.getOptionLabel(option)
        : key;

    return (
      <TagPickerListItem
        key={key}
        listItemProps={listItemProps}
        primary={primary}
        state={state}
      />
    );
  };

  const renderGroupWrapper: typeof renderGroup = ({ key, ...params }) => {
    const total = getGroupTotal && getGroupTotal(params.group);

    const groupLabel = getGroupLabel
      ? getGroupLabel(params.group)
      : params.group;

    if (layout === "column") {
      return (
        <TagPickerListCollapseGroup
          {...params}
          key={key}
          label={groupLabel}
          total={total}
        />
      );
    } else {
      return (
        <TagPickerListGroup
          {...params}
          key={key}
          label={groupLabel}
          total={total}
        />
      );
    }
  };

  return (
    <AppAutocomplete<T, true>
      {...props}
      multiple
      ref={rootRef}
      disableCloseOnSelect
      value={value.list}
      onChange={(nextValue) => {
        onChange({
          list: nextValue,
          logic: value.logic,
        });
      }}
      ListboxComponent={ListBoxComponent}
      dropDownWidth={dropDownWidth}
      dropDownBoundsEl={dropDownBoundsEl}
      renderOption={renderOptionWrapper}
      renderStartAdornment={renderStartAdornmentWrapper}
      renderGroup={renderGroupWrapper}
      renderDropDownHeader={renderDropDownHeader}
    />
  );
}
