import { endOfDay, formatISO, startOfDay } from "date-fns";
import { Stack } from "@mui/material";
import { EventsNoResultsOverlay } from "src/components/NoResultsOverlay/NoResultsOverlay";
import { TileLayout } from "src/components/TileLayout/TileLayout";
import { ScrollHeaderLayout } from "src/components/ScrollHeaderLayout/ScrollHeaderLayout";
import { WatchListUserQueryDTO } from "src/models/WatchListUserQueryDTO";
import { useWatchQueryListInfinite } from "src/api/useWatchQueryListInfinite";
import { useListFilter } from "src/utils/useListFilter";
import { useScrollQueryLoader } from "src/utils/useScrollQueryLoader";
import { makeQueryDefinition } from "src/utils/makeQueryDefinition";
import { combineInfiniteTilesData } from "src/utils/combineInfiniteTilesData";
import { WatchQueryTile } from "./WatchQueryTile";
import { WatchQueryTilesProps } from "./WatchQueryTiles.model";
import { makeInfiniteNormalQueries } from "./WatchQueryTiles.utils";

export function WatchQueryTiles({
  id,
  filterText,
  filterQuery,
  dateRange,
  renderHeader,
  hideThumbnail,
  canCreateQuery,
}: WatchQueryTilesProps) {
  const isIOS = window?.navigator?.platform === "iPhone";
  const watchQueryList = useWatchQueryListInfinite({
    params: {
      filters: makeQueryDefinition(filterQuery),
      query: {
        startDate: formatISO(startOfDay(dateRange[0])),
        endDate: formatISO(endOfDay(dateRange[1])),
        includeSummary: true,
      },
    },
  });

  const combinedData = combineInfiniteTilesData(
    makeInfiniteNormalQueries(watchQueryList.data?.pages)
  );

  const filterData = useListFilter(combinedData || [], filterText, ["title"]);

  const isLoading = watchQueryList.isLoading || watchQueryList.isFetching;

  const renderItem = (item: WatchListUserQueryDTO) => {
    const queries = combinedData || [];

    const query = queries.find((query) => {
      // FIXME: currently clusters are not shown in the list
      // return item.clusterId
      //   ? query.id === item.clusterId
      //   : query.id === item.id;

      return query.id === item.id;
    });

    if (!query) {
      return "Missing query";
    }

    const alerts = query.alerts || [];

    return (
      <WatchQueryTile
        key={item.id}
        query={item}
        alerts={alerts}
        hideThumbnail={hideThumbnail}
        canCreateQuery={canCreateQuery}
      />
    );
  };

  const handleScroll = useScrollQueryLoader({
    query: watchQueryList,
  });

  return (
    <ScrollHeaderLayout
      id="watch-list-tiles"
      onScroll={handleScroll}
      renderHeader={renderHeader}
      isLoading={isLoading}
      children={
        <Stack
          pl={2}
          pr={isIOS ? 2 : 1}
          flex={1}
          pb={1}
          children={
            <TileLayout<WatchListUserQueryDTO>
              rows={filterData}
              renderItem={renderItem}
              renderEmpty={EventsNoResultsOverlay}
            />
          }
        />
      }
    />
  );
}
