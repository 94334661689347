import { useState } from "react";
import {
  Alert,
  alertClasses,
  Box,
  InputAdornment,
  Link,
  Snackbar,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useRSSLink } from "src/api/useRSSLink";
import { LoadingButton } from "src/components/buttons/LoadingButton";
import { DialogBase } from "src/components/DialogBase/DialogBase";
import { DialogBaseProps } from "src/components/DialogBase/DialogBase.model";
import { TextInputBase } from "src/components/TextInputBase";
import { useOpenState } from "src/utils/useOpenState";

type GenerateRSSLinkDialogProps = Omit<
  DialogBaseProps,
  "title" | "onSubmit" | "onClose"
> & {
  onClose: () => void;
};

export function GenerateRSSLinkDialog({
  onClose,
  ...props
}: GenerateRSSLinkDialogProps) {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const [rssLinkValue, setRssLinkValue] = useState<undefined | string>(
    undefined
  );

  const shareLinkSnackBar = useOpenState();

  const generateRSSLink = useRSSLink({
    options: {
      onSuccess: (data: string) => {
        setRssLinkValue(data);
      },
    },
  });
  const handleGenerateRSSLink = () => generateRSSLink.mutate({});
  const { isLoading } = generateRSSLink;

  const copyLinkToClipboard = async (instantLinkValue: string) => {
    if (!navigator.clipboard) {
      console.error("browser does not support navigation.clipboard");
    }
    await navigator.clipboard.writeText(instantLinkValue);
    onClose();
    shareLinkSnackBar.show();
  };

  return (
    <>
      <DialogBase
        title="Generate RSS link for Watchlist"
        maxWidth="sm"
        extraMobileMargin={3}
        disableHeaderDivider
        {...props}
        children={
          <Stack pt={1}>
            <Box>
              <TextInputBase
                id="rss-link-input"
                label="RSS link"
                fullWidth
                size="small"
                placeholder="RSS link"
                value={rssLinkValue || ""}
                InputProps={{
                  readOnly: true,
                  sx: {
                    pr: 0,
                    mb: isMobile ? 2 : 3,
                    borderTopRightRadius: "8px",
                    borderBottomRightRadius: "8px",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <LoadingButton
                        variant="contained"
                        color="info"
                        sx={{
                          width: isMobile ? 100 : 125,
                          height: 40,
                          borderBottomLeftRadius: 0,
                          borderTopLeftRadius: 0,
                        }}
                        loading={!isMobile && isLoading}
                        disabled={isLoading}
                        onClick={
                          rssLinkValue
                            ? () => copyLinkToClipboard(rssLinkValue)
                            : () => handleGenerateRSSLink()
                        }
                      >
                        {!isMobile && !isLoading && (
                          <Link fontSize="small" sx={{ mr: 1 }} />
                        )}
                        {rssLinkValue ? "Copy link" : "Generate"}
                      </LoadingButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <Alert severity="info">
              <Typography variant="body2" color="text.primary">
                Copy this link and add it to your RSS reader for real-time
                updates on media mentions.
              </Typography>
            </Alert>
          </Stack>
        }
        footer={
          <Stack direction={isMobile ? "column" : "row-reverse"} width="100%">
            <LoadingButton
              variant="contained"
              color="primary"
              sx={{
                width: isMobile ? 132 : 84,
                ml: isMobile ? 0 : 1,
              }}
              disabled={isLoading}
              onClick={onClose}
            >
              Done
            </LoadingButton>
          </Stack>
        }
      />
      <Snackbar
        open={shareLinkSnackBar.isOpen}
        autoHideDuration={5000}
        onClose={shareLinkSnackBar.hide}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          severity="success"
          variant="filled"
          onClose={shareLinkSnackBar.hide}
          sx={{
            borderRadius: "4px",
            alignItems: "center",
            [`.${alertClasses.action}`]: {
              pt: 0,
            },
          }}
        >
          The link to the RSS Feed was successfully copied to the clipboard
        </Alert>
      </Snackbar>
    </>
  );
}
