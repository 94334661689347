import { CircularProgress, Stack, Typography } from "@mui/material";

export function TileLayoutLoading() {
  return (
    <Stack flex={1} justifyContent="center" alignItems="center">
      <CircularProgress size="24px" />
      <Typography align="center" variant="h6" children="Loading..." />
    </Stack>
  );
}
