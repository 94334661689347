import { create } from "zustand";
import { isWatchQueryFilterValues } from "src/components/WatchQueryKeywordForm/WatchQueryKeyword.guard";
import { PowerSearchQueryKeywordValues } from "src/api/usePowerSearchQueryKeyword";
import { queryAdvertisementValues } from "src/models/Advertisement";

type WatchTermResultsQueryKeywordValues = Omit<
  PowerSearchQueryKeywordValues,
  "displayName" | "sourcesExclude"
>;

type WatchTermResultsQueryState = {
  data: WatchTermResultsQueryKeywordValues;
  save: (data: WatchTermResultsQueryKeywordValues) => void;
};

const watchQueryFilters = "watchQueryFilters";

const parseWatchTermResultsQueryKeyword =
  (): WatchTermResultsQueryKeywordValues => {
    try {
      const json = localStorage.getItem(watchQueryFilters);
      const query = json && JSON.parse(json);

      if (isWatchQueryFilterValues(query)) {
        return query;
      }
    } catch (e) {
      console.error(`Unable to parse saved query`, e);
    }

    return {
      // terms
      termsInclude: {
        list: [],
        logic: "or",
      },
      termsExclude: {
        list: [],
        logic: "or",
      },
      // terms

      // sources
      sourcesInclude: {
        list: [],
        logic: "or",
      },
      // sources

      // program
      programInclude: {
        list: [],
        logic: "or",
      },
      programExclude: {
        list: [],
        logic: "or",
      },
      // program

      advertisement: queryAdvertisementValues.all,
    };
  };

export const useWatchQueryFilter = create<WatchTermResultsQueryState>(
  (setState) => ({
    data: parseWatchTermResultsQueryKeyword(),
    save: (data) => {
      const json = JSON.stringify(data);
      localStorage.setItem(watchQueryFilters, json);
      setState({ data });
    },
  })
);
