import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import {
  Alert,
  alertClasses,
  Box,
  Collapse,
  Divider,
  Paper,
  Snackbar,
  Stack,
} from "@mui/material";
import { differenceInSeconds } from "date-fns";
import { components } from "@tveyes/twosionwebapischema";
import { useClipSave } from "src/api/useClipSave";
import {
  useShareEventFormSnackbar,
  useShareEventLinkSnackbar,
} from "src/api/useShareEventSnackbar";
import { AppTopNavBarMobile } from "src/components/AppTopNavBarMobile/AppTopNavBarMobile";
import { ClipViewer } from "src/components/ClipViewer/ClipViewer";
import { PageHeaderDesktop } from "src/components/PageHeaderDesktop/PageHeaderDesktop";
import { TranscriptView } from "src/components/TranscriptView/TranscriptView";
import { EventDetails } from "src/models/EventDetails";
import { makeOffsetsFromHighlights } from "src/utils/makeOffsetsFromHighlights";
import { useClipViewLayout } from "src/utils/useClipViewLayout";
import { useIsMobile } from "src/utils/useIsMobile";
import { useOpenState } from "src/utils/useOpenState";
import { formatDateTimeWithoutTimeZone } from "src/utils/formatDateTimeWithoutTimeZone";
import { ShiftPlayerDataProps } from "src/pages/WatchListTermResultClipEditor/WatchListTermResultClipEditor.page";
import { EventType } from "src/models/EventType";
import { useSessionContext } from "src/api/useSessionContext";
import { makeDates } from "./ClipEditorPage.utils";
import { ClipEditTabsMobile } from "../ClipEditTabsMobile/ClipEditTabsMobile";
import { ClipEditFooterMobile } from "../ClipEditFooterMobile/ClipEditFooterMobile";
import { ClipEditorToolBarDesktop } from "../ClipEditorToolBarDesktop/ClipEditorToolBarDesktop";
import { ClipEditorTrim } from "../ClipEditorTrim/ClipEditorTrim";
import { PageLayoutDesktop } from "../PageLayoutDesktop";
import { PageLayoutMobile } from "../PageLayoutMobile";
import { ShiftPlayerDrawer } from "../ShiftPlayerDrawer/ShiftPlayerDrawer";
import { ShiftPlayerDrawerMobile } from "../ShiftPlayerDrawerMobile/ShiftPlayerDrawerMobile";
import {
  TranscriptLineBundleViewLabelLayout,
  TranscriptLineBundleViewTrimShowMode,
} from "../TranscriptLineBundleView/TranscriptLineBundleView.const";
import { TranscriptViewProps } from "../TranscriptView/TranscriptView.model";
import { useTrimRangeErrorMessage } from "./hook/useTrimRangeErrorMessage";
import { useTrimRangeState } from "./hook/useTrimRangeState";
import { ShareInstantPlayDialog } from "../ShareInstantPlayDialog/ShareInstantPlayDialog";
import { SnackbarBase } from "../SnackbarBase/SnackbarBase";

export const ClipEditorPage = ({
  event,
  query,
  loading,
  routeRoot,
  minSpanSec = 2,
  defaultSpanSec = 10,
  onShiftPlayerPlay,
  initialStartDateTime,
  shiftOffset,
}: {
  routeRoot: "Watchlist" | "PowerSearch" | "Date/Time search" | "Snapshots";
  event: EventDetails;
  query?: components["schemas"]["QueryDefinition"];
  loading?: boolean;
  minSpanSec?: number;
  defaultSpanSec?: number;
  onShiftPlayerPlay?: (data: ShiftPlayerDataProps) => void;
  initialStartDateTime?: string;
  shiftOffset?: number;
}) => {
  const clipLayout = useClipViewLayout();
  const isMobile = useIsMobile();
  const [autoScroll, setAutoScroll] = useState(true);
  const [mobileClipViewCollapsed, setMobileClipViewCollapsed] = useState(false);

  const shareDialog = useOpenState();
  const shareFormSnackBar = useShareEventFormSnackbar();
  const shareLinkSnackBar = useShareEventLinkSnackbar();

  const rightSideDrawerState = useOpenState();
  const [shiftPlayerOffset, setShiftPlayerOffset] = useState(0);
  const [collapsedMobileVersion, setCollapsedMobileVersion] = useState(false);

  const [isPreviewing, setPreviewing] = useState(false);
  const [offsetMs, setOffsetMs] = useState(0);
  const [seekToMs, setSeekToMs] = useState(0);
  const { effectiveEntitlements } = useSessionContext();
  const enableMediaDownload = effectiveEntitlements.enableMediaDownloads?.value;

  const isFeatureShiftPlayerAvailable = Boolean(onShiftPlayerPlay);
  const hasShiftPlayer =
    event.eventType === EventType.BCast || event.eventType === EventType.Radio;
  const isShiftPlayerFeatureAvailable =
    isFeatureShiftPlayerAvailable && hasShiftPlayer;

  const clipSave = useClipSave({ event });

  const eventDateRange: [Date, Date] | null = useMemo(() => {
    const nextTrimStartDate = event.startDateTime
      ? new Date(event.startDateTime)
      : null;

    const nextTrimEndDate = event.endDateTime
      ? new Date(event.endDateTime)
      : null;

    if (nextTrimStartDate && nextTrimEndDate) {
      return [nextTrimStartDate, nextTrimEndDate];
    }

    return null;
  }, [event.endDateTime, event.startDateTime]);

  const [trimRange, updateTrimRange, setTrimRange] = useTrimRangeState({
    bounds: eventDateRange,
    minSpanSec,
  });

  const dateRangeErrorMessages = useTrimRangeErrorMessage({
    event,
    range: trimRange,
  });

  useEffect(() => {
    // update trim bounds to event bounds when event changes
    if (eventDateRange) {
      setTrimRange(eventDateRange);
    } else {
      setTrimRange([null, null]);
    }
  }, [eventDateRange, setTrimRange]);

  const handleSaveToMediaCenter = useCallback(
    (clipTitle: string) => {
      clipSave.mutate({
        event,
        clipTitle,
        archive: true,
        trimRange,
      });
    },
    [clipSave, event, trimRange]
  );

  const handleDownload = useCallback(() => {
    clipSave.mutate({
      event,
      trimRange,
    });
  }, [clipSave, event, trimRange]);

  const toolbarDesktop = useMemo(
    () => (
      <ClipEditorToolBarDesktop
        event={event}
        handleSaveToMediaCenter={handleSaveToMediaCenter}
        handleDownload={handleDownload}
        disabled={!enableMediaDownload || dateRangeErrorMessages.some((v) => v)}
        enableMediaDownload={enableMediaDownload}
        showShareDialog={shareDialog.show}
      />
    ),
    [
      dateRangeErrorMessages,
      handleDownload,
      handleSaveToMediaCenter,
      event,
      enableMediaDownload,
      shareDialog.show,
    ]
  );

  const dateTimeLabel = event.startDateTime
    ? new Date(event.startDateTime).toLocaleTimeString()
    : " - ";

  const titleLabel = loading
    ? "Loading..."
    : isMobile
    ? [dateTimeLabel, event.source, event.title].filter(Boolean).join(" - ")
    : [dateTimeLabel, event.title].filter(Boolean).join(" ");

  const handleCurrentTimeChange = useCallback(
    (_nextId: string, nextTime: number) => {
      setOffsetMs(nextTime);
    },
    [setOffsetMs]
  );

  const handleParagraphSetStart = useCallback<
    NonNullable<TranscriptViewProps["onClickParagraphSetStart"]>
  >(
    (nextTrimStartDate) =>
      setTrimRange((prevTrimRange) => [nextTrimStartDate, prevTrimRange[1]]),
    [setTrimRange]
  );

  const handleParagraphSetEnd = useCallback<
    NonNullable<TranscriptViewProps["onClickParagraphSetEnd"]>
  >(
    (nextTrimEndDate) =>
      setTrimRange((prevTrimRange) => [prevTrimRange[0], nextTrimEndDate]),
    [setTrimRange]
  );

  const handleTrimRangeChange = useCallback(
    (inOffset: number, outOffset: number) => {
      if (!eventDateRange) {
        return;
      }

      updateTrimRange({
        offsetInSec: inOffset,
        offsetOutSec: outOffset,
      });
    },
    [eventDateRange, updateTrimRange]
  );

  const handleInTrimChange = useCallback(
    (offset: number) => {
      if (!eventDateRange) {
        return;
      }

      updateTrimRange({
        offsetInSec: offset,
      });
    },
    [eventDateRange, updateTrimRange]
  );

  const handleOutTrimChange = useCallback(
    (offset: number) => {
      if (!eventDateRange) {
        return;
      }

      updateTrimRange({
        offsetOutSec: offset,
      });
    },
    [eventDateRange, updateTrimRange]
  );

  useEffect(() => {
    if (isShiftPlayerFeatureAvailable) return;
    const offsets = makeOffsetsFromHighlights({
      highlights: event.highlights || [],
    });

    const firstOffset = offsets.length ? offsets[0] : null;

    if (firstOffset) {
      setSeekToMs(firstOffset * 1000);
      const nextTrimStartOffset = firstOffset - defaultSpanSec / 2;
      const nextTrimEndOffset = firstOffset + defaultSpanSec / 2;

      handleTrimRangeChange(nextTrimStartOffset, nextTrimEndOffset);
    }
  }, [
    event,
    handleTrimRangeChange,
    defaultSpanSec,
    isShiftPlayerFeatureAvailable,
  ]);

  const handlePreviewEnd = useCallback(() => {
    setPreviewing(false);
  }, []);

  const toggleMobileClipViewHeight = useCallback(() => {
    setMobileClipViewCollapsed(!mobileClipViewCollapsed);
  }, [setMobileClipViewCollapsed, mobileClipViewCollapsed]);

  const onTimeExtensionClick = useCallback(
    (currentTime: number, v?: string) => {
      if (!v && isMobile) {
        setCollapsedMobileVersion((prevState) => !prevState);
        toggleMobileClipViewHeight();
      }

      if (!v) {
        !rightSideDrawerState.isOpen && setShiftPlayerOffset(currentTime);

        return rightSideDrawerState.isOpen
          ? rightSideDrawerState.hide()
          : rightSideDrawerState.show();
      }

      if (!event.endDateTime || !event.startDateTime || !onShiftPlayerPlay)
        return;

      const data = makeDates(
        new Date(event.startDateTime),
        new Date(event.endDateTime),
        v
      );
      onShiftPlayerPlay(data);
    },
    [
      event,
      onShiftPlayerPlay,
      rightSideDrawerState,
      isMobile,
      toggleMobileClipViewHeight,
    ]
  );

  const clipViewer = useMemo(() => {
    const trimStartOffset =
      event.startDateTime && trimRange?.[0]
        ? differenceInSeconds(trimRange[0], new Date(event.startDateTime))
        : 0;

    const trimEndOffset =
      event.startDateTime && trimRange?.[1]
        ? differenceInSeconds(trimRange[1], new Date(event.startDateTime))
        : 0;

    return (
      <ClipViewer
        id="clip-viewer"
        loading={loading}
        event={event}
        onCurrentTimeChange={handleCurrentTimeChange}
        onPreviewEnd={handlePreviewEnd}
        onChangePlayState={(_instanceId, isPlaying) => {
          if (isPreviewing) {
            setPreviewing(isPlaying);
          }
        }}
        seekOffsetMs={seekToMs}
        shiftOffset={shiftOffset}
        hotStart
        editInOffset={
          // FIXME: this is a bug in MediaViewport 0.0.116 so it does not respect zero In offset
          trimStartOffset === 0 ? Number.MIN_VALUE : trimStartOffset
        }
        editOutOffset={trimEndOffset}
        onEditInChange={handleInTrimChange}
        onEditOutChange={handleOutTrimChange}
        onEditRangeChange={handleTrimRangeChange}
        play={isPreviewing}
        playOnDragEnd={false}
        isPreviewing={isPreviewing}
        viewMode={mobileClipViewCollapsed ? "playback" : "full"}
        sx={{
          ".player-container": {
            maxHeight: mobileClipViewCollapsed ? "112px" : undefined,
          },
        }}
        showTimeExtensionControls={isShiftPlayerFeatureAvailable}
        onTimeExtensionClick={onTimeExtensionClick}
        timeExtensionSeconds={240}
      />
    );
  }, [
    event,
    trimRange,
    loading,
    handleCurrentTimeChange,
    handlePreviewEnd,
    seekToMs,
    handleInTrimChange,
    handleOutTrimChange,
    handleTrimRangeChange,
    isPreviewing,
    onTimeExtensionClick,
    isShiftPlayerFeatureAvailable,
    shiftOffset,
    mobileClipViewCollapsed,
  ]);

  const headerDesktop = useMemo(
    () => (
      <PageHeaderDesktop
        title={titleLabel}
        toolbar={toolbarDesktop}
        breadcrumbLabels={{
          "3": routeRoot,
          "2": "Results",
          "1": loading ? "Loading..." : event.title || " - ",
        }}
      />
    ),
    [event, routeRoot, toolbarDesktop, titleLabel, loading]
  );

  const transcriptViewDesktop = useMemo(
    () => (
      <TranscriptView
        offset={offsetMs}
        event={event}
        loading={loading}
        hideAutoScrollSwitch={isMobile}
        overflow="scroll"
        position="absolute"
        height="100%"
        width="100%"
        autoScroll={isMobile || autoScroll}
        showTimeLabel={TranscriptLineBundleViewLabelLayout.top}
        showTrimToolBar={TranscriptLineBundleViewTrimShowMode.hover}
        onAutoScrollChange={setAutoScroll}
        onClickLineBlock={setSeekToMs}
        onClickParagraphLabel={setSeekToMs}
        onClickParagraphSetStart={handleParagraphSetStart}
        onClickParagraphSetEnd={handleParagraphSetEnd}
      />
    ),
    [
      offsetMs,
      event,
      loading,
      isMobile,
      autoScroll,
      setSeekToMs,
      handleParagraphSetStart,
      handleParagraphSetEnd,
    ]
  );

  const transcriptViewMobile = useMemo(
    () => (
      <TranscriptView
        offset={offsetMs}
        event={event}
        loading={loading}
        hideAutoScrollSwitch
        overflow="scroll"
        maxWidth={600}
        height="100%"
        mx="auto"
        autoScroll
        showTimeLabel={TranscriptLineBundleViewLabelLayout.top}
        showTrimToolBar={TranscriptLineBundleViewTrimShowMode.hover}
        onAutoScrollChange={setAutoScroll}
        onClickLineBlock={setSeekToMs}
        onClickParagraphLabel={setSeekToMs}
        onClickParagraphSetStart={handleParagraphSetStart}
        onClickParagraphSetEnd={handleParagraphSetEnd}
      />
    ),
    [
      offsetMs,
      event,
      loading,
      setSeekToMs,
      handleParagraphSetStart,
      handleParagraphSetEnd,
    ]
  );

  const clipEditorTrim = useMemo(() => {
    return (
      eventDateRange && (
        <ClipEditorTrim
          value={trimRange}
          bounds={eventDateRange}
          minSpanSec={minSpanSec}
          offsetSec={offsetMs / 1000}
          onChange={setTrimRange}
          errors={dateRangeErrorMessages}
          isPreviewing={isPreviewing}
          isMobile={isMobile}
          onClickPreview={() => setPreviewing((prev) => !prev)}
        />
      )
    );
  }, [
    trimRange,
    eventDateRange,
    offsetMs,
    isPreviewing,
    isMobile,
    setTrimRange,
    dateRangeErrorMessages,
    minSpanSec,
  ]);

  const commonContent = useMemo(
    () => (
      <Fragment>
        <ShareInstantPlayDialog
          open={shareDialog.isOpen}
          event={event}
          startDateTime={
            trimRange[0]
              ? formatDateTimeWithoutTimeZone(trimRange[0])
              : event.startDateTime
          }
          endDateTime={
            trimRange[1]
              ? formatDateTimeWithoutTimeZone(trimRange[1])
              : event.endDateTime
          }
          onClose={shareDialog.hide}
          onSubmit={shareFormSnackBar.show}
          copyShareLinkCallback={shareLinkSnackBar.show}
        />
        <Snackbar
          open={shareLinkSnackBar.isOpen}
          autoHideDuration={5000}
          onClose={shareLinkSnackBar.hide}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            severity="success"
            variant="filled"
            onClose={shareLinkSnackBar.hide}
            sx={{
              borderRadius: "4px",
              alignItems: "center",
              [`.${alertClasses.action}`]: {
                pt: 0,
              },
            }}
          >
            The link to the Event was successfully copied to the clipboard
          </Alert>
        </Snackbar>

        <SnackbarBase
          open={shareFormSnackBar.isOpen}
          onClose={shareFormSnackBar.hide}
          title="Your share is being processed."
          subtitle="You and your recipients will get an email."
        />
      </Fragment>
    ),
    [event, shareDialog, trimRange, shareFormSnackBar, shareLinkSnackBar]
  );

  const mobileContent = useMemo(
    () => (
      <Stack height="100%" overflow="hidden">
        <Collapse in={!collapsedMobileVersion}>
          <Stack
            minHeight={56}
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <AppTopNavBarMobile edited title={titleLabel} ml={0.5} />
          </Stack>
        </Collapse>

        {clipViewer}
        <Divider />
        {hasShiftPlayer && (
          <ShiftPlayerDrawerMobile
            open={rightSideDrawerState.isOpen}
            onClose={() => {
              rightSideDrawerState.hide();
              setCollapsedMobileVersion((prevState) => !prevState);
              toggleMobileClipViewHeight();
            }}
            onOpen={rightSideDrawerState.show}
            event={event}
            query={query}
            onShiftPlayerPlay={onShiftPlayerPlay}
            bounds={eventDateRange}
            minSpanSec={minSpanSec}
            trimRange={trimRange}
            setTrimRange={setTrimRange}
            mode="edit"
            initialStartDateTime={initialStartDateTime}
            shiftPlayerOffset={shiftPlayerOffset}
            offset={offsetMs}
          />
        )}

        <ClipEditTabsMobile
          transcript={transcriptViewMobile}
          trim={clipEditorTrim}
        />

        <ClipEditFooterMobile
          handleSaveToMediaCenter={handleSaveToMediaCenter}
          handleDownload={handleDownload}
          showShareDialog={shareDialog.show}
          disabled={
            !enableMediaDownload || dateRangeErrorMessages.some((v) => !!v)
          }
          event={event}
        />

        {commonContent}
      </Stack>
    ),
    [
      titleLabel,
      clipViewer,
      clipEditorTrim,
      transcriptViewMobile,
      dateRangeErrorMessages,
      handleSaveToMediaCenter,
      handleDownload,
      rightSideDrawerState,
      event,
      query,
      hasShiftPlayer,
      onShiftPlayerPlay,
      eventDateRange,
      minSpanSec,
      setTrimRange,
      trimRange,
      initialStartDateTime,
      shiftPlayerOffset,
      offsetMs,
      collapsedMobileVersion,
      toggleMobileClipViewHeight,
      enableMediaDownload,
      shareDialog.show,
      commonContent,
    ]
  );

  const desktopContent = useMemo(
    () => (
      <Stack direction="row" columnGap={3}>
        <Stack
          direction="column"
          flex={clipLayout.main.flex}
          flexBasis={clipLayout.main.flexBasis}
          overflow="hidden"
          rowGap={3}
        >
          <Stack borderRadius={2} component={Paper} overflow="hidden">
            {clipViewer}
          </Stack>
          <Stack px={3} pt={3} pb={1} component={Paper} minHeight={70}>
            {clipEditorTrim}
          </Stack>
        </Stack>

        <Box
          flex={clipLayout.right.flex}
          flexBasis={clipLayout.right.flexBasis}
          display="flex"
          position="relative"
          overflow="hidden"
          component={Paper}
          children={transcriptViewDesktop}
        />
        {hasShiftPlayer && (
          <ShiftPlayerDrawer
            initialStartDateTime={initialStartDateTime}
            query={query}
            open={rightSideDrawerState.isOpen}
            onClose={rightSideDrawerState.hide}
            onShiftPlayerPlay={onShiftPlayerPlay}
            setTrimRange={setTrimRange}
            bounds={eventDateRange}
            minSpanSec={minSpanSec}
            event={event}
            trimRange={trimRange}
            mode="edit"
            shiftPlayerOffset={shiftPlayerOffset}
            offset={offsetMs}
          />
        )}
        {commonContent}
      </Stack>
    ),
    [
      clipViewer,
      clipLayout,
      clipEditorTrim,
      rightSideDrawerState,
      event,
      query,
      transcriptViewDesktop,
      onShiftPlayerPlay,
      initialStartDateTime,
      eventDateRange,
      minSpanSec,
      setTrimRange,
      trimRange,
      hasShiftPlayer,
      shiftPlayerOffset,
      offsetMs,
      commonContent,
    ]
  );

  if (isMobile) {
    return <PageLayoutMobile hideTopBar content={mobileContent} />;
  }

  return <PageLayoutDesktop header={headerDesktop} content={desktopContent} />;
};
