import { create } from "zustand";
import { queryAdvertisementValues } from "src/models/Advertisement";
import { EventSourceOption } from "src/api/useEventSources";
import { Advertisement } from "src/models/Advertisement";
import { TagPickerValue } from "src/components/TagPicker/TagPicker.model";
import { ProgramTagPickerValue } from "src/components/ProgramTagPicker/ProgramPicker.model";
import { isWatchQueryCustomValues } from "src/components/WatchQueryCustomForm/WatchQueryCustom.guard";

// use WatchQueryCustomFormValues
export type PowerSearchQueryCustomValues = {
  customQuery: string;

  displayName: string;

  sourcesInclude: TagPickerValue<EventSourceOption>;
  sourcesExclude: TagPickerValue<EventSourceOption>;

  advertisement: Advertisement;

  programInclude: ProgramTagPickerValue;
  programExclude: ProgramTagPickerValue;
};

type PowerSearchQueryCustomState = {
  data: PowerSearchQueryCustomValues;
  save: (data: PowerSearchQueryCustomValues) => void;
};

const powerSearchQueryKey = "powerSearchQuery";

const parsePowerSearchQueryCustom = (): PowerSearchQueryCustomValues => {
  try {
    const json = localStorage.getItem(powerSearchQueryKey);
    const query = json && JSON.parse(json);

    if (isWatchQueryCustomValues(query)) {
      return query;
    }
  } catch (e) {
    console.error(`Unable to parse saved query`, e);
  }

  return {
    displayName: "",

    customQuery: "",

    // sources
    sourcesInclude: {
      list: [],
      logic: "or",
    },
    sourcesExclude: {
      list: [],
      logic: "or",
    },
    // sources

    // program
    programInclude: {
      list: [],
      logic: "or",
    },
    programExclude: {
      list: [],
      logic: "or",
    },
    // program

    advertisement: queryAdvertisementValues.all,
  };
};

export const usePowerSearchQueryCustom = create<PowerSearchQueryCustomState>(
  (setState) => ({
    data: parsePowerSearchQueryCustom(),
    save: (data) => {
      const json = JSON.stringify(data);
      localStorage.setItem(powerSearchQueryKey, json);
      setState({ data });
    },
  })
);
