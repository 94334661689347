import { useEffect } from "react";
import { useForm } from "react-hook-form";
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";
import { useDebounce } from "use-debounce";
import { useValidateCustomQueryBasic } from "src/api/useValidateCustomQueryBasic";
import { useCustomQueryValidationData } from "src/api/useCustomQueryValidationData";
import { WatchQueryCustomFormValues } from "../WatchQueryCustomForm/WatchQueryCustomForm.model";
import { emptyWatchQueryCustomFormValues } from "../WatchQueryCustomForm/WatchQueryCustomForm.hook";

type UseWatchQueryFormArgs = {
  initial?: WatchQueryCustomFormValues;
  schema: Joi.Schema;
};

export function useWatchListCreateCustomForm(params?: UseWatchQueryFormArgs) {
  const { initial, schema } = params || {};
  const formHook = useForm<WatchQueryCustomFormValues>({
    mode: "all",
    reValidateMode: "onChange",
    resolver: schema ? joiResolver(schema) : undefined,
    defaultValues: initial || emptyWatchQueryCustomFormValues,
  });

  const { formState, watch, setValue, setError, clearErrors, trigger } =
    formHook;
  const { dirtyFields } = formState;

  const { customQuery } = watch();
  const [debouncedCustomQuery] = useDebounce(customQuery, 500);

  const basicValData = useValidateCustomQueryBasic({
    customQuery: debouncedCustomQuery || "",
  });

  useEffect(() => {
    if (
      (basicValData.data && !basicValData.data?.isValid) ||
      basicValData.isLoading
    ) {
      setError("customQuery", {
        type: "custom",
      });
    }
    if (basicValData.data?.isValid) {
      clearErrors("customQuery");
      trigger();
    }
  }, [
    setError,
    basicValData.data,
    basicValData.isLoading,
    clearErrors,
    trigger,
  ]);

  const { validationData } = useCustomQueryValidationData();

  useEffect(() => {
    if (!validationData.isValid) {
      setError("customQuery", {
        type: "custom",
        message: validationData.message || "",
      });
    } else {
      clearErrors("customQuery");
      trigger();
    }
  }, [
    setError,
    validationData.isValid,
    validationData.message,
    clearErrors,
    trigger,
  ]);

  useEffect(() => {
    if (
      validationData.isValid &&
      validationData.titleSuggestions?.length &&
      !dirtyFields.displayName
    ) {
      const suggestedDisplayName = validationData.titleSuggestions[0];
      if (suggestedDisplayName) {
        setValue("displayName", suggestedDisplayName, { shouldValidate: true });
      }
    }
  }, [validationData, dirtyFields.displayName, setValue]);

  return formHook;
}
