import { useState, useEffect, FormEvent, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Accordion,
  AccordionSummary,
  Alert,
  AlertColor,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  Snackbar,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import {
  DataGridPremium,
  GridColDef,
  GridValueGetterParams,
  GridCellParams,
  GridRenderCellParams,
} from "@mui/x-data-grid-premium";
import { components, paths } from "@tveyes/twosionwebapischema";
import { useLocalStorageFlags } from "src/utils/useLocalStorageFlags";

type UserPagedResultSet = components["schemas"]["UserPagedResultSet"];
type AccountPagedResultSet = components["schemas"]["AccountDTOPagedResultSet"];
type ErrorDetails = components["schemas"]["ErrorDetails"];
type SessionInformation = components["schemas"]["SessionInformation"];

const userGrid: GridColDef[] = [
  { field: "id", flex: 1 },
  { field: "accountId", flex: 1 },
  { field: "email", flex: 1 },
  {
    field: "name",
    flex: 1,
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.firstName || ""} ${params.row.lastName || ""}`,
  },
  {
    field: "impersonate",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Button size="small" variant="contained">
          Impersonate User
        </Button>
      );
    },
  },
  { field: "modfiedDate", flex: 0.75 },
  { field: "language", flex: 0.5 },
  {
    field: "roles",
    flex: 1,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.roles ? params.row.roles.join(",") : "no roles set",
  },
  { field: "lastBrowserLocation", flex: 0.5 },
  { field: "timeZone", flex: 0.5 },
  {
    field: "contentAccessFilter",
    flex: 1,
    valueGetter: (params: GridValueGetterParams) =>
      JSON.stringify(params.row.contentAccessFilter),
  },
];

const accountGrid: GridColDef[] = [
  { field: "id", flex: 1 },
  { field: "name", flex: 1 },
  { field: "expirationDate", flex: 1 },
  { field: "accountManager", flex: 1 },
  { field: "active", flex: 0.5 },
  { field: "isDeleted", flex: 0.5 },
  {
    field: "contentAccessFilter",
    flex: 1,
    valueGetter: (params: GridValueGetterParams) =>
      JSON.stringify(params.row.contentAccessFilter),
  },
];

export function DevAccounts() {
  const flags = useLocalStorageFlags();
  const [userPosition, setUserPosition] = useState<GeolocationPosition>();
  const [userPositionError, setUserPositionError] =
    useState<GeolocationPositionError>();
  const [userList, setUserList] = useState<UserPagedResultSet>();
  const [accountList, setAccountList] = useState<AccountPagedResultSet>();
  const [sessionInfo, setSessionInfo] = useState<SessionInformation>();
  const [activeAccountId, setActiveAccountId] = useState<string>("");

  const [userPaginationModel, setUserPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const userPage = userPaginationModel.page;
  const userPageSize = userPaginationModel.pageSize;

  const [accountPaginationModel, setAccountPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const accountPage = accountPaginationModel.page;
  const accountPageSize = accountPaginationModel.pageSize;

  const [snackMessage, setSnackMessage] = useState<{
    message: string;
    severity: string;
    isOpen: boolean;
  }>({ message: "Default Message", severity: "info", isOpen: false });
  const { getAccessTokenSilently } = useAuth0();

  const enableGeolocationHandler = useCallback(() => {
    if (!navigator.geolocation) {
      console.log("navigator.geolocation not supported");
    } else {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          setUserPosition(position);
        },
        (e: GeolocationPositionError) => {
          setUserPositionError(e);
        }
      );
    }
  }, []);

  // get user location
  useEffect(() => {
    enableGeolocationHandler();
  }, [enableGeolocationHandler]);

  const getSession = useCallback(async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const getUserSession = async function () {
        const response: paths["/api/Session"]["get"]["responses"][200]["content"]["application/json"] =
          await fetch("/api/Session", {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }).then((res) => res.json());

        setSessionInfo(response);
      };

      return await getUserSession();
    } catch (e) {
      return "error";
    }
  }, [getAccessTokenSilently]);

  // get session api
  useEffect(() => {
    getSession();
  }, [getAccessTokenSilently, getSession]);

  // get user list from api
  const getUserData = useCallback(async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const getUserList = async function () {
        const response: paths["/api/Users"]["get"]["responses"][200]["content"]["application/json"] =
          await fetch(
            `/api/Users?from=${userPage * userPageSize}&size=${userPageSize}`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          ).then((res) => res.json());

        setUserList(response);
      };

      return await getUserList();
    } catch (e) {
      return "error";
    }
  }, [getAccessTokenSilently, userPage, userPageSize]);

  useEffect(() => {
    getUserData();
  }, [userPage, userPageSize, getUserData]);

  // get account list from api
  const getAccountData = useCallback(async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const getAccountList = async function () {
        const response: paths["/api/Accounts"]["get"]["responses"][200]["content"]["application/json"] =
          await fetch(
            `/api/Accounts?from=${
              accountPage * accountPageSize
            }&size=${accountPageSize}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
              },
            }
          ).then((res) => res.json());

        // console.log((response as ErrorDetails), (response as AccountPagedResultSet));
        // if ((response as ErrorDetails).statusCode) throw response;
        setAccountList(response);
      };

      return await getAccountList();
    } catch (e) {
      if ((e as ErrorDetails).statusCode) {
        console.log((e as ErrorDetails).message);
      } else {
        console.log(e);
      }
      return "error";
    }
  }, [getAccessTokenSilently, accountPage, accountPageSize]);

  useEffect(() => {
    getAccountData();
  }, [accountPage, accountPageSize, getAccountData]);

  // get User and Account data on mount
  useEffect(() => {
    getUserData();
    getAccountData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // add user
  const addUser = async (e: FormEvent) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      email: { value: string };
      firstName: { value: string };
      lastName: { value: string };
      accountId: { value: string };
    };
    const email = target.email.value;
    const firstName = target.firstName.value;
    const lastName = target.lastName.value;
    const accountId = target.accountId.value;

    console.log("addUser", email, firstName, lastName, accountId);

    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch("/api/User", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ email, firstName, lastName, accountId }),
      });
      const data = await response.json();
      // reload user data
      getUserData();
      console.log(data);
      setSnackMessage({
        ...snackMessage,
        isOpen: true,
        message: "Added New User",
        severity: "success",
      });
    } catch (thrown) {
      setSnackMessage({
        ...snackMessage,
        isOpen: true,
        message: "Could not add User",
        severity: "error",
      });
      console.log(thrown);
    }
  };

  // add account
  const addAccount = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      name: { value: string };
      expirationDate: { value: string };
    };
    const name = target.name.value;
    const expirationDate = `${target.expirationDate.value}T10:00:00.000Z`;
    console.log("addAccount", name, expirationDate);

    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch("/api/Account", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ name, expirationDate }),
      });
      const data = await response.json();
      console.log(data);
      setSnackMessage({
        ...snackMessage,
        isOpen: true,
        message: "Added New Account",
        severity: "success",
      });
    } catch (thrown) {
      setSnackMessage({
        ...snackMessage,
        isOpen: true,
        message: "Could not add Account",
        severity: "error",
      });
      console.log(thrown);
    }
  };

  // impersonate a user
  const impersonateUser = async (userId: string) => {
    console.log("impersonateUser", userId);

    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(`/api/User/${userId}/Impersonate`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = await response.json();
      console.log(data);
    } catch (thrown) {
      console.log(thrown);
    }
  };

  const cancelImpersonation = async () => {
    console.log("cancelImpersonation");
    await impersonateUser("00000000-0000-0000-0000-000000000000");
    getSession();
  };

  const accountListCellClickHandler = (params: GridCellParams) => {
    setActiveAccountId(params.row.id);
  };

  const userListCellClickHandler = async (params: GridCellParams) => {
    console.log("userListCellClickHandler", params.field);
    if (params.field === "impersonate") {
      await impersonateUser(params.row.id);
      getSession();
    }
  };

  const snackClose = () => {
    console.log("snackClose");
    setSnackMessage({ ...snackMessage, isOpen: false });
  };

  const render = () => {
    let geoContent;

    if (userPosition !== undefined) {
      geoContent = (
        <Stack>
          <Typography variant="h5">Geolocation</Typography>
          <code>
            latitude: {userPosition.coords.latitude}, longitude:{" "}
            {userPosition.coords.longitude}
          </code>
          <Button
            onClick={() => enableGeolocationHandler()}
            variant="contained"
          >
            Get User Position
          </Button>
        </Stack>
      );
    } else if (userPositionError !== undefined) {
      geoContent = (
        <Stack>
          <Typography variant="h5">Geolocation</Typography>
          <code>error: {userPositionError.message}</code>
          <Button
            onClick={() => enableGeolocationHandler()}
            variant="contained"
            color="error"
          >
            Cannot Get User Position (Clear Browser Permission)
          </Button>
        </Stack>
      );
    } else {
      geoContent = (
        <Stack>
          <Typography variant="h5">Geolocation</Typography>
          <Button
            onClick={() => enableGeolocationHandler()}
            variant="contained"
          >
            Get User Position
          </Button>
        </Stack>
      );
    }

    return (
      <Stack spacing={2} sx={{ my: 2 }} height="100%" overflow="auto">
        <Stack direction="row">
          <FormControlLabel
            label={
              <span>
                Use <strong>"extend"</strong> flag for Events API calls
              </span>
            }
            sx={{ ml: 1 }}
            control={
              <Switch
                checked={flags.isExtendedEvent}
                onChange={(_e, checked) =>
                  flags.write("isExtendedEvent", checked)
                }
              />
            }
          />
        </Stack>
        {sessionInfo !== undefined && (
          <Accordion defaultExpanded={true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Session Info</Typography>
            </AccordionSummary>
            <Box
              sx={{ border: 1, borderRadius: 2, borderColor: "grey.200", p: 2 }}
            >
              <Typography variant="subtitle2">
                accountId: {sessionInfo.accountId}
              </Typography>
              <Typography variant="subtitle2">
                userId: {sessionInfo.userId}
              </Typography>
              <Typography variant="subtitle2">
                timezone: {sessionInfo.timezone}
              </Typography>
              <Box>
                <Typography variant="subtitle2">
                  isImpersonatingUser:{" "}
                  {sessionInfo.isImpersonatingUser === false ? "false" : "true"}
                </Typography>
                {sessionInfo.isImpersonatingUser === true && (
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => cancelImpersonation()}
                  >
                    Stop Impersonating
                  </Button>
                )}
              </Box>
              <Typography variant="h6">Entitlements</Typography>
              {sessionInfo.effectiveEntitlements?.enableEditWatchList &&
                !sessionInfo.effectiveEntitlements?.enableEditWatchList
                  .value && (
                  <Typography variant="subtitle2">
                    {
                      sessionInfo.effectiveEntitlements.enableEditWatchList
                        .displayName
                    }
                    :{" "}
                    {sessionInfo.effectiveEntitlements.enableEditWatchList.value.toString()}
                  </Typography>
                )}
              {sessionInfo.effectiveEntitlements?.enableAdFiltering &&
                !sessionInfo.effectiveEntitlements?.enableAdFiltering.value && (
                  <Typography variant="subtitle2">
                    {
                      sessionInfo.effectiveEntitlements.enableAdFiltering
                        .displayName
                    }
                    :{" "}
                    {sessionInfo.effectiveEntitlements.enableAdFiltering.value.toString()}
                  </Typography>
                )}
              {sessionInfo.effectiveEntitlements?.enableCustomReportBanner &&
                !sessionInfo.effectiveEntitlements?.enableCustomReportBanner
                  .value && (
                  <Typography variant="subtitle2">
                    {
                      sessionInfo.effectiveEntitlements.enableCustomReportBanner
                        .displayName
                    }
                    :{" "}
                    {sessionInfo.effectiveEntitlements.enableCustomReportBanner.value.toString()}
                  </Typography>
                )}
              {sessionInfo.effectiveEntitlements?.enableMediaDownloads &&
                !sessionInfo.effectiveEntitlements?.enableMediaDownloads
                  .value && (
                  <Typography variant="subtitle2">
                    {
                      sessionInfo.effectiveEntitlements.enableMediaDownloads
                        .displayName
                    }
                    :{" "}
                    {sessionInfo.effectiveEntitlements.enableMediaDownloads.value.toString()}
                  </Typography>
                )}
              {sessionInfo.effectiveEntitlements?.enableReportDownloads &&
                !sessionInfo.effectiveEntitlements?.enableReportDownloads
                  .value && (
                  <Typography variant="subtitle2">
                    {
                      sessionInfo.effectiveEntitlements.enableReportDownloads
                        .displayName
                    }
                    :{" "}
                    {sessionInfo.effectiveEntitlements.enableReportDownloads.value.toString()}
                  </Typography>
                )}
              {sessionInfo.effectiveEntitlements?.enableWatchlistRSS &&
                !sessionInfo.effectiveEntitlements?.enableWatchlistRSS
                  .value && (
                  <Typography variant="subtitle2">
                    {
                      sessionInfo.effectiveEntitlements.enableWatchlistRSS
                        .displayName
                    }
                    :{" "}
                    {sessionInfo.effectiveEntitlements.enableWatchlistRSS.value.toString()}
                  </Typography>
                )}
              {sessionInfo.effectiveEntitlements?.limitItemsPerEmail?.value && (
                <Typography variant="subtitle2">
                  {
                    sessionInfo.effectiveEntitlements.limitItemsPerEmail
                      .displayName
                  }
                  : {sessionInfo.effectiveEntitlements.limitItemsPerEmail.value}
                </Typography>
              )}
              {sessionInfo.effectiveEntitlements?.limitWatchlistSize?.value && (
                <Typography variant="subtitle2">
                  {
                    sessionInfo.effectiveEntitlements.limitWatchlistSize
                      .displayName
                  }
                  : {sessionInfo.effectiveEntitlements.limitWatchlistSize.value}
                </Typography>
              )}
              <Typography variant="h6">effectiveAccessFilter</Typography>
              <code>{JSON.stringify(sessionInfo.effectiveAccessFilter)}</code>
            </Box>
          </Accordion>
        )}
        {userList !== undefined && userList.results && (
          <Box
            sx={{ border: 1, borderRadius: 2, borderColor: "grey.200", p: 2 }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h5">Users</Typography>
              <Typography variant="subtitle2">
                total: {userList.total}
              </Typography>
            </Stack>
            <div style={{ height: 640, width: "100%" }}>
              <DataGridPremium
                rows={userList.results}
                rowCount={userList.total}
                columns={userGrid}
                paginationModel={userPaginationModel}
                onPaginationModelChange={setUserPaginationModel}
                pageSizeOptions={[5, 10, 20, 50, 100]}
                pagination={true}
                paginationMode="server"
                onCellClick={userListCellClickHandler}
              />
            </div>
          </Box>
        )}
        {accountList !== undefined && accountList.results && (
          <Box
            sx={{ border: 1, borderRadius: 2, borderColor: "grey.200", p: 2 }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h5">Accounts</Typography>
              <Typography variant="subtitle2">
                total: {accountList.total}
              </Typography>
            </Stack>
            <div style={{ height: 640, width: "100%" }}>
              <DataGridPremium
                rows={accountList.results}
                rowCount={accountList.total}
                columns={accountGrid}
                paginationModel={accountPaginationModel}
                onPaginationModelChange={setAccountPaginationModel}
                pageSizeOptions={[5, 10, 20, 50, 100]}
                pagination={true}
                paginationMode="server"
                onCellClick={accountListCellClickHandler}
              />
            </div>
          </Box>
        )}
        <Grid2 container spacing={2}>
          <Grid2 xs={12} md={6}>
            <Box
              sx={{ border: 1, borderRadius: 2, borderColor: "grey.200", p: 2 }}
            >
              <Typography variant="h5">Add Account</Typography>
              <form onSubmit={addAccount} name="form-add-account">
                <Stack spacing={4}>
                  <FormControl>
                    <InputLabel>Name</InputLabel>
                    <Input
                      type="text"
                      placeholder="Account Name"
                      required={true}
                      name="name"
                      id="input-account-name"
                    />
                  </FormControl>
                  <FormControl>
                    <InputLabel>Expiration Date</InputLabel>
                    <Input
                      type="date"
                      required={true}
                      name="expirationDate"
                      id="input-account-expiration-date"
                    />
                  </FormControl>
                  <FormControl>
                    <Button type="submit" variant="contained" color="success">
                      Submit
                    </Button>
                  </FormControl>
                </Stack>
              </form>
            </Box>
          </Grid2>
          <Grid2 xs={12} md={6}>
            <Box
              sx={{ border: 1, borderRadius: 2, borderColor: "grey.200", p: 2 }}
            >
              <Typography variant="h5">Add User</Typography>
              <form onSubmit={addUser} name="form-add-user">
                <Stack spacing={4}>
                  <FormControl>
                    <InputLabel>Email</InputLabel>
                    <Input
                      type="email"
                      placeholder="Email Address"
                      required={true}
                      name="email"
                      id="input-user-email"
                    />
                  </FormControl>
                  <FormControl>
                    <InputLabel>First Name</InputLabel>
                    <Input
                      type="text"
                      placeholder="First Name"
                      required={true}
                      name="firstName"
                      id="input-first-name"
                    />
                  </FormControl>
                  <FormControl>
                    <InputLabel>Last Name</InputLabel>
                    <Input
                      type="text"
                      placeholder="Last Name"
                      required={true}
                      name="lastName"
                      id="input-last-name"
                    />
                  </FormControl>
                  <FormControl>
                    <InputLabel>Account Id</InputLabel>
                    <Input
                      type="text"
                      placeholder="Account Id"
                      required={true}
                      name="accountId"
                      value={activeAccountId}
                      id="input-account-id"
                    />
                  </FormControl>
                  <FormControl>
                    <Button type="submit" variant="contained" color="success">
                      Submit
                    </Button>
                  </FormControl>
                </Stack>
              </form>
            </Box>
          </Grid2>
        </Grid2>

        <Box sx={{ border: 1, borderRadius: 2, borderColor: "grey.200", p: 2 }}>
          {geoContent}
        </Box>
        <Snackbar
          open={snackMessage.isOpen}
          autoHideDuration={5000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          message={snackMessage.message}
          onClose={snackClose}
        >
          <Alert
            severity={snackMessage.severity as AlertColor}
            sx={{ width: "100%" }}
            variant="filled"
            elevation={4}
          >
            {snackMessage.message}
          </Alert>
        </Snackbar>
      </Stack>
    );
  };

  return render();
}
