import axios from "axios";
import { UseQueryOptions, useQuery } from "react-query";
import { enqueueSnackbar } from "notistack";
import { useAuth0 } from "@auth0/auth0-react";
import { components, paths } from "@tveyes/twosionwebapischema";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { AccountDTO } from "src/models/AccountDTO";

const apiPath: keyof paths = "/api/Accounts/management-view";
const method: keyof paths[typeof apiPath] = "post";

type AccountItemOriginal = components["schemas"]["AccountManagementViewDTO"];

//FIXME: Update missing property annualizedRecurringRevenue/annualRecurringRevenue
export type AccountItem = Required<
  Omit<AccountItemOriginal, "account"> & {
    account?: AccountDTO;
  }
>;

type Endpoint = Required<paths[typeof apiPath][typeof method]>;
type EndpointResponse =
  Endpoint["responses"]["200"]["content"]["application/json"];
type Parameters = Endpoint["parameters"];

export type RequestAccountsBody =
  Endpoint["requestBody"]["content"]["application/json"];

export const defaultAccountsManagementBody = {
  accountManager: null,
  expirationDateMaximum: null,
  expirationDateMinimum: null,
  orgTypes: null,
  states: null,
  scoreMinimum: 0,
  scoreMaximum: 100,
  search: null,
  accountActivity: [0, 100],
  status: [],
};

export type AccountsManagementResponse = {
  total?: EndpointResponse["total"];
  results?: AccountItem[];
  configuration?: EndpointResponse["configuration"];
};

type RequestOptions = {
  params?: Parameters;
  body: RequestAccountsBody;
};

export const accountsManagementKey = "accountsManagementKey";

export function useAccountsManagement({
  request,
  options,
}: {
  request: RequestOptions;
  options?: UseQueryOptions<AccountsManagementResponse, unknown>;
}) {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery<AccountsManagementResponse, unknown>({
    ...options,
    keepPreviousData: true,
    queryKey: [accountsManagementKey, request],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const url = makeApiUrl<Parameters>(apiPath, request.params);
      const { data } = await axios<AccountsManagementResponse>(url, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: request.body,
      });

      return data;
    },
    onError: (error) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
  });
}
