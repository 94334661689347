import { PropsWithChildren, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Stack } from "@mui/material";
import {
  emptySharedValues,
  PowerSearchSharedValues,
  usePowerSearchSharedForm,
} from "src/api/usePowerSearchSharedForm";
import { getObjectKeys } from "src/utils/getObjectKeys";
import { PowerSearchCustomQueryFormValues } from "./PowerSearchCustomQueryForm.model";

export const PowerSearchCustomQueryFormWrapper = (props: PropsWithChildren) => {
  const { watch, reset, trigger } =
    useFormContext<PowerSearchCustomQueryFormValues>();
  const formValues = watch();

  const { sharedValues, saveSharedValue } = usePowerSearchSharedForm();
  const sharedValuesKeys = getObjectKeys(emptySharedValues);

  useEffect(() => {
    reset({
      ...formValues,
      ...sharedValues,
    });
    trigger(sharedValuesKeys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (type === "change" && name && name in emptySharedValues) {
        const itemValue = value[name as keyof PowerSearchSharedValues];
        saveSharedValue(
          name as keyof PowerSearchSharedValues,
          itemValue as PowerSearchSharedValues[keyof PowerSearchSharedValues]
        );
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, saveSharedValue, sharedValuesKeys]);

  return <Stack>{props.children}</Stack>;
};
