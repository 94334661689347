import { useRef, RefObject, useCallback, MouseEventHandler } from "react";
import { Button, ButtonProps, Tooltip } from "@mui/material";

/**
 *  A simple button dropdown menu. Value is set as button title.
 */
export function OptionCycler<V extends string>({
  value,
  options,
  onChange,
  disabled,
  getOptionLabel,
  onFocus,
  onBlur,
  onClick,
  size,
  displayTooltip,
}: {
  value: V;
  options: V[];
  onChange: (value: V) => void;
  disabled?: ButtonProps["disabled"];
  getOptionLabel?: (option: V) => string;
  onFocus?: ButtonProps["onFocus"];
  onBlur?: ButtonProps["onBlur"];
  onClick?: ButtonProps["onClick"];
  size?: ButtonProps["size"];
  anchorEl?: RefObject<Element>;
  displayTooltip?: boolean;
  disableAutoFocusItem?: boolean;
}) {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const defaultGetOptionLabel = (option: V) => option.toString();

  const valueLabel = getOptionLabel
    ? getOptionLabel(value)
    : defaultGetOptionLabel(value);

  const onClickWrapper: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      const prevIndex = options.indexOf(value);
      const nextIndex = prevIndex + 1 < options.length ? prevIndex + 1 : 0;

      onChange(options[nextIndex]);

      onClick?.(e);
    },
    [onChange, onClick, options, value]
  );

  const optionsButton = (
    <Button
      onClick={onClickWrapper}
      onBlur={onBlur}
      onFocus={onFocus}
      disabled={disabled}
      ref={buttonRef}
      size={size}
      children={valueLabel}
      sx={{
        alignSelf: "stretch",
        borderRadius: 1,
      }}
    />
  );
  return displayTooltip ? (
    <Tooltip
      title={
        'Use "or" for including results that contain any of several keywords. Use "and" for including results with multiple keywords.'
      }
    >
      {optionsButton}
    </Tooltip>
  ) : (
    optionsButton
  );
}
