import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { isAxiosError } from "axios";
import { SingleEventResultItem } from "src/api/useEventDetails";
import { useMediaCenterItemPublic } from "src/api/useMediaCenterItemPublic";
import { mediaCenterClipRoute } from "src/pages/MediaCenterClip/MediaCenterClip.route";
import { PublicEventSingle } from "src/components/PublicEventSingle/PublicEventSingle";
import { PageLoader } from "src/components/PageLoader";
import { PublicMediaCenterItemPathParams } from "./PublicMediaCenterItem.route";
import { ExpiredLinkPage } from "./ExpiredLinkPage";

export function PublicMediaCenterItemPage() {
  const { userId = "", itemId = "" } =
    useParams<PublicMediaCenterItemPathParams>();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth0();

  const mediaCenterItem = useMediaCenterItemPublic({
    request: {
      params: {
        path: {
          userId,
          itemId,
        },
      },
    },
    options: {},
  });

  /**  mediaCenterItemType === "Item" is used for shared media center items */
  /**  mediaCenterItemType === "Clip" is used for event items that are prepared for downloading */
  useEffect(() => {
    if (
      isAuthenticated &&
      mediaCenterItem.data?.isItemOwner &&
      mediaCenterItem.data?.mediaCenterItemType === "Item"
    ) {
      navigate(
        mediaCenterClipRoute.makeUrl({
          eventId: itemId,
        })
      );
    }
  }, [
    isAuthenticated,
    mediaCenterItem.data?.isItemOwner,
    mediaCenterItem.data?.mediaCenterItemType,
    itemId,
    navigate,
  ]);

  if (mediaCenterItem.isLoading) {
    <PageLoader />;
  }

  const errorStatusCode = isAxiosError(mediaCenterItem.error)
    ? mediaCenterItem.error.response?.status
    : null;

  if (errorStatusCode === 404) {
    return <ExpiredLinkPage />;
  }

  if (!mediaCenterItem.data?.eventDetails) {
    return null;
  }

  return (
    <PublicEventSingle
      mediaItemId={itemId}
      itemType={mediaCenterItem.data.itemType || ""}
      eventData={mediaCenterItem.data.eventDetails as SingleEventResultItem}
      isLoading={mediaCenterItem.isLoading}
      routeRoot="MediaCenter"
    />
  );
}
