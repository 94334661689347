import axios from "axios";
import { components, paths } from "@tveyes/twosionwebapischema";
import { UseQueryOptions, useQuery } from "react-query";
import { enqueueSnackbar } from "notistack";
import { makeApiUrl } from "src/utils/makeApiUrl";

const apiKey: keyof paths = "/api/mediacenter/{userId}/{itemId}";
const method: keyof paths[typeof apiKey] = "get";

export const mediaCenterItemFetchKey = "fetch-media-center-item";

type Endpoint = Required<paths[typeof apiKey][typeof method]>;
type Parameters = Endpoint["parameters"];
type RequestOptions = {
  params: {
    path: {
      userId: Parameters["path"]["userId"];
      itemId: Parameters["path"]["itemId"];
    };
  };
};

export type MediaCenterItem = Required<
  components["schemas"]["MediaCenterEventItemHeader"]
>;

export type MediaCenterItemResponse =
  Endpoint["responses"]["200"]["content"]["application/json"];

export function useMediaCenterItemPublic({
  request,
  options,
}: {
  request: RequestOptions;
  options: UseQueryOptions<MediaCenterItemResponse, unknown>;
}) {
  return useQuery<MediaCenterItemResponse & { isItemOwner?: boolean }, unknown>(
    {
      ...options,
      keepPreviousData: true,
      queryKey: [mediaCenterItemFetchKey],
      queryFn: async () => {
        const url = makeApiUrl<Parameters>(apiKey, {
          path: {
            userId: request.params.path.userId,
            itemId: request.params.path.itemId,
          },
        });

        const { data, headers } = await axios<MediaCenterItemResponse>(url, {
          method,
        });

        const isItemOwner = headers["x-insight-user-owns-object"];
        return { ...data, isItemOwner };
      },
      onError(err) {
        if (axios.isAxiosError(err) && err.response?.status !== 404) {
          enqueueSnackbar({
            message: `Error fetching Media Center item: ${err}`,
            variant: "error",
          });
        }
      },
    }
  );
}
