import Markdown from "react-markdown";
import { useEffect, useState } from "react";
import { Button, Stack, Typography, useTheme } from "@mui/material";
import { PageLoader } from "src/components/PageLoader";
import { Logo } from "src/components/logo/Logo";
import { ScrollBox } from "src/components/ScrollBox/ScrollBox";
import { AppLink } from "src/components/AppLink/AppLink";
import { LogoEye } from "src/components/logo/LogoEye";
import { LogoTVEyes } from "src/components/logo/LogoTVEyes";
import { useIsMobile } from "src/utils/useIsMobile";

const fetchToSText = async () => {
  const res = await fetch("/insight-terms-of-service.md");
  const text = await res.text();

  await new Promise((resolve) => setTimeout(resolve, 3000));

  return text;
};

export function PublicTermsOfServicePage() {
  const isMobile = useIsMobile();
  const [text, setText] = useState("");
  const [isLoading, setLoading] = useState(false);
  const { palette } = useTheme();

  useEffect(() => {
    setLoading(true);
    fetchToSText()
      .then(setText)
      .finally(() => setLoading(false));
  }, []);

  if (isMobile) {
    return (
      <Stack height="100%">
        <Stack height={88} bgcolor={palette.background.brand} px={2}>
          <Stack width="100%" height="100%" mx="auto" justifyContent="center">
            <Stack direction="row" gap={1}>
              <LogoEye dark height={30} />
              <LogoTVEyes dark height={30} />
            </Stack>

            <Stack direction="row" gap={2}>
              <Button
                children="Go to Insight app"
                variant="text"
                sx={{
                  color: palette.getContrastText(palette.background.brand),
                }}
                href="/"
              />
              <Button
                children="Go to TVEyes"
                variant="text"
                sx={{
                  color: palette.getContrastText(palette.background.brand),
                }}
                href="https://www.tveyes.com"
              />
            </Stack>
          </Stack>
        </Stack>

        <ScrollBox flex={1} pl={2} pr={1}>
          <Stack m="auto" flex={1}>
            {isLoading ? (
              <PageLoader title="Loading Insight Terms of Service" />
            ) : (
              text && <Markdown children={text} />
            )}
          </Stack>

          {!isLoading && (
            <Stack alignItems="center" gap={5} mt={8} mb={2}>
              <Logo height={20} />

              <Stack gap={2} alignItems="center">
                <Typography
                  variant="body2"
                  color={palette.text.secondary}
                  align="center"
                  children="All Rights Reserved. Copyright © 2024 TVEyes. Fairfield CT, 06824. USA"
                />
                <Stack direction="row" gap={2} justifyContent="center">
                  <AppLink
                    title="Privacy Policy"
                    to="https://www.tveyes.com/privacy-policy/"
                    variant="body2"
                  />

                  <AppLink
                    title="TVEyes.com"
                    to="https://www.tveyes.com"
                    variant="body2"
                  />
                </Stack>
              </Stack>
            </Stack>
          )}
        </ScrollBox>
      </Stack>
    );
  }

  const maxWidth = 1150;

  return (
    <Stack height="100%">
      <Stack height={88} bgcolor={palette.background.brand} px={2}>
        <Stack
          direction="row"
          alignItems="center"
          maxWidth={maxWidth}
          width="100%"
          height="100%"
          mx="auto"
        >
          <Stack direction="row" gap={1}>
            <LogoEye dark height={32} />
            <LogoTVEyes dark height={32} />
          </Stack>

          <Stack flex={1} />

          <Stack direction="row" gap={4}>
            <Button
              children="Go to Insight app"
              variant="text"
              sx={{ color: palette.getContrastText(palette.background.brand) }}
              href="/"
            />
            <Button
              children="Go to TVEyes"
              variant="text"
              sx={{ color: palette.getContrastText(palette.background.brand) }}
              href="https://www.tveyes.com"
            />
          </Stack>
        </Stack>
      </Stack>
      <ScrollBox flex={1} pl={2} pr={1}>
        <Stack m="auto" flex={1} maxWidth={maxWidth}>
          {isLoading ? (
            <PageLoader title="Loading Insight Terms of Service" />
          ) : (
            text && <Markdown children={text} />
          )}
        </Stack>

        {!isLoading && (
          <Stack alignItems="center" gap={3} mt={5} mb={8}>
            <Logo height={24} />

            <Typography
              variant="body2"
              color={palette.text.secondary}
              children="All Rights Reserved. Copyright © 2024 TVEyes. Fairfield CT, 06824. USA"
            />

            <Stack direction="row" gap={2}>
              <AppLink
                title="Privacy Policy"
                to="https://www.tveyes.com/privacy-policy/"
                variant="body2"
              />
              <AppLink
                title="TVEyes.com"
                to="https://www.tveyes.com"
                variant="body2"
              />
            </Stack>
          </Stack>
        )}
      </ScrollBox>
    </Stack>
  );
}
