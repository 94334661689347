import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { differenceInSeconds, format } from "date-fns";
import { useDebouncedCallback } from "use-debounce";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { DateTimeSearchResultItem } from "src/api/useDateTimeSearch";
import { UseTrimRangeValue } from "src/components/ClipEditorPage/hook/useTrimRangeState";
import { TranscriptParser } from "src/components/TranscriptParser/TranscriptParser";
import { ShiftPlayerDataProps } from "src/pages/WatchListTermResultClipEditor/WatchListTermResultClipEditor.page";
import { EventDetails } from "src/models/EventDetails";
import { updateDateTimeRange } from "src/utils/updateDateTimeRange";
import { ShiftPlayerPlayButton } from "./ShiftPlayerPlayButton";
import { ShiftPlayerSetStartEndButtons } from "./ShiftPlayerSetStartEndButtons";

export function ShiftPlayerEventSlide({
  value,
  event,
  mode,
  onShiftPlayerPlay,
  bounds,
  minSpanSec,
  trimRange,
  setTrimRange = () => null,
  offset,
}: {
  value: DateTimeSearchResultItem;
  event: EventDetails;
  mode: "view" | "edit";
  onShiftPlayerPlay?: (data: ShiftPlayerDataProps) => void;
  bounds?: [Date, Date] | null;
  minSpanSec: number;
  trimRange?: UseTrimRangeValue;
  setTrimRange?: Dispatch<SetStateAction<UseTrimRangeValue>>;
  offset: number;
}) {
  const { palette } = useTheme();

  const onChangeWrapper = useDebouncedCallback(setTrimRange, 300);

  // "force" param used to combine different events into one directly
  const handleSetStart = useCallback(
    (t0?: Date, force?: boolean, t1?: Date | null) => {
      if (!bounds || !trimRange) return;

      if (force && t0) {
        const outputNextRange: [Date, Date | null] = [t0, t1 ?? null];
        return onChangeWrapper(outputNextRange);
      }

      const nextRange = updateDateTimeRange({
        offsetInSec: bounds[0] && t0 ? differenceInSeconds(t0, bounds[0]) : 0,
        prev: trimRange,
        bounds,
        minSpanSec,
      });

      onChangeWrapper(nextRange);
    },
    [bounds, minSpanSec, onChangeWrapper, trimRange]
  );

  // "force" param used to combine different events into one directly
  const handleSetEnd = useCallback(
    (t1?: Date, force?: boolean, t0?: Date | null) => {
      if (!bounds || !trimRange) return;

      if (force && t1) {
        const outputNextRange: [Date | null, Date] = [t0 ?? null, t1];
        return onChangeWrapper(outputNextRange);
      }

      const nextRange = updateDateTimeRange({
        offsetOutSec: bounds[0] && t1 ? differenceInSeconds(t1, bounds[0]) : 0,
        prev: trimRange,
        bounds,
        minSpanSec,
      });
      onChangeWrapper(nextRange);
    },
    [bounds, minSpanSec, onChangeWrapper, trimRange]
  );

  const blockTimeLabel = value.blockStartTime
    ? format(new Date(value.blockStartTime), "pp")
    : "";

  const isTranscriptNotAvailable =
    value.transcript === "No Transcript Available";

  return useMemo(() => {
    return (
      <Stack
        key={value.id}
        flex={1}
        position="relative"
        boxShadow={8}
        borderRadius={2}
        my={1}
        rowGap={2}
      >
        <Box
          overflow="hidden"
          width="100%"
          component="img"
          alt="Thumbnail"
          src={value.thumbnail ?? ""}
        />
        <ShiftPlayerPlayButton
          value={value}
          event={event}
          onShiftPlayerPlay={onShiftPlayerPlay}
          offset={offset}
        />

        <Stack
          flex={1}
          gap={1}
          justifyContent="space-between"
          overflow="hidden"
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={1.5}
            px={1.75}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="subtitle2">{blockTimeLabel}</Typography>
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              minWidth="fit-content"
              sx={{
                display: mode === "view" ? "none" : undefined,
              }}
            >
              <ShiftPlayerSetStartEndButtons
                value={value}
                event={event}
                onShiftPlayerPlay={onShiftPlayerPlay}
                handleSetStart={handleSetStart}
                handleSetEnd={handleSetEnd}
                trimRange={trimRange}
              />
            </Stack>
          </Stack>

          <Stack
            height="100%"
            sx={{ backgroundColor: palette.action.selected }}
            px={2}
            mx={2}
            py={1}
            borderRadius={1}
            overflow="auto"
            justifyContent={isTranscriptNotAvailable ? "center" : undefined}
            alignItems={isTranscriptNotAvailable ? "center" : undefined}
          >
            <Typography variant="body1">
              <TranscriptParser
                highlightColor={palette.primary.main}
                transcript={value.transcript ?? ""}
                color={
                  isTranscriptNotAvailable ? palette.text.secondary : undefined
                }
              />
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    );
  }, [
    palette.action.selected,
    palette.primary.main,
    palette.text.secondary,
    isTranscriptNotAvailable,
    blockTimeLabel,
    value,
    event,
    mode,
    onShiftPlayerPlay,
    handleSetStart,
    handleSetEnd,
    trimRange,
    offset,
  ]);
}
