import { formatISO } from "date-fns";
import { Add, SearchOutlined } from "@mui/icons-material";
import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import { useRef, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { usePowerSearchQueryKeyword } from "src/api/usePowerSearchQueryKeyword";
import { useOpenState } from "src/utils/useOpenState";
import { useWatchQueryCreate } from "src/api/useWatchQueryCreate";
import { powerSearchResultRoute } from "src/pages/PowerSearchResult/PowerSearchResult.route";
import { makeQueryDefinition } from "src/utils/makeQueryDefinition";
import { DialogPrompt } from "../DialogPrompt/DialogPrompt";
import { PowerSearchKeywordFormValues } from "../PowerSearchKeywordForm/PowerSearchKeywordForm.model";
import { LoadingButton } from "../buttons/LoadingButton";
import { SourcesPicker } from "../SourcesPicker/SourcesPicker";
import { WatchTermInput } from "../WatchTermInput/WatchTermInput";
import { TextInputBase } from "../TextInputBase";

export function PowerSearchFormSimple() {
  const { breakpoints } = useTheme();
  const titlePromptOpenState = useOpenState();
  const [prevDisplayName, setPrevDisplayName] = useState("");
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const containerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const watchQueryCreate = useWatchQueryCreate({
    options: {
      onSuccess: () => {
        titlePromptOpenState.hide();
      },
    },
  });

  const powerSearchQuery = usePowerSearchQueryKeyword();

  const formHook = useFormContext<PowerSearchKeywordFormValues>();
  const { handleSubmit, control, formState, watch, resetField } = formHook;

  const { displayName } = watch();

  const isBusy = formState.isSubmitting || watchQueryCreate.isLoading;

  const showWatchQueryTitleDialog = () => {
    setPrevDisplayName(displayName);
    titlePromptOpenState.show();
  };

  const handleWatchTermCreateDecline = () => {
    titlePromptOpenState.hide();
    resetField("displayName", { defaultValue: prevDisplayName });
  };

  const handleWatchTermCreateConfirm = handleSubmit(
    (data: PowerSearchKeywordFormValues) => {
      const query = makeQueryDefinition(data);
      watchQueryCreate.mutate({
        title: data.displayName,
        queryBuilderDefinition: query,
      });
    }
  );

  const handlePowerSearch = (data: PowerSearchKeywordFormValues) => {
    const { startDateTime, endDateTime, ...form } = data;

    powerSearchQuery.save(form);
    const url = powerSearchResultRoute.makeUrl(
      {},
      {
        startDate: startDateTime ? formatISO(startDateTime) : "",
        endDate: endDateTime ? formatISO(endDateTime) : "",
      }
    );
    navigate(url);
  };

  const watchTermIncludeRow = (
    <Grid item xs={12} md={6} lg={3}>
      <Controller
        name="termsInclude"
        control={control}
        render={({ field, fieldState, formState }) => {
          const { list, logic } = formState.errors[field.name] || {};
          const message = list?.message || logic?.message || " ";

          return (
            <WatchTermInput
              id="watch-term-input"
              label="Keyword"
              value={field.value}
              options={[]} // no suggestions for now
              onChange={field.onChange}
              onBlur={field.onBlur}
              disabled={isBusy}
              error={!!fieldState.error}
              helperText={message}
              displayTooltip
            />
          );
        }}
      />
    </Grid>
  );

  const watchTermExcludeRow = (
    <Grid item xs={12} md={6} lg={3}>
      <Controller
        name="termsExclude"
        control={control}
        render={({ field, fieldState, formState }) => {
          const { list, logic } = formState.errors[field.name] || {};
          const message = list?.message || logic?.message || " ";

          return (
            <WatchTermInput
              id="term-exclude-input"
              label="Exclude keyword"
              value={field.value}
              options={[]} // no suggestions for now
              onChange={field.onChange}
              onBlur={field.onBlur}
              disabled={isBusy}
              error={!!fieldState.error}
              helperText={message}
            />
          );
        }}
      />
    </Grid>
  );

  const sourcesRow = (
    <Grid item xs={12} md={6} lg={3}>
      <Controller
        name="sourcesInclude"
        control={control}
        render={({ field, fieldState }) => {
          const defaultLabel = field.value.list.length
            ? "Source"
            : "All Sources";

          return (
            <SourcesPicker
              id="source-picker"
              label={defaultLabel}
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
              disabled={isBusy}
              error={!!fieldState.error}
              helperText={fieldState.error?.message || " "}
              dropDownBoundsEl={containerRef}
            />
          );
        }}
      />
    </Grid>
  );

  return (
    <form>
      <div ref={containerRef} style={{ width: "100%" }} />
      <Grid
        container
        rowSpacing={isMobile ? 1.5 : 3}
        columnSpacing={{ xs: 2, xl: 3 }}
      >
        {watchTermIncludeRow}
        {watchTermExcludeRow}
        {sourcesRow}

        <Grid
          item
          xs={12}
          md={12}
          lg={4}
          display="flex"
          columnGap={{ xs: 2, xl: 3 }}
        >
          <LoadingButton
            variant="contained"
            color="info"
            id="add-watch-term"
            sx={{
              height: 56,
              width: { xs: 160, md: "50%", xl: 176 },
              whiteSpace: "nowrap",
            }}
            disabled={isBusy || !formState.isValid}
            onClick={showWatchQueryTitleDialog}
            loading={isBusy}
            startIcon={<Add />}
            children="Watch term"
          />
          <LoadingButton
            variant="contained"
            color="primary"
            sx={{
              height: 56,
              width: { xs: 160, md: "50%", xl: 176 },
              whiteSpace: "nowrap",
            }}
            disabled={isBusy || !formState.isValid}
            loading={isBusy}
            startIcon={<SearchOutlined />}
            onClick={handleSubmit(handlePowerSearch)}
            children="Search"
          />
        </Grid>
      </Grid>

      <DialogPrompt
        title="Save to Watchlist"
        open={titlePromptOpenState.isOpen}
        onDecline={handleWatchTermCreateDecline}
        onConfirm={handleWatchTermCreateConfirm}
        confirmLabel="Ok"
        confirmDisabled={isBusy || !formState.isValid}
        children={
          <Controller
            name="displayName"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <Box pt={1}>
                  <TextInputBase
                    label="Display name"
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message || " "}
                    fullWidth
                  />
                </Box>
              );
            }}
          />
        }
      />
    </form>
  );
}
