import { alpha, Divider, Stack, Typography, useTheme } from "@mui/material";
import { Fragment } from "react/jsx-runtime";
import { CustomQueryHighlightedText } from "src/components/CustomQueryHighlightedText/CustomQueryHighlightedText";
import { TextLineClamp } from "src/components/TextLineClamp/TextLineClamp";
import { NameValuePair } from "src/models/NameValuePair";
import { QueryDefinition } from "src/models/QueryDefinition";
import { getEventSourceIcon } from "src/utils/getEventSourceIcon";

export enum SourceBlockType {
  include = "include",
  exclude = "exclude",
}

const renderSourceItem = (
  item: NameValuePair,
  i: number,
  all: NameValuePair[]
) => {
  const Icon = getEventSourceIcon(item.value);

  return (
    <Stack
      key={item.value}
      direction="row"
      flexWrap="nowrap"
      columnGap={0.25}
      alignItems="center"
    >
      {Icon ? <Icon fontSize="small" /> : null}
      <Typography variant="subtitle2" noWrap>
        {item.name}
      </Typography>
    </Stack>
  );
};

const renderSourceBlocks = (
  type: SourceBlockType,
  queryDefinition?: QueryDefinition | null
) => {
  const { sourceFilter } = queryDefinition || {};
  const includeExcludeCountries = sourceFilter?.countries?.[type] || [];
  const includeExcludeMarkets = sourceFilter?.markets?.[type] || [];
  const includeExcludeState = sourceFilter?.state?.[type] || [];
  const includeExcludeStations = sourceFilter?.stations?.[type] || [];
  const includeExcludeContentType = sourceFilter?.contentType?.[type] || [];

  const blocks = [
    ...includeExcludeCountries.map(renderSourceItem),
    ...includeExcludeMarkets.map(renderSourceItem),
    ...includeExcludeState.map(renderSourceItem),
    ...includeExcludeStations.map(renderSourceItem),
    ...includeExcludeContentType.map(renderSourceItem),
  ].map((el, i, all) => {
    if (i < all.length - 1) {
      return (
        <Fragment key={`block:${i}`}>
          {el}
          <Typography variant="subtitle2">,&nbsp;</Typography>
        </Fragment>
      );
    }

    return el;
  });

  return blocks.length ? blocks : null;
};

export function WatchQueryExplanation({
  title,
  queryDefinition,
}: {
  title: string;
  queryDefinition?: QueryDefinition | null;
}) {
  const { palette } = useTheme();
  const { keywordQuery, sourceFilter, customQuery } = queryDefinition || {};
  const lColumnMin = 140;

  const allSources = <Typography variant="subtitle2">All</Typography>;

  const sourcesBlocksInclude = renderSourceBlocks(
    SourceBlockType.include,
    queryDefinition
  );

  const sourcesBlocksExclude = renderSourceBlocks(
    SourceBlockType.exclude,
    queryDefinition
  );

  return (
    <Stack maxWidth={{ xs: "100%", sm: 480 }}>
      <Typography px={{ xs: 2, sm: 3 }} py={2} variant="subtitle1">
        {title}
      </Typography>
      <Divider />

      <Stack gap={2} py={2}>
        <Stack
          px={{ xs: 2, sm: 3 }}
          columnGap={{ xs: 1, sm: 2 }}
          direction="row"
        >
          {customQuery ? (
            <Stack direction="row">
              <Typography minWidth={lColumnMin} variant="body2">
                Custom Query:
              </Typography>
              <Stack
                sx={{
                  backgroundColor: alpha(palette.action.hover, 0.04),
                  border: `1px solid ${alpha(palette.common.black, 0.23)}`,
                  borderRadius: "4px",
                  py: 0.5,
                  px: 1,
                }}
              >
                <TextLineClamp lineClamp={4}>
                  <CustomQueryHighlightedText value={customQuery} />
                </TextLineClamp>
              </Stack>
            </Stack>
          ) : (
            <>
              <Typography minWidth={lColumnMin} variant="body2">
                Keyword Include:
              </Typography>
              <Typography
                variant="subtitle2"
                children={keywordQuery?.include?.join(", ")}
              />
            </>
          )}
        </Stack>

        {keywordQuery?.exclude?.length ? (
          <Stack
            px={{ xs: 2, sm: 3 }}
            columnGap={{ xs: 1, sm: 2 }}
            direction="row"
          >
            <Typography minWidth={lColumnMin} variant="body2">
              Keyword Exclude:
            </Typography>
            <Typography
              variant="subtitle2"
              children={keywordQuery?.exclude?.join(", ")}
            />
          </Stack>
        ) : null}

        <Stack
          px={{ xs: 2, sm: 3 }}
          columnGap={{ xs: 1, sm: 2 }}
          direction="row"
        >
          <Typography minWidth={lColumnMin} variant="body2">
            Sources Include:
          </Typography>
          <Stack
            direction="row"
            flexWrap="wrap"
            rowGap={1}
            children={sourcesBlocksInclude || allSources}
          />
        </Stack>

        {sourcesBlocksExclude && (
          <Stack
            px={{ xs: 2, sm: 3 }}
            columnGap={{ xs: 1, sm: 2 }}
            direction="row"
          >
            <Typography minWidth={lColumnMin} variant="body2">
              Sources Exclude:
            </Typography>
            <Stack
              direction="row"
              flexWrap="wrap"
              rowGap={1}
              children={sourcesBlocksExclude}
            />
          </Stack>
        )}

        <Stack
          px={{ xs: 2, sm: 3 }}
          columnGap={{ xs: 1, sm: 2 }}
          direction="row"
        >
          <Typography minWidth={lColumnMin} variant="body2">
            Advertisements:
          </Typography>
          <Typography
            variant="subtitle2"
            children={sourceFilter?.adverts ?? "All"}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
