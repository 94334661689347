import { isAfter } from "date-fns";
import { useSessionContext } from "./useSessionContext";

export function useIsUserExpired() {
  const { expiry } = useSessionContext();

  const expiryDate = new Date(expiry);
  const currentDate = new Date();

  return isAfter(currentDate, expiryDate);
}
