import Joi from "joi";
import { useMemo } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Stack } from "@mui/material";
import { joiResolver } from "@hookform/resolvers/joi";
import { DialogPrompt } from "../DialogPrompt/DialogPrompt";
import { DialogPromptProps } from "../DialogPrompt/DialogPrompt.model";
import { TextInputBase } from "../TextInputBase";

type TitleForm = { clipTitle: string };

export type ClipEditorTitlePromptProps = Omit<
  DialogPromptProps,
  "title" | "children" | "onConfirm"
> & {
  eventTitle?: string;
  onConfirm: (value: TitleForm) => void;
};

const useTitlePromptSchema = () => {
  return useMemo(
    () =>
      Joi.object<TitleForm>({
        clipTitle: Joi.string().required(),
      }),
    []
  );
};

export const ClipEditorTitlePrompt = (props: ClipEditorTitlePromptProps) => {
  const schema = useTitlePromptSchema();
  const formHook = useForm<TitleForm>({
    resolver: joiResolver(schema),
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      clipTitle: props.eventTitle || "",
    },
  });

  const handleSubmit = formHook.handleSubmit(props.onConfirm);

  return (
    <DialogPrompt
      {...props}
      title="Clip title"
      onConfirm={handleSubmit}
      declineLabel="Cancel"
      confirmLabel="Ok"
      confirmDisabled={!formHook.formState.isValid}
      children={
        <Stack pt={1}>
          <FormProvider {...formHook}>
            <Controller
              name="clipTitle"
              control={formHook.control}
              render={({ field, fieldState }) => {
                return (
                  <TextInputBase
                    label="Clip Title"
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message || " "}
                    fullWidth
                  />
                );
              }}
            />
          </FormProvider>
        </Stack>
      }
    />
  );
};
