import { set, sub } from "date-fns";
import { joiResolver } from "@hookform/resolvers/joi";
import { useEffect, useState } from "react";
import { UseFormProps, useForm } from "react-hook-form";
import { EventSourceOption } from "src/api/useEventSources";
import { DateTimeSearchFormSchema } from "src/pages/DateTimeSearchInitial/DateTimeSearchForm/DateTimeSearchForm.schema";

export type DateTimeSearchFormValues = {
  market?: EventSourceOption | null;
  station?: EventSourceOption | null;
  dateTime: Date;
  sentenceAlign?: boolean;
};

type UseDateTimeSearchFormParams = Pick<
  UseFormProps<DateTimeSearchFormValues>,
  "defaultValues"
>;

export function useDateTimeSearchForm(params?: UseDateTimeSearchFormParams) {
  const { defaultValues } = params || {};
  const [prevMarket, setPrevMarket] = useState<EventSourceOption | null>(null);
  const initialDateTime = sub(new Date(), { minutes: 20 });

  const formHook = useForm<DateTimeSearchFormValues>({
    mode: "all",
    resolver: joiResolver(DateTimeSearchFormSchema),
    defaultValues: {
      market: null,
      station: null,
      dateTime: set(initialDateTime, { seconds: 0, milliseconds: 0 }),
      ...defaultValues,
    },
  });

  const { watch, setValue, formState } = formHook;
  const market = watch("market");
  const isStationDirty = !!formState.dirtyFields.station;

  useEffect(() => {
    if (market && market !== prevMarket) {
      setValue("station", null, { shouldValidate: isStationDirty });
      setPrevMarket(market);
    }
  }, [market, prevMarket, setValue, isStationDirty]);

  return formHook;
}
