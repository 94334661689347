import { useMemo } from "react";
import { Typography, useTheme } from "@mui/material";
import { blue, green, purple } from "@mui/material/colors";

export function CustomQueryHighlightedText({ value }: { value?: string }) {
  const { palette } = useTheme();

  type ColorMap = {
    [key: string]: string;
  };

  const colorMap: ColorMap = useMemo(() => {
    return {
      AND: green["500"],
      OR: blue["500"],
      NOT: palette.warning.main,
      "~": purple["A200"],
    };
  }, [palette.warning.main]);
  const words = value?.split(/(\s+)/);

  const highlightWord = (word: string) => {
    const color = colorMap[word];
    return color ? (
      <Typography component={"span"} style={{ color }}>
        {word}
      </Typography>
    ) : (
      word
    );
  };

  return (
    <>
      {words?.map((word, index) => (
        <Typography component={"span"} key={index}>
          {highlightWord(word)}
        </Typography>
      ))}
    </>
  );
}
