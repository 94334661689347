import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { pageNotFoundRoute } from "src/pages/PageNotFound/PageNotFound.route";
import { PublicEventSingle } from "src/components/PublicEventSingle/PublicEventSingle";
import { PageLoader } from "src/components/PageLoader";
import { useEventDetailsPublic } from "src/api/useEventDetailsPublic";
import { PublicYoutubePodcastPathParams } from "./PublicYoutubePodcastEvent.route";
import { watchListTermResultClipRoute } from "../WatchListTermResultClip/WatchListTermResultClip.route";

export function PublicYoutubePodcastEventPage() {
  const { queryId = "", eventId = "" } =
    useParams<PublicYoutubePodcastPathParams>();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth0();

  const eventDetails = useEventDetailsPublic({
    params: {
      path: {
        source: "query",
        sourceId: queryId,
        eventId,
      },
    },
  });

  useEffect(() => {
    if (
      isAuthenticated &&
      eventDetails.data?.isItemOwner &&
      eventDetails.data?.eventDetails &&
      !eventDetails.data.eventDetails.isSynthetic
    ) {
      if (queryId && eventId) {
        navigate(
          watchListTermResultClipRoute.makeUrl({
            queryId,
            eventId,
          })
        );
      }
    }
  }, [
    isAuthenticated,
    eventDetails.data?.eventDetails,
    eventDetails.data?.isItemOwner,
    queryId,
    eventId,
    navigate,
  ]);

  useEffect(() => {
    if (eventDetails.isFetched && !eventDetails.data) {
      navigate(pageNotFoundRoute.path);
    }
  }, [eventDetails.isFetched, eventDetails.data, navigate]);

  if (eventDetails.isLoading) {
    <PageLoader />;
  }

  if (!eventDetails.data?.eventDetails) {
    return null;
  }

  return (
    <PublicEventSingle
      eventData={eventDetails.data?.eventDetails}
      isLoading={eventDetails.isLoading}
      routeRoot="Report"
      bannerData={eventDetails.data?.bannerData}
    />
  );
}
