import { ReactNode, useMemo, useState } from "react";
import {
  Box,
  Divider,
  ListItemSecondaryAction,
  Stack,
  Tooltip,
  alpha,
  useTheme,
} from "@mui/material";
import { DateRange } from "@mui/icons-material";
import { PowerSearchResultItem } from "src/api/usePowerSearch";
import { ListBaseItem } from "src/components/ListBase/ListBaseItem";
import { TranscriptText } from "src/components/TranscriptText/TranscriptText";
import {
  getPowerSearchResultCountry,
  getPowerSearchResultDateTime,
  getPowerSearchResultMarket,
  getPowerSearchResultEvent,
  getPowerSearchResultEventType,
  getPowerSearchResultSource,
  getPowerSearchResultViews,
  getPowerSearchResultMediaType,
  getPowerSearchResultTag,
  getPowerSearchResultIndividualTranscript,
} from "src/api/usePowerSearchResult.getters";
import { KeyValue } from "src/components/KeyValue/KeyValue";
import { AdvChip } from "src/components/AdvChip/AdvChip";
import { AppLink } from "src/components/AppLink/AppLink";
import { TextLineClamp } from "src/components/TextLineClamp/TextLineClamp";
import { getEventIcon, getEventTooltipTitle } from "src/utils/getEventIcon";
import { TranscriptBlock } from "src/components/TranscriptBlock/TranscriptBlock";
import { ClipViewer } from "src/components/ClipViewer/ClipViewer";
import { TileCheckBox } from "src/components/TileCheckBox/TileCheckBox";
import { ReportEventSelectionState } from "src/api/useEventSelectionReport";
import { powerSearchResultClipRoute } from "src/pages/PowerSearchResultClip/PowerSearchResultClip.route";

export function ReportEventsListItem({
  value,
  isChecked,
  renderEventsItemActions,
  selectionState,
  getEventUrl,
  highlightedEventsIds,
  isCompact = false,
}: {
  value: PowerSearchResultItem;
  isChecked: boolean;
  renderEventsItemActions?: (value: PowerSearchResultItem) => ReactNode;
  selectionState?: ReportEventSelectionState<PowerSearchResultItem>;
  getEventUrl?: (eventId: string) => string;
  highlightedEventsIds?: string[];
  isCompact?: boolean;
}) {
  const { palette, spacing } = useTheme();

  const [activeMention, setActiveMention] = useState(0);

  const eventType = getPowerSearchResultEventType(value);
  const mediaType = getPowerSearchResultMediaType(value);
  const Icon = getEventIcon(eventType, mediaType);

  const detailsUrl =
    (getEventUrl && getEventUrl(value.id)) ||
    powerSearchResultClipRoute.makeUrl({
      eventId: value.id,
    });

  const handleChange = (checked: boolean) => {
    if (checked) {
      selectionState?.select(value);
    } else {
      selectionState?.unselect(value);
    }
  };

  const isHighlighted = useMemo(() => {
    return highlightedEventsIds?.includes(value.id);
  }, [highlightedEventsIds, value]);

  return (
    <ListBaseItem
      key={value.id}
      sx={{
        maxHeight: 208,
        minHeight: 208,
        alignItems: "stretch",
        columnGap: 2,
        backgroundColor: isHighlighted
          ? alpha(palette.secondary.main, 0.08)
          : "inherit",
      }}
    >
      <Box
        position="relative"
        borderRadius={2}
        overflow="hidden"
        border={`1px solid ${palette.action.disabledBackground}`}
        width={215}
      >
        <ClipViewer
          id={value.id}
          onMentionChange={setActiveMention}
          event={value}
          viewMode="compact"
        />
        {!!selectionState && (
          <TileCheckBox value={isChecked} onChange={handleChange} zIndex={3} />
        )}
        {getPowerSearchResultTag(value).length ? (
          <AdvChip
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          />
        ) : null}
      </Box>

      <Stack flex={1} justifyContent="space-between" overflow="hidden">
        {/* header card */}
        <Stack direction="row" spacing={2} mr={2}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Tooltip title={eventType}>
              <Icon />
            </Tooltip>
            <AppLink to={detailsUrl}>
              <TextLineClamp
                variant="subtitle1"
                color="primary"
                height={20}
                lineClamp={1}
              >
                {getPowerSearchResultEvent(value)}
              </TextLineClamp>
            </AppLink>
          </Stack>

          <Divider orientation="vertical" variant="middle" flexItem />

          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            minWidth="fit-content"
          >
            <DateRange sx={{ color: palette.action.active }} />
            <TextLineClamp variant="body2" lineClamp={1}>
              {getPowerSearchResultDateTime(value).toLocaleString()}
            </TextLineClamp>
          </Stack>
        </Stack>

        {/* attributes */}
        {isCompact ? (
          <Stack direction="row" columnGap={2}>
            <Stack width="calc(100% / 3)">
              <KeyValue
                name="Type"
                value={getEventTooltipTitle(eventType, mediaType)}
              />
              <KeyValue
                name="Country"
                value={getPowerSearchResultCountry(value)}
              />
            </Stack>
            <Stack width="calc(100% / 3)">
              <KeyValue
                name="Market"
                value={getPowerSearchResultMarket(value)}
              />
              <KeyValue
                name="Source"
                value={getPowerSearchResultSource(value)}
              />
            </Stack>
            <Stack width="calc(100% / 3)">
              <KeyValue
                name="Viewership"
                value={getPowerSearchResultViews(value)}
              />
            </Stack>
          </Stack>
        ) : (
          <Stack
            direction="row"
            columnGap={2}
            justifyContent="space-between"
            maxWidth="100%"
            overflow="hidden"
          >
            <KeyValue name="Source" value={getPowerSearchResultSource(value)} />
            <KeyValue
              name="Country"
              value={getPowerSearchResultCountry(value)}
            />
            <KeyValue name="Market" value={getPowerSearchResultMarket(value)} />
            <KeyValue
              name="Viewership"
              value={getPowerSearchResultViews(value)}
            />
          </Stack>
        )}

        <TranscriptBlock px={2} py={1} borderRadius={1}>
          <TranscriptText
            transcript={getPowerSearchResultIndividualTranscript(
              value,
              activeMention,
              false
            )}
            lineClamp={isCompact ? 3 : 4}
          />
        </TranscriptBlock>
      </Stack>
      {!!renderEventsItemActions && (
        <ListItemSecondaryAction
          sx={{ top: spacing(1), right: 0, transform: "none" }}
        >
          {renderEventsItemActions(value)}
        </ListItemSecondaryAction>
      )}
    </ListBaseItem>
  );
}
