import { AppRouteBuilder } from "src/models/AppRoute";
import { PublicYoutubePodcastEventPage } from "./PublicYoutubePodcastEvent.page";

export type PublicYoutubePodcastPathParams = {
  queryId: string;
  eventId: string;
};

export const publicYoutubePodcastEventRoute =
  new AppRouteBuilder<PublicYoutubePodcastPathParams>({
    path: "/public/query/:queryId/:eventId",
    element: <PublicYoutubePodcastEventPage />,
  });
