import { Dispatch, SetStateAction } from "react";
import { Button, Chip, Stack, useTheme } from "@mui/material";
import { ScheduledAlertControls } from "src/components/ScheduledAlertPopover/ScheduledAlertControls/ScheduledAlertControls";
import {
  AlertConfig,
  PresetTypeList,
} from "src/components/ScheduledAlertPopover/ScheduledAlertPopover.model";
import { getDefaultAlertStateData } from "src/components/ScheduledAlertPopover/ScheduledAlertPopover.utils";
import { StackScroll } from "src/components/StackScroll/StackScroll";
import { ArchiveAlerts } from "src/components/ScheduledAlertPopover/ArchiveAlerts/ArchiveAlerts";
import { AlertType } from "src/models/AlertType";

type EditSectionProps = {
  presets?: PresetTypeList;
  config: AlertConfig;
  setConfig: Dispatch<SetStateAction<AlertConfig>>;
  onAddAlert: () => void;
  onCancel: () => void;
  sheduledAlertArchiveEnabled: boolean;
  editRule: AlertType | null;
  setSheduledAlertArchiveEnabled: (value: SetStateAction<boolean>) => void;
};

export function EditSection({
  presets = [],
  onAddAlert,
  onCancel,
  config,
  setConfig,
  sheduledAlertArchiveEnabled,
  editRule,
  setSheduledAlertArchiveEnabled,
}: EditSectionProps) {
  const { palette } = useTheme();
  const themeBackground =
    palette.mode === "dark"
      ? palette.background.default
      : palette.background.paper;

  const presetElements = Object.entries(presets).map(([k, v]) => {
    const key = `${k}:${v.value}`;
    return (
      <Chip
        label={v.label}
        key={key}
        variant="outlined"
        onClick={(_e) => {
          setConfig(() => getDefaultAlertStateData(v.value));
        }}
        size="medium"
      />
    );
  });

  return (
    <Stack justifyContent="space-between" height="100%">
      <Stack
        p={3}
        gap={2}
        sx={{
          background: themeBackground,
          borderBottomLeftRadius: "16px",
          borderBottomRightRadius: "16px",
        }}
      >
        <ScheduledAlertControls config={config} setConfig={setConfig} />
        {presets.length && (
          <StackScroll
            scrollOrientation="horizontal"
            flexDirection="row"
            gap={1}
            width="100%"
            height={40}
            alignItems="center"
          >
            {presetElements}
          </StackScroll>
        )}

        <ArchiveAlerts
          config={config}
          sheduledAlertArchiveEnabled={sheduledAlertArchiveEnabled}
          setSheduledAlertArchiveEnabled={setSheduledAlertArchiveEnabled}
        />
      </Stack>
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        p={3}
      >
        <Button
          variant="outlined"
          sx={{ width: 248 }}
          size="large"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{ width: 248 }}
          size="large"
          onClick={onAddAlert}
        >
          {editRule ? "Edit alert" : "Add alert"}
        </Button>
      </Stack>
    </Stack>
  );
}
