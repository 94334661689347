import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { paths } from "@tveyes/twosionwebapischema";
import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { reportScratchEventsKey } from "./useReportScratchEvents";
import { reportScratchEventsInfiniteKey } from "./useReportScratchEventsInfinite";

const apiKey: keyof paths = "/api/report/scratch";
const method: keyof paths[typeof apiKey] = "delete";

type Endpoint = Required<paths[typeof apiKey][typeof method]>;

type RequestBody = Endpoint["requestBody"]["content"]["application/json"];
type Response = Endpoint["responses"]["200"]["content"]["application/json"];

type RequestMutationFnParams = {
  excludeIds: string[];
};

type RequestOptions = Pick<
  UseMutationOptions<Response, unknown, RequestMutationFnParams>,
  "onSuccess" | "onError" | "onSettled"
>;

export function useReportScratchDelete({
  options,
}: {
  options?: RequestOptions;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  return useMutation<Response, unknown, RequestMutationFnParams, void>({
    ...options,
    mutationFn: async ({ excludeIds }) => {
      const token = await getAccessTokenSilently();
      let url = makeApiUrl(apiKey);
      const body: RequestBody = excludeIds;

      const { data } = await axios<Response>({
        url,
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: body,
      });

      return data;
    },
    onError: (error, variables, context) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
      options?.onError && options.onError(error, variables, context);
    },
    onSettled(data, error, variables, context) {
      queryClient.refetchQueries({
        queryKey: reportScratchEventsInfiniteKey,
      });
      queryClient.refetchQueries({
        queryKey: reportScratchEventsKey,
      });
      options?.onSettled && options.onSettled(data, error, variables, context);
    },
  });
}
