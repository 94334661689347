import { useEffect, useState } from "react";
import {
  IconButton,
  IconButtonProps,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { intercomButtonId } from "src/providers/IntercomProvider";
import { useIntercomButtonState } from "src/api/useIntercomButtonState";
import { IntercomLauncherIcon } from "../icons/IntercomLauncherIcon";
import { intercomDesktopButtonClasses } from "./IntercomDesktopButton.const";

export const IntercomDesktopButton = ({
  ...props
}: Omit<IconButtonProps, "children" | "id">) => {
  const { palette, breakpoints, zIndex } = useTheme();
  const { isShown } = useIntercomButtonState();
  const [isMessengerOpen, setIsMessengerOpen] = useState(false);

  const downXl = useMediaQuery(breakpoints.down("xl"));

  useEffect(() => {
    window.Intercom("onShow", function () {
      setIsMessengerOpen(true);
    });
  }, []);

  useEffect(() => {
    window.Intercom("onHide", function () {
      setIsMessengerOpen(false);
    });
  }, []);

  return isShown ? (
    <IconButton
      className={`${downXl ? intercomDesktopButtonClasses.hideButton : ""} ${
        isMessengerOpen && downXl ? intercomDesktopButtonClasses.active : ""
      }`}
      sx={{
        width: 48,
        zIndex: zIndex.drawer,
        height: 48,
        bgcolor: palette.primary.main,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        bottom: "18px",
        right: downXl ? "-26px" : "28px",
        transition: "transform 500ms",
        [`&.${intercomDesktopButtonClasses.hideButton}:hover`]: {
          transform: "translateX(-38px)",
          transition: "transform 500ms",
        },
        [`&.${intercomDesktopButtonClasses.active}:hover`]: {
          transform: "translateX(-38px)",
          transition: "transform 500ms cubic-bezier(0.33, 0.00, 0.00, 1.00)",
          bgcolor: palette.primary.main,
        },
        [`&.${intercomDesktopButtonClasses.active}`]: {
          transform: "translateX(-38px)",
          transition: "transform 500ms cubic-bezier(0.33, 0.00, 0.00, 1.00)",
        },
        "&:hover": {
          transform: "scale(1.1)",
          transition: "transform 500ms cubic-bezier(0.33, 0.00, 0.00, 1.00)",
          bgcolor: palette.primary.main,
        },
      }}
      {...props}
      id={intercomButtonId}
      children={
        <IntercomLauncherIcon
          sx={{
            color: "#FFF",
            width: 24,
            height: 24,
          }}
        />
      }
    />
  ) : null;
};
