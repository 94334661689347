import { Fragment, useCallback } from "react";
import {
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import { useEffect, useMemo, useState } from "react";
import {
  useNavigate,
  useParams,
  useBlocker,
  BlockerFunction,
} from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { joiResolver } from "@hookform/resolvers/joi";
import isEqual from "lodash/isEqual";
import { AppTopNavBar } from "src/components/AppTopNavBar/AppTopNavBar";
import { useOpenState } from "src/utils/useOpenState";
import { PageLayoutDesktop } from "src/components/PageLayoutDesktop";
import { AppTabLayout } from "src/components/AppTabLayout/AppTabLayout";
import { AppTab } from "src/components/AppTabLayout/AppTab";
import { useViewModeQueryParam } from "src/utils/useViewModeQueryParam";
import { ContentAccessFilter } from "src/models/ContentAccessFilter";
import { NewAccountFormValues } from "src/pages/AccountsManagementCreateAccount/AccountsManagementCreateAccount.model";
import {
  limitsConfiguration,
  NewAccountSchema,
} from "src/pages/AccountsManagementCreateAccount/AccountsManagementCreateAccount.schema";
import { createAccountDefaultValues } from "src/pages/AccountsManagementCreateAccount/AccountsManagementCreateAccount.const";
import { useAccount } from "src/api/useAccount";
import { useAccountUpdate } from "src/api/useAccountUpdate";
import { useGlobalEntitlements } from "src/api/useGlobalEntitlements";
import { EventSourceCategory, useEventSources } from "src/api/useEventSources";
import { useAccountConvert } from "src/api/useAccountConvert";
import { INCLUDE_EXCLUDE_PRESETS } from "src/utils/contentFilterPresets";
import { IncludeExcludeListGroupLogic } from "src/utils/makeWatchQueryFormValues";
import { makeContentFilterQuery } from "src/utils/makeContentFilterQuery";
import { useUserData } from "src/api/useUserData";
import { AccountsManagementGeneralTab } from "./components/AccountsManagementEditGeneralTab/AccountsManagementEditGeneralTab";
import { SettingsCustomizationTab } from "../Settings/components/SettingsCustomizationTab";
import { AccountsManagementEditContentTab } from "./components/AccountsManagementEditContentTab/AccountsManagementEditContentTab";
import { AccountsManagementEditFeaturesTab } from "./components/AccountsManagementEditFeaturesTab/AccountsManagementEditFeaturesTab";
import { sourceFilterMapper } from "./utils/sourceFilterMapper";
import { useAccountStore } from "./hooks/useAccountStore";
import { AccountsManagementConvertDialog } from "../AccountsManagement/components/AccountsManagementConvertDialog/AccountsManagementConvertDialog";
import { EditAccountDetailsFormValues } from "./components/AccountsManagementEditGeneralTab/AccountsManagementEditDetails.model";
import { EditAccountDetailsSchema } from "./components/AccountsManagementEditGeneralTab/AccountsManagementEditDetails.schema";
import { EditAccountContentFilterFormValues } from "./components/AccountsManagementEditContentTab/AccountsManagementContentTab.model";
import { EditAccountContentFilterSchema } from "./components/AccountsManagementEditContentTab/AccountsManagementEditContentFilter.schema";
import { EditAccountFeaturesFormValues } from "./components/AccountsManagementEditFeaturesTab/AccountManagementEditFeatures.model";
import { AccountManagementEditFeaturesSchema } from "./components/AccountsManagementEditFeaturesTab/AccountManagementEditFeatures.schema";
import { AccountsManagementLeaveConfirmationDialog } from "./AccountsManagementLeaveConfirmationDialog/AccountsManagementLeaveConfirmationDialog";
import { makeEntitlements } from "./AccountsManagementEditAccount.utils";
import { accountsManagementRoute } from "../AccountsManagement/AccountsManagement.route";

enum AccountsManagementTabs {
  general = "general",
  content = "content",
  features = "features",
  customization = "customization",
}

export const AccountsManagementEditAccount = () => {
  const [accountsTab, setAccountsTab] =
    useViewModeQueryParam<AccountsManagementTabs>({
      paramKey: "edit",
      defaultValue: AccountsManagementTabs.general,
    });
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { accountId = "" } = useParams();
  const convertAccountDialog = useOpenState();
  const [selectedPreset, setSelectedPreset] =
    useState<IncludeExcludeListGroupLogic | null>(null);

  const { data: globalEntitlementsData } = useGlobalEntitlements();
  const limits = globalEntitlementsData?.globalEntitlements;

  const accountConvert = useAccountConvert({
    options: {
      onSuccess: () => {
        enqueueSnackbar(
          t("deactivateAccountMessage", "Account deactivated successfully"),
          {
            variant: "success",
          }
        );
      },
    },
  });

  const { setAccountName, setIsActive, accountName } = useAccountStore();

  const { data: sourcesData } = useEventSources({
    path: {
      categories: Object.values(EventSourceCategory),
      searchText: "*",
    },
  });

  const { data } = useAccount({
    request: {
      path: {
        id: accountId,
      },
    },
  });
  //FIXME: Replace with useAccount account manager data from /api/Account/{id} when data is available
  const { data: accountManagerData } = useUserData({
    request: {
      params: {
        path: {
          id: data?.accountManager || "",
        },
      },
    },
  });

  const accountUpdate = useAccountUpdate({
    options: {
      onSuccess: () => {
        navigate(accountsManagementRoute.path);
      },
    },
  });

  const [contentFilters, setContentFilters] =
    useState<ContentAccessFilter | null>(null);

  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const formHook = useForm<NewAccountFormValues>({
    mode: "all",
    resolver: joiResolver(
      NewAccountSchema(limits || null, limitsConfiguration.system)
    ),
    defaultValues: createAccountDefaultValues,
  });

  const accountDetailsFormHook = useForm<EditAccountDetailsFormValues>({
    mode: "all",
    resolver: joiResolver(EditAccountDetailsSchema),
    defaultValues: {
      name: data?.name || "",
      organizationType: data?.organizationType || "",
      country: data?.country || "",
      city: data?.city || "",
      stateOrProvince: data?.stateOrProvince || "",
      postalCode: data?.postalCode || "",
      timezone: data?.timeZone || "",
    },
  });
  const accountSources =
    data?.contentAccessFilter &&
    sourceFilterMapper(data?.contentAccessFilter, sourcesData);

  const matchingPreset = INCLUDE_EXCLUDE_PRESETS.find((item) =>
    isEqual(item.contentAccessFilter, data?.contentAccessFilter)
  );
  const isCustom =
    accountSources?.excludedFields?.list?.length ||
    accountSources?.includedFields?.list?.length;

  const accountContentFilterFormHook =
    useForm<EditAccountContentFilterFormValues>({
      mode: "all",
      resolver: joiResolver(EditAccountContentFilterSchema),
      defaultValues: {
        sourcesExclude: accountSources?.excludedFields,
        sourcesInclude: accountSources?.includedFields,
        preset: matchingPreset
          ? matchingPreset?.title
          : isCustom
          ? "custom"
          : "",
      },
    });

  const accountFeaturesFormHook = useForm<EditAccountFeaturesFormValues>({
    mode: "all",
    resolver: joiResolver(AccountManagementEditFeaturesSchema(limits || null)),
    defaultValues: {
      enableMediaDownloads:
        data?.entitlements?.enableMediaDownloads?.value || false,
      enableEditWatchList:
        data?.entitlements?.enableEditWatchList?.value || false,
      enableAdFiltering: data?.entitlements?.enableAdFiltering?.value || false,
      enableCustomReportBanner:
        data?.entitlements?.enableCustomReportBanner?.value || false,
      enableReportDownloads:
        data?.entitlements?.enableReportDownloads?.value || false,
      enableWatchlistRSS:
        data?.entitlements?.enableWatchlistRSS?.value || false,
      limitWatchlistSize: data?.entitlements?.limitWatchlistSize?.value,
      limitItemsPerEmail: data?.entitlements?.limitItemsPerEmail?.value,
      limitEventsInReport: data?.entitlements?.limitEventsInReport?.value,
      limitEventsInCSV: data?.entitlements?.limitEventsInCSV?.value,
      limitEventsInPDF: data?.entitlements?.limitEventsInPDF?.value,
      limitScratchReportItems:
        data?.entitlements?.limitScratchReportItems?.value,
    },
  });

  const {
    setValue: setDetailsValue,
    formState,
    handleSubmit,
  } = accountDetailsFormHook;
  const {
    setValue: setFeaturesValue,
    formState: accountFeaturesFormState,
    watch: watchFeatures,
  } = accountFeaturesFormHook;

  const featuresFormData = watchFeatures();

  const {
    setValue: setContentFilterValue,
    watch,
    reset: resetContentFilters,
    formState: accountContentFilterFormState,
  } = accountContentFilterFormHook;

  const isFormsSubmitted =
    formState.isSubmitted ||
    accountContentFilterFormState.isSubmitted ||
    accountFeaturesFormState.isSubmitted;

  const dataWasChanged =
    formState.isDirty ||
    accountFeaturesFormState.isDirty ||
    accountContentFilterFormState.isDirty;

  const shouldBlockLeaving = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) => {
      return (
        !isFormsSubmitted &&
        dataWasChanged &&
        currentLocation.pathname !== nextLocation.pathname
      );
    },
    [isFormsSubmitted, dataWasChanged]
  );

  const blocker = useBlocker(shouldBlockLeaving);

  const { setValue } = formHook;

  const goBack = () => {
    navigate("/accounts-management");
  };

  const excludeValues = watch("sourcesExclude");
  const includeValues = watch("sourcesInclude");

  useEffect(() => {
    setIsActive(data?.isActive);
    if (data?.organizationType) {
      setValue("organizationType", data.organizationType);
    }
    if (data?.name) {
      setAccountName(data?.name);
      setDetailsValue("name", data.name);
    }

    if (data?.country) {
      setDetailsValue("country", data.country);
    }

    if (data?.city) {
      setDetailsValue("city", data.city);
    }

    if (data?.stateOrProvince) {
      setDetailsValue("stateOrProvince", data.stateOrProvince);
    }

    if (data?.postalCode) {
      setDetailsValue("postalCode", data.postalCode);
    }

    if (data?.isActive) {
      setValue("isActive", data.isActive);
    }
    if (data?.expirationDate) {
      setValue("expirationDate", data.expirationDate);
    }
    if (data?.organizationType) {
      setDetailsValue("organizationType", data.organizationType);
    }
    if (data?.timeZone) {
      setDetailsValue("timezone", data?.timeZone);
    }
    if (data?.isTrial) {
      setValue("isTrial", data.isTrial);
    }

    if (data?.entitlements) {
      setFeaturesValue(
        "enableMediaDownloads",
        data.entitlements.enableMediaDownloads?.value
      );
      setFeaturesValue(
        "enableEditWatchList",
        data.entitlements.enableEditWatchList?.value
      );
      setFeaturesValue(
        "enableAdFiltering",
        data.entitlements.enableAdFiltering?.value
      );
      setFeaturesValue(
        "enableCustomReportBanner",
        data.entitlements.enableCustomReportBanner?.value
      );
      setFeaturesValue(
        "enableReportDownloads",
        data.entitlements.enableReportDownloads?.value
      );
      setFeaturesValue(
        "enableWatchlistRSS",
        data.entitlements.enableWatchlistRSS?.value
      );
      setFeaturesValue(
        "limitWatchlistSize",
        data.entitlements.limitWatchlistSize?.value
      );
      setFeaturesValue(
        "limitItemsPerEmail",
        data.entitlements.limitItemsPerEmail?.value
      );
      setFeaturesValue(
        "limitEventsInReport",
        data.entitlements.limitEventsInReport?.value
      );
      setFeaturesValue(
        "limitEventsInCSV",
        data.entitlements.limitEventsInCSV?.value
      );
      setFeaturesValue(
        "limitEventsInPDF",
        data.entitlements.limitEventsInPDF?.value
      );
      setFeaturesValue(
        "limitScratchReportItems",
        data.entitlements.limitScratchReportItems?.value
      );
    }
    if (data?.contentAccessFilter) {
      const sources = sourceFilterMapper(data.contentAccessFilter, sourcesData);
      const isCustom =
        sources?.includedFields?.list?.length ||
        sources?.excludedFields?.list?.length;
      resetContentFilters({
        sourcesExclude: sources?.excludedFields || {
          list: [],
          logic: "or",
        },
        sourcesInclude: sources?.includedFields || {
          list: [],
          logic: "or",
        },
        preset: matchingPreset
          ? matchingPreset?.title
          : isCustom
          ? "custom"
          : "",
      });
      if (matchingPreset) {
        if (sources?.includedFields || sources?.excludedFields)
          setSelectedPreset({
            include: sources?.includedFields || {
              list: [],
              logic: "or",
            },
            exclude: sources?.excludedFields || {
              list: [],
              logic: "or",
            },
          });
      }
    }
  }, [
    data,
    setValue,
    setFeaturesValue,
    setContentFilterValue,
    setDetailsValue,
    sourcesData,
    setAccountName,
    setIsActive,
    resetContentFilters,
    matchingPreset,
  ]);

  useEffect(() => {
    const filters = makeContentFilterQuery(includeValues, excludeValues);
    setContentFilters(filters);
  }, [excludeValues, includeValues]);

  const saveAccountButton = (
    <Button
      variant={!isMobile ? "contained" : "text"}
      color="primary"
      sx={
        isMobile
          ? { fontSize: "16px" }
          : { height: "42px", width: "196px", fontSize: "15px" }
      }
      onClick={goBack}
    >
      Done
    </Button>
  );

  const convertButton = (
    <Button
      startIcon={<ChangeCircleIcon />}
      variant={!isMobile ? "contained" : "text"}
      color="secondary"
      sx={
        isMobile
          ? { fontSize: "16px" }
          : { height: "42px", width: "196px", fontSize: "15px" }
      }
      onClick={convertAccountDialog.show}
    >
      Convert
    </Button>
  );

  const convertBody = useMemo(
    () => ({
      accountId: accountId,
      expiration: data?.expirationDate,
      note: "",
      isTrial: data?.isTrial,
      isActive: data?.isActive,
      isPaying: data?.isPaying,
      accountManagerId: data?.accountManager ?? undefined,
      seatCount: data?.seatCount,
      annualizedRecurringRevenue: data?.annualRecurringRevenue,
    }),
    [data, accountId]
  );

  const deactivateAccount = (convertKey: string, value?: string | boolean) => {
    if (accountId) {
      accountConvert.mutate({
        body: { ...convertBody, [convertKey]: value },
        path: { id: accountId },
      });
    }
  };

  const submitAccountData = handleSubmit(
    (detailsData: EditAccountDetailsFormValues) => {
      if (!data) return;
      accountUpdate.mutate({
        body: {
          name: detailsData.name,
          organizationType: detailsData.organizationType,
          country: detailsData.country,
          city: detailsData.city,
          stateOrProvince: detailsData.stateOrProvince,
          postalCode: detailsData.postalCode,
          contentAccessFilter: contentFilters,
          entitlements: makeEntitlements(featuresFormData),
          id: accountId,
          timeZone: detailsData.timezone,
        },
        path: { id: accountId },
      });
    }
  );

  const pageHeaderDesktop = (
    <Stack
      flexWrap="wrap"
      direction="row"
      mb={3}
      justifyContent="space-between"
      alignItems="center"
      marginBottom={3.75}
    >
      <Stack>
        <Typography flexGrow={1} variant="h6" mr={2}>
          Edit Account
        </Typography>
        <AppTopNavBar
          lastBreadcrumbLabel={data?.name || ""}
          sx={{ mb: 2, mt: 0.5 }}
        />
      </Stack>

      <Stack direction="row" spacing={2}>
        {convertButton}
        {saveAccountButton}
      </Stack>
    </Stack>
  );

  const content = (
    <Fragment>
      <AppTabLayout
        flex={1}
        overflow="auto"
        variant="scrollable"
        currentTab={accountsTab}
        onCurrentTabChange={(tab) =>
          setAccountsTab(tab as AccountsManagementTabs)
        }
      >
        <AppTab
          label="General"
          value={AccountsManagementTabs.general}
          children={
            <FormProvider {...accountDetailsFormHook}>
              <AccountsManagementGeneralTab
                deactivateAccount={deactivateAccount}
                data={data}
                accountManagerData={accountManagerData}
              />
            </FormProvider>
          }
        />
        <AppTab
          label="Content Filter"
          value={AccountsManagementTabs.content}
          children={
            <FormProvider {...accountContentFilterFormHook}>
              <AccountsManagementEditContentTab
                contentFilters={contentFilters}
                data={data}
                selectedPreset={selectedPreset}
                setSelectedPreset={setSelectedPreset}
              />
            </FormProvider>
          }
        />
        <AppTab
          label="Features"
          value={AccountsManagementTabs.features}
          children={
            <FormProvider {...accountFeaturesFormHook}>
              <AccountsManagementEditFeaturesTab data={data} />
            </FormProvider>
          }
        />
        <AppTab
          label="Customization"
          value={AccountsManagementTabs.customization}
          children={<SettingsCustomizationTab page="account" />}
        />
      </AppTabLayout>
      {data && (
        <AccountsManagementConvertDialog
          title={`Convert ${accountName} account`}
          open={convertAccountDialog.isOpen}
          onClose={convertAccountDialog.hide}
          accountId={accountId}
          accountExpirationDate={data?.expirationDate}
          seatCount={data?.seatCount}
          status={data?.status}
          isActive={data?.isActive}
          isTrial={data?.isTrial}
          isPaying={data?.isPaying}
          accountManager={data?.accountManager}
          accountManagerData={accountManagerData}
          annualizedRecurringRevenue={data?.annualRecurringRevenue}
        />
      )}
      {blocker && (
        <FormProvider {...accountDetailsFormHook}>
          <AccountsManagementLeaveConfirmationDialog
            title="Save changes?"
            accountDetailsDirtyFields={formState.dirtyFields}
            accountContentFiltersDirtyFields={
              accountContentFilterFormState.dirtyFields
            }
            errors={formState.errors}
            accountFeaturesDirtyFields={accountFeaturesFormState.dirtyFields}
            onSubmit={submitAccountData}
            onDataReset={() => blocker.proceed?.()}
            open={blocker.state === "blocked"}
            onClose={() => blocker.reset?.()}
          />
        </FormProvider>
      )}
    </Fragment>
  );

  return <PageLayoutDesktop header={pageHeaderDesktop} content={content} />;
};
