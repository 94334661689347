import { ReactNode } from "react";
import AutoSizer from "react-virtualized-auto-sizer";

export type TileLayoutAutoSizerProps = {
  /** count of items loaded */
  count: number;

  /** total number of items */
  total: number;

  /** Calculate column count */
  getColumnCount: (containerWidth: number) => number;

  children: (params: {
    itemCount: number;
    columnCount: number;
    rowCount: number;
    width: number;
    height: number;
  }) => ReactNode;
};

export function TileLayoutAutoSizer({
  count,
  total,
  getColumnCount,
  children,
}: TileLayoutAutoSizerProps) {
  return (
    <AutoSizer>
      {({ width, height }: { width: number; height: number }) => {
        // for onItemsRendered column we prefetch limited number of items
        const fetchCountSingleColumn = 4;

        // for multiple columns - prefetch remaining items in a partial row plus several new rows
        const fetchRowCountMultiColumn = 2;
        const columnCount = getColumnCount(width) ?? 1;

        // when total is omitted remains is infinity accurate and may result in
        //more loading indicators than actually items remained
        const remains = total - count;

        const nextBlockSize =
          columnCount === 1
            ? Math.min(remains, fetchCountSingleColumn)
            : Math.min(
                remains,
                columnCount * fetchRowCountMultiColumn + (count % columnCount)
              );

        const hasNextPage = count < total;
        const itemCount = hasNextPage ? count + nextBlockSize : count;

        // check if items fit in integer number of rows and add space to contain loading indicator
        const isFullRow = itemCount % columnCount === 0;
        const rowCount = isFullRow
          ? itemCount / columnCount + (hasNextPage ? 1 : 0)
          : Math.floor(itemCount / columnCount) + 1;

        return children({ width, height, columnCount, rowCount, itemCount });
      }}
    </AutoSizer>
  );
}
