import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { paths } from "@tveyes/twosionwebapischema";
import { UseMutationOptions, useMutation } from "react-query";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { ReportItem } from "src/models/Report";
import { EventDetails } from "src/models/EventDetails";

const apiKey: keyof paths = "/api/report/instantplay";
const method: keyof paths[typeof apiKey] = "post";

type Endpoint = paths[typeof apiKey][typeof method];

// FIXME: this is to make response fields non-optional,
// keep an eye for type updates and get rid of this mock
type Response = ReportItem;

type RequestMutationFnParams = {
  title: string;
  startDateTime?: string;
  endDateTime?: string;
  description?: string;
  event: EventDetails;
  notify?: boolean;
};

type RequestOptions = Pick<
  UseMutationOptions<Response, unknown, RequestMutationFnParams>,
  "onSuccess" | "onError"
>;

export function useReportCreateInstantPlay({
  options,
}: {
  options?: RequestOptions;
}) {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation<Response, unknown, RequestMutationFnParams, void>({
    ...options,
    mutationKey: [apiKey],
    mutationFn: async ({
      title,
      startDateTime,
      endDateTime,
      description,
      event,
      notify,
    }) => {
      const token = await getAccessTokenSilently();
      const url = makeApiUrl<Endpoint["parameters"]>(apiKey, {
        query: {
          title,
          startDateTime,
          endDateTime,
          description,
          notify,
        },
      });

      const { data } = await axios<Response>({
        url,
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: event,
      });

      return data;
    },
    onSuccess: (data, variables, context) => {
      options?.onSuccess && options.onSuccess(data, variables, context);
    },
  });
}
