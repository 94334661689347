import { useState } from "react";
import { DateRange } from "@mui/icons-material";
import {
  BoxProps,
  Divider,
  Stack,
  Tooltip,
  Typography,
  useTheme,
  sliderClasses,
  Box,
  buttonBaseClasses,
  alpha,
} from "@mui/material";
import { WatchTermResultItem } from "src/api/useWatchTermResults";
import {
  getPowerSearchResultEvent,
  getPowerSearchResultDateTime,
  getPowerSearchResultEventType,
  getPowerSearchResultMediaType,
  getPowerSearchResultTag,
  getPowerSearchResultIndividualTranscript,
  getPowerSearchResultDuplicates,
  getPowerSearchResultId,
} from "src/api/usePowerSearchResult.getters";
import { useEventSelectionDuplicatesDrawer } from "src/api/useEventSelectionDuplicatesDrawer";
import { getEventIcon } from "src/utils/getEventIcon";
import { TranscriptBlock } from "src/components/TranscriptBlock/TranscriptBlock";
import { TranscriptText } from "src/components/TranscriptText/TranscriptText";
import { TextLineClamp } from "src/components/TextLineClamp/TextLineClamp";
import { watchListTermResultClipRoute } from "src/pages/WatchListTermResultClip/WatchListTermResultClip.route";
import { AppLink } from "src/components/AppLink/AppLink";
import { AdvChip } from "src/components/AdvChip/AdvChip";
import { TileCheckBox } from "src/components/TileCheckBox/TileCheckBox";
import { useEventSelectionWatchTermResults } from "src/api/useEventSelectionWatchTermResults";
import { ClipViewer } from "src/components/ClipViewer/ClipViewer";
import { ClipViewerProps } from "src/components/ClipViewer/ClipViewer.model";
import { TranscriptParser } from "src/components/TranscriptParser/TranscriptParser";
import { DuplicatesChip } from "src/components/DuplicatesChip/DuplicatesChip";
import { stripHTMLTags } from "src/utils/stripHTMLTags";
import { WatchTermResultsActions } from "../WatchTermResultsActions/WatchTermResultsActions";

export function WatchTermResultTile({
  value,
  queryId,
  width = "100%",
  height = "100%",
  isExpandedTextMode,
  isChecked,
  isPlaying,
  onChangePlayState,
  addItemToReport,
  showDuplicatesDrawer,
  drawerParentEventId,
}: {
  value: WatchTermResultItem;
  queryId: string;
  isChecked: boolean;
  isPlaying?: boolean;
  width?: BoxProps["width"];
  height?: BoxProps["width"];
  isExpandedTextMode: boolean;
  onChangePlayState?: ClipViewerProps["onChangePlayState"];
  addItemToReport: (value: WatchTermResultItem) => void;
  showDuplicatesDrawer: (eventId: string, duplicatesIds: string[]) => void;
  drawerParentEventId?: string;
}) {
  const { palette } = useTheme();

  const [activeMention, setActiveMention] = useState(0);

  const eventId = getPowerSearchResultId(value);
  const eventType = getPowerSearchResultEventType(value);
  const mediaType = getPowerSearchResultMediaType(value);
  const Icon = getEventIcon(eventType, mediaType);

  const eventTitle = getPowerSearchResultEvent(value);
  const detailsUrl = watchListTermResultClipRoute.makeUrl({
    queryId,
    eventId,
  });

  const transcript = getPowerSearchResultIndividualTranscript(
    value,
    activeMention,
    isExpandedTextMode
  );
  const transcriptTitle = stripHTMLTags({ transcript });

  const duplicatesIds = getPowerSearchResultDuplicates(value);
  const duplicatesDrawerEventsState = useEventSelectionDuplicatesDrawer();
  const selectedDuplicatesIds = duplicatesDrawerEventsState.list.map(
    (ev) => ev.id
  );
  const itemSelectedDuplicates = duplicatesIds.filter((id) =>
    selectedDuplicatesIds.includes(id)
  );

  const selectedEventsState = useEventSelectionWatchTermResults();

  const handleChange = (checked: boolean) => {
    if (checked) {
      selectedEventsState.select(value);
    } else {
      selectedEventsState.unselect(value);
    }
  };

  return (
    <Stack
      border={`1px solid ${palette.divider}`}
      borderRadius={2}
      width={width}
      height={height}
      position="relative"
    >
      <ClipViewer
        id={eventId}
        event={value}
        play={isPlaying}
        viewMode="compact"
        onChangePlayState={onChangePlayState}
        onMentionChange={setActiveMention}
        sx={{
          [`& > .MuiBox-root:first-of-type div`]: {
            borderTopRightRadius: 7, // not 8 because of outer 1px border
            borderTopLeftRadius: 7, // not 8 because of outer 1px border
            overflow: "hidden",
          },
          [`.${sliderClasses.root}`]: {
            marginTop: "-5px",
          },
          [`.player-container .${buttonBaseClasses.root}`]: {
            minWidth: "59px",
            width: "59px",
            height: "59px",
            opacity: "0.4",
          },
          backgroundColor:
            eventId === drawerParentEventId
              ? alpha(palette.secondary.main, 0.08)
              : "inherit",
        }}
      />

      <TileCheckBox value={isChecked} onChange={handleChange} zIndex={2} />

      {getPowerSearchResultTag(value).length ? (
        <AdvChip
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        />
      ) : null}

      {!!duplicatesIds.length && (
        <Box
          sx={{
            position: "absolute",
            right: getPowerSearchResultTag(value).length ? 56 : 8,
            top: 8,
            backgroundColor:
              palette.mode === "light"
                ? palette.common.white
                : palette.common.black,
            borderRadius: "16px",
          }}
        >
          <DuplicatesChip
            count={duplicatesIds.length}
            selectedCount={itemSelectedDuplicates.length}
            onClick={() => showDuplicatesDrawer(eventId, duplicatesIds)}
          />
        </Box>
      )}

      <Divider />

      <Stack
        direction="row"
        pl={2}
        py={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack rowGap={1}>
          <Stack direction="row" alignItems="center">
            <Tooltip title={eventType}>
              <Icon />
            </Tooltip>
            <Tooltip title={eventTitle}>
              <AppLink to={detailsUrl}>
                <TextLineClamp
                  variant="subtitle1"
                  color="primary"
                  lineClamp={1}
                  ml={1}
                  height={20}
                >
                  {getPowerSearchResultEvent(value)}
                </TextLineClamp>
              </AppLink>
            </Tooltip>
          </Stack>

          <Stack direction="row" alignItems="center">
            <DateRange sx={{ color: palette.action.active }} />
            <Typography variant="body2" ml={1}>
              {getPowerSearchResultDateTime(value).toLocaleString()}
            </Typography>
          </Stack>
        </Stack>
        <WatchTermResultsActions
          value={value}
          addItemToReport={addItemToReport}
          queryId={queryId}
        />
      </Stack>
      <Tooltip title={transcriptTitle}>
        <TranscriptBlock borderRadius={1} mx={2} mb={2} px={2} py={1}>
          {isExpandedTextMode ? (
            <Typography variant="body2">
              <TranscriptParser
                highlightColor={palette.primary.main}
                transcript={transcript}
              />
            </Typography>
          ) : (
            <TranscriptText
              variant="body2"
              transcript={transcript}
              lineClamp={2}
            />
          )}
        </TranscriptBlock>
      </Tooltip>
    </Stack>
  );
}
