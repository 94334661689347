import { useRef } from "react";
import { Add, SearchOutlined } from "@mui/icons-material";
import { Grid, Stack, useMediaQuery, useTheme } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { usePowerSearchQueryKeyword } from "src/api/usePowerSearchQueryKeyword";
import { powerSearchResultRoute } from "src/pages/PowerSearchResult/PowerSearchResult.route";
import { LoadingButton } from "../buttons/LoadingButton";
import { SourcesPicker } from "../SourcesPicker/SourcesPicker";
import { WatchTermInput } from "../WatchTermInput/WatchTermInput";
import { WatchQueryKeywordFormValues } from "../WatchQueryKeywordForm/WatchQueryKeywordForm.model";

export function GlobalSearchFormSimple({
  hideButtons,
  setTitlePromptOpen,
  setPrevDisplayName,
}: {
  hideButtons?: boolean;
  setTitlePromptOpen: (v: boolean) => void;
  setPrevDisplayName: (v: string) => void;
}) {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const containerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const powerSearchQueryKeyword = usePowerSearchQueryKeyword();

  const { handleSubmit, control, formState, watch } =
    useFormContext<WatchQueryKeywordFormValues>();

  const { displayName } = watch();

  const isBusy = formState.isSubmitting;

  const showWatchQueryTitleDialog = () => {
    setPrevDisplayName(displayName);
    setTitlePromptOpen(true);
  };

  const handlePowerSearch = (data: WatchQueryKeywordFormValues) => {
    powerSearchQueryKeyword.save(data);

    navigate(powerSearchResultRoute.path);
  };

  const watchTermIncludeRow = (
    <Grid item xs={12} md={6} lg={6}>
      <Controller
        name="termsInclude"
        control={control}
        render={({ field, fieldState, formState }) => {
          const { list, logic } = formState.errors[field.name] || {};
          const message = list?.message || logic?.message || " ";

          return (
            <WatchTermInput
              id="watch-term-input"
              label="Keyword"
              value={field.value}
              options={[]} // no suggestions for now
              onChange={field.onChange}
              onBlur={field.onBlur}
              autoFocus
              disabled={isBusy}
              error={!!fieldState.error}
              helperText={message}
              displayTooltip
            />
          );
        }}
      />
    </Grid>
  );

  const sourcesRow = (
    <Grid item xs={12} md={6} lg={6}>
      <Controller
        name="sourcesInclude"
        control={control}
        render={({ field, fieldState }) => {
          const defaultLabel = field.value.list.length
            ? "Source"
            : "All Sources";

          return (
            <SourcesPicker
              id="source-picker"
              label={defaultLabel}
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
              disabled={isBusy}
              error={!!fieldState.error}
              helperText={fieldState.error?.message || " "}
              dropDownBoundsEl={containerRef}
            />
          );
        }}
      />
    </Grid>
  );

  return (
    <form>
      <div ref={containerRef} style={{ width: "100%" }} />
      <Grid
        container
        rowSpacing={isMobile ? 1.5 : 3}
        columnSpacing={{ xs: 2, xl: 3 }}
      >
        {watchTermIncludeRow}
        {sourcesRow}

        {!hideButtons && (
          <Grid item xs={12} md={12} lg={3}>
            <Stack direction="row" spacing={{ xs: 2, xl: 3 }} width="100%">
              <LoadingButton
                variant="contained"
                color="info"
                id="add-watch-term"
                sx={{
                  width: "100%",
                  height: 56,
                  minWidth: isMobile ? 160 : 176,
                }}
                disabled={isBusy || !formState.isValid}
                onClick={showWatchQueryTitleDialog}
                loading={isBusy}
                startIcon={<Add />}
              >
                Watch term
              </LoadingButton>
              <LoadingButton
                variant="contained"
                color="primary"
                sx={{
                  width: "100%",
                  height: 56,
                  minWidth: isMobile ? 160 : 176,
                }}
                disabled={isBusy || !formState.isValid}
                loading={isBusy}
                startIcon={<SearchOutlined />}
                onClick={handleSubmit(handlePowerSearch)}
              >
                Search
              </LoadingButton>
            </Stack>
          </Grid>
        )}
      </Grid>
    </form>
  );
}
