import React, { PropsWithChildren, useEffect, useState } from "react";
import { useParentBgColor } from "src/utils/useParentBgColor";
import { ScrollBox } from "../ScrollBox/ScrollBox";

export type AppAutocompleteChipRowProps = PropsWithChildren<{
  scrollToLast?: boolean;
  size: "small" | "medium";
}>;

export const AppAutocompleteChipRow = ({
  scrollToLast,
  size,
  ...props
}: AppAutocompleteChipRowProps) => {
  const [scrollContainerEl, setScrollContainerEl] =
    useState<HTMLDivElement | null>(null);
  const [lastTagElement, setLastTagElement] = useState<HTMLDivElement | null>(
    null
  );

  useEffect(() => {
    if (!scrollToLast) {
      return;
    }

    scrollContainerEl?.scrollTo({ left: lastTagElement?.offsetLeft });
  }, [scrollContainerEl, lastTagElement, scrollToLast]);

  const total = React.Children.count(props.children);
  const tagElements = React.Children.map(props.children, (child, i) => {
    if (i === total - 1) {
      return <div ref={(ref) => setLastTagElement(ref)}>{child}</div>;
    }

    return child;
  });

  const gradientEndColor = useParentBgColor(scrollContainerEl);

  return (
    <ScrollBox
      ref={setScrollContainerEl}
      direction="row"
      className="AppAutocompleteTagContainer-root"
      children={tagElements}
      flex={1}
      position="relative"
      alignItems="center"
      customScrollBarStyle="hidden"
      shadowColor={gradientEndColor}
    />
  );
};
