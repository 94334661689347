import { SetStateAction, useEffect, useState } from "react";
import { Info } from "@mui/icons-material";
import {
  Button,
  FormControlLabel,
  Stack,
  Switch,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { PopperInfo } from "src/components/PopperInfo/PopperInfo";
import { AlertConfig } from "../ScheduledAlertPopover.model";

const archiveAlertTypes = ["weekly", "monthly"];

type ArchiveAlertsType = {
  config: AlertConfig;
  sheduledAlertArchiveEnabled: boolean;
  setSheduledAlertArchiveEnabled: (value: SetStateAction<boolean>) => void;
};

export function ArchiveAlerts({
  config,
  sheduledAlertArchiveEnabled,
  setSheduledAlertArchiveEnabled,
}: ArchiveAlertsType) {
  const { palette, breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (e: any) => {
    if (!isMobile) return;
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (
      !archiveAlertTypes.includes(config.type) &&
      sheduledAlertArchiveEnabled
    ) {
      setSheduledAlertArchiveEnabled(false);
    }
  }, [
    config.type,
    setSheduledAlertArchiveEnabled,
    sheduledAlertArchiveEnabled,
  ]);

  return archiveAlertTypes.includes(config.type) ? (
    <Stack
      border={`1px solid ${palette.action.disabledBackground}`}
      flexDirection="row"
      borderRadius={1}
      pl={2}
      py={2}
    >
      <FormControlLabel
        label="Archive Alerts"
        control={
          <Switch
            checked={sheduledAlertArchiveEnabled}
            onChange={() =>
              setSheduledAlertArchiveEnabled(!sheduledAlertArchiveEnabled)
            }
          />
        }
      />
      <Tooltip title="Once you activate this toggle, you'll receive the ‘Scheduled Alert’, and the Report will be generated automatically.">
        <Info
          sx={{
            color: palette.text.disabled,
            width: "24px",
            height: "24px",
            margin: "4px 8px 0px 0px",
          }}
          onClick={handleClick}
        />
      </Tooltip>
      <PopperInfo
        open={open}
        anchorEl={anchorEl}
        title="Archive Alerts"
        text="Once you activate this toggle, you'll receive the ‘Scheduled Alert’, and the Report will be generated automatically."
        onClose={handleClose}
        footer={
          <Stack
            flexDirection="row"
            justifyContent="end"
            alignItems="center"
            px={3}
            py={2}
            gap={1}
          >
            <Button onClick={handleClose} color="error">
              Close
            </Button>
          </Stack>
        }
      />
    </Stack>
  ) : null;
}
