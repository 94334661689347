import { darken, lighten, Stack, Typography, useTheme } from "@mui/material";
import { WarningAmber } from "@mui/icons-material";

export const AdvertisementFilterNotice = () => {
  const { palette } = useTheme();
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        width: "100%",
        minHeight: "76px",
        backgroundColor: lighten(palette.warning.main, 0.9),
        borderRadius: "4px",
        color: darken(palette.warning.main, 0.6),
        p: 2,
      }}
    >
      <WarningAmber
        sx={{
          color: darken(palette.warning.main, 0.6),
          mr: "12px",
          alignSelf: "flex-start",
        }}
      />
      <Stack>
        <Typography fontSize="16px" fontWeight={500}>
          Advertisement Filter Notice
        </Typography>
        <Typography variant="body2">
          Only broadcast events are shown. Other event types are excluded.
          Support for other event types is in development.
        </Typography>
      </Stack>
    </Stack>
  );
};
