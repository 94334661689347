import { format } from "date-fns";
/**
 * FIXME: this purpose of this entire file is to provide a value getters for badly typed
 * power search result. We may get rid of this once types are stabilized.
 */
import { PowerSearchResultItem } from "./usePowerSearch";
import { EventDetails } from "src/models/EventDetails";

export function getPowerSearchResultId(value: PowerSearchResultItem) {
  return value.id;
}

export function getPowerSearchResultEvent(value: PowerSearchResultItem) {
  return value.title;
}

export function getPowerSearchResultMediaType(value: PowerSearchResultItem) {
  return value.mediaType || " - ";
}

export function getPowerSearchResultEventType(value: PowerSearchResultItem) {
  return value.eventType || " - ";
}

export function getPowerSearchResultTranscript(value: PowerSearchResultItem) {
  return value.highlights.map(({ text }) => text).join(", ");
}

export function getPowerSearchResultIndividualTranscript(
  value: PowerSearchResultItem,
  idx: number,
  expand: boolean
) {
  if (expand) {
    return (
      value.highlights
        .slice(idx, idx + 2)
        .map(({ text }) => text)
        .join(" ") || " - "
    );
  }

  return value.highlights[idx]?.text || " - ";
}

export function getPowerSearchResultDateTime(value: PowerSearchResultItem) {
  const startDateTime = value.startDateTime
    ? format(new Date(value.startDateTime), "PP pp")
    : " - ";

  return startDateTime;
}

export function getPowerSearchResultCountry(value: PowerSearchResultItem) {
  return value.country || " - ";
}

export function getPowerSearchResultMarket(value: PowerSearchResultItem) {
  return value.market || " - ";
}

export function getPowerSearchResultState(value: PowerSearchResultItem) {
  return "FIXME: state is unmapped";
}

export function getPowerSearchResultStation(value: PowerSearchResultItem) {
  return "FIXME: station is unmapped";
}

export function getPowerSearchResultViews(value: PowerSearchResultItem) {
  return value.viewership?.toLocaleString() || " - ";
}

export function getPowerSearchResultCategory(value: PowerSearchResultItem) {
  return value?.category || " - ";
}

export function getPowerSearchResultSource(value: PowerSearchResultItem) {
  return value.source || " - ";
}

export function getPowerSearchResultLanguage(
  value: PowerSearchResultItem
): string {
  return value.language || " - ";
}

export function getPowerSearchResultDuplicates(value: PowerSearchResultItem) {
  return value.duplicates || [];
}

export function getPowerSearchResultTag(value: PowerSearchResultItem) {
  return value.adTrackIds || [];
}

export function getEventDetailsMediaDownloadExtension(value: EventDetails) {
  return value.mediaDownloadExtension?.toUpperCase() || "";
}
