import { Stack } from "@mui/material";
import {
  differenceInSeconds,
  isAfter,
  isValid,
  roundToNearestMinutes,
  sub,
} from "date-fns";
import { useCallback, useMemo, useState } from "react";
import { makeTimeLineBlockParams } from "src/utils/makeTimeLineBlockParams";
import { timeLineMaxScale, timeLineMinScale } from "./TimeLine.const";
import { TimeLineProps } from "./TimeLine.model";
import { TimeLineBlockLayout } from "./components/TimeLineBlockLayout/TimeLineBlockLayout";
import { TimeLineCursorRange } from "./components/TimeLineCursorRange/TimeLineCursorRange";
import { TimeLineDragBox } from "./components/TimeLineDragBox/TimeLineDragBox";
import { TimeLineZoomControls } from "./components/TimeLineZoomControls/TimeLineZoomControls";
import { TimeLineCursorBlock } from "./components/TimeLineCursorBlock/TimeLineCursorBlock";

/**
 * Draggable TimeLine base component
 */
export function TimeLine({
  value,
  onChange,
  blockCount = 14,
  height = 100,
  maxDate,
  cursorBounds = [0, +20],
  hideCursor,
  hideCursorBounds,
  hideZoomControls,
  showBounderyLines,
  zoomControlPosition = "inside",
  isPlaying,
  renderBlockContent,
  renderTimeLineContent,
  renderDisabledBlockContent,
  disabledColor,
  initialUserScale,
}: TimeLineProps) {
  const [userScale, setUserScale] = useState(initialUserScale ?? 1.0);
  const scale = isPlaying ? timeLineMaxScale : userScale;
  const [dragOrigin, setDragOrigin] = useState<{
    position: number;
    time: Date;
  } | null>(null);

  const blockParams = useMemo(() => makeTimeLineBlockParams(scale), [scale]);
  const secondsPerPixel = blockParams.spanSec / blockParams.width;
  const stepValue = roundToNearestMinutes(value, {
    nearestTo: blockParams.spanSec / 60,
    roundingMethod: "floor",
  });

  const [min, max] = cursorBounds;
  const absMin = Math.abs(min);
  const absMax = Math.abs(max);

  const rangeWidth = ((absMin + absMax) * 60) / secondsPerPixel;

  const blockOffset = differenceInSeconds(value, stepValue) / secondsPerPixel;

  const handleDragStart = useCallback(
    (x: number) => {
      setDragOrigin({ position: x, time: value });
    },
    [value]
  );

  const handleDrag = useCallback(
    (x: number) => {
      if (!dragOrigin) {
        return;
      }

      const offset = x - dragOrigin.position;
      const offsetSec = offset * secondsPerPixel;
      const nextDateTime = sub(dragOrigin.time, { seconds: offsetSec });

      if (isAfter(nextDateTime, maxDate)) {
        // stop dragging at maxDate limit
        onChange(maxDate);
        return;
      }

      onChange(nextDateTime);
    },
    [dragOrigin, maxDate, secondsPerPixel, onChange]
  );

  const handleDragEnd = useCallback(() => {
    setDragOrigin(null);
  }, []);

  const timeLineBlocks = isValid(stepValue) && (
    <Stack
      direction="row"
      position="absolute"
      top={0}
      bottom={0}
      right={`calc(50% + ${blockOffset}px)`}
      sx={{ transform: "translate(50%)" }}
      children={
        <TimeLineBlockLayout
          date={stepValue}
          maxDate={maxDate}
          count={blockCount}
          blockParams={blockParams}
          renderBlockContent={renderBlockContent}
          renderDisabledBlockContent={renderDisabledBlockContent}
          disabledColor={disabledColor}
        />
      }
    />
  );

  return (
    <Stack
      direction="row"
      position="relative"
      sx={{
        overflowX: "clip" /* must use clip for this */,
        overflowY: "visible",
      }}
      minHeight={height}
      maxHeight={height}
    >
      {timeLineBlocks}

      {!hideCursorBounds && (
        <TimeLineCursorRange
          // this should scale with block size
          showBounderyLines={showBounderyLines}
          bounds={cursorBounds}
          secondsPerPixel={secondsPerPixel}
        />
      )}

      {renderTimeLineContent?.({ secondsPerPixel })}

      {!hideCursor && <TimeLineCursorBlock width={rangeWidth} />}

      <TimeLineDragBox
        position="absolute"
        left={0}
        top={0}
        right={0}
        bottom={0}
        onDragStart={handleDragStart}
        onDrag={handleDrag}
        onDragEnd={handleDragEnd}
      />

      {!hideZoomControls && (
        <TimeLineZoomControls
          value={scale}
          onChange={setUserScale}
          minScale={timeLineMinScale}
          maxScale={timeLineMaxScale}
          disabled={isPlaying}
          position={zoomControlPosition}
        />
      )}
    </Stack>
  );
}
