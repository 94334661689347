import { useCallback, useEffect, useState } from "react";
import { DrawerProps, Stack } from "@mui/material";
import { useWatchQueryAlertEdit } from "src/api/useWatchQueryAlertEdit";
import {
  OriginType,
  useWatchQueryAlertCreate,
} from "src/api/useWatchQueryAlertCreate";
import { makeTypedPropList } from "src/utils/makeTypedPropList";
import { AlertType } from "src/models/AlertType";
import { UserQueryDTO } from "src/models/UserQueryDTO";
import { ScheduledAlertsSection } from "./ScheduledAlertsSection/ScheduledAlertsSection";
import { EditSection } from "./EditSection/EditSection";
import { RightSideDrawer } from "../RightSideDrawer/RightSideDrawer";
import {
  AlertConfig,
  PresetTypeList,
} from "../ScheduledAlertPopover/ScheduledAlertPopover.model";
import {
  AlertTypesKey,
  formatTimeValue,
  getDefaultAlertStateData,
  getMonthlyScheduleTime,
  getScheduledAlertRuleData,
  getWeeklyScheduleTime,
} from "../ScheduledAlertPopover/ScheduledAlertPopover.utils";

type ModeType = "view" | "edit";

type AlertsDrawerProps = Omit<
  DrawerProps,
  "anchor" | "hideBackdrop" | "onClose" | "title"
> & {
  defaultAlertId?: boolean;
  watchTermName: string;
  queryId: string;
  editedRule?: AlertType | null;
  onSuccessEditRule?: (rule?: AlertType | null) => void;
  presets?: PresetTypeList;
  alerts: UserQueryDTO["alerts"];
  origin: OriginType;
  onClose: () => void;
};

export function AlertsDrawer({
  watchTermName,
  defaultAlertId,
  onSuccessEditRule,
  alerts,
  origin,
  queryId,
  presets = [],
  ...props
}: AlertsDrawerProps) {
  const [mode, setMode] = useState<ModeType>(alerts.length ? "view" : "edit");
  const [scheduledAlertEnabled, setScheduledAlertEnabled] = useState(true);

  const [config, setConfig] = useState<AlertConfig>(
    getDefaultAlertStateData(AlertTypesKey.daily)
  );

  const [sheduledAlertArchiveEnabled, setSheduledAlertArchiveEnabled] =
    useState(false);

  const [editRule, setEditRule] = useState<AlertType | null>(null);

  const scheduledAlerts = alerts.filter((alert) => alert.type !== "realtime");

  useEffect(() => {
    const isAlertsEnabled = scheduledAlerts.every((alert) => alert.enabled);
    setScheduledAlertEnabled(isAlertsEnabled);
  }, [scheduledAlerts]);

  const isScheduledAlertsEnabled = scheduledAlerts.every(
    (alert) => alert.enabled
  );

  const handleModeChange = () => {
    setMode((prev) => (prev === "view" ? "edit" : "view"));
  };

  const alertCreate = useWatchQueryAlertCreate({
    options: {
      onSettled: () => {
        setConfig(getDefaultAlertStateData(AlertTypesKey.daily));
        setSheduledAlertArchiveEnabled(false);
        handleModeChange();
      },
      origin,
      type: "scheduled",
    },
  });
  const scheduledAlertCreate = () => {
    if (!queryId) return;

    let scheduleTime: string[] = [];
    if (config.type === AlertTypesKey.daily) {
      scheduleTime = config.time.map((time) => formatTimeValue(new Date(time)));
    }
    if (config.type === AlertTypesKey.weekly) {
      const days = {
        sat: config.sat,
        mon: config.mon,
        tue: config.tue,
        wed: config.wed,
        thu: config.thu,
        fri: config.fri,
        sun: config.sun,
      };
      const keys = makeTypedPropList(days);
      const activeDays = keys.filter((v) => days[v]);
      scheduleTime = getWeeklyScheduleTime(activeDays, [config.time]);
    }
    if (config.type === AlertTypesKey.monthly) {
      scheduleTime = getMonthlyScheduleTime(config);
    }

    alertCreate.mutate({
      params: {
        path: {
          queryId,
        },
        query: {
          type: config.type,
          archive: sheduledAlertArchiveEnabled,
          enabled: scheduledAlertEnabled,
        },
        schedule: scheduleTime,
      },
    });
  };

  const alertEdit = useWatchQueryAlertEdit({
    options: {
      onSettled: () => {
        setEditRule(null);
        setConfig(getDefaultAlertStateData(AlertTypesKey.daily));
        setSheduledAlertArchiveEnabled(false);
        onSuccessEditRule && onSuccessEditRule(null);
        handleModeChange();
      },
      origin,
    },
  });
  const scheduledAlertEdit = (editRule: AlertType) => {
    if (!editRule.id) return;

    let scheduleTime: string[] = [];
    if (config.type === AlertTypesKey.daily) {
      scheduleTime = config.time.map((time) => formatTimeValue(new Date(time)));
    }
    if (config.type === AlertTypesKey.weekly) {
      const days = {
        sat: config.sat,
        mon: config.mon,
        tue: config.tue,
        wed: config.wed,
        thu: config.thu,
        fri: config.fri,
        sun: config.sun,
      };
      const keys = makeTypedPropList(days);
      const activeDays = keys.filter((v) => days[v]);
      scheduleTime = getWeeklyScheduleTime(activeDays, [config.time]);
    }
    if (config.type === AlertTypesKey.monthly) {
      scheduleTime = getMonthlyScheduleTime(config);
    }

    alertEdit.mutate({
      params: {
        path: {
          queryId,
          alertId: editRule.id,
        },
      },
      alert: {
        ...editRule,
        type: config.type,
        schedule: scheduleTime,
        archive: sheduledAlertArchiveEnabled,
      },
    });
  };

  const addAlert = () => {
    editRule ? scheduledAlertEdit(editRule) : scheduledAlertCreate();
  };

  const editRuleHandler = useCallback((rule: AlertType) => {
    setEditRule(rule);

    const parsedRuleScheduledData = getScheduledAlertRuleData(rule);
    setConfig(parsedRuleScheduledData);
    setSheduledAlertArchiveEnabled(rule.archive || false);
    handleModeChange();
  }, []);

  const handleBack = () => {
    if (alerts.length) {
      handleModeChange();
    } else {
      props.onClose();
    }
  };

  const content = (
    <Stack height="100%">
      {mode === "view" ? (
        <ScheduledAlertsSection
          isScheduledAlertsAvailable={
            scheduledAlerts.length !== 0 && isScheduledAlertsEnabled
          }
          alerts={scheduledAlerts}
          origin={origin}
          queryId={queryId}
          open={props.open}
          onModeChange={handleModeChange}
          onEdit={editRuleHandler}
        />
      ) : (
        <EditSection
          presets={presets}
          onAddAlert={addAlert}
          onCancel={() => {
            setEditRule(null);
            setConfig(getDefaultAlertStateData(AlertTypesKey.daily));
            handleBack();
          }}
          config={config}
          setConfig={setConfig}
          sheduledAlertArchiveEnabled={sheduledAlertArchiveEnabled}
          editRule={editRule}
          setSheduledAlertArchiveEnabled={setSheduledAlertArchiveEnabled}
        />
      )}
    </Stack>
  );

  const drawerTitle =
    mode === "view"
      ? `Alerts of the “${watchTermName}”`
      : "New scheduled alert";

  return (
    <RightSideDrawer
      {...props}
      title={drawerTitle}
      onArrowBack={mode === "view" ? undefined : handleBack}
      children={content}
      PaperProps={{
        sx: { width: 568 },
      }}
      ModalProps={{
        slotProps: {
          root: {
            style: {
              zIndex: 4,
              width: 10,
            },
          },
        },
      }}
    />
  );
}
