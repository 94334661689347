import { ReactNode } from "react";
import { Article, TimerOutlined } from "@mui/icons-material";
import { AppTabLayout } from "../AppTabLayout/AppTabLayout";
import { AppTab } from "../AppTabLayout/AppTab";

export function ClipEditTabsMobile({
  transcript,
  trim,
}: {
  transcript: ReactNode;
  trim: ReactNode;
}) {
  return (
    <AppTabLayout flex={1} layout="mobile" overflow="hidden">
      <AppTab
        label="Transcription"
        value="transcript"
        icon={<Article />}
        children={transcript}
        overflow="hidden" // TranscriptView scrolls itself
      />

      <AppTab
        label="Set Time"
        value="set-time"
        icon={<TimerOutlined />}
        children={trim}
        overflow="auto"
      />
    </AppTabLayout>
  );
}
