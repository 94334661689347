import { Grid, Typography } from "@mui/material";
import { useState } from "react";
import { EventSourceOption } from "src/api/useEventSources";
import { ProgramOption } from "src/api/useProgram";
import { AppAutocomplete } from "src/components/AppAutocomplete/AppAutocomplete";
import { ClusterTagPicker } from "src/components/ClusterTagPicker/ClusterTagPicker";
import { ClusterOption } from "src/components/ClusterTagPicker/ClusterTagPicker.model";
import { OptionPicker } from "src/components/OptionPicker/OptionPicker";
import { TagPicker } from "src/components/TagPicker/TagPicker";
import {
  TagPickerLogic,
  TagPickerValue,
} from "src/components/TagPicker/TagPicker.model";
import { WatchTermCreatePrompt } from "src/components/WatchTermInput/WatchTermCreatePrompt";
import { WatchTermInput } from "src/components/WatchTermInput/WatchTermInput";
import { WatchTerm } from "src/components/WatchTermInput/WatchTermInput.model";

const plainOptions = [...new Array<EventSourceOption>(6)].map(
  (_, i) => `option ${1 + i}`
);

const complexOptions = plainOptions.map((_, i) => ({
  name: `option ${1 + i}`,
  value: `option ${1 + i}`,
  group: "preset",
}));

export function DevAppAutocomplete() {
  const [singlePlainValue, setSinglePlainValue] = useState<string>();
  const [multiplePlainValue, setMultiplePlainValue] = useState<string[]>([]);
  const [logic, setLogic] = useState<string>("or");
  const [customOptions, setCustomOptions] = useState<string[]>([]);
  const [customComplexOptions, setCustomComplexOptions] = useState<
    ProgramOption[]
  >([]);

  const [tagPickerValue, setTagPickerValue] = useState<
    TagPickerValue<ProgramOption>
  >({ list: [], logic: "or" });

  const [watchTermValue, setWatchTermValue] = useState<WatchTerm>({
    list: [],
    logic: "or",
  });

  const [cluster, setCluster] = useState<ClusterOption | null>(null);

  return (
    <Grid container p={2} rowSpacing={2} columnSpacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" children="AppAutocomplete" />
      </Grid>

      <Grid item xs={12} md={4}>
        <AppAutocomplete<string>
          label="AppAutocomplete single"
          placeholder="AppAutocomplete"
          value={singlePlainValue}
          options={plainOptions}
          onChange={(v) => setSinglePlainValue(v ?? "")}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <AppAutocomplete<string, true>
          label="AppAutocomplete multiple"
          placeholder="AppAutocomplete"
          multiple
          value={multiplePlainValue}
          options={plainOptions}
          onChange={(v) => setMultiplePlainValue(v)}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <AppAutocomplete<string, true>
          label="AppAutocomplete create"
          placeholder="AppAutocomplete"
          multiple
          fullWidth
          options={[...customOptions, ...plainOptions]}
          autoSelect
          value={multiplePlainValue}
          onChange={setMultiplePlainValue}
          getOptionFromInput={(inputValue) => inputValue}
          onInputSubmit={({ isExisting, option }) => {
            if (!isExisting) {
              // add custom to the options
              setCustomOptions((prev) => [option, ...prev]);
            }

            setMultiplePlainValue((prev) => {
              const i = prev.indexOf(option);
              if (i < 0) {
                return [option, ...prev];
              } else {
                const next = [...prev];
                next.splice(i, 1);
                return next;
              }
            });
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant="h6"
          children="TagPicker create, with custom header"
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TagPicker<ProgramOption>
          label="TagPicker create, with custom header"
          placeholder="tag picker"
          value={tagPickerValue}
          onChange={setTagPickerValue}
          getOptionKey={(o) => o.value}
          getOptionLabel={(o) => o.name}
          getOptionFromInput={(inputValue) => ({
            name: inputValue,
            value: inputValue,
            group: "custom",
          })}
          onInputSubmit={({ option, isExisting }) => {
            if (!isExisting) {
              setCustomComplexOptions((prev) => [option, ...prev]);
            }

            setTagPickerValue((prev) => ({
              ...prev,
              list: [...prev.list, option],
            }));
          }}
          groupBy={(o) => `${o.group}`}
          options={[...customComplexOptions, ...complexOptions]}
          helperText="Helper text"
          fullWidth
          renderDropDownHeader={({ inputValue }) => (
            <WatchTermCreatePrompt userInput={inputValue} />
          )}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <OptionPicker<TagPickerLogic>
          onChange={(logic) => setLogic(logic)}
          value={logic}
          getOptionLabel={(opt) => opt.toUpperCase()}
          options={["and", "or"]}
          disableAutoFocusItem
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h6" children="WatchTermInput" />
      </Grid>
      <Grid item xs={12} md={12}>
        <WatchTermInput
          label="Pick or Create"
          value={watchTermValue}
          onChange={setWatchTermValue}
          options={["option 1", "option 2"]}
          noOptionsText="Nothing to show placeholder"
          helperText="Error helper text"
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h6" children="Cluster Picker" />
      </Grid>

      <Grid item xs={12} md={6}>
        <ClusterTagPicker
          label="Pick cluster"
          value={cluster}
          placeholder="pick a cluster..."
          onChange={setCluster}
        />
      </Grid>
    </Grid>
  );
}
