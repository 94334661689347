import { Box, LinearProgress } from "@mui/material";
import InfiniteLoader from "react-window-infinite-loader";
import { TileLayoutGrid, TileLayoutGridProps } from "./TileLayoutGrid";
import {
  TileLayoutAutoSizer,
  TileLayoutAutoSizerProps,
} from "./TileLayoutAutoSizer";

type TileLayoutLazyProps<T = unknown> = {
  /** Currently loaded items */
  items: T[];

  /** Total number of items in the collection */
  total: number;

  /** called when next batch of items need to be loaded */
  loadMoreItems: (startIndex: number, stopIndex: number) => Promise<void>;

  /** when true, the component-level loading indicator will be shown */
  isLoading?: boolean;

  /** When this returns true, item loading indicator will be rendered */
  isItemLoaded: (index: number) => boolean;

  /** Render item */
  renderItem: TileLayoutGridProps<T>["renderItem"];

  /** Column gap from container width */
  getColumnGap?: TileLayoutGridProps<T>["getColumnGap"];

  /** Row gap from container width */
  getRowGap?: TileLayoutGridProps<T>["getRowGap"];

  /** Calculate column count from container width */
  getColumnCount?: TileLayoutAutoSizerProps["getColumnCount"];

  /** Render function for a item-level loading indicator */
  renderLoadingIndicator?: TileLayoutGridProps<T>["renderLoadingIndicator"];
};

export function TileLayoutLazy<T = unknown>({
  isLoading,

  items,

  total,

  getColumnGap,

  getRowGap,

  getColumnCount,

  loadMoreItems,

  isItemLoaded,

  renderItem,

  renderLoadingIndicator,
}: TileLayoutLazyProps<T>) {
  const onScrollWrapper: TileLayoutGridProps["onScroll"] = (e) => {
    console.log(`@@ DEBUG:TileLayoutLazy:onScrollWrapper`, e);
  };

  const getColumnCountWrapper: TileLayoutAutoSizerProps["getColumnCount"] = (
    containerWidth
  ) => {
    if (getColumnCount) {
      return getColumnCount(containerWidth);
    }

    const defaultColumnCount = Math.floor(containerWidth / 335);
    return defaultColumnCount;
  };

  const getColumnGapWrapper: typeof getColumnGap = (containerWidth) => {
    return getColumnGap?.(containerWidth) ?? 1;
  };

  const getRowGapWrapper: typeof getRowGap = (containerWidth) => {
    return getRowGap?.(containerWidth) ?? getColumnGapWrapper(containerWidth);
  };

  return (
    <Box flex={1} position="relative">
      <TileLayoutAutoSizer
        count={items.length}
        total={total}
        getColumnCount={getColumnCountWrapper}
      >
        {({ width, height, columnCount, rowCount, itemCount }) => {
          return (
            <InfiniteLoader
              itemCount={itemCount}
              isItemLoaded={isItemLoaded}
              loadMoreItems={loadMoreItems}
            >
              {({ onItemsRendered, ref }) => (
                <TileLayoutGrid<T>
                  innerRef={ref}
                  items={items}
                  width={width}
                  height={height}
                  columnCount={columnCount}
                  rowCount={rowCount}
                  onItemsRendered={onItemsRendered}
                  isItemLoaded={isItemLoaded}
                  getColumnGap={getColumnGapWrapper}
                  getRowGap={getRowGapWrapper}
                  renderItem={renderItem}
                  renderLoadingIndicator={renderLoadingIndicator}
                  onScroll={onScrollWrapper}
                />
              )}
            </InfiniteLoader>
          );
        }}
      </TileLayoutAutoSizer>

      {isLoading ? (
        <LinearProgress
          sx={{ position: "absolute", left: 0, top: 0, right: 0 }}
        />
      ) : null}
    </Box>
  );
}
