import { Fragment } from "react";
import { EventSourceOption } from "src/api/useEventSources";
import { useOpenState } from "src/utils/useOpenState";
import { TagPickerMobileModal } from "./TagPickerMobileModal/TagPickerMobileModal";
import { AutocompleteInline } from "../AutocompleteInline/AutocompleteInline";
import { TextInputBase } from "../TextInputBase";
import { OptionCycler } from "../OptionCycler/OptionCycler";
import { TagPickerLogic, TagPickerProps } from "../TagPicker/TagPicker.model";

type TagPickerMobileProps<T> = Omit<
  TagPickerProps<T>,
  "dropDownWidth" | "dropDownBoundsEl"
>;

export function TagPickerMobile({
  label,
  value,
  placeholder,
  getGroupTotal,
  onChange,
  onInputChange,
  renderOption,
  renderStartAdornment,
  error,
  helperText,
  disableClearable,
  getGroupLabel,
  hideOperator,
  ...props
}: TagPickerMobileProps<EventSourceOption>) {
  const menuState = useOpenState();

  const renderStartAdornmentWrapper: TagPickerProps["renderStartAdornment"] = (
    inherited
  ) => {
    const adornment = (
      <Fragment>
        {inherited}
        {value.list.length > 1 && !hideOperator && (
          <OptionCycler<TagPickerLogic>
            onChange={(logic) => onChange({ list: value.list, logic })}
            value={value.logic}
            options={["and", "or"]}
            getOptionLabel={(opt) => opt.toUpperCase()}
            onClick={(e) => {
              // to prevent Autocomplete modal opening
              e.stopPropagation();
              e.preventDefault();
            }}
          />
        )}
      </Fragment>
    );

    return renderStartAdornment ? renderStartAdornment(adornment) : adornment;
  };

  return (
    <>
      <AutocompleteInline<EventSourceOption, true>
        {...props}
        multiple
        value={value.list}
        renderStartAdornment={renderStartAdornmentWrapper}
        readOnly
        renderInput={(params) => (
          <TextInputBase
            {...params}
            label={label}
            placeholder={placeholder}
            error={error}
            helperText={helperText}
            fullWidth
            InputProps={{
              ...params.InputProps,
              readOnly: true,
              ref: undefined,
              onClick: menuState.isOpen ? menuState.hide : menuState.show,
              endAdornment: undefined,
            }}
          />
        )}
      />

      <TagPickerMobileModal
        {...props}
        getGroupLabel={getGroupLabel}
        onInputChange={onInputChange}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        menuState={menuState}
        label={label}
      />
    </>
  );
}
