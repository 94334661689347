import { create } from "zustand";
import { queryAdvertisementValues } from "src/models/Advertisement";
import { EventSourceOption } from "src/api/useEventSources";
import { Advertisement } from "src/models/Advertisement";
import { WatchTerm } from "src/components/WatchTermInput/WatchTermInput.model";
import { TagPickerValue } from "src/components/TagPicker/TagPicker.model";
import { ProgramTagPickerValue } from "src/components/ProgramTagPicker/ProgramPicker.model";
import { isWatchQueryKeywordValues } from "src/components/WatchQueryKeywordForm/WatchQueryKeyword.guard";

export type PowerSearchQueryKeywordValues = {
  termsInclude: WatchTerm;
  termsExclude: WatchTerm;

  displayName: string;

  sourcesInclude: TagPickerValue<EventSourceOption>;
  sourcesExclude: TagPickerValue<EventSourceOption>;

  advertisement: Advertisement;

  programInclude: ProgramTagPickerValue;
  programExclude: ProgramTagPickerValue;
};

type PowerSearchQueryState = {
  data: PowerSearchQueryKeywordValues;
  save: (data: PowerSearchQueryKeywordValues) => void;
};

const powerSearchQueryKey = "powerSearchQuery";

const parsePowerSearchQueryKeyword = (): PowerSearchQueryKeywordValues => {
  try {
    const json = localStorage.getItem(powerSearchQueryKey);
    const query = json && JSON.parse(json);

    if (isWatchQueryKeywordValues(query)) {
      return query;
    }
  } catch (e) {
    console.error(`Unable to parse saved query`, e);
  }

  return {
    displayName: "",

    // terms
    termsInclude: {
      list: [],
      logic: "or",
    },
    termsExclude: {
      list: [],
      logic: "or",
    },
    // terms

    // sources
    sourcesInclude: {
      list: [],
      logic: "or",
    },
    sourcesExclude: {
      list: [],
      logic: "or",
    },
    // sources

    // program
    programInclude: {
      list: [],
      logic: "or",
    },
    programExclude: {
      list: [],
      logic: "or",
    },
    // program

    advertisement: queryAdvertisementValues.all,
  };
};

export const usePowerSearchQueryKeyword = create<PowerSearchQueryState>(
  (setState) => ({
    data: parsePowerSearchQueryKeyword(),
    save: (data) => {
      const json = JSON.stringify(data);
      localStorage.setItem(powerSearchQueryKey, json);
      setState({ data });
    },
  })
);
