import { Tooltip, Palette, Box } from "@mui/material";
import {
  getReportTitle,
  getReportDateTime,
  getReportDescription,
  getReportEventsCount,
  getReportId,
  getReportStatus,
} from "src/api/useReport.getters";
import { TextLineClamp } from "src/components/TextLineClamp/TextLineClamp";
import { AppLink } from "src/components/AppLink/AppLink";
import { publicReportRoute } from "src/pages/PublicReport/PublicReport.route";
import { ReportsTableColDef } from "./ReportsTable.model";
import { ReportItemActions } from "../ReportItemActions/ReportItemActions";
import { ReportsTableClasses } from "./ReportsTable.const";
import { ReportLink } from "../ReportLink";

const commonCellParams: Pick<
  ReportsTableColDef,
  "sortable" | "align" | "headerAlign" | "disableColumnMenu"
> = {
  sortable: false,
  disableColumnMenu: true,
};

export function makeReportsTableColumns({
  palette,
}: {
  palette: Palette;
}): ReportsTableColDef[] {
  return [
    {
      ...commonCellParams,
      headerName: "Reports",
      field: "title",
      minWidth: 160,
      flex: 1,
      cellClassName: ReportsTableClasses.cellReportTitle,
      renderCell(params) {
        return getReportStatus(params.row) === "ready" ? (
          <Tooltip title={getReportTitle(params.row)}>
            <AppLink
              to={publicReportRoute.makeUrl({
                reportId: getReportId(params.row),
              })}
              target="_blank"
              rel="noopener"
            >
              <TextLineClamp lineClamp={2} color="primary">
                {getReportTitle(params.row)}
              </TextLineClamp>
            </AppLink>
          </Tooltip>
        ) : (
          <TextLineClamp lineClamp={2} color={palette.text.disabled}>
            {getReportTitle(params.row)}
          </TextLineClamp>
        );
      },
    },
    {
      ...commonCellParams,
      headerName: "Date",
      field: "date",
      type: "dateTime",
      minWidth: 140,
      renderCell(params) {
        return (
          <Tooltip title={getReportDateTime(params.row)}>
            <TextLineClamp
              lineClamp={2}
              color={
                params.row.status !== "ready"
                  ? palette.text.disabled
                  : "inherit"
              }
            >
              {getReportDateTime(params.row)}
            </TextLineClamp>
          </Tooltip>
        );
      },
    },
    {
      ...commonCellParams,
      headerName: "Description",
      field: "description",
      flex: 1,
      minWidth: 160,
      renderCell(params) {
        return (
          <Tooltip title={getReportDescription(params.row)}>
            <TextLineClamp
              lineClamp={2}
              color={
                params.row.status !== "ready"
                  ? palette.text.disabled
                  : "inherit"
              }
            >
              {getReportDescription(params.row)}
            </TextLineClamp>
          </Tooltip>
        );
      },
    },
    {
      ...commonCellParams,
      headerName: "Events",
      headerAlign: "center",
      field: "views",
      align: "center",
      type: "number",
      valueGetter(params) {
        return getReportEventsCount(params.row);
      },
      valueFormatter({ value }) {
        if (value === undefined) {
          return "";
        }
        if (Number.isNaN(value)) {
          return "-";
        }
        return value.toLocaleString();
      },
      renderCell(params) {
        return (
          <Box
            color={
              params.row.status !== "ready" ? palette.text.disabled : "inherit"
            }
          >
            {params.formattedValue}
          </Box>
        );
      },
    },
    {
      ...commonCellParams,
      headerName: "Link to the Report",
      field: "reportId",
      minWidth: 170,
      valueGetter(params) {
        return getReportId(params.row);
      },
      renderCell(params) {
        return (
          <ReportLink
            reportId={getReportId(params.row)}
            reportStatus={getReportStatus(params.row)}
          />
        );
      },
    },
    {
      ...commonCellParams,
      headerName: "Actions",
      headerAlign: "center",
      field: "actions",
      align: "center",
      type: "actions",
      cellClassName: ReportsTableClasses.cellReportActions,
      renderCell(params) {
        return <ReportItemActions value={params.row} />;
      },
    },
  ];
}
