import { useMemo } from "react";
import {
  Stack,
  Badge,
  badgeClasses,
  ToggleButton,
  ClickAwayListener,
  Box,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import AddTask from "@mui/icons-material/AddTask";
import { LoadingButton } from "src/components/buttons/LoadingButton";
import { TooltipBase } from "src/components/TooltipBase/TooltipBase";
import { TooltipBaseMobile } from "src/components/TooltipBaseMobile/TooltipBaseMobile";
import { useOpenState } from "src/utils/useOpenState";
import { useLocalStorage } from "src/utils/useLocalStorage";
import { PowerSearchResultItem } from "src/api/usePowerSearch";
import { MediaCenterItem } from "src/api/useMediaCenter";
import usePreselectLimit from "src/utils/usePreselectedLimit";
import { preselectedEventsTooltipKey } from "src/api/useEventsPreselected";
import { EventsResultsExportDialogMobile } from "../EventsResultsExportDialogMobile/EventsResultsExportDialogMobile";
import { MediaCenterExportDialogMobile } from "../MediaCenterExportDialogMobile/MediaCenterExportDialogMobile";

// every commented line here is related to MMSR-2997

export function ActionButtonsMobile({
  showShareDialog,
  showReportDialog,
  preselectEvents,
  selectedEvents,
  selectedEventsLength,
  mediaCenterProps,
  // downloadPdf,
  downloadCsv,
  disableShare,
  limitEventsInReport,
}: {
  showShareDialog: () => void;
  showReportDialog: () => void;
  preselectEvents: () => void;
  selectedEvents: PowerSearchResultItem[] | MediaCenterItem[];
  selectedEventsLength: number;
  mediaCenterProps?: {
    downloadMedia: () => void;
    isDownloadDisabled: boolean;
  };
  // downloadPdf: () => void;
  downloadCsv: () => void;
  disableShare?: boolean;
  limitEventsInReport?: number;
}) {
  const exportDialog = useOpenState();
  const [tooltipWasViewed, setTooltipWasViewed] = useLocalStorage(
    preselectedEventsTooltipKey
  );
  const { t } = useTranslation();
  const { palette } = useTheme();
  const { maxEventsToPreselect, preselectLimitTitle } = usePreselectLimit();

  const isPreselectDisabled = selectedEventsLength > maxEventsToPreselect;

  const preselectEventsButton = useMemo(
    () => (
      <Badge badgeContent={selectedEventsLength} color="primary">
        <ToggleButton
          value={true}
          size="small"
          disabled={isPreselectDisabled}
          onClick={preselectEvents}
        >
          <AddTask color={isPreselectDisabled ? "disabled" : "primary"} />
        </ToggleButton>
      </Badge>
    ),
    [selectedEventsLength, isPreselectDisabled, preselectEvents]
  );

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        gap={2}
        sx={{
          px: 2,
          py: 3,
        }}
      >
        {isPreselectDisabled ? (
          <Box>
            <TooltipBaseMobile
              width={250}
              placement="top"
              title={preselectLimitTitle}
            >
              {preselectEventsButton}
            </TooltipBaseMobile>
          </Box>
        ) : (
          <ClickAwayListener onClickAway={() => setTooltipWasViewed("true")}>
            <Box>
              <TooltipBase
                open={!tooltipWasViewed}
                width={250}
                placement="top"
                title="Select required events, then click this button in order to create Report with them"
              >
                <Box onClick={() => setTooltipWasViewed("true")}>
                  {preselectEventsButton}
                </Box>
              </TooltipBase>
            </Box>
          </ClickAwayListener>
        )}

        <LoadingButton
          variant="contained"
          color="primary"
          size="large"
          sx={{
            flex: 1,
          }}
          onClick={exportDialog.show}
        >
          Export
        </LoadingButton>
        <Box>
          <TooltipBaseMobile
            title={t("limitEventsInReport", {
              limit: `${limitEventsInReport}`,
            })}
            width={300}
            disableHoverListener={!disableShare}
          >
            <Badge
              badgeContent={selectedEventsLength}
              color="success"
              sx={{
                minWidth: "calc(50% - 37px)",
                [`.${badgeClasses.badge}`]: {
                  top: 21,
                  right: 25,
                  backgroundColor: disableShare
                    ? palette.action.disabled
                    : "success",
                },
              }}
            >
              <LoadingButton
                disabled={disableShare}
                variant="contained"
                color="secondary"
                size="large"
                sx={{
                  minWidth: "140px",
                  width: "100%",
                  px: 3,
                }}
                onClick={showShareDialog}
              >
                Share
              </LoadingButton>
            </Badge>
          </TooltipBaseMobile>
        </Box>
      </Stack>
      {mediaCenterProps ? (
        <MediaCenterExportDialogMobile
          open={exportDialog.isOpen}
          onClose={exportDialog.hide}
          createReport={showReportDialog}
          downloadMedia={mediaCenterProps.downloadMedia}
          // downloadPdf={downloadPdf}
          downloadCsv={downloadCsv}
          isDownloadDisabled={mediaCenterProps.isDownloadDisabled}
        />
      ) : (
        <EventsResultsExportDialogMobile
          open={exportDialog.isOpen}
          onClose={exportDialog.hide}
          createReport={showReportDialog}
          // downloadPdf={downloadPdf}
          downloadCsv={downloadCsv}
          values={selectedEvents as PowerSearchResultItem[]}
          selectedEventsLength={selectedEventsLength}
        />
      )}
    </>
  );
}
