import { ArrowBack, Close } from "@mui/icons-material";
import {
  Divider,
  Drawer,
  DrawerProps,
  IconButton,
  Stack,
  Typography,
  backdropClasses,
  drawerClasses,
} from "@mui/material";

type RightSideDrawerProps = Omit<DrawerProps, "anchor" | "onClose"> & {
  title: string;
  onClose: () => void;
  footer?: JSX.Element;
  onArrowBack?: () => void;
};

export function RightSideDrawer({
  onClose,
  title,
  children,
  footer,
  onArrowBack,
  ...props
}: RightSideDrawerProps) {
  return (
    <Drawer
      {...props}
      anchor="right"
      onClose={onClose}
      sx={{
        [`.${backdropClasses.root}`]: {
          opacity: "0.6 !important",
        },
        [`.${drawerClasses.paper}`]: {
          borderTopLeftRadius: 8,
          borderBottomLeftRadius: 8,
        },
      }}
    >
      <Stack justifyContent="space-between" height="100%">
        <Stack>
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            px={2}
            height={64}
          >
            <Stack flexDirection="row" alignItems="center">
              {onArrowBack && (
                <IconButton
                  onClick={onArrowBack}
                  sx={{
                    marginLeft:
                      -1 /* required to align icon with left border */,
                  }}
                >
                  <ArrowBack />
                </IconButton>
              )}
              <Typography variant="h6">{title}</Typography>
            </Stack>

            <IconButton onClick={onClose} edge="end">
              <Close />
            </IconButton>
          </Stack>
          <Divider />
        </Stack>

        {children}
        {footer}
      </Stack>
    </Drawer>
  );
}
