import { useEffect, useMemo } from "react";
import { format } from "date-fns";
import { FormProvider, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { useReportCreateInstantPlay } from "src/api/useReportCreateInstantPlay";
import { useReportNotify } from "src/api/useReportNotify";
import { ReportItem } from "src/models/Report";
import { EventDetails } from "src/models/EventDetails";
import { getFullPublicEventLink } from "src/utils/getFullPublicPageLink";
import { DialogBaseProps } from "../DialogBase/DialogBase.model";
import { ShareFormValues } from "../ShareForm/ShareForm.model";
import { ShareFormSchema } from "../ShareForm/ShareForm.schema";
import { ShareBaseDialog } from "../ShareBaseDialog/ShareBaseDialog";

type ShareInstantPlayDialogProps = Omit<
  DialogBaseProps,
  "title" | "onSubmit" | "onClose"
> & {
  event: EventDetails;
  startDateTime?: string;
  endDateTime?: string;
  onSubmit: () => void;
  onClose: () => void;
  copyShareLinkCallback: () => void;
};

const defaultValues = {
  recipients: [],
  subject: "",
  note: "",
};

export const ShareInstantPlayDialog = ({
  event,
  startDateTime,
  endDateTime,
  onClose,
  onSubmit,
  copyShareLinkCallback,
  ...props
}: ShareInstantPlayDialogProps) => {
  const formHook = useForm<ShareFormValues>({
    mode: "all",
    resolver: joiResolver(ShareFormSchema),
    defaultValues,
  });

  const { formState, handleSubmit, reset } = formHook;
  const formValues = formHook.watch();

  const reportNotifyMutation = useReportNotify({});

  const shareMutationOptions = {
    onSuccess: (data: ReportItem) => {
      if (!data?.id) return;
      const { recipients, subject, note } = formValues;
      if (!recipients.length) return;
      reportNotifyMutation.mutate({
        params: {
          path: {
            id: data.id,
          },
        },
        recipients,
        subject,
        message: note,
      });
    },
  };

  const shareEventMutation = useReportCreateInstantPlay({
    options: shareMutationOptions,
  });

  const titleLabel = `${format(new Date(), "MM/d/yy")} - Shared 1 event`;

  const shareEvents = (notify: boolean) => {
    shareEventMutation.mutate({
      title: titleLabel,
      startDateTime,
      endDateTime,
      event,
      notify,
    });
  };

  const instantLinkValue = useMemo(() => {
    const reportId = shareEventMutation.data?.id;
    if (reportId) {
      return getFullPublicEventLink(reportId, event.id as string);
    }
    return "";
  }, [shareEventMutation.data, event]);

  const handleSubmitWrapper = handleSubmit(() => {
    shareEvents(true);
    onClose();
    onSubmit();
  });

  useEffect(() => {
    // reset when Dialog is closed, but not unmounted
    if (!props.open) {
      reset({}, { keepDefaultValues: true });
    } else {
      reset(defaultValues);
    }
  }, [reset, props.open]);

  return (
    <FormProvider {...formHook}>
      <ShareBaseDialog
        title="Share Event"
        instantLinkValue={instantLinkValue}
        getInstantLink={() => shareEvents(false)}
        isLoadingLink={shareEventMutation.isLoading}
        copyShareLinkCallback={copyShareLinkCallback}
        onClose={onClose}
        isBusy={!formState.isValid || !formState.dirtyFields?.recipients}
        handleSubmit={handleSubmitWrapper}
        {...props}
      />
    </FormProvider>
  );
};
