import { useCallback, useState } from "react";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { PowerSearchResultItem } from "src/api/usePowerSearch";
import { WatchTermResultItem } from "src/api/useWatchTermResultsInfinite";
import { useScrollQueryLoader } from "src/utils/useScrollQueryLoader";
import { useEventSelectionWatchTermResults } from "src/api/useEventSelectionWatchTermResults";
import { TileLayout } from "src/components/TileLayout/TileLayout";
import { ScrollHeaderLayout } from "src/components/ScrollHeaderLayout/ScrollHeaderLayout";
import {
  EventsLoadingOverlay,
  EventsNoResultsOverlay,
  FilteredEventsNoResultsOverlay,
} from "src/components/NoResultsOverlay/NoResultsOverlay";
import { WatchTermResultTile } from "./WatchTermResultTile";
import { WatchTermResultTilesProps } from "./WatchTermResultTiles.model";

export function WatchTermResultTiles({
  queryId,
  isExpandedTextMode,
  addItemToReport,
  renderHeader,
  watchTermResults,
  rows,
  renderCustomFooterEl,
  showDuplicatesDrawer,
  drawerParentEventId,
  hasFilters,
}: WatchTermResultTilesProps) {
  const { palette, breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const [activePlayerId, setActivePlayerId] = useState<string | null>(null);

  const handleScroll = useScrollQueryLoader({ query: watchTermResults });
  const isIOS = window?.navigator?.platform === "iPhone";

  const setPlayerIdWrapper = useCallback((id: string, isPlaying: boolean) => {
    if (isPlaying) {
      setActivePlayerId((prevActivePlayerId) =>
        prevActivePlayerId !== id ? id : prevActivePlayerId
      );
    }
  }, []);

  const { list, areAllPagesSelected, excludeList } =
    useEventSelectionWatchTermResults();

  const renderItem = useCallback(
    (data: PowerSearchResultItem) => {
      let isChecked = false;
      if (areAllPagesSelected) {
        isChecked = !excludeList.find((event) => event.id === data.id);
      } else {
        isChecked = !!list.find((event) => event.id === data.id);
      }
      return (
        <WatchTermResultTile
          key={data.id}
          value={data}
          queryId={queryId}
          isExpandedTextMode={isExpandedTextMode}
          isChecked={isChecked}
          isPlaying={activePlayerId === data.id}
          onChangePlayState={setPlayerIdWrapper}
          addItemToReport={addItemToReport}
          showDuplicatesDrawer={showDuplicatesDrawer}
          drawerParentEventId={drawerParentEventId}
        />
      );
    },
    [
      queryId,
      isExpandedTextMode,
      activePlayerId,
      setPlayerIdWrapper,
      addItemToReport,
      list,
      areAllPagesSelected,
      excludeList,
      showDuplicatesDrawer,
      drawerParentEventId,
    ]
  );

  const tilesFooter = (
    <Stack
      direction="row"
      alignItems="center"
      borderTop={`1px solid ${palette.divider}`}
      minHeight={53}
    >
      {renderCustomFooterEl && renderCustomFooterEl()}
    </Stack>
  );

  const isLoading = watchTermResults.isLoading || watchTermResults.isFetching;

  const emptyComponent = hasFilters
    ? FilteredEventsNoResultsOverlay
    : isLoading
    ? EventsLoadingOverlay
    : EventsNoResultsOverlay;

  return (
    <>
      <ScrollHeaderLayout
        headerMinHeight={56}
        onScroll={handleScroll}
        renderHeader={renderHeader}
        isLoading={isLoading}
        children={
          <Stack
            pl={2}
            pr={isIOS ? 2 : 1}
            pb={1}
            flex={1}
            children={
              <TileLayout<WatchTermResultItem>
                rows={rows}
                renderItem={renderItem}
                renderEmpty={emptyComponent}
              />
            }
          />
        }
      />
      {!isMobile && !!renderCustomFooterEl && tilesFooter}
    </>
  );
}
