import { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { usePowerSearchQueryKeyword } from "src/api/usePowerSearchQueryKeyword";
import { useWatchQueryCreate } from "src/api/useWatchQueryCreate";
import { makeQueryDefinition } from "src/utils/makeQueryDefinition";
import { DialogBase } from "src/components/DialogBase/DialogBase";
import { WatchQueryDialogActions } from "src/components/WatchQueryDialogActions/WatchQueryDialogActions";
import { WatchQueryKeywordForm } from "src/components/WatchQueryKeywordForm/WatchQueryKeywordForm";
import {
  emptyWatchQueryKeywordFormValues,
  useWatchQueryKeywordForm,
} from "src/components/WatchQueryKeywordForm/WatchQueryKeywordForm.hook";
import { WatchQueryKeywordFormValues } from "src/components/WatchQueryKeywordForm/WatchQueryKeywordForm.model";
import { WatchQueryKeywordFormSchema } from "../WatchQueryKeywordForm/WatchQueryKeywordForm.schema";
import { PowerSearchFilterDialogProps } from "./PowerSearchFilterDialog.model";

export function PowerSearchFilterDialog({
  onClose,
  initial,
  onSubmit,
  closeOnLeftSide,
  ...props
}: PowerSearchFilterDialogProps) {
  const watchQueryCreate = useWatchQueryCreate({
    options: {
      onSettled: onClose,
    },
  });

  // FIXME: rename to useWatchQueryKeywordCache
  const powerSearchQuery = usePowerSearchQueryKeyword();

  const formHook = useWatchQueryKeywordForm({
    schema: WatchQueryKeywordFormSchema,
    initial,
  });

  const { formState, handleSubmit, reset } = formHook;

  useEffect(() => {
    if (!props.open) {
      reset({}, { keepDefaultValues: true });
    } else {
      reset(initial);
    }
  }, [reset, props.open, initial]);

  const isBusy = formState.isSubmitting || watchQueryCreate.isLoading;

  const submitWatchTerm = handleSubmit(
    (data: WatchQueryKeywordFormValues) => {
      const query = makeQueryDefinition(data);

      watchQueryCreate.mutate({
        title: data.displayName,
        queryBuilderDefinition: query,
      });
    },
    (errors) => {
      console.log("@@ DEBUG:submitWatchTerm:error", errors);
    }
  );

  const submitPowerSearch = handleSubmit(
    (data: WatchQueryKeywordFormValues) => {
      powerSearchQuery.save(data);

      onSubmit();
    },
    (errors) => {
      console.log("@@ DEBUG:submitPowerSearch:error", errors);
    }
  );

  const onFormClear = () => {
    reset(emptyWatchQueryKeywordFormValues);
    powerSearchQuery.save(emptyWatchQueryKeywordFormValues);

    onSubmit();
  };

  return (
    <FormProvider {...formHook}>
      <DialogBase
        fullWidth
        maxWidth="lg"
        onClose={onClose}
        closeOnLeftSide={closeOnLeftSide}
        {...props}
        children={
          <WatchQueryKeywordForm
            layout="create"
            loading={watchQueryCreate.isLoading}
          />
        }
        footer={
          <WatchQueryDialogActions
            isBusy={isBusy}
            isValid={formState.isValid}
            onFormClear={onFormClear}
            onClose={onClose}
            submitPowerSearch={submitPowerSearch}
            submitWatchQueryCreate={submitWatchTerm}
          />
        }
      />
    </FormProvider>
  );
}
