import {
  Button,
  Divider,
  PopoverProps,
  Stack,
  Typography,
  alpha,
  useTheme,
  paperClasses,
  Popover,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useIsMobile } from "src/utils/useIsMobile";
import { PopperBase } from "../PopperBase/PopperBase";

type PopperInfoProps = {
  open: boolean;
  anchorEl: PopoverProps["anchorEl"];
  onClose: () => void;
  title: string;
  text: string;
  footer?: JSX.Element;
};

export const PopperInfo = ({
  open,
  anchorEl,
  title,
  text,
  onClose,
  footer,
}: PopperInfoProps) => {
  const { palette } = useTheme();
  const isMobile = useIsMobile();

  const footerContent = footer ?? (
    <Stack
      flexDirection="row"
      justifyContent="end"
      alignItems="center"
      px={3}
      py={2}
      gap={1}
    >
      <Button
        sx={{
          color:
            palette.mode === "dark"
              ? palette.text.primary
              : palette.primary.main,
        }}
      >
        See more
      </Button>
      <Button onClick={onClose} color="error">
        Close
      </Button>
    </Stack>
  );

  const content = (
    <Stack>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        px={3}
        py={2}
        gap={2}
      >
        <Typography variant="h6">{title}</Typography>
        <Close
          onClick={onClose}
          sx={{
            cursor: "pointer",
            color:
              palette.mode === "light"
                ? "inherit"
                : alpha(palette.common.white, 0.56),
          }}
        />
      </Stack>

      <Divider />

      <Stack
        px={3}
        py={2}
        sx={{
          backgroundColor:
            palette.mode === "light" ? palette.background.default : "inherit",
        }}
      >
        <Typography variant="body2">{text}</Typography>
      </Stack>
      <Divider />

      {footerContent}
    </Stack>
  );

  if (isMobile) {
    return (
      <Popover
        open={open}
        anchorEl={anchorEl}
        children={content}
        onClose={onClose}
      />
    );
  }

  return (
    <PopperBase
      open={open}
      anchorEl={anchorEl}
      placement="right"
      onClose={onClose}
      //FIXME: In the future, -150 should be dynamic and dependent on the height of the content (and should be half of it).
      popperOffset={[-150, 10]}
      sx={{
        width: 440,
        mt: "10px !important",
        mr: "12px !important",
        [`.${paperClasses.root}`]: {
          background: palette.background.paper,
          backgroundImage:
            palette.mode === "light"
              ? "inherit"
              : "linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12));",
          boxShadow:
            palette.mode === "light"
              ? `0px 9px 44px 8px rgba(92, 113, 132, 0.12);` +
                `0px 23px 36px 3px rgba(92, 113, 132, 0.14);` +
                `0px 11px 14px -7px rgba(92, 113, 132, 0.2);`
              : `0px 6px 6px -3px rgba(0,0,0,0.2),` +
                `0px 10px 14px 1px rgba(0,0,0,0.14),` +
                `0px 4px 18px 3px rgba(0,0,0,0.12)`,
        },
        ".arrow::before": {
          background: palette.background.paper,
          backgroundImage:
            palette.mode === "light"
              ? "inherit"
              : "linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12));",
        },
        //without this prop, the buttons to switch views would be superimposed on the popup
        //value should be more 1300 (because modal has index 1300)
        zIndex: 1301,
      }}
      arrowSx={{
        left: -12,
      }}
    >
      {content}
    </PopperBase>
  );
};
