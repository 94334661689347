import { Stack, Button, useTheme } from "@mui/material";
import CenterFocusWeakIcon from "@mui/icons-material/CenterFocusWeak";

export function MapBoxResetButton({ onClick }: { onClick: () => void }) {
  const { zIndex } = useTheme();

  return (
    <Stack
      sx={{
        borderRadius: "4px",
        backgroundColor: "#fff",
        boxShadow: "0 0 0 2px rgba(0, 0, 0, .1)",
        left: "10px",
        position: "absolute",
        top: "80px",
        zIndex: zIndex.drawer,
      }}
    >
      <Button
        onClick={onClick}
        sx={{
          alignItems: "center",
          backgroundColor: "transparent",
          border: 0,
          borderRadius: "4px",
          boxShadow: "0 0 0 2px rgba(0, 0, 0, .1)",
          boxSizing: "border-box",
          display: "flex",
          justifyContent: "center",
          minWidth: "29px",
          width: "29px",
          height: "29px",
        }}
      >
        <CenterFocusWeakIcon
          sx={{ fill: "#333", height: "20px", width: "20px" }}
        />
      </Button>
    </Stack>
  );
}
