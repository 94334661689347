import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import {
  Advertisement,
  queryAdvertisementValues,
} from "src/models/Advertisement";
import { useSessionContext } from "src/api/useSessionContext";
import { useIsMobile } from "src/utils/useIsMobile";
import { ButtonSelector } from "../ButtonSelector/ButtonSelector";
import { ButtonSelectorProps } from "../ButtonSelector/ButtonSelector.model";
import { TooltipBaseMobile } from "../TooltipBaseMobile/TooltipBaseMobile";
import { TooltipBase } from "../TooltipBase/TooltipBase";

const getAdvertisementLabel = (o: Advertisement) => {
  switch (o) {
    case queryAdvertisementValues.all:
      return "Off";
    case queryAdvertisementValues.none:
      return "Hide Ads";
    case queryAdvertisementValues.only:
      return "Only Ads";
    default:
      return o;
  }
};

export function AdvertisementPicker(
  props: Omit<
    ButtonSelectorProps<Advertisement>,
    "sx" | "options" | "getOptionLabel"
  >
) {
  const isMobile = useIsMobile();
  const { effectiveEntitlements } = useSessionContext();
  const enableAdFilters = effectiveEntitlements.enableAdFiltering?.value;
  const { t } = useTranslation();
  const title = enableAdFilters ? "" : t("adFiltersDisabled");

  const advertisementPickerContent = (
    <ButtonSelector<Advertisement>
      getOptionLabel={getAdvertisementLabel}
      options={Object.values(queryAdvertisementValues)}
      {...props}
      disabled={!enableAdFilters}
    />
  );

  return isMobile ? (
    <TooltipBaseMobile
      width={249}
      title={title}
      placement="top"
      disableHoverListener={enableAdFilters}
      children={advertisementPickerContent}
    />
  ) : (
    <Box>
      <TooltipBase
        width={249}
        textAlign="left"
        title={title}
        placement="bottom"
        disableHoverListener={enableAdFilters}
        children={advertisementPickerContent}
      />
    </Box>
  );
}
