import { Stack, Typography, useTheme } from "@mui/material";
import { AppLink } from "src/components/AppLink/AppLink";
import { SettingsPageTab } from "src/pages/Settings/Settings.page";
import { settingsRoute } from "src/pages/Settings/Settings.route";

export function MapBoxError({ error }: { error: unknown }) {
  const { palette } = useTheme();
  const contactUrl = settingsRoute.makeUrl(undefined, {
    pageTab: SettingsPageTab.contact,
  });

  return error ? (
    <Stack
      m="auto"
      justifyContent="center"
      position="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
      children={
        <Stack>
          <Typography
            align="center"
            color={palette.error.main}
            children={`${error}`}
          />
          <Typography align="center">
            You can connect with your{" "}
            <AppLink to={contactUrl}>Sales Representative.</AppLink>
          </Typography>
        </Stack>
      }
    />
  ) : null;
}
