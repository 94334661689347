import { paths } from "@tveyes/twosionwebapischema";
import { makeApiUrl } from "src/utils/makeApiUrl";

export enum DownloadReportFormat {
  csv = "csv",
  pdf = "pdf",
}

export const downloadLimitTitle =
  "Download is available for up to 10 reports. Please select less items";

export const getDownloadTooltipTitle = (
  format: DownloadReportFormat,
  limit: number
) => {
  return `You can obtain a ${format.toUpperCase()} report with a maximum of ${limit.toLocaleString()} events for download.`;
};

const apiPathCSV: keyof paths = "/api/report/export/{id}/csv";
const apiPathPDF: keyof paths = "/api/report/export/{id}/pdf";

export function downloadReport({
  format,
  reportId,
  title,
  refresh,
}: {
  format: DownloadReportFormat;
  reportId: string;
  title: string;
  refresh?: boolean;
}) {
  const apiPath = format === "csv" ? apiPathCSV : apiPathPDF;
  const url = makeApiUrl(apiPath, {
    path: {
      id: reportId,
    },
    query: {
      refresh,
    },
  });

  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", title.replace(/ /g, "_"));
  link.click();
}
