import Joi from "joi";
import { AccountsManagementConvertFormValues } from "./AccountsManagementConvertForm.model";

export const AccountsManagementConvertFormSchema =
  Joi.object<AccountsManagementConvertFormValues>({
    status: Joi.string().optional().label("Status").allow(""),
    accountManager: Joi.string().required().label("Account manager"),
    expiration: Joi.date().required().label("Expiration date"),
    seatCount: Joi.number().required().label("User seats").messages({
      "number.base": "User seats is required",
      "number.unsafe": "Max limit reached",
    }),
    note: Joi.string().optional().label("Notes").allow(""),
    notifyUsers: Joi.boolean().required().label("Notify users"),
    annualizedRecurringRevenue: Joi.number().required().messages({
      "number.base": "Annualized revenue is required",
      "number.unsafe": "Max limit reached",
    }),
  });
