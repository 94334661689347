import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useRef } from "react";
import { useQuery, UseQueryOptions } from "react-query";
import { paths } from "@tveyes/twosionwebapischema";
import { makeApiUrl } from "src/utils/makeApiUrl";

const apiPath: keyof paths = "/api/report/{id}/thumbnail";
const method: keyof paths[typeof apiPath] = "post";

type Endpoint = paths[typeof apiPath][typeof method];
type EndpointParameters = Endpoint["parameters"];

// Usually we get response type from the schema.
// For some reason in the schema it is a Blob but actually it's a blob
// type Response = Endpoint["responses"]["200"]["content"]["application/json"];
type Response = Blob;

export function useReportThumbnail({
  params,
  options,
}: {
  params: EndpointParameters;
  options?: Pick<UseQueryOptions<string>, "onSuccess" | "onError" | "enabled">;
}) {
  const { getAccessTokenSilently } = useAuth0();
  const url = makeApiUrl(apiPath, {
    path: params.path,
  });

  const srcRef = useRef("");

  const queryFn = useCallback(async (): Promise<string> => {
    const token = await getAccessTokenSilently();

    const { data } = await axios<Response>(url, {
      method,
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (srcRef.current) {
      URL.revokeObjectURL(srcRef.current);
    }

    srcRef.current = URL.createObjectURL(data);
    return srcRef.current;
  }, [url, getAccessTokenSilently]);

  return useQuery({
    ...options,
    // cache images as they don't change often
    cacheTime: 24 * 60 * 60 * 1000,
    staleTime: 24 * 60 * 60 * 1000,
    queryKey: [apiPath, params],
    queryFn,
  });
}
