import React, {
  Fragment,
  RefObject,
  forwardRef,
  useEffect,
  useMemo,
  useState,
} from "react";
import { KeyboardReturn } from "@mui/icons-material";
import { ArrowDropDownIcon } from "@mui/x-date-pickers-pro";
import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteValue,
  Chip,
  IconButton,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";

import { useOpenState } from "src/utils/useOpenState";
import { TextInputBase } from "../TextInputBase";
import {
  AppAutocompleteChipRow,
  AppAutocompleteChipRowProps,
} from "./AppAutocompleteChipRow";
import { AppAutocompleteCountBadge } from "./AppAutocompleteCountBadge";
import { makeAppAutocompletePaperComponent } from "./AppAutocompletePaper";
import { makeAppAutocompletePopperComponent } from "./AppAutocompletePopper";
import { useAppAutocompleteHelpers } from "./hooks/useAppAutocompleteHelpers";
import { useAppAutocompleteInlineStyles } from "./hooks/useAppAutocompleteInlineStyles";
import { useAppAutocompleteMultilineStyles } from "./hooks/useAppAutocompleteMultilineStyles";
import { AppAutocompleteMultilineChipContainer } from "./AppAutocompleteMultilineChipContainer";

const defaultShowTotalCount = (count: number) => count > 1;

export type AppAutocompleteProps<
  T,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
> = Omit<
  AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  | "sx"
  | "renderInput"
  | "onChange"
  | "onInputChange"
  // visibility state is hidden
  | "open"
  | "onOpen"
  | "onClose"
> & {
  label?: string;
  placeholder?: string;
  helperText?: string;
  error?: boolean;

  /** Width of the dropdown component */
  dropDownWidth?: number;

  /** Limit size of the dropdown to the bounding element */
  dropDownBoundsEl?: RefObject<HTMLElement>;

  /**
   * Render elements at the beginning of the component layout, they will not be scrolled
   * @param inherited - React elements rendered by inherited component
   * @returns - New React node to be used as start adornment
   */
  renderStartAdornment?: (inherited: React.ReactNode) => JSX.Element;

  renderDropDownHeader?: (params: { inputValue: string }) => JSX.Element;

  /** Show total tag count badge at the start of the control */
  showTotalCount?: boolean | typeof defaultShowTotalCount;

  /** Automatically scroll to the last tag added */
  scrollToLast?: AppAutocompleteChipRowProps["scrollToLast"];

  /**
   * By default chips are rendered in one line with horizontal scrolling.
   * Set this to true to allow standard MUI behavior for multiple lines of chips.
   */
  allowMultipleLines?: number;

  inputBackgroundColor?: string;

  onChange?: (
    value: AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>
  ) => void;

  onInputChange?: (value: string) => void;

  // Called when user hits enter inside input or clicks "return" button at the input's end
  onInputSubmit?: (params: {
    option: T;
    isExisting: boolean;
    inputValue: string;
  }) => void;

  /**
   * Provide a factory to create custom option from user's input.
   * Return "null" to disable this when input invalid.
   */
  getOptionFromInput?: (inputValue: string) => T | null;
};

/** This is internal class for proper typecasting with forwardRef */
const AppAutocompleteComponent = <
  T,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
>(
  _props: AppAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>
): JSX.Element => <div />;

/**
 * A tuned version of MUI Autocomplete
 * - has horizontal scrolling instead of vertical growth by default.
 * - can limit dropdown dimensions to width and/or specific container.
 * - shows total number of tags selected at the left.
 * - uses fade gradient for items that are going out of the viewport.
 * - handle input growing on hover and focus.
 * - includes label and helper text for forms
 * - allows further customizations of layout elements
 */
export const AppAutocomplete = forwardRef(
  <
    T,
    Multiple extends boolean | undefined = false,
    DisableClearable extends boolean | undefined = false,
    FreeSolo extends boolean | undefined = false
  >(
    {
      label,
      error,
      placeholder,
      helperText,
      dropDownWidth,
      dropDownBoundsEl,
      showTotalCount = defaultShowTotalCount,
      scrollToLast,
      onChange,
      onInputChange,
      getOptionFromInput,
      onInputSubmit,
      onFocus,
      onBlur,
      renderTags,
      renderOption,
      renderStartAdornment,
      renderDropDownHeader,
      //
      PopperComponent,
      PaperComponent,
      loading,
      size = "medium",
      openOnFocus,
      allowMultipleLines,
      inputBackgroundColor,
      limitTags,
      getLimitTagsText,
      onClick,
      ...props
    }: AppAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    ref: React.ForwardedRef<HTMLDivElement | null>
  ) => {
    type InternalProps = AutocompleteProps<
      T,
      Multiple,
      DisableClearable,
      FreeSolo
    >;
    const dropdownOpenState = useOpenState();
    const [isFocused, setFocused] = useState(false);
    const helpers = useAppAutocompleteHelpers();
    const { injectIntoElement } = helpers;

    const inlineStylesSx: InternalProps["sx"] = useAppAutocompleteInlineStyles({
      size,
    });

    const multilineStylesSx: InternalProps["sx"] =
      useAppAutocompleteMultilineStyles({
        isFocused,
        size,
      });

    const effectiveSx: InternalProps["sx"] = allowMultipleLines
      ? multilineStylesSx
      : inlineStylesSx;

    const [inputValue, setInputValue] = useState<string>("");

    const onFocusWrapper: InternalProps["onFocus"] = (e) => {
      setFocused(true);
      onFocus?.(e);
    };

    const onBlurWrapper: InternalProps["onBlur"] = (e) => {
      setFocused(false);
      onBlur?.(e);
    };

    const renderTotalCountAdornment = () => {
      if (!Array.isArray(props.value)) {
        return null;
      }

      const canRenderCountBadge =
        typeof showTotalCount === "function"
          ? showTotalCount(props.value.length)
          : showTotalCount;

      return (
        canRenderCountBadge && (
          <AppAutocompleteCountBadge count={props.value.length} size={size} />
        )
      );
    };

    const totalCountBadge = renderTotalCountAdornment();
    const startAdornment = renderStartAdornment
      ? renderStartAdornment(totalCountBadge)
      : totalCountBadge;

    const defaultRenderTags: typeof renderTags = (
      value,
      getTagProps,
      _ownerState
    ) => {
      const sourceTags =
        limitTags && !isFocused
          ? // limit source tags for rendering in the input
            value.slice(0, limitTags)
          : value;

      const hiddenCount = limitTags ? value.length - limitTags : 0;

      const tagElements = sourceTags.map((option, index) => {
        const label = props.getOptionLabel?.(option) || `${option}`;
        const { key, ...tagProps } = getTagProps({ index });
        return <Chip label={label} {...tagProps} key={key} size={size} />;
      });

      if (hiddenCount && !isFocused) {
        const hiddenChipsText = getLimitTagsText
          ? getLimitTagsText(hiddenCount)
          : `+${hiddenCount}`;

        tagElements.push(
          <Typography
            key="hidden-count"
            variant={size === "small" ? "body2" : "body1"}
            children={hiddenChipsText}
          />
        );
      }

      return tagElements;
    };

    const renderTagsWrapper: typeof renderTags = (
      value,
      getTagProps,
      ownerState
    ) => {
      const tagElements = renderTags
        ? renderTags(value, getTagProps, ownerState)
        : defaultRenderTags(value, getTagProps, ownerState);

      if (allowMultipleLines && isFocused) {
        return (
          <AppAutocompleteMultilineChipContainer
            lineClamp={allowMultipleLines}
            size={size}
            children={
              <Fragment>
                {startAdornment}
                {tagElements}
              </Fragment>
            }
          />
        );
      }

      return (
        <Stack direction="row" overflow="hidden">
          {startAdornment}
          <AppAutocompleteChipRow
            size={size}
            scrollToLast={scrollToLast}
            children={tagElements}
          />
        </Stack>
      );
    };

    const onChangeWrapper: InternalProps["onChange"] = (
      _event,
      value,
      _reason,
      _details
    ) => {
      onChange?.(value);
    };

    const handleInputSubmit = () => {
      // find existing option first by comparing labels
      const existing = props.options.find((option) => {
        const label1 = inputValue;
        const label2 = props.getOptionLabel
          ? props.getOptionLabel(option)
          : `${option}`;

        return label1.toLowerCase() === label2?.toLowerCase();
      });

      // check if user's text can be converted to a value
      const custom =
        inputValue && getOptionFromInput
          ? getOptionFromInput?.(inputValue)
          : null;

      const option = existing ? existing : custom;

      if (onInputSubmit && option) {
        dropdownOpenState.hide();

        onInputSubmit({
          option,
          isExisting: !!existing,
          inputValue,
        });
      }
    };

    const inputSubmitButton = inputValue && (
      <IconButton
        size="small"
        onClick={handleInputSubmit}
        children={<KeyboardReturn fontSize="small" />}
        sx={{
          padding: 0,
          width: 28,
          height: 28,
        }}
      />
    );

    const onInputChangeWrapper: InternalProps["onInputChange"] = (
      _event,
      value,
      _reason
    ) => {
      setInputValue(value);
    };

    useEffect(() => {
      onInputChange?.(inputValue);
    }, [inputValue, onInputChange]);

    const renderInputWrapper: InternalProps["renderInput"] = (params) => {
      const { endAdornment } = params.InputProps;
      // magically inject custom elements into existing MUI adornment
      const nextAdornment = inputSubmitButton
        ? injectIntoElement({
            parent: endAdornment,
            inject: inputSubmitButton,
            position: "start",
          })
        : endAdornment;

      return (
        <TextInputBase
          {...params}
          onClick={onClick}
          label={label}
          error={error}
          helperText={helperText}
          placeholder={placeholder}
          background={inputBackgroundColor}
          InputProps={{
            ...params.InputProps,
            endAdornment: nextAdornment,
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.stopPropagation();
              handleInputSubmit();
            }
          }}
        />
      );
    };

    const DefaultPopperComponent = useMemo(() => {
      return makeAppAutocompletePopperComponent({
        dropDownBoundsEl,
        dropDownWidth,
      });
    }, [dropDownBoundsEl, dropDownWidth]);

    const DefaultPaperComponent = useMemo(() => {
      return makeAppAutocompletePaperComponent({
        spinner: loading,
        renderHeader: renderDropDownHeader
          ? () => renderDropDownHeader?.({ inputValue })
          : undefined,
      });
    }, [loading, inputValue, renderDropDownHeader]);

    const renderOptionWrapper: InternalProps["renderOption"] = (
      listItemProps,
      option,
      state,
      ownerState
    ) => {
      if (renderOption) {
        return renderOption(listItemProps, option, state, ownerState);
      }

      const key = props.getOptionKey?.(option);
      const primary = props.getOptionLabel?.(option) || `${option}`;

      return (
        <ListItem {...listItemProps} key={key}>
          <ListItemText primary={primary} />
        </ListItem>
      );
    };

    return (
      <Autocomplete<T, Multiple, DisableClearable, FreeSolo>
        {...props}
        ref={ref}
        size={size}
        limitTags={limitTags}
        // remove open/close icon when automatically opening on focus
        open={openOnFocus ? isFocused : undefined}
        slotProps={{
          ...props.slotProps,
          paper: {
            elevation: 6,
            ...props.slotProps?.paper,
          },
        }}
        popupIcon={openOnFocus ? null : <ArrowDropDownIcon />}
        sx={effectiveSx}
        clearOnBlur={!!onInputSubmit}
        onChange={onChangeWrapper}
        onInputChange={onInputChangeWrapper}
        PopperComponent={PopperComponent || DefaultPopperComponent}
        PaperComponent={PaperComponent || DefaultPaperComponent}
        renderInput={renderInputWrapper}
        renderOption={renderOptionWrapper}
        renderTags={renderTagsWrapper}
        onFocus={onFocusWrapper}
        onBlur={onBlurWrapper}
      />
    );
  }
  // typecast is the simplest way to forwardRef for generic component
) as typeof AppAutocompleteComponent;
