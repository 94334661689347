import { create } from "zustand";
import { IncludeExcludeListGroupLogic } from "./makeWatchQueryFormValues";

type SelectedPresetState = {
  selectedPreset: IncludeExcludeListGroupLogic | null;
  setSelectedPreset: (valData: IncludeExcludeListGroupLogic | null) => void;
};

export const useSelectedPresetStore = create<SelectedPresetState>(
  (setState) => ({
    selectedPreset: null,
    setSelectedPreset: (newSelectedPresets) =>
      setState({ selectedPreset: newSelectedPresets }),
  })
);
