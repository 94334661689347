import { AppRouteBuilder } from "src/models/AppRoute";
import { SettingsPage, SettingsPageTab } from "./Settings.page";

type SettingsRouteQueryParams = {
  pageTab?: SettingsPageTab;
};

export const settingsRoute = new AppRouteBuilder<
  void,
  SettingsRouteQueryParams | void
>({
  path: "/settings",
  element: <SettingsPage />,
});
