import {
  Accordion,
  AccordionSummary,
  accordionSummaryClasses,
  alpha,
  Box,
  Collapse,
  FormHelperText,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useOpenState } from "src/utils/useOpenState";
import { CodeEditorControls } from "src/components/CodeEditorSection/CodeEditorControls";
import { MonacoCodeEditor } from "src/components/CodeEditor/MonacoCodeEditor";
import { CodeEditorHelpSection } from "src/components/CodeEditorSection/CodeEditorSection";
import { CustomQueryHighlightedText } from "../CustomQueryHighlightedText/CustomQueryHighlightedText";

export function CustomQueryInput({
  value,
  error,
  helperText,
  onChange,
}: {
  value?: string;
  error: boolean;
  helperText: string;
  onChange: (value?: string) => void;
}) {
  const { palette } = useTheme();
  const inputState = useOpenState();

  return (
    <>
      {inputState.isOpen ? (
        <>
          <Stack
            height={56}
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            pl={2}
            sx={{
              backgroundColor: alpha(palette.action.hover, 0.04),
              border: `1px solid ${palette.action.disabled}`,
              borderBottom: "none",
              borderTopRightRadius: "4px",
              borderTopLeftRadius: "4px",
            }}
          >
            <CodeEditorControls value={value} onChange={onChange} hideTitle />
            <IconButton
              size="large"
              aria-label="hide custom query"
              onClick={inputState.hide}
            >
              <KeyboardArrowUp />
            </IconButton>
          </Stack>
        </>
      ) : (
        <>
          <Accordion
            sx={{
              height: 56,
              background: alpha(palette.action.hover, 0.04),
              border: `1px solid ${
                error ? palette.error.main : palette.action.disabled
              }`,
              borderRadius: "4px",
            }}
          >
            <AccordionSummary
              onClick={inputState.show}
              sx={{
                height: 56,
                [`.${accordionSummaryClasses.content}`]: {
                  width: "calc(100% - 36px)",
                },
              }}
              expandIcon={
                <IconButton
                  size="large"
                  aria-label="show custom query"
                  onClick={inputState.show}
                  edge="end"
                >
                  <KeyboardArrowDownIcon />
                </IconButton>
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box
                sx={{
                  width: "100%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  display: "block",
                }}
              >
                <CustomQueryHighlightedText value={value} />
              </Box>
            </AccordionSummary>
          </Accordion>
          <FormHelperText>
            <Typography variant="caption" color="error" m={"3px 14px 0"}>
              {helperText}
            </Typography>
          </FormHelperText>
        </>
      )}
      <Collapse in={inputState.isOpen}>
        <Stack
          p={2}
          mb={3}
          sx={{
            border: `1px solid ${palette.action.disabled}`,
            borderBottomRightRadius: "4px",
            borderBottomLeftRadius: "4px",
          }}
        >
          <MonacoCodeEditor value={value} onChange={onChange} />
          <CodeEditorHelpSection value={value} onChange={onChange} />
        </Stack>
      </Collapse>
    </>
  );
}
