import { alpha, Chip, svgIconClasses } from "@mui/material";
import { WatchListQueries } from "src/api/useWatchQueryList";
import { useAppChartColorMapper } from "src/components/charts/utils/useAppChartColorMapper";
import { useOpenState } from "src/utils/useOpenState";
import {
  AppAutocomplete,
  AppAutocompleteProps,
} from "../AppAutocomplete/AppAutocomplete";
import { WatchPickerMobileModal } from "../WatchQueryPickerMobileModal/WatchQueryPickerMobileModal";

type WatchQuery = WatchListQueries[number];

type WatchQueryPickerProps = Omit<
  AppAutocompleteProps<WatchQuery, true>,
  "renderOption" | "renderTags" | "getOptionLabel" | "getOptionKey"
> & {
  maxActiveQueries?: number;
};

export function WatchQueryPickerMobile({
  maxActiveQueries = 6,
  onChange,
  options,
  ...props
}: WatchQueryPickerProps) {
  const { getColorForId } = useAppChartColorMapper();
  const menuState = useOpenState();

  const onChangeWrapper: WatchQueryPickerProps["onChange"] = (value) => {
    if (value.length > maxActiveQueries) {
      return;
    }
    onChange?.(value);
  };

  const handlePickerClick = () => {
    if (menuState.isOpen) {
      menuState.hide();
    }
    menuState.show();
  };

  return (
    <>
      <AppAutocomplete<WatchQuery, true>
        options={options}
        onChange={onChangeWrapper}
        getOptionLabel={(o) => o.title}
        getOptionKey={(o) => o.id}
        fullWidth
        readOnly
        onClick={handlePickerClick}
        multiple
        renderTags={(value, getTagProps, _ownerState) => {
          const tagElements = value.map((option, index) => {
            const label = option.title;
            const { bg, fg, icon } = getColorForId(option.id);
            const { key, ...tagProps } = getTagProps({ index });

            return (
              <Chip
                label={label}
                {...tagProps}
                key={key}
                size="small"
                sx={{
                  backgroundColor: bg,
                  "&:hover": {
                    backgroundColor: alpha(bg, 0.75),
                  },
                  color: fg,
                  [`.${svgIconClasses.root}`]: {
                    color: icon,
                  },
                }}
              />
            );
          });

          return tagElements;
        }}
        {...props}
      />
      <WatchPickerMobileModal
        menuState={menuState}
        maxActiveQueries={maxActiveQueries}
        onChangeWrapper={onChangeWrapper}
        options={options}
        {...props}
      />
    </>
  );
}
