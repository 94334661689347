import { Box, BoxProps } from "@mui/material";
import { bbox } from "@turf/bbox";
import { featureCollection } from "@turf/helpers";
import { LngLat, LngLatBounds, MapEventOf, MapMouseEvent } from "mapbox-gl";
import { useCallback, useMemo, useRef, useState } from "react";
import { useMapBoxInstance } from "./utils/useMapBoxInstance";
import { useMapBoxLayerEvents } from "./utils/useMapBoxLayerEvents";
import { MapBoxPopper } from "./MapBoxPopper/MapBoxPopper";
import {
  MapBoxLayerTransformFn,
  useMapBoxLayerData,
} from "./utils/useMapBoxLayerData";
import { MapBoxError } from "./MapBoxError/MapBoxError";
import { MapBoxResetButton } from "./MapBoxResetButton/MapBoxResetButton";

type MapBoxSelection = {
  lngLat: MapMouseEvent["lngLat"];
  features: MapMouseEvent["features"];
};

export type { MapBoxLayerTransformFn };
const defaultCenter: LngLat = new LngLat(-100, 38);

export type MapBoxWrapperProps<T extends number> = Omit<
  BoxProps,
  "children"
> & {
  onFeatureClick?: (e: MapEventOf<"click">) => void;
  /** Provide content of the feature Popper  */
  renderFeatureDetails?: (selectionData: MapBoxSelection) => JSX.Element | null;
  /** External data that can be used to augment remote source data */
  valueByFeatureId?: { [featureId: string]: T };
  /** provide a transformation function to append external data to the remote source */
  transform?: MapBoxLayerTransformFn<number>;
  /** Automatically zoom to feature clicked  */
  autoZoom?: boolean;
  /** Show geometry borders */
  showBorders?: boolean;
  /** Level of data */
  dataLevel: "Regional" | "National";
};

const marketLayerIds = {
  sourceId: "markets",
  //
  sourceUrl: "mapbox://moeitrope.0h6hf9fy",
  sourceLayer: "mmsboundariesbundle",
  areaId: "market-area",
  borderId: "market-border",
};

export function MapBoxWrapper<T extends number>({
  renderFeatureDetails,
  valueByFeatureId,
  transform,
  autoZoom,
  showBorders,
  dataLevel = "Regional",
  ...props
}: MapBoxWrapperProps<T>) {
  const [featureSelected, setFeatureSelected] =
    useState<MapBoxSelection | null>(null);

  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const { map, error } = useMapBoxInstance({ containerRef: mapContainerRef });

  useMapBoxLayerData<T>({
    map,
    valueByFeatureId,
    showBorders,
    transform,
    dataLevel,
  });

  // attach event handlers
  useMapBoxLayerEvents({
    map: map,
    layerId: marketLayerIds.areaId,
    onMouseLeave: useCallback((e: MapEventOf<"mouseleave">) => {
      setFeatureSelected(null);
    }, []),
    onMouseMove: useCallback(
      (e: MapEventOf<"mousemove">) => {
        if (!map || !e?.features) return;

        setFeatureSelected({
          lngLat: e.lngLat,
          features: e.features,
        });
      },
      [map]
    ),
    onMouseClick: useCallback(
      (e: MapEventOf<"click">) => {
        if (!map || !e?.features) return;

        console.log(`@@ DEBUG:MapChart:click`, e.features);

        const all = featureCollection(e.features);
        const box = bbox(all);
        const bounds = new LngLatBounds([box[0], box[1]], [box[2], box[3]]);

        if (autoZoom) {
          const currentZoom = map.getZoom();

          if (currentZoom < 3.5) {
            map.fitBounds(bounds, {
              padding: 20,
              maxZoom: 3.5,
            });
          } else {
            map.flyTo({ center: bounds.getCenter() });
          }
        }
      },
      [map, autoZoom]
    ),
  });
  const handleReset = () => {
    map?.flyTo({
      center: defaultCenter,
      zoom: 0,
    });
  };

  const popperContent = useMemo(() => {
    return featureSelected && renderFeatureDetails
      ? renderFeatureDetails(featureSelected)
      : null;
  }, [featureSelected, renderFeatureDetails]);

  return (
    <Box {...props} position="relative">
      <MapBoxResetButton onClick={handleReset} />
      <div ref={mapContainerRef} style={{ height: "100%" }} />
      <MapBoxError error={error} />
      {/* MapBoxPopper is used to make it clipped and attached to LngLat coordinates */}
      <MapBoxPopper
        map={map}
        open={!!popperContent}
        lngLat={featureSelected?.lngLat}
        children={popperContent}
      />
    </Box>
  );
}
