import {
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  alpha,
} from "@mui/material";
import { Warning } from "@mui/icons-material";
import { PageLayoutPublicDesktop } from "src/components/PageLayoutPublicDesktop";
import { CustomBannerHeader } from "src/components/CustomBannerHeader/CustomBannerHeader";

export const ExpiredLinkPage = () => {
  const { palette, breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const content = (
    <Stack
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        sx={{
          width: "100px",
          height: "100px",
          backgroundColor: alpha(palette.warning.main, 0.04),
          borderRadius: "48px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Warning
          color="warning"
          sx={{
            width: "68px",
            height: "68px",
          }}
        />
      </Stack>

      <Typography variant="h5" my={3} color={palette.text.primary}>
        Expired or Removed Link
      </Typography>
      <Typography
        variant="subtitle1"
        align="center"
        color={palette.text.primary}
      >
        The link you tried has expired or been removed.
        <br />
        We keep download links active for only 7 days.
        <br />
        If the link was removed by the owner, you will also see this message.
        <br />
        For access or help, please contact the link owner or your administrator.
      </Typography>
    </Stack>
  );

  if (isMobile) {
    return (
      <>
        <CustomBannerHeader isPublicPage />
        <Stack px={2} height="100%">
          {content}
        </Stack>
      </>
    );
  }

  return (
    <PageLayoutPublicDesktop
      overflow="scroll"
      header={<CustomBannerHeader isPublicPage maxWidth="882px" />}
      content={content}
    />
  );
};
