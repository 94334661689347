import { Button, Stack, useTheme } from "@mui/material";
import { EventDetails } from "src/models/EventDetails";
import { useOpenState } from "src/utils/useOpenState";
import { ClipEditorSaveDialogMobile } from "./ClipEditorSaveDialogMobile";

type ClipEditFooterMobileProps = {
  handleSaveToMediaCenter: (clipTitle: string) => void;
  handleDownload: () => void;
  showShareDialog: () => void;
  disabled?: boolean;
  event: Pick<EventDetails, "mediaDownloadExtension">;
};

export function ClipEditFooterMobile({
  handleSaveToMediaCenter,
  handleDownload,
  showShareDialog,
  disabled,
  event,
}: ClipEditFooterMobileProps) {
  const { palette } = useTheme();
  const saveDialog = useOpenState();

  return (
    <Stack
      px={2}
      py={1}
      borderTop={`1px solid ${palette.divider}`}
      flexDirection="row"
      justifyContent="space-between"
      gap={2}
    >
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={saveDialog.show}
        children="Save"
        sx={{
          flex: 1,
        }}
      />
      <Button
        variant="contained"
        color="secondary"
        size="large"
        disabled={disabled}
        children="Share"
        onClick={showShareDialog}
        sx={{
          flex: 1,
        }}
      />

      <ClipEditorSaveDialogMobile
        open={saveDialog.isOpen}
        onClose={saveDialog.hide}
        handleSaveToMediaCenter={handleSaveToMediaCenter}
        handleDownload={handleDownload}
        disabled={disabled}
        event={event}
      />
    </Stack>
  );
}
