import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { isValid } from "date-fns";
import { StringParam, useQueryParam } from "use-query-params";
import { useAuth0 } from "@auth0/auth0-react";
import { useEventPublic } from "src/api/useEventPublic";
import { pageNotFoundRoute } from "src/pages/PageNotFound/PageNotFound.route";
import { formatDateTimeWithoutTimeZone } from "src/utils/formatDateTimeWithoutTimeZone";
import { PublicEventPathParams } from "./PublicEvent.route";
import { Fragment, useCallback, useMemo, useState } from "react";
import { Article, BurstMode, Info, LiveTv, People } from "@mui/icons-material";
import {
  Paper,
  Stack,
  StackProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AppTab } from "src/components/AppTabLayout/AppTab";
import { AppTabLayout } from "src/components/AppTabLayout/AppTabLayout";
import { ClipViewer } from "src/components/ClipViewer/ClipViewer";
import { TranscriptView } from "src/components/TranscriptView/TranscriptView";
import { EventType } from "src/models/EventType";
import { BCastProgram } from "src/components/ClipViewTabs/BCastProgram/BCastProgram";
import { BCastViewership } from "src/components/ClipViewTabs/BCastViewership/BCastViewership";
import { PCastEpisode } from "src/components/ClipViewTabs/PCastEpisode/PCastEpisode";
import { PCastSource } from "src/components/ClipViewTabs/PCastSource/PCastSource";
import { TranscriptLineBundleViewLabelLayout } from "src/components/TranscriptLineBundleView/TranscriptLineBundleView.const";
import { PCastAudience } from "src/components/ClipViewTabs/PCastAudience/PCastAudience";
import { ValueOf } from "src/models/ValueOf";
import { PageLoader } from "src/components/PageLoader";
import { useViewModeQueryParam } from "src/utils/useViewModeQueryParam";
import { PageLayoutPublicDesktop } from "src/components/PageLayoutPublicDesktop";
import { CustomBannerHeader } from "src/components/CustomBannerHeader/CustomBannerHeader";
import { LoadingOverlay } from "src/components/LoadingOverlay/LoadingOverlay";
import { watchListTermResultClipRoute } from "src/pages/WatchListTermResultClip/WatchListTermResultClip.route";

const PublicEventSingleTabs = {
  transcript: "transcript",
  program: "program",
  audience: "audience",
  episode: "episode",
  viewership: "viewership",
} as const;

type PublicEventSingleTabValues = ValueOf<typeof PublicEventSingleTabs>;

export function PublicEventPage() {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const [autoScroll, setAutoScroll] = useState(false);
  const [currentTab, setCurrentTab] =
    useViewModeQueryParam<PublicEventSingleTabValues>({
      paramKey: "tab",
      defaultValue: PublicEventSingleTabs.transcript,
    });

  const pathParams = useParams<PublicEventPathParams>();
  const { stationId = "", dateTime = "" } = pathParams;
  const [queryId] = useQueryParam("query", StringParam);

  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  const regex = /(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/;
  const dateParts = (dateTime.match(regex) || []).slice(1); // exclude entire string [0]
  const [year, month, day, hours, minutes, seconds] = dateParts;

  const date = new Date(
    Number(year),
    Number(month) - 1, // month index starts from "0"
    Number(day),
    Number(hours),
    Number(minutes),
    Number(seconds)
  );

  const eventDetails = useEventPublic({
    query: {
      dateTime: formatDateTimeWithoutTimeZone(date),
      station: stationId,
      queryId: queryId ?? "",
    },
  });

  useEffect(() => {
    if (
      isAuthenticated &&
      eventDetails.data?.isItemOwner &&
      eventDetails.data?.eventDetails &&
      !eventDetails.data.eventDetails.isSynthetic
    ) {
      const eventId = eventDetails.data.eventDetails.assetId;
      if (queryId && eventId) {
        navigate(
          watchListTermResultClipRoute.makeUrl({
            queryId,
            eventId,
          })
        );
      }
    }
  }, [
    isAuthenticated,
    eventDetails.data?.eventDetails,
    eventDetails.data?.isItemOwner,
    queryId,
    navigate,
  ]);

  const isLoading = eventDetails.isLoading;

  if (!isValid(date)) {
    console.error(`Can not parse date: ${dateTime}`);
  }

  const [offset, setOffset] = useState(0);

  const event = useMemo(
    () => eventDetails.data?.eventDetails || {},
    [eventDetails.data?.eventDetails]
  );
  const dateTimeLabel = event?.startDateTime
    ? new Date(event.startDateTime).toLocaleTimeString()
    : "n/a";
  const titleLabel = isLoading
    ? "Loading..."
    : `${dateTimeLabel} - ${event.source} - ${event.title}`;

  const handleCurrentTimeChange = useCallback(
    (_nextId: string, nextTime: number) => {
      setOffset(nextTime);
    },
    [setOffset]
  );

  useEffect(() => {
    if (eventDetails.isFetched && !eventDetails.data) {
      navigate(pageNotFoundRoute.path);
    }
  }, [eventDetails.isFetched, eventDetails.data, navigate]);

  if (eventDetails.isLoading) {
    <PageLoader />;
  }

  const clipViewer = (
    <ClipViewer
      id="clip-viewer"
      hotStart
      loading={isLoading}
      event={event}
      onCurrentTimeChange={handleCurrentTimeChange}
    />
  );

  const tabContentLayout: StackProps["direction"] = isMobile
    ? "column" // for usage in mobile and compact mode
    : "row";

  const programTabContent = useMemo(
    () => (
      <BCastProgram
        event={event}
        flex={1}
        py={2}
        direction={tabContentLayout}
      />
    ),
    [event, tabContentLayout]
  );

  const viewershipTabContent = useMemo(
    () => (
      <BCastViewership
        event={event}
        direction={tabContentLayout}
        flex={1}
        py={2}
      />
    ),
    [event, tabContentLayout]
  );

  const episodeTabContent = useMemo(
    () => (
      <PCastEpisode
        event={event}
        flex={1}
        py={2}
        direction={tabContentLayout}
      />
    ),
    [event, tabContentLayout]
  );

  const sourceTabContent = useMemo(
    () => (
      <PCastSource event={event} flex={1} py={2} direction={tabContentLayout} />
    ),
    [event, tabContentLayout]
  );

  const audienceTabContent = useMemo(
    () => (
      <PCastAudience
        event={event}
        flex={1}
        py={2}
        direction={tabContentLayout}
      />
    ),
    [event, tabContentLayout]
  );

  const transcriptView = useMemo(
    () => (
      <TranscriptView
        offset={offset}
        event={event}
        loading={isLoading}
        height="100%"
        hideAutoScrollSwitch={isMobile}
        autoScroll={isMobile || autoScroll}
        showTimeLabel={TranscriptLineBundleViewLabelLayout.top}
        onAutoScrollChange={setAutoScroll}
        overflow="scroll"
      />
    ),
    [event, isLoading, offset, autoScroll, isMobile]
  );

  const tabSectionMobile = useMemo(
    () => (
      <Fragment>
        {/* Broadcast Tabs */}
        {event.eventType === EventType.BCast && (
          <AppTabLayout flex={1} layout="mobile">
            <AppTab
              label="Program"
              value="program"
              icon={<LiveTv />}
              children={programTabContent}
              overflow="auto"
            />

            <AppTab
              label="Transcription"
              value="transcription"
              icon={<Article />}
              children={transcriptView}
              overflow="hidden" // TranscriptView scrolls itself
            />

            <AppTab
              label="Viewership"
              value="viewership"
              icon={<People />}
              children={viewershipTabContent}
              overflow="auto"
            />
          </AppTabLayout>
        )}
        {/* Broadcast Tabs */}

        {/* Podcast Tabs */}
        {event.eventType === EventType.PCast && (
          <AppTabLayout flex={1} layout="mobile">
            <AppTab
              label="Transcription"
              value="transcription"
              icon={<Article />}
              children={transcriptView}
              overflow="hidden" // TranscriptView scrolls itself
            />

            <AppTab
              label="Episode"
              value="episode"
              icon={<BurstMode />}
              children={episodeTabContent}
              overflow="auto"
            />

            <AppTab
              label="Source"
              value="source"
              icon={<Info />}
              children={sourceTabContent}
              overflow="auto"
            />

            <AppTab
              label="Audience"
              value="audience"
              icon={<People />}
              children={audienceTabContent}
              overflow="auto"
            />
          </AppTabLayout>
        )}
        {/* Podcast Tabs */}
      </Fragment>
    ),
    [
      audienceTabContent,
      episodeTabContent,
      event.eventType,
      programTabContent,
      sourceTabContent,
      transcriptView,
      viewershipTabContent,
    ]
  );

  const tabSectionDesktop = useMemo(
    () => (
      <Fragment>
        {eventDetails.data?.eventDetails?.eventType === EventType.BCast && (
          <AppTabLayout
            flex={1}
            overflow="visible"
            currentTab={currentTab}
            onCurrentTabChange={(tab) =>
              setCurrentTab(tab as PublicEventSingleTabValues)
            }
          >
            <AppTab
              label="Program"
              value={PublicEventSingleTabs.program}
              children={programTabContent}
            />

            <AppTab
              label="Transcription"
              value={PublicEventSingleTabs.transcript}
              children={transcriptView}
            />

            <AppTab
              label="Viewership"
              value={PublicEventSingleTabs.viewership}
              children={viewershipTabContent}
            />
          </AppTabLayout>
        )}

        {eventDetails.data?.eventDetails?.eventType === EventType.PCast && (
          <AppTabLayout flex={1} overflow="auto">
            <AppTab
              label="Transcription"
              value={PublicEventSingleTabs.transcript}
              children={transcriptView}
            />

            <AppTab
              label="Episode"
              value={PublicEventSingleTabs.episode}
              children={episodeTabContent}
            />

            <AppTab label="Source" value="source" children={sourceTabContent} />

            <AppTab
              label="Audience"
              value={PublicEventSingleTabs.audience}
              children={audienceTabContent}
            />
          </AppTabLayout>
        )}
      </Fragment>
    ),
    [
      eventDetails.data?.eventDetails?.eventType,
      currentTab,
      programTabContent,
      transcriptView,
      viewershipTabContent,
      episodeTabContent,
      sourceTabContent,
      audienceTabContent,
      setCurrentTab,
    ]
  );

  const mobileContent = (
    <>
      <CustomBannerHeader
        isPublicPage
        eventBannerData={eventDetails.data?.bannerData}
      />
      <Stack component={Paper} height="100%" overflow="hidden" pb={2}>
        <Stack>
          <Typography
            variant="subtitle1"
            m={2}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {titleLabel}
          </Typography>
        </Stack>

        {clipViewer}
        {tabSectionMobile}
      </Stack>
    </>
  );

  if (eventDetails.isLoading) return <LoadingOverlay />;

  if (isMobile) {
    return mobileContent;
  }

  return (
    <PageLayoutPublicDesktop
      overflow="scroll"
      header={
        <CustomBannerHeader
          isPublicPage
          eventBannerData={eventDetails?.data?.bannerData}
          maxWidth="882px"
        />
      }
      content={
        <Stack>
          <Typography variant="h6" m={4} ml={0} mr={0}>
            {titleLabel}
          </Typography>
          <Stack component={Paper}>{clipViewer}</Stack>
          <Stack mt={2} pb={2} component={Paper}>
            {tabSectionDesktop}
          </Stack>
        </Stack>
      }
    />
  );
}
