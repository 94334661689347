import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { usePowerSearchQueryKeyword } from "src/api/usePowerSearchQueryKeyword";
import { usePowerSearchEventDetails } from "src/api/usePowerSearchEventDetails";
import { useEventByStationAndDate } from "src/api/useEventByStationAndDate";
import { makeQueryDefinition } from "src/utils/makeQueryDefinition";
import { ClipViewerPage } from "src/components/ClipViewerPage/ClipViewerPage";
import { PowerSearchResultClipPathParam } from "./PowerSearchResultClip.route";
import { powerSearchResultClipEditorRoute } from "../PowerSearchResultClipEditor/PowerSearchResultClipEditor.route";
import { ShiftPlayerDataProps } from "../WatchListTermResultClipEditor/WatchListTermResultClipEditor.page";

export const PowerSearchResultClipPage = () => {
  const navigate = useNavigate();
  const { eventId = "" } = useParams<PowerSearchResultClipPathParam>();
  const powerSearchQuery = usePowerSearchQueryKeyword();
  const query = useMemo(
    () => makeQueryDefinition(powerSearchQuery.data),
    [powerSearchQuery.data]
  );

  const [dateTime, setDateTime] = useState<undefined | ShiftPlayerDataProps>(
    undefined
  );

  const eventDetails = usePowerSearchEventDetails(query, eventId);

  const dateTimeEventDetails = useEventByStationAndDate({
    request: {
      query: {
        stationUUID: eventDetails.data?.sourceId ?? "",
        startDateTime: dateTime?.startDateTime ?? "",
        endDateTime: dateTime?.endDateTime ?? "",
      },
    },
    body: query,
    options: {
      enabled: Boolean(dateTime),
    },
  });

  const event = useMemo(() => {
    if (dateTime && dateTimeEventDetails.data) {
      return (
        {
          ...eventDetails.data,
          startDateTime: dateTime.startDateTime,
          endDateTime: dateTime.endDateTime,
          transcript: dateTimeEventDetails.data.transcript,
          transcriptLines: dateTimeEventDetails.data.transcriptLines,
          transcriptLineBundles:
            dateTimeEventDetails.data.transcriptLineBundles,
          highlights: dateTimeEventDetails.data.highlights,
        } || {}
      );
    }

    return eventDetails.data || {};
  }, [eventDetails.data, dateTimeEventDetails.data, dateTime]);

  const onShiftPlayerPlay = (data: ShiftPlayerDataProps) => setDateTime(data);

  const goToClipEditor = () => {
    const url = powerSearchResultClipEditorRoute.makeUrl({
      eventId,
    });

    navigate(url);
  };

  return (
    <ClipViewerPage
      initialStartDateTime={eventDetails.data?.startDateTime}
      event={event}
      query={query}
      loading={eventDetails.isLoading}
      shiftOffset={dateTime?.shiftOffset}
      onShiftPlayerPlay={onShiftPlayerPlay}
      routeRoot="PowerSearch"
      onEdit={goToClipEditor}
    />
  );
};
