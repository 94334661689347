import { Fragment, useEffect, useState } from "react";
import {
  alpha,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent,
  DialogTitle,
  ListItem,
  ListItemText,
  Stack,
  svgIconClasses,
  Typography,
  useTheme,
} from "@mui/material";
import { WatchListQueries } from "src/api/useWatchQueryList";
import {
  AppAutocomplete,
  AppAutocompleteProps,
} from "../AppAutocomplete/AppAutocomplete";
import { useAppChartColorMapper } from "../charts/utils/useAppChartColorMapper";

type WatchQuery = WatchListQueries[number];

type WatchQueryPickerModalProps = Omit<
  AppAutocompleteProps<WatchQuery, true>,
  "renderOption" | "renderTags" | "getOptionLabel" | "getOptionKey"
> & {
  maxActiveQueries: number;
  menuState: {
    isOpen: boolean;
    show: () => void;
    hide: () => void;
  };
  onChangeWrapper: WatchQueryPickerModalProps["onChange"];
};

export function WatchPickerMobileModal({
  menuState,
  onChangeWrapper,
  maxActiveQueries,
  options,
  ...props
}: WatchQueryPickerModalProps) {
  const { palette } = useTheme();
  const { getColorForId } = useAppChartColorMapper();
  const [inputValue, setInputValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState<
    WatchQueryPickerModalProps["options"]
  >([]);

  const { value } = props;

  const handleSelectedOptions = (option: WatchQuery) => {
    if (value?.length) {
      const isSelected = value?.some((value) => value.id === option.id);
      if (isSelected) {
        const newValues = value.filter((value) => value.id !== option.id);
        onChangeWrapper?.([...newValues]);
      } else {
        onChangeWrapper?.([option, ...value]);
      }
    } else {
      onChangeWrapper?.([option]);
    }
  };

  const resetChanges = () => onChangeWrapper?.([]);

  useEffect(() => {
    const filteredArray = options.filter((option) =>
      option?.title?.toLowerCase().includes(inputValue?.toLowerCase())
    );
    setFilteredOptions(filteredArray);
  }, [options, inputValue]);

  return (
    <Dialog
      open={menuState.isOpen}
      onClose={menuState.hide}
      sx={{
        [`& .${dialogClasses.paper}`]: {
          m: "32px 24px",
          width: "100%",
          minHeight: "calc(100% - 64px)",
        },
      }}
    >
      <DialogTitle sx={{ px: 2, py: 2, width: "100%" }}>
        <Stack gap={1} width="100%">
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            pb={2}
          >
            <Typography variant="subtitle1">{`${props.label}:`}</Typography>
          </Stack>
          <AppAutocomplete<WatchQuery, true>
            {...props}
            options={options}
            renderOption={() => <Fragment />}
            onChange={onChangeWrapper}
            getOptionLabel={(o) => o.title}
            getOptionKey={(o) => o.id}
            fullWidth
            multiple
            PaperComponent={() => null}
            disablePortal
            openOnFocus={false}
            onInputChange={setInputValue}
            filterOptions={(x) => x}
            renderTags={(value, getTagProps, _ownerState) => {
              const tagElements = value.map((option, index) => {
                const label = option.title;
                const { bg, fg, icon } = getColorForId(option.id);
                const { key, ...tagProps } = getTagProps({ index });

                return (
                  <Chip
                    label={label}
                    {...tagProps}
                    key={key}
                    size="small"
                    sx={{
                      backgroundColor: bg,
                      "&:hover": {
                        backgroundColor: alpha(bg, 0.75),
                      },
                      color: fg,
                      [`.${svgIconClasses.root}`]: {
                        color: icon,
                      },
                    }}
                  />
                );
              });

              return tagElements;
            }}
            {...props}
          />
          {!value ||
            (value.length < maxActiveQueries ? null : (
              <Stack justifyContent="center">
                <Typography
                  px={2}
                  py={1}
                  variant="subtitle2"
                  color={palette.error.main}
                  children={`Max ${maxActiveQueries} queries can be selected at the same time`}
                />
              </Stack>
            ))}
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ overflow: "auto", px: 2 }}>
        <Stack>
          {filteredOptions.map((option) => {
            const isSelected = value?.some((value) => value.id === option.id);
            const bgColor: string | undefined = isSelected
              ? getColorForId(option.id).bg
              : undefined;

            return (
              <ListItem
                sx={{ paddingX: 1 }}
                key={option.id}
                onClick={() => handleSelectedOptions(option)}
              >
                <Box
                  height={24}
                  width={24}
                  minWidth={24}
                  mr={2}
                  border={`1px solid ${palette.text.secondary}`}
                  bgcolor={isSelected ? bgColor : undefined}
                />
                <ListItemText
                  children={option.title}
                  onClick={() => handleSelectedOptions(option)}
                />
              </ListItem>
            );
          })}
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{ px: 2, pt: 1, width: "100%", justifyContent: "end" }}
      >
        <Stack width="100%">
          <Stack
            flexDirection="row"
            gap={2}
            justifyContent="space-between"
            mt={1.5}
          >
            <Button
              variant="outlined"
              sx={{
                flex: 1,
                color: palette.text.primary,
                borderColor: palette.text.primary,
              }}
              onClick={() => {
                resetChanges();
                menuState.hide();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ flex: 1 }}
              onClick={menuState.hide}
            >
              Apply
            </Button>
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
