import { useState, useCallback, Fragment, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Badge,
  Paper,
  Stack,
  Typography,
  alpha,
  badgeClasses,
  useTheme,
} from "@mui/material";
import { ViewMode } from "src/components/ViewModeSwitch/ViewModeSwitch.model";
import { useReport } from "src/api/useReport";
import { useReportEvents } from "src/api/useReportEvents";
import { useReportEventsInfinite } from "src/api/useReportEventsInfinite";
import { useReportLoad } from "src/api/useReportLoad";
import { ReportItem } from "src/models/Report";
import { useListFilter } from "src/utils/useListFilter";
import { useViewModeQueryParam } from "src/utils/useViewModeQueryParam";
import {
  SortByParameters,
  OrderByParameters,
} from "src/components/SortingMenu/SortingMenu.model";
import { useRenderPDFMode } from "src/utils/useRenderPDFMode";
import { useReportEventsDataView } from "src/components/ReportEvents/ReportEvents.hook";
import { useQueryPagination } from "src/utils/useQueryPagination";
import { PageLoader } from "src/components/PageLoader";
import { PageLayoutPublicDesktop } from "src/components/PageLayoutPublicDesktop";
import { pageNotFoundRoute } from "src/pages/PageNotFound/PageNotFound.route";
import { CustomBannerHeader } from "src/components/CustomBannerHeader/CustomBannerHeader";
import { publicReportEventRoute } from "../PublicReportEvent/PublicReportEvent.route";

export enum PublicReportPageTabMode {
  info = "info",
  events = "events",
  analytics = "analytics",
}

export function PublicReportPDFPage() {
  const { palette } = useTheme();
  useRenderPDFMode();

  const [filterText] = useState("");

  const [sortBy] = useState<SortByParameters>("default");
  const [orderBy] = useState<OrderByParameters>("desc");
  const [pagination, setPagination] = useQueryPagination();
  const navigate = useNavigate();

  const { reportId = "" } = useParams();

  const initialViewMode: ViewMode = ViewMode.list;
  const [viewMode] = useViewModeQueryParam<ViewMode>({
    paramKey: "tab",
    defaultValue: initialViewMode,
  });

  const analytics = null;

  const [refetchInterval, setRefetchInterval] = useState<number | false>(2000);
  const [reportWasLoaded, setReportWasLoaded] = useState(false);

  const reportLoadMutation = useReportLoad({
    options: {
      onSuccess: () => {
        setReportWasLoaded(true);
      },
    },
  });

  const reportData = useReport(
    {
      path: {
        id: reportId,
      },
    },
    refetchInterval,
    {
      onSuccess: (report: ReportItem) => {
        if (!report.warm && !reportWasLoaded)
          reportLoadMutation.mutate({
            params: {
              path: { id: report.id },
            },
          });
      },
    }
  );

  useEffect(() => {
    if (reportData.data?.warm) {
      setRefetchInterval(false);
    }
  }, [reportData.data?.warm]);

  useEffect(() => {
    if (reportData.isFetched && !reportData.data) {
      navigate(pageNotFoundRoute.path);
    }
  }, [reportData.isFetched, reportData.data, navigate]);

  const reportEvents = useReportEvents({
    options: {
      enabled: !!reportData.data?.warm && viewMode !== ViewMode.tile,
    },
    params: {
      path: {
        id: reportId,
      },
      query: {
        from: pagination.page * pagination.pageSize,
        size: 10000,
        sortby: sortBy,
        orderby: orderBy,
      },
    },
  });

  const reportEventsTiles = useReportEventsInfinite({
    options: {
      enabled: !!reportData.data?.warm && viewMode === ViewMode.tile,
    },
    params: {
      path: {
        id: reportId,
      },
      query: {
        from: pagination.page * pagination.pageSize,
        size: 10000,
        sortby: sortBy,
        orderby: orderBy,
      },
    },
  });

  const reportEventsData = reportEvents.data?.results;
  const reportsEventsTotal = reportEvents.data?.total || 0;

  const filterData = useListFilter(reportEventsData || [], filterText, [
    "title",
    "highlights",
  ]);

  const getEventUrl = useCallback(
    (eventId: string) => {
      return publicReportEventRoute.makeUrl({
        reportId,
        eventId,
      });
    },
    [reportId]
  );

  const dataViewElement = useReportEventsDataView({
    data: filterData,
    viewMode,
    rowCount: reportsEventsTotal,
    getEventUrl,
    isLoading: reportEvents.isLoading || reportEvents.isFetching,
    paginationModel: pagination,
    onPaginationModelChange: setPagination,
    reportEventsTiles,
    renderHeader: undefined,
    isCompact: true,
  });

  const desktopContent = (
    <Fragment>
      <Paper
        sx={{
          padding: 2,
          backgroundColor: alpha(palette.primary.light, 0.04),
        }}
      >
        <Typography variant="subtitle1">Information</Typography>
        <Stack p={2} gap={2}>
          <Stack>
            <Typography variant="subtitle2">Description</Typography>
            <Typography>{reportData.data?.description || "-"}</Typography>
          </Stack>
          <Stack>
            <Typography variant="subtitle2">Audience information</Typography>
            <Typography>
              {reportData.data?.audienceInformation || "-"}
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="subtitle2">Local Market Viewership</Typography>
            <Typography>{reportData.data?.localViewership || "-"}</Typography>
          </Stack>
          <Stack>
            <Typography variant="subtitle2">National Viewership</Typography>
            <Typography>
              {reportData.data?.nationalViewership || "-"}
            </Typography>
          </Stack>
        </Stack>
      </Paper>

      {!!analytics && (
        <Paper
          sx={{
            padding: 2,
            backgroundColor: alpha(palette.primary.light, 0.04),
            mt: 1,
          }}
        >
          <Typography variant="subtitle1">Analytics</Typography>
          <Typography variant="body2">Content</Typography>
        </Paper>
      )}

      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          border: `1px solid ${palette.divider}`,
          mt: 2,
          pt: 2,
          pb: 1,
          overflow: "hidden",
          height: "100%",
        }}
      >
        <Stack flex={1} overflow="hidden">
          {dataViewElement}
        </Stack>
      </Paper>
    </Fragment>
  );

  if (
    reportData.isLoading ||
    reportEvents.isLoading ||
    reportEventsTiles.isLoading ||
    !reportData.data?.warm
  ) {
    return <PageLoader />;
  }

  return (
    <PageLayoutPublicDesktop
      overflow="visible"
      header={
        <>
          <CustomBannerHeader
            isPublicPage
            publicUserId={reportData?.data?.userId}
            maxWidth="882px"
          />
          <Stack
            my={3}
            mx={{ xs: 2, md: 0 }}
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ flexWrap: { sm: "wrap", md: "nowrap" } }}
            gap={{ sm: 1, md: 4 }}
          >
            <Stack>
              <Badge
                badgeContent={reportsEventsTotal}
                color="info"
                sx={{
                  [`.${badgeClasses.badge}`]: {
                    top: 16,
                    right: 18,
                  },
                }}
              >
                <Typography variant="h5" sx={{ pr: 5 }}>
                  {reportData.data?.title || ""}
                </Typography>
              </Badge>
            </Stack>
          </Stack>
        </>
      }
      content={desktopContent}
    />
  );
}
