import { SvgIcon, SvgIconProps } from "@mui/material";

export const YouTubeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1ZM14.4679 3.34256C16.1263 3.8144 17.5895 4.7499 18.7083 6H15.3531C15.1127 4.98607 14.8131 4.08791 14.4679 3.34256ZM15.3531 18H18.7083C17.5894 19.2501 16.1263 20.1856 14.4679 20.6574C14.8131 19.9121 15.1127 19.0139 15.3531 18ZM20 15C20 15.5523 19.5523 16 19 16H5C4.44772 16 4 15.5523 4 15V9C4 8.44772 4.44772 8 5 8H19C19.5523 8 20 8.44772 20 9V15ZM13.2687 5.00966C13.3797 5.31496 13.4844 5.6459 13.5814 6H10.4186C10.5156 5.6459 10.6203 5.31496 10.7313 5.00966C11.0394 4.16257 11.3626 3.60484 11.635 3.28793C11.7665 3.13496 11.8661 3.06146 11.925 3.0283C11.9702 3.00288 11.9922 3.00022 11.9983 3H12.0018C12.0078 3.00022 12.0298 3.0029 12.075 3.0283C12.1339 3.06146 12.2335 3.13496 12.365 3.28793C12.6374 3.60484 12.9606 4.16257 13.2687 5.00966ZM9.53211 3.34257C9.18685 4.08791 8.88728 4.98607 8.64688 6H5.29168C6.41055 4.7499 7.87371 3.8144 9.53211 3.34257ZM9.53211 20.6574C7.87371 20.1856 6.41055 19.2501 5.29168 18H8.64689C8.88729 19.0139 9.18685 19.9121 9.53211 20.6574ZM10.7313 18.9903C10.6203 18.685 10.5156 18.3541 10.4186 18H13.5814C13.4844 18.3541 13.3797 18.685 13.2687 18.9903C12.9606 19.8374 12.6374 20.3952 12.365 20.7121C12.2335 20.865 12.1339 20.9385 12.075 20.9717C12.0298 20.9971 12.0077 20.9998 12.0017 21H11.9983C11.9924 20.9998 11.9704 20.9972 11.925 20.9717C11.8661 20.9385 11.7665 20.865 11.635 20.7121C11.3626 20.3952 11.0394 19.8374 10.7313 18.9903Z"
        />
        <path d="M10 15V9L15 12L10 15Z" />
      </svg>
    </SvgIcon>
  );
};
