import {
  FormControlLabel,
  Paper,
  Slider,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { TileLayoutLazy } from "src/components/TileLayoutLazy/TileLayoutLazy";

const makeItem = (index: number) => `Item ${index}`;
const genItems = (count: number, startIndex = 0) => {
  return [...new Array(count)].map((_, index) => makeItem(startIndex + index));
};

const lazyItemsInitial = genItems(5);
const lazyItemsLimit = 100;

export function DevListLayoutLazy() {
  const { palette } = useTheme();
  const [lazyItems, setLazyItems] = useState<string[]>(lazyItemsInitial);
  const [isLoading, setLoading] = useState(false);
  const hasNextPage = lazyItems.length < lazyItemsLimit;
  const [columnCount, setColumnCount] = useState(0);
  const isItemLoaded = (index: number) =>
    !hasNextPage || index < lazyItems.length;

  return (
    <Stack height="100%" gap={3} pt={2} bgcolor={palette.background.default}>
      <Typography
        children={`${lazyItems.length} of ${lazyItemsLimit} loaded`}
      />

      <Stack px={2}>
        <FormControlLabel
          label={`Columns ${columnCount || "Auto"}`}
          labelPlacement="top"
          control={
            <Slider
              min={0}
              max={4}
              step={1}
              marks
              value={columnCount}
              onChange={(e, value) => setColumnCount(value as number)}
              valueLabelDisplay="auto"
            />
          }
        />
      </Stack>

      <TileLayoutLazy
        items={lazyItems}
        total={lazyItemsLimit}
        isItemLoaded={isItemLoaded}
        isLoading={isLoading}
        getColumnCount={columnCount > 0 ? () => columnCount : undefined}
        loadMoreItems={async (startIndex, stopIndex) => {
          try {
            setLoading(true);
            console.log(`@@ DEBUG:loadMoreItems: ${startIndex} ${stopIndex}`);
            await new Promise((resolve) => setTimeout(resolve, 2000));
            const loaded = genItems(1 + stopIndex - startIndex, startIndex);
            setLazyItems((prevLazyItems) => {
              const nextLazyItems = [...prevLazyItems];
              for (let index = 0; index < loaded.length; index++) {
                if (nextLazyItems.length === lazyItemsLimit) break;
                nextLazyItems[startIndex + index] = loaded[index];
              }

              return nextLazyItems;
            });
          } finally {
            setLoading(false);
          }
        }}
        renderItem={({ data, columnIndex, rowIndex }) => {
          return (
            <Stack
              flex={1}
              justifyContent="center"
              component={Paper}
              elevation={4}
            >
              <Typography align="center" variant="h6" children={data} />

              <Typography
                align="center"
                variant="h6"
                children={`r=${rowIndex}, c=${columnIndex}`}
              />
            </Stack>
          );
        }}
      />
    </Stack>
  );
}
