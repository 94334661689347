import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import {
  UseInfiniteQueryOptions,
  useInfiniteQuery,
  useQueryClient,
} from "react-query";
import { enqueueSnackbar } from "notistack";
import { paths } from "@tveyes/twosionwebapischema";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { countPagedResultsTotal } from "src/utils/countPagedResultTotal";
import { makeQueryDefinition } from "src/utils/makeQueryDefinition";
import { PowerSearchResponse } from "./usePowerSearch";
import {
  PowerSearchQueryKeywordValues,
  usePowerSearchQueryKeyword,
} from "./usePowerSearchQueryKeyword";
import {
  PowerSearchQueryCustomValues,
  usePowerSearchQueryCustom,
} from "./usePowerSearchQueryCustom";
import { isWatchQueryCustomValues } from "src/components/WatchQueryCustomForm/WatchQueryCustom.guard";
import { isWatchQueryKeywordValues } from "src/components/WatchQueryKeywordForm/WatchQueryKeyword.guard";

const apiKey: keyof paths = "/api/PowerSearch";
const method: keyof paths[typeof apiKey] = "post";

type Endpoint = Required<paths[typeof apiKey][typeof method]>;
type Request = Endpoint["requestBody"]["content"]["application/json"];
type RequestParams = {
  params: {
    query: Omit<Endpoint["parameters"]["query"], "from" | "size">;
  };
  body: PowerSearchQueryKeywordValues | PowerSearchQueryCustomValues;
};

const pageSize = 24;

export function usePowerSearchInfinite({
  request,
  options,
  keepPreviousData,
}: {
  request: RequestParams;
  options?: UseInfiniteQueryOptions<PowerSearchResponse, unknown>;
  keepPreviousData: boolean;
}) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();
  const powerSearchQueryKeyword = usePowerSearchQueryKeyword();
  const powerSearchQueryCustom = usePowerSearchQueryCustom();

  return useInfiniteQuery<PowerSearchResponse, unknown>({
    ...options,
    keepPreviousData,
    queryKey: [apiKey, request],
    initialData: () => {
      const cacheWatchListResults =
        queryClient.getQueryData<PowerSearchResponse>(
          [
            apiKey,
            {
              // we want cached data only from start regardless of the page size
              query: { from: 0 },
            },
          ],
          {
            // ignore page size
            exact: false,
          }
        );

      const { results = [], total = 0 } = cacheWatchListResults || {};
      const pages: PowerSearchResponse[] = [];

      while (true) {
        const from = pages.length * pageSize;
        const to = from + pageSize;
        const pageData = (results || []).slice(from, to);

        if (pageData.length < pageSize) {
          // no more data to fill next page
          break;
        }

        pages.push({
          total,
          results: pageData,
          configuration: {
            from,
            size: pageSize,
          },
        });
      }

      return {
        pages: pages,
        pageParams: pages.map(({ configuration }) => configuration),
      };
    },
    queryFn: async ({ pageParam }) => {
      if (isWatchQueryKeywordValues(request.body)) {
        powerSearchQueryKeyword.save(request.body);
      }

      if (isWatchQueryCustomValues(request.body)) {
        powerSearchQueryCustom.save(request.body);
      }

      const from = pageParam?.from || 0;
      const size = pageParam?.size || pageSize;
      const after = pageParam?.after || undefined;

      const token = await getAccessTokenSilently();
      const apiPath = makeApiUrl(apiKey, {
        query: {
          from,
          size,
          after,
          ...request.params.query,
        },
      });

      const body: Request = {
        queryDefinition: makeQueryDefinition(request.body),
      };

      const { data } = await axios<PowerSearchResponse>(apiPath, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: body,
      });

      return data;
    },
    getNextPageParam: (lastGroup = {}, allPages) => {
      const loadedCount = countPagedResultsTotal(allPages);
      const { total, configuration } = lastGroup;
      if (total === loadedCount) return;

      /** in case groupDuplicates is set to true */
      /** "after" is overriding "from" on the BE side */
      if (configuration && configuration.next !== null) {
        return {
          after: configuration.next,
          size: pageSize,
        };
      }

      if (configuration && configuration.from !== null) {
        const { from = 0 } = configuration;
        return {
          from: from + pageSize,
          size: pageSize,
        };
      }

      return {
        from: 0,
        size: pageSize,
      };
    },
    onError: (error) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
  });
}
