import { SvgIcon, SvgIconProps } from "@mui/material";

export const TermsOfUseIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="15"
        viewBox="0 0 16 16"
        fill="currentColor"
      ></svg> */}
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8L14 2ZM14 9H13V4L18 9H14Z"
          fill="#042441"
          fillOpacity="0.65"
        />
        <path
          d="M7.36314 17.9444V14.6667H6V14.0556H9.49011V14.6667H8.12697V17.9444H7.36314Z"
          fill="white"
        />
        <path
          d="M11.9882 18C11.667 18 11.3713 17.95 11.101 17.85C10.8308 17.75 10.5957 17.6111 10.396 17.4333C10.1962 17.2519 10.0415 17.0407 9.93179 16.8C9.82211 16.5556 9.76727 16.2889 9.76727 16C9.76727 15.7111 9.82211 15.4463 9.93179 15.2056C10.0415 14.9611 10.1962 14.75 10.396 14.5722C10.5957 14.3907 10.8308 14.25 11.101 14.15C11.3713 14.05 11.6651 14 11.9824 14C12.3036 14 12.5974 14.05 12.8637 14.15C13.134 14.25 13.369 14.3907 13.5688 14.5722C13.7686 14.75 13.9233 14.9611 14.033 15.2056C14.1426 15.4463 14.1975 15.7111 14.1975 16C14.1975 16.2889 14.1426 16.5556 14.033 16.8C13.9233 17.0444 13.7686 17.2556 13.5688 17.4333C13.369 17.6111 13.134 17.75 12.8637 17.85C12.5974 17.95 12.3055 18 11.9882 18ZM11.9824 17.3667C12.19 17.3667 12.3819 17.3333 12.5582 17.2667C12.7345 17.2 12.8872 17.1056 13.0165 16.9833C13.1457 16.8574 13.2456 16.713 13.3161 16.55C13.3906 16.3833 13.4278 16.2 13.4278 16C13.4278 15.8 13.3906 15.6185 13.3161 15.4556C13.2456 15.2889 13.1457 15.1444 13.0165 15.0222C12.8872 14.8963 12.7345 14.8 12.5582 14.7333C12.3819 14.6667 12.19 14.6333 11.9824 14.6333C11.7748 14.6333 11.5828 14.6667 11.4066 14.7333C11.2342 14.8 11.0814 14.8963 10.9483 15.0222C10.819 15.1444 10.7172 15.2889 10.6427 15.4556C10.5722 15.6185 10.537 15.8 10.537 16C10.537 16.1963 10.5722 16.3778 10.6427 16.5444C10.7172 16.7111 10.819 16.8574 10.9483 16.9833C11.0775 17.1056 11.2303 17.2 11.4066 17.2667C11.5828 17.3333 11.7748 17.3667 11.9824 17.3667Z"
          fill="white"
        />
        <path
          d="M16.3372 18C16.016 18 15.7085 17.9574 15.4147 17.8722C15.121 17.7833 14.8879 17.6704 14.7155 17.5333L14.9799 16.9722C15.1445 17.0944 15.3481 17.1963 15.591 17.2778C15.8339 17.3593 16.0826 17.4 16.3372 17.4C16.5526 17.4 16.727 17.3778 16.8601 17.3333C16.9933 17.2889 17.0912 17.2296 17.1539 17.1556C17.2166 17.0778 17.2479 16.9907 17.2479 16.8944C17.2479 16.7759 17.2029 16.6815 17.1128 16.6111C17.0227 16.537 16.9052 16.4796 16.7602 16.4389C16.6192 16.3944 16.4606 16.3537 16.2843 16.3167C16.112 16.2796 15.9377 16.237 15.7614 16.1889C15.589 16.137 15.4304 16.0722 15.2855 15.9944C15.1445 15.913 15.0289 15.8056 14.9388 15.6722C14.8487 15.5389 14.8037 15.3685 14.8037 15.1611C14.8037 14.95 14.8624 14.7574 14.9799 14.5833C15.1014 14.4056 15.2835 14.2648 15.5264 14.1611C15.7731 14.0537 16.0846 14 16.4606 14C16.7074 14 16.9522 14.0296 17.195 14.0889C17.4379 14.1481 17.6494 14.2333 17.8296 14.3444L17.5887 14.9056C17.4046 14.8019 17.2146 14.7259 17.0188 14.6778C16.8229 14.6259 16.6349 14.6 16.4547 14.6C16.2432 14.6 16.0708 14.6241 15.9377 14.6722C15.8084 14.7204 15.7124 14.7833 15.6498 14.8611C15.591 14.9389 15.5616 15.0278 15.5616 15.1278C15.5616 15.2463 15.6047 15.3426 15.6909 15.4167C15.781 15.487 15.8965 15.5426 16.0375 15.5833C16.1825 15.6241 16.3431 15.6648 16.5193 15.7056C16.6956 15.7426 16.8699 15.7852 17.0423 15.8333C17.2185 15.8815 17.3772 15.9444 17.5182 16.0222C17.6631 16.1 17.7787 16.2056 17.8649 16.3389C17.955 16.4722 18 16.6407 18 16.8444C18 17.0519 17.9393 17.2444 17.8179 17.4222C17.7003 17.5963 17.5182 17.737 17.2714 17.8444C17.0247 17.9481 16.7132 18 16.3372 18Z"
          fill="white"
        />
        <path
          d="M7.36314 17.9444V14.6667H6V14.0556H9.49011V14.6667H8.12697V17.9444H7.36314Z"
          stroke="white"
          strokeWidth="0.3"
        />
        <path
          d="M11.9882 18C11.667 18 11.3713 17.95 11.101 17.85C10.8308 17.75 10.5957 17.6111 10.396 17.4333C10.1962 17.2519 10.0415 17.0407 9.93179 16.8C9.82211 16.5556 9.76727 16.2889 9.76727 16C9.76727 15.7111 9.82211 15.4463 9.93179 15.2056C10.0415 14.9611 10.1962 14.75 10.396 14.5722C10.5957 14.3907 10.8308 14.25 11.101 14.15C11.3713 14.05 11.6651 14 11.9824 14C12.3036 14 12.5974 14.05 12.8637 14.15C13.134 14.25 13.369 14.3907 13.5688 14.5722C13.7686 14.75 13.9233 14.9611 14.033 15.2056C14.1426 15.4463 14.1975 15.7111 14.1975 16C14.1975 16.2889 14.1426 16.5556 14.033 16.8C13.9233 17.0444 13.7686 17.2556 13.5688 17.4333C13.369 17.6111 13.134 17.75 12.8637 17.85C12.5974 17.95 12.3055 18 11.9882 18ZM11.9824 17.3667C12.19 17.3667 12.3819 17.3333 12.5582 17.2667C12.7345 17.2 12.8872 17.1056 13.0165 16.9833C13.1457 16.8574 13.2456 16.713 13.3161 16.55C13.3906 16.3833 13.4278 16.2 13.4278 16C13.4278 15.8 13.3906 15.6185 13.3161 15.4556C13.2456 15.2889 13.1457 15.1444 13.0165 15.0222C12.8872 14.8963 12.7345 14.8 12.5582 14.7333C12.3819 14.6667 12.19 14.6333 11.9824 14.6333C11.7748 14.6333 11.5828 14.6667 11.4066 14.7333C11.2342 14.8 11.0814 14.8963 10.9483 15.0222C10.819 15.1444 10.7172 15.2889 10.6427 15.4556C10.5722 15.6185 10.537 15.8 10.537 16C10.537 16.1963 10.5722 16.3778 10.6427 16.5444C10.7172 16.7111 10.819 16.8574 10.9483 16.9833C11.0775 17.1056 11.2303 17.2 11.4066 17.2667C11.5828 17.3333 11.7748 17.3667 11.9824 17.3667Z"
          stroke="white"
          strokeWidth="0.3"
        />
        <path
          d="M16.3372 18C16.016 18 15.7085 17.9574 15.4147 17.8722C15.121 17.7833 14.8879 17.6704 14.7155 17.5333L14.9799 16.9722C15.1445 17.0944 15.3481 17.1963 15.591 17.2778C15.8339 17.3593 16.0826 17.4 16.3372 17.4C16.5526 17.4 16.727 17.3778 16.8601 17.3333C16.9933 17.2889 17.0912 17.2296 17.1539 17.1556C17.2166 17.0778 17.2479 16.9907 17.2479 16.8944C17.2479 16.7759 17.2029 16.6815 17.1128 16.6111C17.0227 16.537 16.9052 16.4796 16.7602 16.4389C16.6192 16.3944 16.4606 16.3537 16.2843 16.3167C16.112 16.2796 15.9377 16.237 15.7614 16.1889C15.589 16.137 15.4304 16.0722 15.2855 15.9944C15.1445 15.913 15.0289 15.8056 14.9388 15.6722C14.8487 15.5389 14.8037 15.3685 14.8037 15.1611C14.8037 14.95 14.8624 14.7574 14.9799 14.5833C15.1014 14.4056 15.2835 14.2648 15.5264 14.1611C15.7731 14.0537 16.0846 14 16.4606 14C16.7074 14 16.9522 14.0296 17.195 14.0889C17.4379 14.1481 17.6494 14.2333 17.8296 14.3444L17.5887 14.9056C17.4046 14.8019 17.2146 14.7259 17.0188 14.6778C16.8229 14.6259 16.6349 14.6 16.4547 14.6C16.2432 14.6 16.0708 14.6241 15.9377 14.6722C15.8084 14.7204 15.7124 14.7833 15.6498 14.8611C15.591 14.9389 15.5616 15.0278 15.5616 15.1278C15.5616 15.2463 15.6047 15.3426 15.6909 15.4167C15.781 15.487 15.8965 15.5426 16.0375 15.5833C16.1825 15.6241 16.3431 15.6648 16.5193 15.7056C16.6956 15.7426 16.8699 15.7852 17.0423 15.8333C17.2185 15.8815 17.3772 15.9444 17.5182 16.0222C17.6631 16.1 17.7787 16.2056 17.8649 16.3389C17.955 16.4722 18 16.6407 18 16.8444C18 17.0519 17.9393 17.2444 17.8179 17.4222C17.7003 17.5963 17.5182 17.737 17.2714 17.8444C17.0247 17.9481 16.7132 18 16.3372 18Z"
          stroke="white"
          strokeWidth="0.3"
        />
      </svg>
    </SvgIcon>
  );
};
