import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useBlocker,
  BlockerFunction,
  useNavigate,
  useParams,
} from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import {
  Avatar,
  Box,
  Button,
  Paper,
  Stack,
  ToggleButton,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import { isEqual } from "lodash";
import { AccountCircle, ContentCopy, DeleteOutline } from "@mui/icons-material";
import { useUserData } from "src/api/useUserData";
import { useUserDataUpdate } from "src/api/useUserDataUpdate";
import { useAccount } from "src/api/useAccount";
import { EventSourceCategory, useEventSources } from "src/api/useEventSources";
import { useNotifyUser } from "src/api/useNotifyUser";
import { useUserDetailsUpdate } from "src/api/useUserDetailsUpdate";
import { useUserContentFilterUpdate } from "src/api/useUserContentFilterUpdate";
import { useUserEntitlementsUpdate } from "src/api/useUserEntitlementsUpdate";
import { AppTabLayout } from "src/components/AppTabLayout/AppTabLayout";
import { AppTab } from "src/components/AppTabLayout/AppTab";
import { PageLayoutDesktop } from "src/components/PageLayoutDesktop";
import { PageHeaderDesktop } from "src/components/PageHeaderDesktop/PageHeaderDesktop";
import { UserManagementFormCreate } from "src/components/UserManagementFormCreate/UserManagementFormCreate";
import { useUserInformationForm } from "src/components/UserInformationUpdateForm/UserInformationUpdateForm.hook";
import { UserInformationSettingsFormSchema } from "src/components/UserInformationUpdateForm/UserInformationUpdateForm.schema";
import { UserInformationUpdateFormValues } from "src/components/UserInformationUpdateForm/UserInformationUpdateForm.model";
import { UploadIcon } from "src/components/icons/UploadIcon";
import { useOpenState } from "src/utils/useOpenState";
import { useViewModeQueryParam } from "src/utils/useViewModeQueryParam";
import { makeContentFilterQuery } from "src/utils/makeContentFilterQuery";
import { ContentAccessFilter } from "src/models/ContentAccessFilter";
import { useUsersManagementProfileImage } from "src/api/useUsersManagementProfileImage";
import { PageLoader } from "src/components/PageLoader";
import { UploadImageDialog } from "src/components/UploadImageDialog/UploadImageDialog";
import { DialogModes } from "src/components/UploadImageDialog/UploadImageDialog.model";
import { useUserManagementProfileImageDelete } from "src/api/useUserProfileImageDelete";
import { useUserManagementProfileImageUpdate } from "src/api/useUserManagementProfileImageUpdate";
import { DialogPrompt } from "src/components/DialogPrompt/DialogPrompt";
import { useGlobalEntitlements } from "src/api/useGlobalEntitlements";
import {
  CONTENT_FILTER_PRESETS,
  INCLUDE_EXCLUDE_PRESETS,
} from "src/utils/contentFilterPresets";
import { useSelectedPresetStore } from "src/utils/usePresets";
import { parseIncludeExcludeGroup } from "src/utils/makeWatchQueryFormValues";
import { LeaveConfirmationDialog } from "./LeaveConfirmationDialog/LeaveConfirmationDialog";
import {
  makeEntitlements,
  UserManagementTabMode,
  getUserData,
  makeAccountFormValues,
  pageTabKey,
  makeAccountEntitlementsFormValues,
  makeAccountContentFilterFormValues,
} from "./UserManagementUpdate.utils";
import { SettingsCustomizationTab } from "../Settings/components/SettingsCustomizationTab";
import { userManagementRoute } from "../UserManagement/UserManagement.route";
import { AccountsManagementFeaturesTab } from "../AccountsManagementCreateAccount/components/AccountsManagementFeaturesTab/AccountsManagementFeaturesTab";
import { AccountsContentTab } from "../AccountsManagementCreateAccount/components/AccountsManagementContentTab/AccountsManagementContentTab";
import { NewAccountFormValues } from "../AccountsManagementCreateAccount/AccountsManagementCreateAccount.model";
import {
  limitsConfiguration,
  NewAccountSchema,
} from "../AccountsManagementCreateAccount/AccountsManagementCreateAccount.schema";
import { createAccountDefaultValues } from "../AccountsManagementCreateAccount/AccountsManagementCreateAccount.const";
import { DuplicateUserFormValues } from "../UserManagement/components/DuplicateUserForm/DuplicateUserForm.model";
import { DuplicateUserDialog } from "../UserManagement/components/DuplicateUserDialog/DuplicateUserDialog";
import defaultAvatar from "../Settings/components/assets/defaultAvatar.png";

const AUTH0 = "auth0";

export function UserManagementUpdatePage() {
  const { userId = "" } = useParams();
  const { data: globalEntitlementsData } = useGlobalEntitlements();
  const systemLimits = globalEntitlementsData?.globalEntitlements;
  const [duplicateUserData, setDuplicateUserData] =
    useState<DuplicateUserFormValues | null>(null);
  const [contentFilters, setContentFilters] =
    useState<ContentAccessFilter | null>(null);
  const [selectedAvatar, setSelectedAvatar] = useState<
    string | Blob | null | undefined
  >(null);

  const { setSelectedPreset } = useSelectedPresetStore();
  const { data: userData } = useUserData({
    request: {
      params: {
        path: {
          id: userId,
        },
      },
    },
  });

  const {
    data: profileImageData,
    isLoading: isProfileImgLoading,
    isFetching: isProfileImageFetching,
  } = useUsersManagementProfileImage({
    request: {
      params: {
        path: {
          id: userId,
        },
      },
    },
  });

  const profileImageUpdate = useUserManagementProfileImageUpdate({
    request: {
      params: {
        path: {
          id: userId,
        },
      },
    },
  });

  const deleteUserProfileImage = useUserManagementProfileImageDelete({
    request: {
      params: {
        path: {
          id: userId,
        },
      },
    },
    options: {
      onSuccess: () => deleteAvatarDialog.hide(),
    },
  });

  const formHook = useUserInformationForm({
    schema: UserInformationSettingsFormSchema,
  });
  const {
    reset,
    formState,
    handleSubmit,
    watch,
    setValue: setUserValue,
  } = formHook;
  const { accountId, firstName, lastName, email } = watch();

  const notifyUser = useNotifyUser({});
  const isDeleteDisabled =
    !profileImageData?.image || profileImageData?.imageSource === AUTH0;

  const onNotifyUser = useCallback(() => {
    if (!userData) return;

    notifyUser.mutate({
      body: {
        toEmail: userData.email,
        ttlSeconds: 0,
      },
    });
  }, [notifyUser, userData]);

  const { data: accountData } = useAccount({
    request: {
      path: {
        id: accountId,
      },
    },
    options: {
      enabled: !!accountId,
    },
  });

  const accountFormHook = useForm<NewAccountFormValues>({
    mode: "all",
    resolver: joiResolver(
      NewAccountSchema(
        accountData?.entitlements || null,
        limitsConfiguration.account,
        systemLimits
      )
    ),
    defaultValues: createAccountDefaultValues,
  });

  const {
    watch: watchAccount,
    formState: accountFormState,
    reset: resetAccount,
  } = accountFormHook;
  const accountFormData = watchAccount();

  const isFormsSubmitted =
    formState.isSubmitted || accountFormState.isSubmitted;
  const dataWasChanged = formState.isDirty || accountFormState.isDirty;

  const shouldBlockLeaving = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) => {
      return (
        !isFormsSubmitted &&
        dataWasChanged &&
        currentLocation.pathname !== nextLocation.pathname
      );
    },
    [isFormsSubmitted, dataWasChanged]
  );

  const blocker = useBlocker(shouldBlockLeaving);

  const { data: sourcesData } = useEventSources({
    path: {
      categories: Object.values(EventSourceCategory),
      searchText: "*",
    },
  });

  const isProfileImageLoading =
    isProfileImageFetching ||
    isProfileImgLoading ||
    profileImageUpdate.isLoading;

  useEffect(() => {
    setSelectedAvatar(profileImageData?.image || defaultAvatar);
  }, [profileImageData]);

  useEffect(() => {
    const filters = makeContentFilterQuery(
      accountFormData.sourcesInclude,
      accountFormData.sourcesExclude
    );
    setContentFilters(filters);
  }, [accountFormData.sourcesExclude, accountFormData.sourcesInclude]);

  const userUpdate = useUserDataUpdate({});
  const { isSuccess, isLoading } = userUpdate;

  const userGeneralDataUpdate = useUserDetailsUpdate({});
  const userContentFilterDataUpdate = useUserContentFilterUpdate({});
  const userFeaturesDataUpdate = useUserEntitlementsUpdate({});

  const [pageTabMode, setPageTabMode] =
    useViewModeQueryParam<UserManagementTabMode>({
      paramKey: pageTabKey,
      defaultValue: UserManagementTabMode.general,
    });

  const handleChangePageTabMode = (newValue: string) => {
    setPageTabMode(newValue as UserManagementTabMode);
  };

  const { palette } = useTheme();

  useEffect(() => {
    if (!userData) return;
    const initialUserData = getUserData(userData);
    const initialFormValues = {
      ...initialUserData,
      expirationDate: accountData?.expirationDate || undefined,
    };
    const matchingPreset = INCLUDE_EXCLUDE_PRESETS.find((item) =>
      isEqual(item.contentAccessFilter, userData?.contentAccessFilter)
    );
    const selectedContentFilterPreset = CONTENT_FILTER_PRESETS.find(
      (preset) => preset.title === matchingPreset?.title
    );
    if (selectedContentFilterPreset) {
      const sources = parseIncludeExcludeGroup(
        selectedContentFilterPreset.queryDefinition.sourceFilter
      );
      setSelectedPreset({
        include: sources.include,
        exclude: sources.exclude,
      });
    }

    const initialAccountFormValues = makeAccountFormValues(
      userData,
      sourcesData,
      matchingPreset
    );

    resetAccount(initialAccountFormValues);
    reset(initialFormValues);
  }, [
    userData,
    reset,
    resetAccount,
    sourcesData,
    accountData,
    setSelectedPreset,
  ]);

  useEffect(() => {
    if (!accountData) return;

    setUserValue("expirationDate", accountData.expirationDate);
  }, [accountData, setUserValue]);

  const avatarDialog = useOpenState();
  const deleteAvatarDialog = useOpenState();

  const selectedAvatarString =
    typeof selectedAvatar === "string" ? selectedAvatar : undefined;

  const navigate = useNavigate();
  useEffect(() => {
    if (isSuccess) {
      navigate(userManagementRoute.path);
    }
  }, [navigate, isSuccess]);

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleDeleteProfileImage = () => {
    deleteUserProfileImage.mutate();
  };

  const submitUserData = handleSubmit(
    (data: UserInformationUpdateFormValues) => {
      if (!userData) return;

      userUpdate.mutate({
        ...userData,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        accountId: data.accountId,
        isActive: data.isActive,
        phoneNumber: data.phoneNumber,
        timeZone: data.timezone,
        roles: data.roles,
        sortName: `${data.lastName} ${data.firstName}`,
        contentAccessFilter: contentFilters,
        entitlements: makeEntitlements(accountFormData),
      });
    },
    (errors) => {
      console.log("@@ DEBUG:submitWatchQueryUpdate:error", errors);
    }
  );

  const resetGeneralTabData = () => {
    if (!userData) return;

    const initialUserData = getUserData(userData);
    const initialFormValues = {
      ...initialUserData,
      expirationDate: accountData?.expirationDate || undefined,
    };
    reset(initialFormValues);
  };

  const resetContentFilterTab = () => {
    if (!userData) return;
    const matchingPreset = INCLUDE_EXCLUDE_PRESETS.find((item) =>
      isEqual(item.contentAccessFilter, userData?.contentAccessFilter)
    );
    const initialAccountFormValues = makeAccountContentFilterFormValues(
      accountFormData,
      userData,
      sourcesData,
      matchingPreset
    );
    const selectedContentFilterPreset = CONTENT_FILTER_PRESETS.find(
      (preset) => preset.title === matchingPreset?.title
    );
    if (selectedContentFilterPreset) {
      const sources = parseIncludeExcludeGroup(
        selectedContentFilterPreset.queryDefinition.sourceFilter
      );
      setSelectedPreset({
        include: sources.include,
        exclude: sources.exclude,
      });
    }

    resetAccount(initialAccountFormValues);
  };

  const resetFeaturesTab = () => {
    if (!userData) return;

    const initialAccountFormValues = makeAccountEntitlementsFormValues(
      accountFormData,
      userData
    );

    resetAccount(initialAccountFormValues);
  };

  const submitGeneralTabData = handleSubmit(
    (data: UserInformationUpdateFormValues) => {
      if (!userData) return;

      userGeneralDataUpdate.mutate({
        body: {
          company: userData.company,
          language: userData.language,
          userTitle: userData.userTitle,
          state: userData.state,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phoneNumber: data.phoneNumber,
          timeZone: data.timezone,
          roles: data.roles,
        },
        params: {
          path: {
            id: userData.id ?? "",
          },
          query: {
            notify: data.notify,
          },
        },
      });
    },
    (errors) => {
      console.log("@@ DEBUG:submitWatchQueryUpdate:error", errors);
    }
  );

  const submitContentFilterTabData = () => {
    if (!userData || !contentFilters) return;

    userContentFilterDataUpdate.mutate({
      body: contentFilters,
      params: {
        path: {
          id: userData.id ?? "",
        },
      },
    });
  };

  const submitFeaturesTabData = () => {
    if (!userData) return;

    userFeaturesDataUpdate.mutate({
      body: makeEntitlements(accountFormData),
      params: {
        path: {
          id: userData.id ?? "",
        },
      },
    });
  };

  const onUserDuplicate = useCallback(
    (userData: DuplicateUserFormValues | null) =>
      setDuplicateUserData(userData),
    [setDuplicateUserData]
  );

  const generalTab = (
    <FormProvider {...formHook}>
      <Stack direction={"row"} gap={3}>
        <Stack component={Paper} flex={1} px={3}>
          <UserManagementFormCreate
            origin="edit"
            loading={isLoading}
            isUserActive={userData?.isActive}
            userFullName={`${userData?.firstName} ${userData?.lastName}`}
            accountName={accountData?.name || ""}
          />
          <Stack
            direction="row"
            justifyContent="flex-end"
            gap={2}
            mr={2}
            mb={3}
          >
            <Button
              variant="outlined"
              sx={{ height: "42px", width: "124px", fontSize: "15px" }}
              onClick={resetGeneralTabData}
              disabled={!formState.isValid || !formState.isDirty}
            >
              Reset
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ height: "42px", width: "124px", fontSize: "15px" }}
              onClick={submitGeneralTabData}
              disabled={!formState.isValid || !formState.isDirty}
            >
              Save
            </Button>
          </Stack>
        </Stack>
        <Stack
          width={300}
          alignItems={"center"}
          component={Paper}
          pt={17.75}
          sx={{
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        >
          <Box
            sx={{
              width: 200,
              height: 200,
              position: "relative",
            }}
          >
            {isProfileImageLoading ? (
              <PageLoader />
            ) : selectedAvatar ? (
              <Avatar
                alt="avatar"
                src={selectedAvatarString}
                sx={{ width: "100%", height: "100%" }}
              ></Avatar>
            ) : (
              <Avatar sx={{ width: "100%", height: "100%" }}>
                <AccountCircle />
              </Avatar>
            )}
          </Box>
          <Stack direction={"row"} gap={2} mt={3}>
            <Button
              variant="outlined"
              sx={{
                width: "40px",
                height: "40px",
                padding: "12px",
                minWidth: "40px",
                borderColor:
                  palette.mode === "light"
                    ? alpha(palette.common.black, 0.23)
                    : alpha(palette.common.white, 0.12),
              }}
              onClick={avatarDialog.show}
            >
              <UploadIcon
                stroke={palette.success.main}
                sx={{
                  width: "16px",
                  height: "16px",
                }}
              />
            </Button>
            <Button
              variant="outlined"
              sx={{
                width: "40px",
                height: "40px",
                padding: "12px",
                minWidth: "40px",
                borderColor:
                  palette.mode === "light"
                    ? alpha(palette.common.black, 0.23)
                    : alpha(palette.common.white, 0.12),
              }}
              onClick={deleteAvatarDialog.show}
              disabled={isDeleteDisabled}
            >
              <DeleteOutline
                sx={{
                  width: "24px",
                  height: "24px",
                  color: isDeleteDisabled
                    ? palette.action.disabled
                    : palette.text.primary,
                }}
              />
            </Button>
          </Stack>
          <Typography variant="body2" width="65%" mt={4} textAlign={"center"}>
            Allowed *.jpeg, *.jpg, *.png size of 3.1 MB
          </Typography>
        </Stack>
        <UploadImageDialog
          maxWidth={"lg"}
          title="Upload Image"
          open={avatarDialog.isOpen}
          onClose={avatarDialog.hide}
          selectedAvatar={selectedAvatar}
          setSelectedAvatar={setSelectedAvatar}
          mode={DialogModes.avatar}
        />
        <DialogPrompt
          disableHeaderDivider
          open={deleteAvatarDialog.isOpen}
          title={"Delete avatar"}
          maxWidth="xs"
          onConfirm={handleDeleteProfileImage}
          isLoading={deleteUserProfileImage.isLoading}
          onDecline={deleteAvatarDialog.hide}
          children={
            <Typography variant="body1" pb={1}>
              Are you sure that you want to delete profile photo?
            </Typography>
          }
        />
      </Stack>
    </FormProvider>
  );

  const contentFilterTab = (
    <FormProvider {...accountFormHook}>
      <AccountsContentTab
        contentFilters={contentFilters}
        onSubmit={submitContentFilterTabData}
        onReset={resetContentFilterTab}
      />
    </FormProvider>
  );

  const featuresTab = (
    <FormProvider {...accountFormHook}>
      <AccountsManagementFeaturesTab
        onSubmit={submitFeaturesTabData}
        onReset={resetFeaturesTab}
      />
    </FormProvider>
  );

  const customizationTab = (
    <FormProvider {...accountFormHook}>
      <Stack height="100%" overflow="auto">
        <SettingsCustomizationTab page="user" />
      </Stack>
    </FormProvider>
  );

  const toolbar = useMemo(
    () => (
      <Stack
        direction="row"
        flex={1}
        justifyContent="flex-end"
        alignItems="center"
        columnGap={2}
      >
        <ToggleButton
          value={true}
          size="small"
          onClick={() => {
            const originalUserData = {
              firstName: firstName,
              lastName: lastName,
              email: email,
              id: userData?.id || "",
            };

            onUserDuplicate(originalUserData);
          }}
          sx={{
            backgroundColor: "transparent",
            borderColor: alpha(palette.primary.main, 0.5),
            width: 42,
            "&:hover": {
              borderColor: palette.primary.main,
            },
          }}
        >
          <ContentCopy sx={{ color: palette.primary.main }} />
        </ToggleButton>

        <Button
          variant="contained"
          color="success"
          sx={{ height: "42px", width: "140px", fontSize: "15px" }}
          onClick={onNotifyUser}
        >
          Notify User
        </Button>

        <Button
          variant="outlined"
          sx={{ height: "42px", width: "140px", fontSize: "15px" }}
          onClick={goBack}
        >
          Done
        </Button>
      </Stack>
    ),
    [
      firstName,
      lastName,
      email,
      userData?.id,
      palette.primary.main,
      onNotifyUser,
      onUserDuplicate,
      goBack,
    ]
  );

  const headerDesktop = useMemo(
    () => (
      <PageHeaderDesktop
        title="Edit User"
        toolbar={toolbar}
        hideBackButton
        hidePenultimateRoute
        breadcrumbLabels={{
          "2": "Users Management",
          "0": userData
            ? `${userData.firstName} ${userData.lastName}`
            : "Edit user",
        }}
      />
    ),
    [userData, toolbar]
  );

  const content = (
    <AppTabLayout
      layout="desktop"
      variant="standard"
      overflow="auto"
      currentTab={pageTabMode}
      onCurrentTabChange={handleChangePageTabMode}
    >
      <AppTab
        label="General"
        value={UserManagementTabMode.general}
        children={generalTab}
      />
      <AppTab
        label="Content filter"
        value={UserManagementTabMode.contentFilter}
        children={contentFilterTab}
      />

      <AppTab
        label="Features"
        value={UserManagementTabMode.features}
        children={featuresTab}
      />

      <AppTab
        label="Customization"
        value={UserManagementTabMode.customization}
        children={customizationTab}
      />
    </AppTabLayout>
  );

  return (
    <>
      <PageLayoutDesktop
        header={headerDesktop}
        content={content}
        overflow="scroll"
      />
      <DuplicateUserDialog
        title="Duplicate user"
        open={Boolean(duplicateUserData)}
        onClose={() => onUserDuplicate(null)}
        userData={duplicateUserData}
      />
      {blocker && (
        <LeaveConfirmationDialog
          title="Save changes?"
          userDirtyFields={formState.dirtyFields}
          accountDirtyFields={accountFormState.dirtyFields}
          onSubmit={submitUserData}
          onDataReset={() => blocker.proceed?.()}
          open={blocker.state === "blocked"}
          onClose={() => blocker.reset?.()}
        />
      )}
    </>
  );
}
