import { useRef, useState } from "react";
import { Grid, Stack } from "@mui/material";
import { TagPicker } from "src/components/TagPicker/TagPicker";
import { EventSourceOption } from "src/api/useEventSources";
import { SourcesPicker } from "src/components/SourcesPicker/SourcesPicker";
import { SourcesPickerValue } from "src/components/SourcesPicker/SourcesPicker.model";

const mockEventSources: EventSourceOption[] = [
  {
    name: "TV",
    value: "tv",
    group: "type",
  },
  {
    name: "Radio",
    value: "radio",
    group: "type",
  },
  {
    name: "Logo",
    value: "logo",
    group: "type",
  },
  {
    name: "Very very long",
    value: "logo1",
    group: "type",
  },
  {
    name: "Five",
    value: "five",
    group: "type",
  },
  {
    name: "United States",
    value: "US",
    group: "country",
  },
  {
    name: "Germany",
    value: "Germany",
    group: "country",
  },
  {
    name: "Greece",
    value: "Greece",
    group: "country",
  },
  {
    name: "Market 1",
    value: "Market 1",
    group: "market",
  },
  {
    name: "Market 2",
    value: "Market 2",
    group: "market",
  },
  {
    group: "market",
    name: "Market 3",
    value: "Market 3",
  },
  {
    group: "state",
    name: "Arizona",
    value: "arizona",
  },
  {
    group: "state",
    name: "Arkanzas",
    value: "arkanzas",
  },
  {
    group: "state",
    name: "California",
    value: "california",
  },
  {
    group: "state",
    name: "Colorado",
    value: "colorado",
  },
  {
    group: "station",
    name: "station 1",
    value: "station-1",
  },
  {
    group: "station",
    name: "station 2",
    value: "station-2",
  },
  {
    group: "station",
    name: "station 3",
    value: "station-3",
  },
];

export function DevSourcesPicker() {
  const containerRef = useRef<HTMLDivElement>(null);
  const [sources, setSources] = useState<SourcesPickerValue>({
    list: [],
    logic: "or",
  });

  const [mockSources, setMockSources] = useState<SourcesPickerValue>({
    list: [],
    logic: "or",
  });

  return (
    <Stack p={2}>
      <div ref={containerRef} style={{ width: "100%" }} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SourcesPicker
            label="Sources (vertical, medium)"
            value={sources}
            onChange={setSources}
            dropDownBoundsEl={containerRef}
            // allowMultipleLines={2}
            // limitTags={4}
            placeholder="This is vertically growing autocomplete"
          />
        </Grid>

        <Grid item xs={12}>
          <SourcesPicker
            label="Sources (vertical, small)"
            value={sources}
            onChange={setSources}
            dropDownBoundsEl={containerRef}
            allowMultipleLines={3}
            limitTags={4}
            size="small"
            placeholder="This is vertically growing autocomplete"
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <TagPicker
            label="Source with anchor and boundary"
            placeholder="Pick some tags, man..."
            value={mockSources}
            options={mockEventSources}
            presets={{ test: mockEventSources.slice(0, 3) }}
            onChange={setMockSources}
            dropDownBoundsEl={containerRef}
            groupBy={(option) => option.group}
            getOptionLabel={(option) => option.name}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <TagPicker
            label="Source"
            placeholder="Pick some tags, man..."
            value={mockSources}
            options={mockEventSources}
            presets={{ test: mockEventSources.slice(0, 3) }}
            onChange={setMockSources}
            groupBy={(option) => option.group}
            getOptionLabel={(option) => option.name}
            size="small"
          />
        </Grid>
      </Grid>
    </Stack>
  );
}
