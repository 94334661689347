import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { paths } from "@tveyes/twosionwebapischema";
import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { QueryDefinition } from "src/models/QueryDefinition";
import { ReportItem } from "src/models/Report";
import { reportsListKey } from "./useReportList";

const apiKey: keyof paths = "/api/report/from-powersearch";
const method: keyof paths[typeof apiKey] = "post";

type Endpoint = Required<paths[typeof apiKey][typeof method]>;
type Parameters = Endpoint["parameters"];

type RequestBody = Endpoint["requestBody"]["content"]["application/json"];

// FIXME: this is to make response fields non-optional,
// keep an eye for type updates and get rid of this mock
type Response = ReportItem;

type RequestMutationFnParams = {
  queryDefinition: QueryDefinition;
  params: Parameters;
};

type RequestOptions = Pick<
  UseMutationOptions<Response, unknown, RequestMutationFnParams>,
  "onSuccess" | "onError"
>;

export function useReportCreateFromPowersearch({
  options,
}: {
  options?: RequestOptions;
}) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation<Response, unknown, RequestMutationFnParams, void>({
    ...options,
    mutationKey: [apiKey],
    mutationFn: async ({ queryDefinition, params }) => {
      const token = await getAccessTokenSilently();
      const url = makeApiUrl<Endpoint["parameters"]>(apiKey, params);
      const body: RequestBody = { queryDefinition };

      const { data } = await axios<Response>({
        url,
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: body,
      });

      return data;
    },
    onSuccess: (data, variables, context) => {
      options?.onSuccess && options.onSuccess(data, variables, context);
      if (variables.params.query?.archive) {
        queryClient.refetchQueries({
          queryKey: reportsListKey,
        });
      }
    },
  });
}
