import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Paper,
  Stack,
  Tooltip,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { AccountCircle, DeleteOutline } from "@mui/icons-material";
import { joiResolver } from "@hookform/resolvers/joi";
import { useAccount } from "src/api/useAccount";
import { EventSourceCategory, useEventSources } from "src/api/useEventSources";
import { useUserCreate } from "src/api/useUserCreate";
import { AppTabLayout } from "src/components/AppTabLayout/AppTabLayout";
import { AppTab } from "src/components/AppTabLayout/AppTab";
import { PageLayoutDesktop } from "src/components/PageLayoutDesktop";
import { PageHeaderDesktop } from "src/components/PageHeaderDesktop/PageHeaderDesktop";
import { UserManagementFormCreate } from "src/components/UserManagementFormCreate/UserManagementFormCreate";
import { UploadIcon } from "src/components/icons/UploadIcon";
import { UserInformationSettingsFormSchema } from "src/components/UserInformationUpdateForm/UserInformationUpdateForm.schema";
import { useUserInformationForm } from "src/components/UserInformationUpdateForm/UserInformationUpdateForm.hook";
import { UserInformationUpdateFormValues } from "src/components/UserInformationUpdateForm/UserInformationUpdateForm.model";
import { ContentAccessFilter } from "src/models/ContentAccessFilter";
import { useOpenState } from "src/utils/useOpenState";
import { makeContentFilterQuery } from "src/utils/makeContentFilterQuery";
import { useViewModeQueryParam } from "src/utils/useViewModeQueryParam";
import { useGlobalEntitlements } from "src/api/useGlobalEntitlements";
import { SettingsCustomizationTab } from "../Settings/components/SettingsCustomizationTab";
import { userManagementRoute } from "../UserManagement/UserManagement.route";
import { AccountsManagementFeaturesTab } from "../AccountsManagementCreateAccount/components/AccountsManagementFeaturesTab/AccountsManagementFeaturesTab";
import { AccountsContentTab } from "../AccountsManagementCreateAccount/components/AccountsManagementContentTab/AccountsManagementContentTab";
import { NewAccountFormValues } from "../AccountsManagementCreateAccount/AccountsManagementCreateAccount.model";
import { createAccountDefaultValues } from "../AccountsManagementCreateAccount/AccountsManagementCreateAccount.const";
import {
  limitsConfiguration,
  NewAccountSchema,
} from "../AccountsManagementCreateAccount/AccountsManagementCreateAccount.schema";
import { sourceFilterMapper } from "../AccountsManagementEditAccount/utils/sourceFilterMapper";
import {
  makeEntitlements,
  UserManagementTabMode,
  pageTabKey,
} from "../UserManagementUpdate/UserManagementUpdate.utils";

export function UserManagementCreatePage() {
  const [selectedAvatar] = useState<string | Blob | null | undefined>(null);
  const [contentFilters, setContentFilters] =
    useState<ContentAccessFilter | null>(null);

  const [pageTabMode, setPageTabMode] =
    useViewModeQueryParam<UserManagementTabMode>({
      paramKey: pageTabKey,
      defaultValue: UserManagementTabMode.general,
    });
  const { data: globalEntitlementsData } = useGlobalEntitlements();
  const systemLimits = globalEntitlementsData?.globalEntitlements;

  const handleChangePageTabMode = (newValue: string) => {
    setPageTabMode(newValue as UserManagementTabMode);
  };

  const { palette } = useTheme();

  const avatarDialog = useOpenState();
  const deleteAvatarDialog = useOpenState();

  const selectedAvatarString =
    typeof selectedAvatar === "string" ? selectedAvatar : undefined;

  const formHook = useUserInformationForm({
    schema: UserInformationSettingsFormSchema,
  });
  const { handleSubmit, watch } = formHook;
  const { accountId } = watch();

  const { data: accountData } = useAccount({
    request: {
      path: {
        id: accountId,
      },
    },
    options: {
      enabled: !!accountId,
    },
  });

  const accountFormHook = useForm<NewAccountFormValues>({
    mode: "all",
    resolver: joiResolver(
      NewAccountSchema(
        accountData?.entitlements || null,
        limitsConfiguration.account,
        systemLimits
      )
    ),
    defaultValues: createAccountDefaultValues,
  });

  const { setValue, watch: watchAccount, reset } = accountFormHook;

  const accountFormData = watchAccount();
  const { sourcesExclude, sourcesInclude } = accountFormData;

  const { data: sourcesData } = useEventSources({
    path: {
      categories: Object.values(EventSourceCategory),
      searchText: "*",
    },
  });

  useEffect(() => {
    if (!accountData) {
      return reset(createAccountDefaultValues);
    }
  }, [accountData, reset]);

  useEffect(() => {
    if (!accountData) return;

    if (accountData?.entitlements) {
      setValue(
        "enableMediaDownloads",
        accountData.entitlements.enableMediaDownloads?.value
      );
      setValue(
        "enableEditWatchList",
        accountData.entitlements.enableEditWatchList?.value
      );
      setValue(
        "enableAdFiltering",
        accountData.entitlements.enableAdFiltering?.value
      );
      setValue(
        "enableCustomReportBanner",
        accountData.entitlements.enableCustomReportBanner?.value
      );
      setValue(
        "enableReportDownloads",
        accountData.entitlements.enableReportDownloads?.value
      );
      setValue(
        "enableWatchlistRSS",
        accountData.entitlements.enableWatchlistRSS?.value
      );
      setValue(
        "limitWatchlistSize",
        accountData.entitlements.limitWatchlistSize?.value
      );
      setValue(
        "limitItemsPerEmail",
        accountData.entitlements.limitItemsPerEmail?.value
      );
      setValue(
        "limitEventsInReport",
        accountData.entitlements.limitEventsInReport?.value
      );
      setValue(
        "limitEventsInCSV",
        accountData.entitlements.limitEventsInCSV?.value
      );
      setValue(
        "limitEventsInPDF",
        accountData.entitlements.limitEventsInPDF?.value
      );
      setValue(
        "limitScratchReportItems",
        accountData.entitlements.limitScratchReportItems?.value
      );
    }

    if (accountData?.contentAccessFilter) {
      const sources = sourceFilterMapper(
        accountData.contentAccessFilter,
        sourcesData
      );
      setValue(
        "sourcesExclude",
        sources?.excludedFields || {
          list: [],
          logic: "or",
        }
      );
      setValue(
        "sourcesInclude",
        sources?.includedFields || {
          list: [],
          logic: "or",
        }
      );
    }
  }, [accountData, setValue, sourcesData]);

  useEffect(() => {
    const filters = makeContentFilterQuery(sourcesInclude, sourcesExclude);
    setContentFilters(filters);
  }, [sourcesExclude, sourcesInclude]);

  const userCreate = useUserCreate({
    options: {
      onSuccess: (data) => {
        const { firstName, lastName } = data;
        enqueueSnackbar(
          `User “${firstName ?? ""} ${
            lastName ?? ""
          }” was successfully added to "${accountData?.name}" account`,
          { variant: "success" }
        );
      },
    },
  });
  const { isSuccess } = userCreate;

  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      navigate(userManagementRoute.path);
    }
  }, [navigate, isSuccess]);

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const submitWatchTerm = handleSubmit(
    (data: UserInformationUpdateFormValues) => {
      const bodyData = {
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        accountId: data.accountId,
        phoneNumber: data.phoneNumber,
        timeZone: data.timezone,
        roles: data.roles,
        isActive: data.isActive,
        contentAccessFilter: contentFilters,
        entitlements: makeEntitlements(accountFormData),
      };

      userCreate.mutate({
        body: bodyData,
        query: {
          notify: data.notify,
        },
      });
    },
    (errors) => {
      console.log("@@ DEBUG:submitWatchTerm:error", errors);
    }
  );

  const generalTab = (
    <FormProvider {...formHook}>
      <Stack direction={"row"} gap={3}>
        <UserManagementFormCreate origin="create" />
        <Stack width={300} alignItems={"center"} component={Paper} pt={14}>
          <Box
            sx={{
              width: 200,
              height: 200,
              position: "relative",
            }}
          >
            {selectedAvatar ? (
              <Avatar
                alt="avatar"
                src={selectedAvatarString}
                sx={{ width: "100%", height: "100%" }}
              ></Avatar>
            ) : (
              <Avatar sx={{ width: "100%", height: "100%" }}>
                <AccountCircle />
              </Avatar>
            )}
          </Box>
          <Stack direction={"row"} gap={2} mt={3}>
            <Tooltip
              title={
                "Users can upload their photo after creating their profile"
              }
            >
              <Box>
                <Button
                  variant="outlined"
                  sx={{
                    width: "40px",
                    height: "40px",
                    padding: "12px",
                    minWidth: "40px",
                    borderColor:
                      palette.mode === "light"
                        ? alpha(palette.common.black, 0.23)
                        : alpha(palette.common.white, 0.12),
                  }}
                  disabled
                  onClick={avatarDialog.show}
                >
                  <UploadIcon
                    stroke={palette.action.disabled}
                    sx={{
                      width: "16px",
                      height: "16px",
                    }}
                  />
                </Button>
              </Box>
            </Tooltip>
            <Tooltip
              title={
                "Users can delete their photo after creating their profile and uploading a photo"
              }
            >
              <Box>
                <Button
                  variant="outlined"
                  sx={{
                    width: "40px",
                    height: "40px",
                    padding: "12px",
                    minWidth: "40px",
                    borderColor:
                      palette.mode === "light"
                        ? alpha(palette.common.black, 0.23)
                        : alpha(palette.common.white, 0.12),
                  }}
                  disabled
                  onClick={deleteAvatarDialog.show}
                >
                  <DeleteOutline
                    sx={{
                      width: "24px",
                      height: "24px",
                      color: palette.action.disabled,
                    }}
                  />
                </Button>
              </Box>
            </Tooltip>
          </Stack>
          <Typography variant="body2" width="65%" mt={4} textAlign={"center"}>
            Allowed *.jpeg, *.jpg, *.png size of 3.1 MB
          </Typography>
        </Stack>
      </Stack>
    </FormProvider>
  );

  const contentFilterTab = (
    <FormProvider {...accountFormHook}>
      <AccountsContentTab contentFilters={contentFilters} disabled />
    </FormProvider>
  );

  const featuresTab = (
    <FormProvider {...accountFormHook}>
      <AccountsManagementFeaturesTab disabled />
    </FormProvider>
  );

  const customizationTab = (
    <FormProvider {...accountFormHook}>
      <Stack height="100%" overflow="auto">
        <SettingsCustomizationTab disabled />
      </Stack>
    </FormProvider>
  );

  const toolbar = useMemo(
    () => (
      <Stack
        direction="row"
        flex={1}
        justifyContent="flex-end"
        alignItems="center"
        columnGap={2}
      >
        <Button
          variant="outlined"
          sx={{ height: "42px", width: "196px", fontSize: "15px" }}
          onClick={goBack}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{ height: "42px", width: "196px", fontSize: "15px" }}
          onClick={submitWatchTerm}
        >
          Create User
        </Button>
      </Stack>
    ),
    [goBack, submitWatchTerm]
  );

  const headerDesktop = useMemo(
    () => (
      <PageHeaderDesktop
        title="Add User"
        toolbar={toolbar}
        hideBackButton
        breadcrumbLabels={{
          "1": "Users Management",
          "0": "New user",
        }}
      />
    ),
    [toolbar]
  );

  const content = (
    <AppTabLayout
      layout="desktop"
      variant="standard"
      overflow="auto"
      currentTab={pageTabMode}
      onCurrentTabChange={handleChangePageTabMode}
    >
      <AppTab
        label="General"
        value={UserManagementTabMode.general}
        children={generalTab}
      />
      <AppTab
        label="Content filter"
        value={UserManagementTabMode.contentFilter}
        children={contentFilterTab}
      />

      <AppTab
        label="Features"
        value={UserManagementTabMode.features}
        children={featuresTab}
      />

      <AppTab
        disabled
        label="Customization"
        value={UserManagementTabMode.customization}
        children={customizationTab}
      />
    </AppTabLayout>
  );

  return (
    <PageLayoutDesktop
      header={headerDesktop}
      content={content}
      overflow="scroll"
    />
  );
}
