import { getUnixTime } from "date-fns";
import { useAuth0 } from "@auth0/auth0-react";
import { PropsWithChildren, createContext, useEffect } from "react";
import { useSessionContext } from "src/api/useSessionContext";
import { useIntercomButtonState } from "src/api/useIntercomButtonState";
import { useIsMobile } from "src/utils/useIsMobile";

declare global {
  interface Window {
    Intercom: (
      event: "boot" | "shutdown" | "navigation" | "showNewMessage", // lets add more later as we use them
      data?: string | { [key: string]: unknown }
    ) => void;
  }
}

window.Intercom = window.Intercom || {};

export const intercomButtonId = "custom-intercom-button";

const IntercomContext = createContext<{}>({});

export function IntercomProvider({ children }: PropsWithChildren<{}>) {
  const { user } = useAuth0();
  const session = useSessionContext();
  const { userId, accountId } = session;
  const isMobile = useIsMobile();
  const { isShown } = useIntercomButtonState();

  useEffect(() => {
    if (user && userId) {
      window.Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: "zpagqe1z",
        custom_launcher_selector: `#${intercomButtonId}`,
        hide_default_launcher: true,
        // user data
        user_id: userId,
        name: user.name,
        email: user.email,
        created_at: getUnixTime(new Date()), // Sign-up date as a Unix timestamp
        avatar: {
          type: "avatar",
          image_url: user.picture,
        },
        company: {
          company_id: accountId,
        },
      });
    }
  }, [user, userId, accountId, isMobile, isShown]);

  return (
    <IntercomContext.Provider value={IntercomContext} children={children} />
  );
}
