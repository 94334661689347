import axios from "axios";
import { useQuery } from "react-query";
import { enqueueSnackbar } from "notistack";
import { useAuth0 } from "@auth0/auth0-react";
import { paths } from "@tveyes/twosionwebapischema";
import { makeApiUrl } from "src/utils/makeApiUrl";

const apiPath: keyof paths = "/api/user/query/{queryId}";
const method: keyof paths[typeof apiPath] = "get";

type Endpoint = paths[typeof apiPath][typeof method];
type Parameters = Endpoint["parameters"] & {
  enabled?: boolean;
};
type Response = Endpoint["responses"][200]["content"]["application/json"];

export const watchQueryKey = "watchQueryKey";

async function fetchQueryById({
  getToken,
  params,
}: {
  getToken: () => Promise<string>;
  params: Parameters;
}) {
  const token = await getToken();
  const url = makeApiUrl<Parameters>(apiPath, params);

  const { data } = await axios<Response>({
    url,
    method,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
}

export function useWatchQuery({ enabled = true, ...params }: Parameters) {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery<Response, unknown>({
    enabled: enabled && !!params.path.queryId,
    queryKey: [watchQueryKey, params.path.queryId],
    keepPreviousData: true,
    queryFn: async () => {
      return fetchQueryById({
        getToken: getAccessTokenSilently,
        params,
      });
    },
    onError: (error) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
  });
}
