import { create } from "zustand";
import { queryAdvertisementValues } from "src/models/Advertisement";
import { PowerSearchKeywordFormValues } from "src/components/PowerSearchKeywordForm/PowerSearchKeywordForm.model";

export type PowerSearchSharedValues = Pick<
  PowerSearchKeywordFormValues,
  | "startDateTime"
  | "endDateTime"
  | "sourcesInclude"
  | "sourcesExclude"
  | "advertisement"
  | "programInclude"
  | "programExclude"
>;

export const emptySharedValues = {
  startDateTime: null,
  endDateTime: null,
  sourcesInclude: {
    list: [],
    logic: "or",
  },
  sourcesExclude: {
    list: [],
    logic: "or",
  },
  advertisement: queryAdvertisementValues.all,
  programInclude: {
    list: [],
    logic: "or",
  },
  programExclude: {
    list: [],
    logic: "or",
  },
};

type PowerSearchQueryState = {
  sharedValues: PowerSearchSharedValues;
  saveSharedValue: <T extends keyof PowerSearchSharedValues>(
    name: T,
    value: PowerSearchSharedValues[T]
  ) => void;
  clearSharedValues: () => void;
};

export const usePowerSearchSharedForm = create<PowerSearchQueryState>(
  (setState, getState) => ({
    sharedValues: emptySharedValues,
    saveSharedValue: (name, value) => {
      const prev = getState();
      setState({
        sharedValues: {
          ...prev.sharedValues,
          [name]: value,
        },
      });
    },
    clearSharedValues: () => {
      setState({
        sharedValues: emptySharedValues,
      });
    },
  })
);
