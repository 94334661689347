import {
  alpha,
  Box,
  Button,
  Chip,
  Divider,
  Paper,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useSessionContext } from "src/api/useSessionContext";
import { LogoEye } from "src/components/logo/LogoEye";
import { useOpenState } from "src/utils/useOpenState";
import { GenerateRSSLinkDialog } from "./GenerateRSSLinkDialog";
import RSSImage from "./RSS.png";
import ZapierImage from "./Zapier.png";
import SlackImage from "./Slack.png";

type FeatureType = "RSS" | "Zapier" | "Slack" | "InsightAPI";

function makeFeatureIcon(featureType: FeatureType, disabled?: boolean) {
  switch (featureType) {
    case "RSS":
      return <Box component="img" alt="RSSImage" src={RSSImage} />;

    case "Zapier":
      return (
        <Box
          component="img"
          alt="ZapierImage"
          src={ZapierImage}
          sx={{ mixBlendMode: disabled ? "luminosity" : undefined }}
        />
      );

    case "Slack":
      return (
        <Box
          component="img"
          alt="SlackImage"
          src={SlackImage}
          sx={{ mixBlendMode: disabled ? "luminosity" : undefined }}
        />
      );

    case "InsightAPI":
      return (
        <LogoEye
          height={25}
          sx={{ mixBlendMode: disabled ? "luminosity" : undefined }}
        />
      );
  }
}

function IconWrapper({
  featureType,
  disabled,
}: {
  featureType: FeatureType;
  disabled?: boolean;
}) {
  const { palette } = useTheme();

  return (
    <Stack
      width={80}
      height={80}
      borderRadius="50%"
      border={`1px solid ${palette.divider}`}
      position="relative"
      justifyContent={featureType === "InsightAPI" ? "center" : undefined}
      alignItems={featureType === "InsightAPI" ? "center" : undefined}
    >
      {makeFeatureIcon(featureType, disabled)}
    </Stack>
  );
}

function TooltipButtonWrapper({
  button,
  disabled,
}: {
  button: JSX.Element;
  disabled?: boolean;
}) {
  if (!disabled) return button;

  return (
    <Tooltip
      title="You can’t connect an RSS feed 
      due to account limitations. 
      Please contact your account 
      manager for more details"
    >
      <Box>{button}</Box>
    </Tooltip>
  );
}

export function SettingsIntegrationsTab() {
  const { palette } = useTheme();

  const { effectiveEntitlements } = useSessionContext();
  const enableWatchlistRSS = effectiveEntitlements.enableWatchlistRSS?.value;

  const shareDialog = useOpenState();

  return (
    <Stack height="100%" gap={2}>
      <Stack component={Paper} px={4} py={5} gap={3}>
        <Stack flexDirection="row" justifyContent="space-between">
          <Stack flexDirection="row" gap={3}>
            <IconWrapper featureType="RSS" />
            <Stack gap={1} justifyContent="center">
              <Typography variant="subtitle1">RSS Feed</Typography>
              <Typography
                variant="body1"
                sx={{
                  color: alpha(palette.text.secondary, 0.65),
                }}
              >
                Connect Insight to an RSS Feed
              </Typography>
            </Stack>
          </Stack>
          <Stack justifyContent="center">
            <TooltipButtonWrapper
              button={
                <Button
                  variant="contained"
                  disabled={!enableWatchlistRSS}
                  onClick={shareDialog.show}
                >
                  Connect
                </Button>
              }
              disabled={!enableWatchlistRSS}
            />
          </Stack>
        </Stack>

        <Divider />

        <Stack flexDirection="row" justifyContent="space-between">
          <Stack flexDirection="row" gap={3}>
            <IconWrapper featureType="Zapier" disabled />
            <Stack gap={1} justifyContent="center">
              <Typography variant="subtitle1">Zapier</Typography>
              <Typography
                variant="body1"
                sx={{
                  color: alpha(palette.text.secondary, 0.65),
                }}
              >
                Connect Insight to Zapier
              </Typography>
            </Stack>
          </Stack>
          <Stack justifyContent="center">
            <Chip label="Coming Soon" />
          </Stack>
        </Stack>

        <Divider />

        <Stack flexDirection="row" justifyContent="space-between">
          <Stack flexDirection="row" gap={3}>
            <IconWrapper featureType="Slack" disabled />
            <Stack gap={1} justifyContent="center">
              <Typography variant="subtitle1">Slack</Typography>
              <Typography
                variant="body1"
                sx={{
                  color: alpha(palette.text.secondary, 0.65),
                }}
              >
                Connect Insight to Slack
              </Typography>
            </Stack>
          </Stack>
          <Stack justifyContent="center">
            <Chip label="Coming Soon" />
          </Stack>
        </Stack>

        <Divider />

        <Stack flexDirection="row" justifyContent="space-between">
          <Stack flexDirection="row" gap={3}>
            <IconWrapper featureType="InsightAPI" disabled />
            <Stack gap={1} justifyContent="center">
              <Typography variant="subtitle1">Insight API</Typography>
              <Typography
                variant="body1"
                sx={{
                  color: alpha(palette.text.secondary, 0.65),
                }}
              >
                Integrate Insight with your application
              </Typography>
            </Stack>
          </Stack>
          <Stack justifyContent="center">
            <Chip label="Coming Soon" />
          </Stack>
        </Stack>
      </Stack>

      <Stack component={Paper} alignItems="center" py={4}>
        <Typography variant="subtitle1">
          Don’t see your favorite application?
        </Typography>
        <Stack flexDirection="row" gap={1}>
          <Typography
            variant="body1"
            sx={{
              color: alpha(palette.text.secondary, 0.65),
            }}
          >
            We're always working to expand our integrations.
          </Typography>
          <Typography variant="body1" color="primary.main">
            Let us know what you'd like!
          </Typography>
        </Stack>
      </Stack>

      <GenerateRSSLinkDialog
        open={shareDialog.isOpen}
        onClose={shareDialog.hide}
      />
    </Stack>
  );
}
