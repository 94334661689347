import { AddTask } from "@mui/icons-material";
import { Badge, badgeClasses, ToggleButton, useTheme } from "@mui/material";
import { TooltipBase } from "src/components/TooltipBase/TooltipBase";
import usePreselectLimit from "src/utils/usePreselectedLimit";

export const PreselectEventsButton = ({
  badgeCounter,
  disabled,
  onClick,
}: {
  badgeCounter: number;
  disabled: boolean;
  onClick: () => void;
}) => {
  const { maxEventsToPreselect, preselectLimitTitle } = usePreselectLimit();
  const { palette } = useTheme();

  return (
    <TooltipBase
      width={250}
      placement="bottom"
      title={
        badgeCounter > maxEventsToPreselect
          ? preselectLimitTitle
          : "Select required events, then click this button in order to create Report with them"
      }
    >
      <Badge
        badgeContent={badgeCounter}
        color="primary"
        sx={{
          [`.${badgeClasses.badge}`]: {
            backgroundColor: disabled ? palette.action.disabled : "primary",
          },
        }}
      >
        <ToggleButton
          value={true}
          disabled={disabled}
          sx={{ width: 42 }}
          size="small"
          onClick={onClick}
        >
          <AddTask color={disabled ? "disabled" : "primary"} />
        </ToggleButton>
      </Badge>
    </TooltipBase>
  );
};
