import { Stack, useTheme } from "@mui/material";
import { LegacyRef, ReactNode } from "react";
import {
  FixedSizeGrid,
  FixedSizeGridProps,
  ListOnItemsRenderedProps,
} from "react-window";
import { TileLayoutLoading } from "./TileLayoutLoading";

export type TileLayoutGridProps<T = unknown> = {
  innerRef: LegacyRef<FixedSizeGrid<T>>;
  items: T[];
  width: number;
  height: number;
  columnCount: number;
  rowCount: number;
  onItemsRendered: (params: ListOnItemsRenderedProps) => ReactNode;
  onScroll?: FixedSizeGridProps["onScroll"];
  isItemLoaded: (index: number) => boolean;

  /** Column gap */
  getColumnGap: (containerWidth: number) => number | void;

  /** Row gap */
  getRowGap: (containerWidth: number) => number | void;

  /** Render item */
  renderItem: (params: {
    data: T;
    index: number;
    columnIndex: number;
    rowIndex: number;
  }) => ReactNode;

  /** Render loading indicator */
  renderLoadingIndicator?: (params: {
    index: number;
    columnIndex: number;
    rowIndex: number;
  }) => ReactNode;
};

export const TileLayoutGrid = <T,>({
  innerRef,
  items,
  width,
  height,
  columnCount,
  rowCount,
  onItemsRendered,
  onScroll,
  isItemLoaded,
  getColumnGap,
  getRowGap,
  renderItem,
  renderLoadingIndicator,
}: TileLayoutGridProps<T>) => {
  const { spacing } = useTheme();

  return (
    <FixedSizeGrid<T>
      ref={innerRef}
      columnCount={columnCount}
      columnWidth={width / columnCount}
      rowCount={rowCount}
      rowHeight={width / columnCount}
      width={width}
      height={height}
      onScroll={onScroll}
      onItemsRendered={({
        visibleRowStartIndex,
        visibleRowStopIndex,
        overscanRowStartIndex,
        overscanRowStopIndex,
      }) => {
        onItemsRendered({
          overscanStartIndex: overscanRowStartIndex * columnCount,
          overscanStopIndex: overscanRowStopIndex * columnCount,
          visibleStartIndex: visibleRowStartIndex * columnCount,
          visibleStopIndex: visibleRowStopIndex * columnCount,
        });
      }}
    >
      {({ style, columnIndex, rowIndex }) => {
        const index = rowIndex * columnCount + columnIndex;
        const isLoading = !isItemLoaded(index);

        // return number of pixels for column gap
        const columnGapPx = getColumnGap?.(width) ?? 0;
        const columnGap = parseInt(spacing(columnGapPx));

        // return number of pixels for row gap, fallback to column gap
        const rowGap = getRowGap
          ? parseInt(spacing(getRowGap(width) ?? columnGapPx))
          : columnGap;

        const columnWidth =
          (width - (columnCount - 1) * columnGap) / columnCount;

        const styles = {
          ...style,
          left: (columnWidth + columnGap) * columnIndex,
          width: columnWidth,
          top: (columnWidth + rowGap) * rowIndex,
          height: columnWidth,
        };

        // console.log(`@@ DEBUG:render: ${index}`, items);

        // if (index >= total) return null;

        if (isLoading) {
          return (
            <Stack
              style={styles}
              children={
                renderLoadingIndicator ? (
                  renderLoadingIndicator({
                    index,
                    columnIndex,
                    rowIndex,
                  })
                ) : (
                  <TileLayoutLoading />
                )
              }
            />
          );
        }

        const data = items[index];
        if (!data) return null;

        const content = renderItem({
          data,
          index,
          columnIndex,
          rowIndex,
        });

        return <Stack style={styles} children={content} />;
      }}
    </FixedSizeGrid>
  );
};
