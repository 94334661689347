import {
  Button,
  Stack,
  Tooltip,
  useMediaQuery,
  useTheme,
  dialogClasses,
  svgIconClasses,
  IconButton,
  Typography,
  Dialog,
  Box,
} from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { AppTab } from "src/components/AppTabLayout/AppTab";
import { useCurrentUser } from "src/api/useCurrentUser";
import { AppTabLayout } from "src/components/AppTabLayout/AppTabLayout";
import { DialogBase } from "src/components/DialogBase/DialogBase";
import { AppVersions } from "src/components/AppVersions";
import { useOpenState } from "src/utils/useOpenState";
import { PageHeaderDesktop } from "src/components/PageHeaderDesktop/PageHeaderDesktop";
import { PageLayout } from "src/components/PageLayout";
import { ScrollBox } from "src/components/ScrollBox/ScrollBox";
import { GlobalSearchDialog } from "src/components/GlobalSearchDialog/GlobalSearchDialog";
import { GlobalSearchDesktopButton } from "src/components/GlobalSearchDesktopButton/GlobalSearchDesktopButton";
import { useGlobalSearchOpen } from "src/api/useGlobalSearchOpen";
import { useResetPassword } from "src/api/useResetPassword";
import { LoadingButton } from "src/components/buttons/LoadingButton";
import { useViewModeQueryParam } from "src/utils/useViewModeQueryParam";
import { SettingsGeneralTab } from "./components/SettingsGeneralTab";
import { SettingsCustomizationTab } from "./components/SettingsCustomizationTab";
import { SettingsContactTab } from "./components/SettingsContactTab";
import { SettingsContactForm } from "./components/SettingsContactForm/SettingsContactForm";
import { SettingsIntegrationsTab } from "./components/SettingsIntegrationsTab/SettingsIntegrationsTab";
import { useSessionDelete } from "src/api/useSessionDelete";

export enum SettingsPageTab {
  general = "general",
  customization = "customization",
  integrations = "integrations",
  contact = "contact",
}
const pageTabKey = "pageTab";

export function SettingsPage() {
  const { data: currentUser, isLoading: isUserLoading } = useCurrentUser();
  const resetPassword = useResetPassword({
    options: {
      onSuccess: () => {
        resetDialog.show();
      },
    },
  });
  const [scrolling, setScrolling] = useState(0);
  const watchQuerySearchDialog = useGlobalSearchOpen();

  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const { logout, user } = useAuth0();
  const [pageTabMode, setPageTabMode] = useViewModeQueryParam<SettingsPageTab>({
    paramKey: pageTabKey,
    defaultValue: SettingsPageTab.general,
  });

  useEffect(() => {
    if (isMobile && pageTabMode === SettingsPageTab.integrations) {
      setPageTabMode(SettingsPageTab.general);
    }
  }, [isMobile, pageTabMode, setPageTabMode]);

  const contactDialog = useOpenState();
  const resetDialog = useOpenState();
  const sessionDelete = useSessionDelete();

  const isRegularUser = currentUser?.roles?.every((role) => role === "User");
  const tabWidth = isMobile ? "33.3%" : "160px";

  const handleChangePageTabMode = useCallback(
    (newValue: string) => {
      setScrolling(0);
      setPageTabMode(newValue as SettingsPageTab);
    },
    [setPageTabMode]
  );

  const handleLogout = async () => {
    // wait for session deletion
    await sessionDelete.mutateAsync();
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const handleResetPassword = () => {
    resetPassword.mutate();
  };

  const resetDialogContent = (
    <Stack sx={{ width: "500px" }} alignItems={"center"}>
      <Box sx={{ width: "400px" }} my={4} textAlign={"center"}>
        <IconButton
          color="success"
          sx={{
            border: "2px solid",
            margin: "16px",
            padding: "16px",
          }}
        >
          <MailOutlineIcon sx={{ height: "50px", width: "50px" }} />
        </IconButton>
        <Typography variant="h6" mb={2}>
          Check Your Email
        </Typography>
        <Typography variant="body1" mb={2}>
          Please check the email address
          <br /> {currentUser?.email} for instructions to reset your password.
        </Typography>
        <Typography variant="body1" mb={1}>
          If you didn't receive the email, you can try to send it again
        </Typography>
        <LoadingButton
          loading={resetPassword.isLoading}
          variant="outlined"
          sx={{ width: "250px", margin: "16px", borderRadius: "20px" }}
          onClick={handleResetPassword}
        >
          Resend email
        </LoadingButton>
      </Box>
    </Stack>
  );

  const enableResetButton = user?.sub?.startsWith("auth0|");
  const tabContent = useMemo(() => {
    return (
      <ScrollBox height="100%" customScrollBarStyle={"hidden"}>
        {pageTabMode === SettingsPageTab.general ? (
          <SettingsGeneralTab
            userInfo={currentUser}
            isRegularUser={isRegularUser}
          />
        ) : pageTabMode === SettingsPageTab.customization ? (
          <Stack>
            <SettingsCustomizationTab />
          </Stack>
        ) : pageTabMode === SettingsPageTab.integrations ? (
          <SettingsIntegrationsTab />
        ) : pageTabMode === SettingsPageTab.contact ? (
          <SettingsContactTab
            isRegularUser={isRegularUser}
            isLoading={isUserLoading}
          />
        ) : null}
      </ScrollBox>
    );
  }, [pageTabMode, currentUser, isRegularUser, isUserLoading]);

  const mobileFooter = (
    <Stack
      direction="row"
      justifyContent={"space-around"}
      height={"80px"}
      alignItems={"center"}
    >
      {pageTabMode === SettingsPageTab.contact ? (
        <>
          <Tooltip
            title={
              !enableResetButton
                ? "You are logged in using 3rd party credentials."
                : undefined
            }
            placement="top-end"
          >
            <span>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  minWidth: 160,
                  height: 42,
                }}
                disabled={!enableResetButton}
                onClick={handleResetPassword}
              >
                Reset Password
              </Button>
            </span>
          </Tooltip>
          <Button
            component="label"
            variant="contained"
            color="success"
            onClick={contactDialog.show}
            sx={{
              minWidth: 160,
              margin: 2,
              padding: "8px 22px",
              display: "flex",
            }}
          >
            Contact us
          </Button>
        </>
      ) : (
        <Stack direction="row" width={"100%"} justifyContent={"space-around"}>
          <Button
            variant="text"
            color="error"
            onClick={handleLogout}
            sx={{ width: "50%" }}
          >
            Log Out
          </Button>
          <Tooltip
            title={
              !enableResetButton
                ? "You are logged in using 3rd party credentials."
                : undefined
            }
            placement="top-end"
          >
            <span>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  minWidth: 160,
                  height: 42,
                }}
                disabled={!enableResetButton}
                onClick={handleResetPassword}
              >
                Reset Password
              </Button>
            </span>
          </Tooltip>
        </Stack>
      )}
    </Stack>
  );

  const tabsContent = useMemo(() => {
    if (isMobile) {
      return (
        <AppTabLayout
          flex={1}
          layout="desktop"
          variant="standard"
          overflow="hidden"
          currentTab={pageTabMode}
          onCurrentTabChange={handleChangePageTabMode}
          scrolling={scrolling}
        >
          <AppTab
            label="General"
            value={SettingsPageTab.general}
            children={tabContent}
            sx={{ width: tabWidth }}
          />
          <AppTab
            label="Customization"
            value={SettingsPageTab.customization}
            children={tabContent}
            sx={{ width: tabWidth }}
          />
          <AppTab
            label="Contact"
            value={SettingsPageTab.contact}
            children={tabContent}
            sx={{ width: tabWidth }}
          />
        </AppTabLayout>
      );
    }

    return (
      <AppTabLayout
        flex={1}
        layout="desktop"
        variant="standard"
        overflow="hidden"
        currentTab={pageTabMode}
        onCurrentTabChange={handleChangePageTabMode}
        scrolling={scrolling}
      >
        <AppTab
          label="General"
          value={SettingsPageTab.general}
          children={tabContent}
          sx={{ width: tabWidth }}
        />
        <AppTab
          label="Customization"
          value={SettingsPageTab.customization}
          children={tabContent}
          sx={{ width: tabWidth }}
        />
        <AppTab
          label="Integrations"
          value={SettingsPageTab.integrations}
          children={tabContent}
          sx={{ width: tabWidth }}
        />
        <AppTab
          label="Contact"
          value={SettingsPageTab.contact}
          children={tabContent}
          sx={{ width: tabWidth }}
        />
      </AppTabLayout>
    );
  }, [
    handleChangePageTabMode,
    isMobile,
    pageTabMode,
    scrolling,
    tabContent,
    tabWidth,
  ]);

  return (
    <PageLayout>
      <PageHeaderDesktop
        style={{
          marginBottom: isMobile ? 8 : 24,
        }}
        title={isMobile ? "" : "My Settings"}
        hideBreadcrumbs
        hideBackButton
        toolbar={
          !isMobile ? (
            <Stack
              flex={1}
              minHeight={42}
              direction="row"
              gap={2}
              justifyContent="flex-end"
            >
              <GlobalSearchDesktopButton />
              <Button variant="text" color="error" onClick={handleLogout}>
                Log Out
              </Button>
              <Tooltip
                title={
                  !enableResetButton
                    ? "You are logged in using 3rd party credentials."
                    : undefined
                }
                placement="top-end"
              >
                <span>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      minWidth: isMobile ? 160 : 176,
                      width: isMobile ? "100%" : undefined,
                      height: "100%",
                    }}
                    disabled={!enableResetButton}
                    onClick={handleResetPassword}
                  >
                    Reset Password
                  </Button>
                </span>
              </Tooltip>
            </Stack>
          ) : undefined
        }
      />
      {tabsContent}
      <Stack px={2} alignItems={isMobile ? "flex-start" : "center"} mt={2}>
        <AppVersions />
      </Stack>
      {isMobile && mobileFooter}
      {isMobile && (
        <DialogBase
          open={contactDialog.isOpen}
          onClose={contactDialog.hide}
          disableHeaderDivider
          title="Contact us"
          children={<SettingsContactForm onClose={contactDialog.hide} />}
          sx={{
            [`.${dialogClasses.container}`]: {
              margin: "24px",
            },
            [`.${svgIconClasses.root}`]: {
              display: "none",
            },
          }}
        />
      )}
      <Dialog
        onClose={resetDialog.hide}
        open={resetDialog.isOpen}
        children={resetDialogContent}
      />
      <GlobalSearchDialog
        open={watchQuerySearchDialog.isOpen}
        onClose={watchQuerySearchDialog.hide}
      />
    </PageLayout>
  );
}
