import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl, { LngLat, Map } from "mapbox-gl";
import { MutableRefObject, useEffect } from "react";
import { useElementSize } from "src/utils/useElementSize";

mapboxgl.accessToken = process.env.REACT_APP_MAP_BOX_ACCESS_TOKEN ?? "";

const defaultCenter: LngLat = new LngLat(-100, 38);

let map: Map | null = null;
let error: unknown | null = null;

export function useMapBoxInstance({
  containerRef,
}: {
  containerRef: MutableRefObject<HTMLElement | null>;
}) {
  const { width, height } = useElementSize(containerRef) || {};

  useEffect(() => {
    try {
      // exit when no container
      if (!containerRef.current) return;

      // map was already initialized
      if (map) return;

      if (!mapboxgl.supported()) {
        map = null;
        error = new Error("Your browser does not support Mapbox GL");
      }

      const mapInstance = new mapboxgl.Map({
        container: containerRef.current,
        style: "mapbox://styles/moeitrope/clh6hebtw01ht01qj9myg36ju?fresh=true",
        center: defaultCenter,
        maxZoom: 10,
      });

      mapInstance.addControl(
        new mapboxgl.NavigationControl({ showCompass: false }),
        "top-left"
      );

      mapInstance.dragRotate.disable();
      mapInstance.touchZoomRotate.disableRotation();

      map = mapInstance;
    } catch (e) {
      map = null;
      error = e;
    }
  }, [containerRef]);

  useEffect(() => {
    if (!map) return;

    map.resize();
  }, [width, height]);

  useEffect(() => {
    return () => {
      console.log(`@@ DEBUG:useMapBoxInstance:remove`);
      map = null;
    };
  }, []);

  return {
    map,
    error,
  };
}
