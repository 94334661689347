import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, Typography } from "@mui/material";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useIsUserExpired } from "src/api/useIsUserExpired";
import { AnimatedBackground } from "src/components/AnimatedBackground/AnimatedBackground";

export const Auth0CallbackPage = () => {
  const { user, error, isLoading } = useAuth0();
  const isUserExpired = useIsUserExpired();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading || error) return;

    navigate("/");
  }, [isLoading, error, navigate, user, isUserExpired]);

  if (error) {
    return (
      <Box mt={2} mr="auto" mb="auto" ml="auto">
        <Typography variant="h4">Error</Typography>
        <Typography color="error" mb={2}>
          {error.message}
        </Typography>
        <Button component={Link} to="/" variant="contained">
          Go Home
        </Button>
      </Box>
    );
  }

  return <AnimatedBackground />;
};
