import { useRef, useState } from "react";
import { Info } from "@mui/icons-material";
import {
  Grid,
  Paper,
  Stack,
  Typography,
  colors,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { queryAdvertisementValues } from "src/models/Advertisement";
import { AdvertisementFilterNotice } from "src/components/AdvertisementPicker/AdvertisementFilterNotice";
import { PowerSearchKeywordFormValues } from "./PowerSearchKeywordForm.model";
import { AppDateTimePicker } from "../AppDateTimePicker/AppDateTimePicker";
import { AdvertisementPicker } from "../AdvertisementPicker/AdvertisementPicker";
import { ProgramTagPicker } from "../ProgramTagPicker/ProgramTagPicker";
import { SourcesPicker } from "../SourcesPicker/SourcesPicker";
import { TextInputBase } from "../TextInputBase";
import { WatchTermInput } from "../WatchTermInput/WatchTermInput";
import { PopperInfo } from "../PopperInfo/PopperInfo";

export function PowerSearchKeywordForm() {
  const { palette, breakpoints } = useTheme();
  const isSmallScreen = useMediaQuery(breakpoints.down("md"));
  const containerRef = useRef<HTMLDivElement>(null);
  const { control } = useFormContext<PowerSearchKeywordFormValues>();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const watchTermRow = (
    <Stack spacing={2} pt={4} px={9}>
      <Typography variant="subtitle1">Keyword</Typography>
      <Controller
        name="termsInclude"
        control={control}
        render={({ field, fieldState, formState }) => {
          const { list, logic } = formState.errors[field.name] || {};
          const message = list?.message || logic?.message || " ";

          return (
            <WatchTermInput
              id="term-include-input"
              label="Include"
              value={field.value}
              options={[]} // no suggestions for now
              onChange={field.onChange}
              onBlur={field.onBlur}
              error={!!fieldState.error}
              helperText={message}
              displayTooltip
            />
          );
        }}
      />

      <Controller
        name="termsExclude"
        control={control}
        render={({ field, fieldState, formState }) => {
          const { list, logic } = formState.errors[field.name] || {};
          const message = list?.message || logic?.message || " ";

          return (
            <WatchTermInput
              id="term-exclude-input"
              label="Exclude"
              value={field.value}
              options={[]} // no suggestions for now
              onChange={field.onChange}
              onBlur={field.onBlur}
              error={!!fieldState.error}
              helperText={message}
              displayTooltip
            />
          );
        }}
      />
    </Stack>
  );

  const dateStartEnd = (
    <Grid container spacing={3} mb={2}>
      <Grid item xs={12}>
        <Typography variant="subtitle1">Date</Typography>
      </Grid>

      <Grid item xs={12} md={6}>
        <Controller
          name="startDateTime"
          control={control}
          render={({ field, fieldState }) => {
            const message = fieldState.error?.message || " ";

            return (
              <AppDateTimePicker
                label="Start Date"
                value={field.value}
                onChange={field.onChange}
                views={["year", "month", "day", "hours", "minutes"]}
                slotProps={{
                  field: {
                    clearable: true,
                  },
                  textField: {
                    helperText: message,
                    error: !!fieldState.error,
                    fullWidth: true,
                    onBlur: field.onBlur,
                  },
                }}
              />
            );
          }}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Controller
          name="endDateTime"
          control={control}
          render={({ field, fieldState }) => {
            const message = fieldState?.error?.message || " ";

            return (
              <AppDateTimePicker
                label="End Date"
                value={field.value}
                onChange={field.onChange}
                views={["year", "month", "day", "hours", "minutes"]}
                slotProps={{
                  field: {
                    clearable: true,
                  },
                  textField: {
                    helperText: message,
                    error: !!fieldState.error,
                    fullWidth: true,
                    onBlur: field.onBlur,
                  },
                }}
              />
            );
          }}
        />
      </Grid>
    </Grid>
  );

  const displayNameRow = (
    <Stack pt={2} pb={1} px={9}>
      <Controller
        name="displayName"
        control={control}
        render={({ field, fieldState }) => {
          return (
            <TextInputBase
              label="Display name"
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
              error={!!fieldState.error}
              helperText={fieldState.error?.message || " "}
              fullWidth
            />
          );
        }}
      />
    </Stack>
  );

  return (
    <Stack
      ref={containerRef}
      sx={{ backgroundColor: palette.background.default }}
    >
      <Stack
        component={Paper}
        sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
      >
        {watchTermRow}
        {displayNameRow}
      </Stack>

      <Stack component={Paper} mt={3} px={9} pt={4}>
        {dateStartEnd}

        {/* Sources */}
        <Grid container spacing={3} mb={2}>
          <Grid item xs={12} md={12}>
            <Typography variant="subtitle1">Sources</Typography>
          </Grid>

          <Grid item xs={12} md={12}>
            <Controller
              name="sourcesInclude"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <SourcesPicker
                    id="source-include"
                    label={field.value.list.length ? "Source" : "All Sources"}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message || " "}
                    dropDownBoundsEl={containerRef}
                  />
                );
              }}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <Controller
              name="sourcesExclude"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <SourcesPicker
                    id="source-exclude"
                    label="Exclude"
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message || " "}
                    dropDownBoundsEl={containerRef}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
        {/* Sources */}

        {/* Advertisement */}
        <Grid container spacing={3} mb={2}>
          <Grid item xs={12} md={12}>
            <Typography
              variant="subtitle1"
              display="flex"
              alignItems="center"
              height={36.5}
            >
              Advertisement{" "}
              <Info
                onClick={handleClick}
                sx={{ ml: 1, color: colors.blueGrey[200], cursor: "pointer" }}
              />
            </Typography>
            <PopperInfo
              open={open}
              anchorEl={anchorEl}
              title="Advertisement"
              text="The TVEyes platform introduces a robust and user-centric tool designed to enhance your experience in monitoring and analyzing advertising content across broadcast television and radio channels. This feature-rich popover provides versatile options for managing advertisements with a focus on convenience and customization."
              onClose={handleClose}
            />
          </Grid>

          <Grid item xs={12} mb={3}>
            <Controller
              name="advertisement"
              control={control}
              render={({ field }) => {
                return (
                  <Stack gap={3}>
                    <Stack width={!isSmallScreen ? 483 : undefined}>
                      <AdvertisementPicker
                        fullWidth
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                      />
                    </Stack>
                    {field.value === queryAdvertisementValues.none && (
                      <AdvertisementFilterNotice />
                    )}
                  </Stack>
                );
              }}
            />
          </Grid>
        </Grid>
        {/* Advertisement */}

        {/* Program title */}
        <Grid container spacing={3} mb={2}>
          <Grid item xs={12} md={12}>
            <Typography variant="subtitle1">Program Title</Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              name="programInclude"
              control={control}
              render={({ field, fieldState, formState }) => {
                const { list, logic } = formState.errors[field.name] || {};
                const message = list?.message || logic?.message || " ";

                return (
                  <ProgramTagPicker
                    id="program-include-input"
                    label="Include"
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    getOptionLabel={(o) => o.name}
                    error={!!fieldState.error}
                    helperText={message}
                  />
                );
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              name="programExclude"
              control={control}
              render={({ field, fieldState, formState }) => {
                const { list, logic } = formState.errors[field.name] || {};
                const message = list?.message || logic?.message || " ";

                return (
                  <ProgramTagPicker
                    id="program-exclude-input"
                    label="Exclude"
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    getOptionLabel={(o) => o.name}
                    error={!!fieldState.error}
                    helperText={message}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
}
