import { Box, ButtonBase, Typography, useTheme } from "@mui/material";
import { Fragment, PropsWithChildren, useMemo } from "react";
import { QuerySourceIncludeIcon } from "src/components/QueryIcons/QuerySourceIncludeIcon";
import { NameValuePair } from "src/models/NameValuePair";
import { QueryDefinition } from "src/models/QueryDefinition";
import { useOpenState } from "src/utils/useOpenState";
import { DialogBase } from "src/components/DialogBase/DialogBase";
import {
  renderSourceBlocks,
  WatchQueryExplanationMobile,
} from "./WatchQueryExplanationMobile";
import { SourceBlockType } from "./WatchQueryExplanation";

const ALL_SOURCES_INCLUDE = [
  { name: "Podcast", value: "Podcast" },
  { name: "Television", value: "Television" },
  { name: "Radio", value: "Radio" },
  { name: "YouTube", value: "YouTube" },
];

const IconFrame = ({
  index,
  overlap,
  size,
  zIndex,
  children,
}: PropsWithChildren<{
  index: number;
  overlap: number;
  size: number;
  zIndex?: number;
}>) => {
  const { palette } = useTheme();

  return (
    <Box
      height={size}
      width={size}
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="absolute"
      zIndex={zIndex}
      bgcolor={palette.background.default}
      borderRadius="50%"
      left={index * (size - overlap)}
      children={children}
    />
  );
};

const renderSourceIcons = ({
  queryDefinition,
  overlap,
  size,
  sourcesBlocksInclude,
}: {
  queryDefinition?: QueryDefinition | null;
  overlap: number;
  size: number;
  sourcesBlocksInclude?: JSX.Element[] | null;
}) => {
  const { sourceFilter } = queryDefinition || {};

  const includeContentType = sourceFilter?.contentType?.include || [];

  //Render all content type icons if no other source include selected.
  const includeContentTypeGroup = includeContentType.length
    ? includeContentType
    : sourcesBlocksInclude
    ? []
    : ALL_SOURCES_INCLUDE;

  const renderSourceIncludeIcon = (item: NameValuePair) => {
    return <QuerySourceIncludeIcon eventType={item.value} />;
  };

  const sourceIconsAll = [
    ...includeContentTypeGroup.map(renderSourceIncludeIcon),
  ];
  const sourceIconsVisible = sourceIconsAll.slice(0, 2);
  const restCount = sourceIconsAll.length - sourceIconsVisible.length;

  if (restCount > 0) {
    sourceIconsVisible.push(
      <Typography variant="body2">+{restCount}</Typography>
    );
  }

  return sourceIconsVisible.map((icon, index, { length }) => {
    return (
      <IconFrame
        key={`frame:${index}`}
        index={index}
        overlap={overlap}
        size={size}
        zIndex={length - index}
        children={icon}
      />
    );
  });
};

export function WatchQueryComplexityIconBar({
  queryDefinition,
}: {
  queryDefinition?: QueryDefinition | null;
}) {
  const sourcesBlocksInclude = useMemo(
    () => renderSourceBlocks(SourceBlockType.include, queryDefinition),
    [queryDefinition]
  );

  const size = 30;
  const overlap = 8;
  const popperState = useOpenState();
  const sourceIcons = renderSourceIcons({
    queryDefinition,
    overlap,
    size,
    sourcesBlocksInclude,
  });
  const count = sourceIcons.length;
  const minWidth = count * size - (count - 1) * overlap;

  return sourceIcons.length ? (
    <Fragment>
      <ButtonBase
        onClick={popperState.show}
        children={sourceIcons}
        sx={{
          height: size,
          minWidth: minWidth,
          borderRadius: size / 2,
          justifyContent: "center",
          direction: "row",
          position: "relative",
          alignItems: "center",
          overflow: "hidden",
        }}
      />
      <DialogBase
        title="Watch Term Monitors"
        open={popperState.isOpen}
        onClose={popperState.hide}
        extraMobileMargin={2}
        hideIntercomButton
        children={
          <WatchQueryExplanationMobile queryDefinition={queryDefinition} />
        }
      />
    </Fragment>
  ) : null;
}
