import { ComponentType, useMemo } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { AppQueryParamsProvider } from "src/providers/AppQueryParamsProvider";
import { useIsUserRegistered } from "src/api/useIsUserRegistered";
import { PageLoader } from "./PageLoader";
import { UnregisteredUserPage } from "src/pages/UnregisteredUser/UnregisteredUserPage";

interface CheckAccountRegisteredProps {
  component: ComponentType;
}

export const CheckAccountRegistered = ({
  component,
}: CheckAccountRegisteredProps) => {
  // const navigate = useNavigate();
  const { user } = useAuth0();
  const isUserRegistered = useIsUserRegistered();

  const Component = useMemo(() => {
    // do not re-create component on every render
    return withAuthenticationRequired(component, {
      onRedirecting: () => <PageLoader />,
    });
  }, [component]);

  if (user && !isUserRegistered) {
    // FIXME: potentially can be moved to Auth0CallbackPage because
    // unregistered user can not access Intercom, etc ?
    return <UnregisteredUserPage />;
  }

  return (
    <AppQueryParamsProvider>
      <Component />
    </AppQueryParamsProvider>
  );
};
