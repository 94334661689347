import { useMemo } from "react";
import { useReportScratch } from "src/api/useReportScratch";
import { useSessionContext } from "src/api/useSessionContext";

const usePreselectLimit = () => {
  const { effectiveEntitlements } = useSessionContext();
  const { data } = useReportScratch();

  const maxEventsToPreselect = useMemo(
    () =>
      (effectiveEntitlements.limitScratchReportItems?.value ?? 0) -
      (data?.total ?? 0),
    [effectiveEntitlements, data]
  );

  const preselectLimitTitle = useMemo(
    () =>
      `Preselected events is available for up to ${
        effectiveEntitlements.limitScratchReportItems?.value
      } events.${
        data?.total
          ? ` You already have ${data?.total} preselected events.`
          : ""
      }  Please select less items or contact your account manager to extend your limits.`,
    [data, effectiveEntitlements.limitScratchReportItems?.value]
  );

  return { maxEventsToPreselect, preselectLimitTitle };
};

export default usePreselectLimit;
