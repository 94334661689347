import axios from "axios";
import {
  UseInfiniteQueryOptions,
  useInfiniteQuery,
  useQueryClient,
} from "react-query";
import { paths } from "@tveyes/twosionwebapischema";
import { enqueueSnackbar } from "notistack";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { countPagedResultsTotal } from "src/utils/countPagedResultTotal";
import { ReportEventsResponse } from "./useReportEvents";

const apiKey: keyof paths = "/api/report/{id}/highlights";
const method: keyof paths[typeof apiKey] = "post";

type Endpoint = Required<paths[typeof apiKey][typeof method]>;
type Parameters = Endpoint["parameters"];

type RequestBody = Endpoint["requestBody"]["content"]["application/json"];

export function useReportEventsInfinite({
  options,
  params,
  excludeFilter,
}: {
  options?: UseInfiniteQueryOptions<ReportEventsResponse, unknown>;
  params: Parameters;
  excludeFilter?: string[];
}) {
  const queryClient = useQueryClient();
  const { path, query } = params;

  const pageSize = query?.size || 24;

  return useInfiniteQuery<ReportEventsResponse>({
    ...options,
    queryKey: ["report-events-infinite-scroll", params, excludeFilter],
    keepPreviousData: true,
    initialData: () => {
      const cacheWatchTermResults =
        queryClient.getQueryData<ReportEventsResponse>(
          [
            apiKey,
            {
              // we want cached data only from start regardless of the page size
              query: { from: 0 },
            },
          ],
          {
            // ignore page size
            exact: false,
          }
        );

      const { results = [], total = 0 } = cacheWatchTermResults || {};
      const pages: ReportEventsResponse[] = [];

      while (true) {
        const from = query?.from ? query.from : pages.length * pageSize;
        const to = from + pageSize;
        const pageData = results.slice(from, to);

        if (pageData.length < pageSize) {
          // no more data to fill next page
          break;
        }

        pages.push({
          total,
          results: pageData,
          configuration: {
            from,
            size: pageSize,
          },
        });
      }

      return {
        pages: pages,
        pageParams: pages.map(({ configuration }) => configuration),
      };
    },
    queryFn: async ({ pageParam }) => {
      const from = pageParam?.from || 0;
      const size = pageParam?.size || pageSize;

      const apiPath = makeApiUrl<Parameters>(apiKey, {
        path,
        query: {
          ...query,
          from,
          size,
        },
      });

      const body: RequestBody = {
        excludeFilter,
      };

      const { data } = await axios<ReportEventsResponse>(apiPath, {
        method,
        data: body,
      });

      return data;
    },
    getNextPageParam: (lastGroup = {}, allPages) => {
      const loadedCount = countPagedResultsTotal(allPages);
      const { total, configuration } = lastGroup;
      if (total === loadedCount) return;

      if (configuration && configuration.from !== null) {
        const { from = 0 } = configuration;
        return {
          from: from + pageSize,
          size: pageSize,
        };
      }

      return {
        from: 0,
        size: pageSize,
      };
    },
    onError: (error) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
  });
}
