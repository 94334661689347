import { ListBase } from "src/components/ListBase/ListBase";
import { EventsNoResultsOverlay } from "src/components/NoResultsOverlay/NoResultsOverlay";
import { WatchListUserQueryDTO } from "src/models/WatchListUserQueryDTO";
import { WatchQueryListProps } from "./WatchQueryList.model";
import { WatchQueryListItem } from "./WatchQueryListItem";

export function WatchQueryList({
  queries,
  hideThumbnail,
  canCreateQuery,
  ...props
}: WatchQueryListProps) {
  const renderItem = (item: WatchListUserQueryDTO) => {
    const query = queries?.find((query) => {
      return query.id === item.id;
    });

    const alerts = query?.alerts || [];

    return (
      <WatchQueryListItem
        key={item.title}
        hideThumbnail={hideThumbnail}
        query={item}
        alerts={alerts}
        canCreateQuery={canCreateQuery}
      />
    );
  };

  return (
    <ListBase<WatchListUserQueryDTO>
      renderItem={renderItem}
      renderEmpty={EventsNoResultsOverlay}
      ListProps={{
        sx: { pl: 2, pr: 1, height: "100%" },
      }}
      {...props}
    />
  );
}
