import { useState } from "react";
import { DateRange } from "@mui/icons-material";
import {
  BoxProps,
  Divider,
  Stack,
  Tooltip,
  Typography,
  useTheme,
  sliderClasses,
  buttonBaseClasses,
  alpha,
} from "@mui/material";
import { PowerSearchResultItem } from "src/api/usePowerSearch";
import {
  getPowerSearchResultEvent,
  getPowerSearchResultDateTime,
  getPowerSearchResultEventType,
  getPowerSearchResultMediaType,
  getPowerSearchResultTag,
  getPowerSearchResultIndividualTranscript,
  getPowerSearchResultId,
} from "src/api/usePowerSearchResult.getters";
import { useEventSelectionDuplicatesDrawer } from "src/api/useEventSelectionDuplicatesDrawer";
import { getEventIcon } from "src/utils/getEventIcon";
import { TranscriptBlock } from "src/components/TranscriptBlock/TranscriptBlock";
import { TranscriptText } from "src/components/TranscriptText/TranscriptText";
import { TextLineClamp } from "src/components/TextLineClamp/TextLineClamp";
import { watchListTermResultClipRoute } from "src/pages/WatchListTermResultClip/WatchListTermResultClip.route";
import { powerSearchResultClipRoute } from "src/pages/PowerSearchResultClip/PowerSearchResultClip.route";
import { AppLink } from "src/components/AppLink/AppLink";
import { AdvChip } from "src/components/AdvChip/AdvChip";
import { TileCheckBox } from "src/components/TileCheckBox/TileCheckBox";
import { ClipViewer } from "src/components/ClipViewer/ClipViewer";
import { ClipViewerProps } from "src/components/ClipViewer/ClipViewer.model";
import { TranscriptParser } from "src/components/TranscriptParser/TranscriptParser";
import { PowerSearchResultActions } from "src/pages/PowerSearchResult/components/PowerSearchResultActions/PowerSearchResultActions";
import { WatchTermResultsActions } from "src/pages/WatchListTermResults/components/WatchTermResultsActions/WatchTermResultsActions";
import { stripHTMLTags } from "src/utils/stripHTMLTags";

export function DuplicatesTile({
  value,
  queryId,
  width = "100%",
  height = "100%",
  isExpandedTextMode,
  isChecked,
  isPlaying,
  onChangePlayState,
  addItemToReport,
  isPowerSearch,
}: {
  value: PowerSearchResultItem;
  queryId: string;
  isChecked: boolean;
  isPlaying?: boolean;
  width?: BoxProps["width"];
  height?: BoxProps["width"];
  isExpandedTextMode: boolean;
  onChangePlayState?: ClipViewerProps["onChangePlayState"];
  addItemToReport: (value: PowerSearchResultItem) => void;
  isPowerSearch?: boolean;
}) {
  const { palette } = useTheme();

  const [activeMention, setActiveMention] = useState(0);

  const eventId = getPowerSearchResultId(value);
  const eventType = getPowerSearchResultEventType(value);
  const mediaType = getPowerSearchResultMediaType(value);
  const Icon = getEventIcon(eventType, mediaType);

  const eventTitle = getPowerSearchResultEvent(value);

  const detailsUrl = isPowerSearch
    ? powerSearchResultClipRoute.makeUrl({ eventId })
    : watchListTermResultClipRoute.makeUrl({
        queryId,
        eventId,
      });

  const transcript = getPowerSearchResultIndividualTranscript(
    value,
    activeMention,
    isExpandedTextMode
  );
  const transcriptTitle = stripHTMLTags({ transcript });

  const { select, unselect } = useEventSelectionDuplicatesDrawer();

  const handleChange = (checked: boolean) => {
    if (checked) {
      select(value);
    } else {
      unselect(value);
    }
  };

  return (
    <Stack
      border={`1px solid ${palette.divider}`}
      borderRadius={2}
      width={width}
      height={height}
      position="relative"
      sx={{
        backgroundColor: alpha(palette.action.hover, 0.04),
      }}
    >
      <ClipViewer
        id={eventId}
        event={value}
        play={isPlaying}
        viewMode="compact"
        onChangePlayState={onChangePlayState}
        onMentionChange={setActiveMention}
        sx={{
          [`& > .MuiBox-root:first-of-type div`]: {
            borderTopRightRadius: 7, // not 8 because of outer 1px border
            borderTopLeftRadius: 7, // not 8 because of outer 1px border
            overflow: "hidden",
          },
          [`.${sliderClasses.root}`]: {
            marginTop: "-5px",
          },
          [`.player-container .${buttonBaseClasses.root}`]: {
            minWidth: "59px",
            width: "59px",
            height: "59px",
            opacity: "0.4",
          },
        }}
      />

      <TileCheckBox value={isChecked} onChange={handleChange} zIndex={2} />

      {getPowerSearchResultTag(value).length ? (
        <AdvChip
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        />
      ) : null}

      <Divider />

      <Stack
        direction="row"
        pl={2}
        py={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack rowGap={1}>
          <Stack direction="row" alignItems="center">
            <Tooltip title={eventType}>
              <Icon />
            </Tooltip>
            <Tooltip title={eventTitle}>
              <AppLink to={detailsUrl}>
                <TextLineClamp
                  variant="subtitle1"
                  color="primary"
                  lineClamp={1}
                  ml={1}
                  height={20}
                >
                  {getPowerSearchResultEvent(value)}
                </TextLineClamp>
              </AppLink>
            </Tooltip>
          </Stack>

          <Stack direction="row" alignItems="center">
            <DateRange sx={{ color: palette.action.active }} />
            <Typography variant="body2" ml={1}>
              {getPowerSearchResultDateTime(value).toLocaleString()}
            </Typography>
          </Stack>
        </Stack>
        {isPowerSearch ? (
          <PowerSearchResultActions
            value={value}
            addItemToReport={addItemToReport}
          />
        ) : (
          <WatchTermResultsActions
            value={value}
            addItemToReport={addItemToReport}
            queryId={queryId}
          />
        )}
      </Stack>
      <Tooltip title={transcriptTitle}>
        <TranscriptBlock borderRadius={1} mx={2} mb={2} px={2} py={1}>
          {isExpandedTextMode ? (
            <Typography variant="body2">
              <TranscriptParser
                highlightColor={palette.primary.main}
                transcript={transcript}
              />
            </Typography>
          ) : (
            <TranscriptText
              variant="body2"
              transcript={transcript}
              lineClamp={2}
            />
          )}
        </TranscriptBlock>
      </Tooltip>
    </Stack>
  );
}
