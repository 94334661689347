import {
  ButtonProps,
  CircularProgress,
  ListItemSecondaryAction,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useRef, useState } from "react";
import { useSessionContext } from "src/api/useSessionContext";
import { useWatchQueryAlertCreate } from "src/api/useWatchQueryAlertCreate";
import { useWatchQueryAlertDelete } from "src/api/useWatchQueryAlertDelete";
import { InstantAlertButton } from "src/components/AlertButtons/InstantAlertButton";
import { ScheduleAlertButton } from "src/components/AlertButtons/ScheduleAlertButton";
import { AppPopper } from "src/components/AppPopper/AppPopper";
import { AppPopperArrowSize } from "src/components/AppPopper/components/AppPopperArrow/AppPopperArrow.model";
import { ListBaseItem } from "src/components/ListBase/ListBaseItem";
import { AlertsDrawer } from "src/components/ScheduledAlertsDrawer/AlertsDrawer";
import { alertTypePresets } from "src/components/ScheduledAlertPopover/ScheduledAlertPopover.utils";
import { TextLineClamp } from "src/components/TextLineClamp/TextLineClamp";
import { WatchQueryInstantAlertDialog } from "src/components/WatchQueryInstantAlertDialog/WatchQueryInstantAlertDialog";
import { WatchQueryThumbnail } from "src/components/WatchQueryThumbnail/WatchQueryThumbnail";
import { UserQueryDTO } from "src/models/UserQueryDTO";
import { WatchListUserQueryDTO } from "src/models/WatchListUserQueryDTO";
import { getSkippedInstantAlert } from "src/utils/instantAlertHelpers";
import { isWatchQueryComplex } from "src/utils/isWatchQueryComplex";
import { useIsMobile } from "src/utils/useIsMobile";
import { useOpenState } from "src/utils/useOpenState";
import { useQueryDateRange } from "src/utils/useQueryDateRange";
import { WatchQueryItemMenuButton } from "../WatchQueryItemMenu/WatchQueryItemMenuButton";
import { WatchQueryExplanation } from "../WatchQueryTable/components/WatchQueryExplanation";
import { WatchQueryTitle } from "../WatchQueryTable/components/WatchQueryTitle";

export function WatchQueryListItem({
  query,
  alerts,
  isLoading,
  hideThumbnail,
  canCreateQuery,
}: {
  query: WatchListUserQueryDTO;
  alerts: UserQueryDTO["alerts"];
  isLoading?: boolean;
  hideThumbnail?: boolean;
  canCreateQuery: boolean;
}) {
  const { spacing, palette } = useTheme();
  const { userId } = useSessionContext();
  const isMobile = useIsMobile();
  const popperState = useOpenState();
  const containerRef = useRef<HTMLDivElement>(null);
  const totalHits = (query?.hits || []).reduce((acc, item) => {
    if (!item?.hits) return acc;
    return (acc += item.hits);
  }, 0);

  const [showInstantAlert, setShowInstantAlert] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [dateRange] = useQueryDateRange();

  const instantAlert = alerts.filter((alert) => alert.type === "realtime");
  const scheduledAlerts = alerts.filter((alert) => alert.type !== "realtime");

  const isAlertsEnabled = scheduledAlerts.every((alert) => alert.enabled);

  const alertDelete = useWatchQueryAlertDelete({
    options: {
      onSettled: () => setShowInstantAlert(false),
      origin: "watchlist",
      type: "instant",
    },
  });
  const instantAlertDelete = () => {
    if (!instantAlert.length) return;

    const alertId = instantAlert[0].id;
    if (!alertId) return;

    alertDelete.mutate({
      params: {
        path: {
          queryId: query.id,
          alertId,
        },
      },
    });
  };

  const alertCreate = useWatchQueryAlertCreate({
    options: {
      onSettled: () => setShowInstantAlert(false),
      origin: "watchlist",
      type: "instant",
    },
  });

  const instantAlertCreate = () => {
    if (!query.id) return;

    alertCreate.mutate({
      params: {
        path: {
          queryId: query.id,
        },
        query: {
          type: "realtime",
          archive: false,
        },
      },
    });
  };

  const skippedInstantAlert = getSkippedInstantAlert();

  const handleClick: ButtonProps["onClick"] = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { queryBuilderDefinition } = query;
  const isComplex = queryBuilderDefinition
    ? isWatchQueryComplex({ queryBuilderDefinition })
    : false;

  const width = 208;
  const height = 117;

  return (
    <ListBaseItem key={query.id} alignItems="flex-start" sx={{ columnGap: 2 }}>
      <Stack flex={1} direction="row" flexWrap="wrap" gap={6.5}>
        {!hideThumbnail ? (
          <WatchQueryThumbnail
            queryId={query.id}
            userId={userId}
            width={width}
            height={height}
            alt={`Video thumbnail of ${query.title}`}
            type="compact"
          />
        ) : (
          <Skeleton variant="rectangular" width={width} height={height} />
        )}

        <Stack
          flex={1}
          direction="row"
          flexWrap="wrap"
          justifyContent="flex-start"
        >
          <Stack rowGap={2} justifyContent="space-between">
            <Stack
              direction="column"
              minWidth={200}
              maxWidth={441}
              mr={1}
              gap={1}
              alignItems="flex-start"
            >
              <Typography variant="body2" color={palette.text.secondary}>
                Watch term:
              </Typography>
              {totalHits > 0 ? (
                <WatchQueryTitle
                  query={query}
                  dateRange={dateRange}
                  placement={isMobile ? undefined : "right"}
                />
              ) : (
                <Typography color="primary" children={0} />
              )}
            </Stack>
            {query.clusterId ? (
              <Stack
                direction="row"
                minWidth={200}
                maxWidth={441}
                mr={1}
                gap={1}
              >
                <Typography variant="body2" color={palette.text.secondary}>
                  Cluster:
                </Typography>
                <TextLineClamp
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  variant="subtitle2"
                  children={query.clusterTitle}
                />
              </Stack>
            ) : null}
          </Stack>

          <Stack direction="row" flex={1} justifyContent="space-between">
            <Stack direction="column" mr={1} flex={1} gap={1} maxWidth={395}>
              <Typography variant="body2" color={palette.text.secondary}>
                Events:
              </Typography>
              <Typography variant="subtitle1">
                {isLoading ? <CircularProgress /> : totalHits}
              </Typography>
            </Stack>

            <Stack direction="column" mr={4} flex={0.7}>
              <Typography variant="body2" color={palette.text.secondary}>
                Notifications:
              </Typography>
              <Stack direction="row" spacing={1}>
                <InstantAlertButton
                  onClick={() => {
                    if (skippedInstantAlert && !instantAlert.length) {
                      return instantAlertCreate();
                    }
                    if (instantAlert.length !== 0) {
                      instantAlertDelete();
                    } else {
                      setShowInstantAlert(true);
                    }
                  }}
                  active={instantAlert.length !== 0}
                />
                {!skippedInstantAlert && (
                  <WatchQueryInstantAlertDialog
                    open={showInstantAlert}
                    queryId={query.id}
                    origin="watchlist"
                    onClose={() => setShowInstantAlert(false)}
                  />
                )}
                <ScheduleAlertButton
                  active={scheduledAlerts.length !== 0 && isAlertsEnabled}
                  onClick={handleClick}
                />
                <AlertsDrawer
                  queryId={query.id}
                  presets={alertTypePresets}
                  open={!!anchorEl}
                  watchTermName={query.title}
                  alerts={alerts}
                  origin="watchlist"
                  onClose={handleClose}
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      {query && (
        <AppPopper
          onClose={popperState.hide}
          open={isComplex && popperState.isOpen}
          anchorEl={containerRef.current}
          placement={isMobile ? undefined : "right"}
          arrow={AppPopperArrowSize.medium}
          children={
            <WatchQueryExplanation
              title="Watch Term Monitors"
              queryDefinition={query.queryBuilderDefinition}
            />
          }
        />
      )}
      <ListItemSecondaryAction
        sx={{ top: spacing(1), right: 0, transform: "none" }}
      >
        <WatchQueryItemMenuButton
          query={query}
          canCreateQuery={canCreateQuery}
        />
      </ListItemSecondaryAction>
    </ListBaseItem>
  );
}
