import { Typography, useTheme } from "@mui/material";
import { useIsMobile } from "src/utils/useIsMobile";

const stripOptionalQuotes = /^"?([^"]+)"?$/;
const strip = (v?: string): string | undefined => {
  return v?.match(stripOptionalQuotes)?.[1];
};

export const AppVersions = () => {
  const { palette } = useTheme();
  const isMobile = useIsMobile();
  const separator = isMobile ? <br /> : " - ";

  const uiVersion = strip(process.env.REACT_APP_VERSION_UI);
  const playerVersion = strip(process.env.REACT_APP_VERSION_PLAYER);
  const schemaVersion = strip(process.env.REACT_APP_VERSION_SCHEMA);

  return (
    <Typography variant="subtitle2" color={palette.text.disabled} pt={1}>
      <span>
        UI Version {uiVersion}
        {separator}
      </span>
      <span>
        Service Model Version {schemaVersion}
        {separator}
      </span>
      <span>Media Viewport {playerVersion}</span>
    </Typography>
  );
};
