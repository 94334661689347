import {
  Box,
  Paper,
  Stack,
  ToggleButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { UploadImageDialog } from "src/components/UploadImageDialog/UploadImageDialog";
import { useOpenState } from "src/utils/useOpenState";
import { useBanner } from "src/api/useBanner";
import { useSessionContext } from "src/api/useSessionContext";
import { UploadIcon } from "src/components/icons/UploadIcon";
import { DialogPrompt } from "src/components/DialogPrompt/DialogPrompt";
import { useBannerDelete } from "src/api/useBannerDelete";
import { DialogModes } from "src/components/UploadImageDialog/UploadImageDialog.model";
import { useAccountBanner } from "src/api/useAccountBanner";
import { useAccountBannerDelete } from "src/api/useAccountBannerDelete";
import { TooltipBaseMobile } from "src/components/TooltipBaseMobile/TooltipBaseMobile";
import { SettingsBannerPreview } from "./SettingsBannerPreview";
import { DESKTOP_PREVIEWS, MOBILE_PREVIEWS } from "./SettingsGeneralTab.const";

export function SettingsCustomizationTab({
  disabled,
  page = "settings",
}: {
  disabled?: boolean;
  page?: "account" | "user" | "settings";
}) {
  const { breakpoints, palette } = useTheme();
  const bannerDialog = useOpenState();
  const deleteBannerDialog = useOpenState();
  const { t } = useTranslation();
  const { accountId = "", userId: managedUser = "" } = useParams();
  const { userId, effectiveEntitlements } = useSessionContext();
  const enableCustomReportBanner =
    effectiveEntitlements?.enableCustomReportBanner?.value;

  const [selectedAvatar, setSelectedAvatar] = useState<
    string | Blob | null | undefined
  >(null);

  const deleteBanner = useBannerDelete({
    options: {
      onSuccess: () => deleteBannerDialog.hide(),
    },
  });

  const deleteAccountBanner = useAccountBannerDelete({
    options: {
      onSuccess: () => deleteBannerDialog.hide(),
    },
  });

  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const isSmallScreen = useMediaQuery(breakpoints.down("lg"));

  const { data } = useBanner({
    request: {
      params: {
        path: {
          userId: (page === "user" && managedUser) || userId,
        },
      },
    },
  });

  const { data: accountData } = useAccountBanner({
    request: {
      params: {
        path: {
          accountId: accountId,
        },
      },
    },
    options: {},
  });
  const handleDeleteBanner = () => {
    if (page === "account") {
      deleteAccountBanner.mutate({
        path: {
          accountId,
        },
      });
    } else {
      //For current user or user management
      deleteBanner.mutate({
        path: {
          userId: managedUser && userId !== managedUser ? managedUser : userId,
        },
      });
    }
  };

  const tabData = page === "account" ? accountData : data;

  const getDeleteButtonState = useMemo(() => {
    let disabledState = false;
    if (
      (page === "user" || page === "settings") &&
      tabData?.bannerOwner !== "User"
    ) {
      disabledState = true;
    }
    if (page === "account" && tabData?.isDefault) {
      disabledState = true;
    }
    if (page === "user" && tabData?.bannerOwner === "Default") {
      disabledState = !!tabData.isDefault;
    }
    //Prop configuration or owner hierarchy
    return disabled || disabledState;
  }, [tabData, disabled, page]);

  const desktopHead = (
    <Stack
      p={4}
      component={Paper}
      direction={"row"}
      sx={{
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      }}
    >
      <Stack>
        <Stack direction={"row"}>
          {tabData?.bannerData ? (
            <Stack
              width={56}
              height={56}
              bgcolor={tabData?.bgColor || ""}
              style={{
                borderRadius: "30px",
              }}
            >
              <img
                src={`data:image/png;base64,${tabData?.bannerData}`}
                width={56}
                height={56}
                alt="banner logo"
                style={{
                  borderRadius: "30px",
                }}
              />
            </Stack>
          ) : (
            <Stack width={56} height={56}></Stack>
          )}
          <Typography variant="subtitle1" pl={2} pt={1}>
            Upload your logo to change banner
            <Typography
              display="inline"
              sx={{
                fontSize: 10,
                marginLeft: "22px",
                padding: "4px 6px",
                bgcolor: palette.action.selected,
                borderRadius: "4px",
              }}
            >
              Size 864x102
            </Typography>
            <Typography variant="body2" pt={1} color={"text.secondary"}>
              Allowed *.jpeg, *.jpg, *.png size of 3.1 MB
            </Typography>
          </Typography>
        </Stack>
      </Stack>
      <Stack direction={"row"} gap={2} mt={1} ml={2}>
        <Tooltip
          title={
            enableCustomReportBanner ? "" : t("customReportBannerDisabled")
          }
        >
          <Box>
            <ToggleButton
              value="upload"
              disabled={!enableCustomReportBanner || disabled}
              onClick={bannerDialog.show}
            >
              <UploadIcon
                stroke={
                  !enableCustomReportBanner || disabled
                    ? palette.action.disabled
                    : palette.success.main
                }
                sx={{
                  width: "16px",
                  height: "16px",
                }}
              />
            </ToggleButton>
          </Box>
        </Tooltip>
        <ToggleButton
          value="delete"
          onClick={deleteBannerDialog.show}
          disabled={getDeleteButtonState}
          sx={{ width: "40px", height: "40px" }}
        >
          <DeleteOutlineIcon
            sx={{
              width: "24px",
              height: "24px",
              color: getDeleteButtonState
                ? palette.action.disabled
                : palette.text.primary,
            }}
          />
        </ToggleButton>
      </Stack>
    </Stack>
  );

  const mobileHead = (
    <Stack p={2} component={Paper}>
      <Typography variant="subtitle1" pt={1}>
        Upload your logo to change banner
      </Typography>
      <Stack direction={"row"} gap={3} my={2} alignItems="center">
        {tabData?.bannerData ? (
          <Stack
            width={56}
            height={56}
            bgcolor={tabData?.bgColor || ""}
            style={{
              borderRadius: "30px",
            }}
          >
            <img
              src={`data:image/png;base64,${tabData?.bannerData}`}
              width={56}
              height={56}
              alt="banner logo"
              style={{
                borderRadius: "30px",
              }}
            ></img>
          </Stack>
        ) : (
          <Stack width={56} height={56}></Stack>
        )}
        <Box width={40}>
          <TooltipBaseMobile
            width={249}
            title={t("customReportBannerDisabled")}
            placement="top"
            disableHoverListener={enableCustomReportBanner}
          >
            <ToggleButton
              value={"upload"}
              onClick={bannerDialog.show}
              disabled={!enableCustomReportBanner || disabled}
            >
              <UploadIcon
                stroke={
                  !enableCustomReportBanner || disabled
                    ? palette.action.disabled
                    : palette.success.main
                }
                sx={{
                  width: "16px",
                  height: "16px",
                }}
              />
            </ToggleButton>
            {/*             <Button
              variant="outlined"
              sx={{
                width: "40px",
                height: "40px",
                padding: "12px",
                minWidth: "40px",
                borderColor: palette.action.disabled,
              }}
              onClick={bannerDialog.show}
              disabled={!enableCustomReportBanner || disabled}
            >
              <UploadIcon
                stroke={
                  !enableCustomReportBanner || disabled
                    ? palette.action.disabled
                    : palette.success.main
                }
                sx={{
                  width: "16px",
                  height: "16px",
                }}
              />
            </Button> */}
          </TooltipBaseMobile>
        </Box>
        {/*         <Button
          disabled={getDeleteButtonState}
          onClick={deleteBannerDialog.show}
          variant="outlined"
          sx={{
            width: "40px",
            height: "40px",
            padding: "12px",
            minWidth: "40px",
            borderColor: palette.action.disabled,
          }}
        >
          <DeleteOutlineIcon
            sx={{
              width: "24px",
              height: "24px",
              color: getDeleteButtonState
                ? palette.action.disabled
                : palette.text.primary,
            }}
          />
        </Button> */}
        <ToggleButton
          value={"upload"}
          disabled={getDeleteButtonState}
          onClick={deleteBannerDialog.show}
          sx={{ width: "40px", height: "40px" }}
        >
          <DeleteOutlineIcon
            sx={{
              width: "24px",
              height: "24px",
              color: getDeleteButtonState
                ? palette.action.disabled
                : palette.text.primary,
            }}
          />
        </ToggleButton>
      </Stack>
      <Stack direction={"row"} mb={2} justifyContent={"space-between"}>
        <Typography variant="body2" pt={1} color={"text.secondary"}>
          Allowed *.jpeg, *.jpg, *.png size <br />
          of 3.1 MB
        </Typography>
        <Typography
          display="inline"
          sx={{
            height: 16,
            fontSize: 10,
            mt: 1,
            p: 1,
            bgcolor: palette.action.selected,
            borderRadius: "4px",
          }}
        >
          Size 864x102
        </Typography>
      </Stack>
    </Stack>
  );

  return (
    <Stack
      direction="column"
      gap={2}
      sx={{ overflowX: "hidden" }}
      bgcolor={palette.background.default}
    >
      {isMobile ? mobileHead : desktopHead}
      <Stack px={2} pt={3} minHeight={325} pb={1} component={Paper}>
        <Typography variant="subtitle1" pl={2} pt={1}>
          Preview Banner (Desktop)
        </Typography>
        <Stack
          sx={{
            position: "relative",
            [`&:after`]: {
              background:
                "linear-gradient(to right, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%)",
              content: "''",
              height: "100%",
              position: "absolute",
              width: "32px",
              zIndex: 2,
              right: 0,
              top: 0,
              transform: "rotateZ(180deg)",
            },
          }}
        >
          <Stack
            direction={"row"}
            overflow={isSmallScreen ? "auto" : "inherit"}
            width={"100%"}
            mt={2}
            alignItems="center"
            flexWrap="nowrap"
          >
            <UploadImageDialog
              page={page}
              mode={DialogModes.banner}
              maxWidth={"lg"}
              title="Upload Image"
              open={bannerDialog.isOpen}
              onClose={bannerDialog.hide}
              selectedAvatar={selectedAvatar}
              setSelectedAvatar={setSelectedAvatar}
            />
            {DESKTOP_PREVIEWS.map((banner) => (
              <SettingsBannerPreview
                key={banner.label}
                image={banner.img}
                data={tabData || {}}
                position={banner.position}
                bannerHeight={banner.height}
                bannerWidth={banner.width}
                hasLogin={banner?.hasLogin}
                imageHeight={235}
                imageWidth={374}
              />
            ))}
          </Stack>
        </Stack>
      </Stack>
      <Stack px={2} pt={3} minHeight={325} pb={1} component={Paper}>
        <Typography variant="subtitle1" pl={2} pt={1}>
          Preview Banner (Mobile)
        </Typography>
        <Stack
          sx={{
            position: "relative",
            [`&:after`]: {
              background:
                "linear-gradient(to right, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%)",
              content: "''",
              height: "100%",
              position: "absolute",
              width: "32px",
              zIndex: 2,
              right: "-16px",
              top: 0,
              transform: "rotateZ(180deg)",
            },
          }}
        >
          <Stack
            direction={"row"}
            overflow={isMobile ? "auto" : "inherit"}
            gap={4}
            width={"100%"}
            mt={2}
            alignItems="center"
            justifyContent={"space-around"}
            flexWrap="nowrap"
            px={3}
          >
            {MOBILE_PREVIEWS.map((banner) => (
              <SettingsBannerPreview
                key={banner.label}
                image={banner.img}
                data={tabData || {}}
                position={banner.position}
                bannerHeight={banner?.height}
                bannerWidth={banner?.width}
                hasLogin={banner?.hasLogin}
                imageHeight={235}
                isMobile={true}
              />
            ))}
          </Stack>
        </Stack>
      </Stack>
      <DialogPrompt
        disableHeaderDivider
        open={deleteBannerDialog.isOpen}
        title={"Delete logo"}
        maxWidth="xs"
        onConfirm={handleDeleteBanner}
        isLoading={deleteBanner.isLoading || deleteAccountBanner.isLoading}
        onDecline={deleteBannerDialog.hide}
        children={
          <Typography variant="body1" pb={1}>
            Are you sure that you want to delete the logo for account banner?
          </Typography>
        }
      />
    </Stack>
  );
}
