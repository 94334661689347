import { Stack, Typography } from "@mui/material";
import { ContentAccessFilter } from "src/models/ContentAccessFilter";

//TODO: Requirements to be updated...

export function AccountsManagementContentCodeFilter({
  contentFilters,
  height,
}: {
  contentFilters?: ContentAccessFilter | null;
  height?: number;
}) {
  const formatterCode = contentFilters ? JSON.stringify(contentFilters) : null;
  return (
    <Stack py={3} height={height || 424}>
      <Typography variant="subtitle1" mb={2}>
        Code Filter
      </Typography>
      <code
        style={{
          background: "#505050",
          color: "#F7f7f7",
          width: "504px",
          minHeight: `${height ? "242px" : "324px"}`,
          wordWrap: "break-word",
          padding: "32px",
          overflowY: "auto",
        }}
      >
        <Stack maxHeight={"391px"}>{formatterCode}</Stack>
      </code>
    </Stack>
  );
}
