import axios from "axios";
import { useQuery } from "react-query";
import { enqueueSnackbar } from "notistack";
import { paths } from "@tveyes/twosionwebapischema";
import { makeApiUrl } from "src/utils/makeApiUrl";

const apiKey: keyof paths = "/api/Event/p/from-station-date-time-and-query";
const method: keyof paths[typeof apiKey] = "get";

type Endpoint = Required<paths[typeof apiKey][typeof method]>;
type Parameters = {
  query: Endpoint["parameters"]["query"];
};

type Response = Endpoint["responses"]["200"]["content"]["application/json"];

export function useEventPublic(params?: Parameters) {
  return useQuery<Response & { isItemOwner: boolean }, unknown>({
    queryKey: [apiKey, params],
    keepPreviousData: true,
    queryFn: async () => {
      const url = makeApiUrl(apiKey, params);
      const { data, headers } = await axios<Response>(url, {
        method,
      });
      const isItemOwner = headers["x-insight-user-owns-object"];
      return { ...data, isItemOwner };
    },
    onError: (error) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
  });
}
