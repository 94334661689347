import { Stack, Typography } from "@mui/material";
import { Spinner, SpinnerProps } from "./Spinner/Spinner";

interface PageLoaderProps {
  title?: string;
  size?: SpinnerProps["size"];
}

export const PageLoader = ({ size = 80, title }: PageLoaderProps) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      height="100%"
      width="100%"
    >
      {title && <Typography variant="subtitle1" children={title} />}
      <Spinner size={size} />
    </Stack>
  );
};
