import { useMemo, useState } from "react";
import {
  alpha,
  Box,
  Divider,
  ListItemSecondaryAction,
  Stack,
  Tooltip,
  useTheme,
} from "@mui/material";
import { DateRange } from "@mui/icons-material";
import { PowerSearchResultItem } from "src/api/usePowerSearch";
import { ListBaseItem } from "src/components/ListBase/ListBaseItem";
import { TranscriptText } from "src/components/TranscriptText/TranscriptText";
import {
  getPowerSearchResultCountry,
  getPowerSearchResultDateTime,
  getPowerSearchResultMarket,
  getPowerSearchResultEvent,
  getPowerSearchResultEventType,
  getPowerSearchResultSource,
  getPowerSearchResultViews,
  getPowerSearchResultId,
  getPowerSearchResultMediaType,
  getPowerSearchResultTag,
  getPowerSearchResultIndividualTranscript,
} from "src/api/usePowerSearchResult.getters";
import { KeyValue } from "src/components/KeyValue/KeyValue";
import { AdvChip } from "src/components/AdvChip/AdvChip";
import { AppLink } from "src/components/AppLink/AppLink";
import { TextLineClamp } from "src/components/TextLineClamp/TextLineClamp";
import { getEventIcon } from "src/utils/getEventIcon";
import { TranscriptBlock } from "src/components/TranscriptBlock/TranscriptBlock";
import { ClipViewer } from "src/components/ClipViewer/ClipViewer";
import { TileCheckBox } from "src/components/TileCheckBox/TileCheckBox";
import { useEventsSelectedForReports } from "src/api/useEventsSelectedForReport";
import { useEventsPreselected } from "src/api/useEventsPreselected";
import { powerSearchResultClipRoute } from "src/pages/PowerSearchResultClip/PowerSearchResultClip.route";
import { PreselectedEventsItemActions } from "../PreselectedEventsItemActions/PreselectedEventsItemActions";

export function PreselectedEventListItem({
  value,
  isChecked,
}: {
  value: PowerSearchResultItem;
  isChecked: boolean;
}) {
  const { palette, spacing } = useTheme();

  const [activeMention, setActiveMention] = useState(0);

  const eventType = getPowerSearchResultEventType(value);
  const mediaType = getPowerSearchResultMediaType(value);
  const Icon = getEventIcon(eventType, mediaType);

  const eventsSelectedForReports = useEventsSelectedForReports();
  const { highlightedEventsIds } = useEventsPreselected();

  const detailsUrl = powerSearchResultClipRoute.makeUrl({
    eventId: value.id,
  });

  const handleChange = (checked: boolean) => {
    if (checked) {
      eventsSelectedForReports.select(value);
    } else {
      eventsSelectedForReports.unselect(value);
    }
  };

  const isHighlighted = useMemo(() => {
    return highlightedEventsIds.includes(getPowerSearchResultId(value));
  }, [highlightedEventsIds, value]);

  return (
    <ListBaseItem
      key={value.id}
      sx={{
        maxHeight: 208,
        minHeight: 208,
        alignItems: "stretch",
        columnGap: 2,
        backgroundColor: isHighlighted
          ? alpha(palette.secondary.main, 0.08)
          : "inherit",
      }}
    >
      <Box
        position="relative"
        borderRadius={2}
        overflow="hidden"
        border={`1px solid ${palette.action.disabledBackground}`}
        width={215}
      >
        <ClipViewer
          id={value.id}
          onMentionChange={setActiveMention}
          event={value}
          viewMode="compact"
        />
        <TileCheckBox value={isChecked} onChange={handleChange} zIndex={3} />
        {getPowerSearchResultTag(value).length ? (
          <AdvChip
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          />
        ) : null}
      </Box>

      <Stack flex={1} justifyContent="space-between" overflow="hidden">
        {/* header card */}
        <Stack direction="row" spacing={2} mr={2}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Tooltip title={eventType}>
              <Icon />
            </Tooltip>
            <AppLink to={detailsUrl}>
              <TextLineClamp
                variant="subtitle1"
                color="primary"
                height={20}
                lineClamp={1}
              >
                {getPowerSearchResultEvent(value)}
              </TextLineClamp>
            </AppLink>
          </Stack>

          <Divider orientation="vertical" variant="middle" flexItem />

          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            minWidth="fit-content"
          >
            <DateRange sx={{ color: palette.action.active }} />
            <TextLineClamp variant="body2" lineClamp={1}>
              {getPowerSearchResultDateTime(value).toLocaleString()}
            </TextLineClamp>
          </Stack>
        </Stack>

        {/* attributes */}
        <Stack
          direction="row"
          columnGap={2}
          justifyContent="space-between"
          maxWidth="100%"
          overflow="hidden"
        >
          <KeyValue name="Source" value={getPowerSearchResultSource(value)} />
          <KeyValue name="Country" value={getPowerSearchResultCountry(value)} />
          <KeyValue name="Market" value={getPowerSearchResultMarket(value)} />
          <KeyValue
            name="Viewership"
            value={getPowerSearchResultViews(value)}
          />
        </Stack>

        <TranscriptBlock px={2} py={1} borderRadius={1}>
          <TranscriptText
            transcript={getPowerSearchResultIndividualTranscript(
              value,
              activeMention,
              false
            )}
            lineClamp={4}
          />
        </TranscriptBlock>
      </Stack>
      <ListItemSecondaryAction
        sx={{ top: spacing(1), right: 0, transform: "none" }}
      >
        <PreselectedEventsItemActions value={value} />
      </ListItemSecondaryAction>
    </ListBaseItem>
  );
}
