import { Stack, useTheme } from "@mui/material";

export function TimeLineCursorBlock({ width }: { width: number }) {
  const { palette } = useTheme();

  return (
    <Stack
      borderRight={`3px solid ${palette.secondary.main}`}
      borderLeft={`3px solid ${palette.secondary.main}`}
      height="100%"
      left="50%"
      position="absolute"
      width={width}
      sx={{
        pointerEvents: "none",
      }}
    />
  );
}
