import { RemoveRedEye } from "@mui/icons-material";
import {
  Button,
  Fade,
  FormControlLabel,
  Grid,
  Paper,
  Stack,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Fragment, useCallback, useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDateTimeSearch } from "src/api/useDateTimeSearch";
import { useStationBroadcastProgram } from "src/api/useStationBroadcastProgram";
import { useStationTime } from "src/api/useStationTime";
import { AppTopNavBarMobile } from "src/components/AppTopNavBarMobile/AppTopNavBarMobile";
import { DateTimeZonedPicker } from "src/components/DateTimeZonedPicker/DateTimeZonedPicker";
import { EventSourceMarketPicker } from "src/components/EventSourceMarketPicker/EventSourceMarketPicker";
import { EventSourceStationPicker } from "src/components/EventSourceStationPicker/EventSourceStationPicker";
import { GlobalSearchDesktopButton } from "src/components/GlobalSearchDesktopButton/GlobalSearchDesktopButton";
import { PageHeaderDesktop } from "src/components/PageHeaderDesktop/PageHeaderDesktop";
import { PageLayoutDesktop } from "src/components/PageLayoutDesktop";
import { TimeLineProgram } from "src/components/TimeLineProgram/TimeLineProgram";
import { PageLayoutMobile } from "src/components/PageLayoutMobile";
import { TextInputBase } from "src/components/TextInputBase";
import { DateTimeSearchFormValues } from "src/pages/DateTimeSearchRoot/hooks/useDateTimeSearchForm";
import { BooleanParam, useQueryParam } from "use-query-params";
import { useDateTimeSearchDetails } from "src/api/useDateTimeSearchDetails";
import { dateTimeSearchClipRoute } from "../DateTimeSearchClip/DateTimeSearchClip.route";
import { useDateTimeSearchFetchParams } from "../DateTimeSearchRoot/hooks/useDateTimeSearchFetchParams";
import { DateTimeSearchDownloadButton } from "./components/DateTimeSearchDownloadButton/DateTimeSearchDownloadButton";
import { DateTimeSearchPlayButton } from "./components/DateTimeSearchPlayButton/DateTimeSearchPlayButton";
import { useDateTimeSearchPlayState } from "./components/DateTimeSearchPlayButton/hooks/useDateTimeSearchPlayState";
import { DateTimeSearchRangePager } from "./components/DateTimeSearchRangePager/DateTimeSearchRangePager";
import { useDateTimeSearchResultDataView } from "./hooks/useDateTimeSearchDataView";
import { DateTimeSearchCommon } from "./components/DateTimeSearchCommon/DateTimeSearchCommon";

const cursorRangeBounds: [number, number] = [0, +20];

export const DateTimeSearchResultsPage = () => {
  const navigate = useNavigate();
  const { breakpoints } = useTheme();
  const [maxDateTime] = useState(new Date());
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const isLgDown = useMediaQuery(breakpoints.down("lg"));
  const dateTimeSearchDetails = useDateTimeSearchDetails({});

  const { getActiveInstancePlayDateTime, isPlaying, activeIndex } =
    useDateTimeSearchPlayState();

  const [isExpandedTextMode, setExpandedTextMode] = useQueryParam(
    "expanded",
    BooleanParam
  );

  const formHook = useFormContext<DateTimeSearchFormValues>();
  const { control, watch, formState, setValue } = formHook;
  const formValues = watch();
  const fetchParams = useDateTimeSearchFetchParams({
    form: formValues,
  });

  const { market, station, dateTime } = formValues;
  const isBusy = formState.isSubmitting;

  const setCurrentDate = useCallback(
    (date: Date) => setValue("dateTime", date),
    [setValue]
  );

  const stationTime = useStationTime({
    params: {
      path: {
        station: station?.value ?? "",
      },
    },
  });

  const { ianaTimeZone } = stationTime.data || {};

  const stationProgram = useStationBroadcastProgram({
    params: {
      fetchParams,
      timeZone: ianaTimeZone,
    },
  });

  const dateTimeSearch = useDateTimeSearch({
    params: {
      cursorRangeBounds,
      timeZone: ianaTimeZone,
      fetchParams,
    },
  });

  const dataViewElement = useDateTimeSearchResultDataView({
    data: dateTimeSearch.data,
    rowCount: dateTimeSearch.data?.length,
    isLoading: dateTimeSearch.isFetching,
    isMobile,
  });

  const expandTextSwitch = useMemo(
    () => (
      <FormControlLabel
        checked={!!isExpandedTextMode}
        control={
          <Switch
            onChange={(_e, checked) =>
              setExpandedTextMode(checked, "replaceIn")
            }
          />
        }
        label={<Typography variant="caption">Expanded Text</Typography>}
        sx={{ mx: 0 }}
      />
    ),
    [isExpandedTextMode, setExpandedTextMode]
  );

  const marketInput = useMemo(() => {
    return (
      <Controller
        name="market"
        control={control}
        render={({ field, fieldState }) => {
          return (
            <EventSourceMarketPicker
              label="Market"
              value={field.value}
              onChange={(_e, nextMarket) => field.onChange(nextMarket)}
              onBlur={field.onBlur}
              disabled={isBusy}
              fullWidth
              variant={isMobile ? "outlined" : "standard"}
              error={!!fieldState.error}
              helperText={fieldState.error?.message || " "}
            />
          );
        }}
      />
    );
  }, [control, isBusy, isMobile]);

  const stationInput = useMemo(() => {
    return (
      <Controller
        name="station"
        control={control}
        render={({ field, fieldState }) => {
          return (
            <EventSourceStationPicker
              label="Station"
              value={field.value}
              queryParams={
                market?.value
                  ? {
                      marketFilter: Number(market.value),
                    }
                  : undefined
              }
              onChange={(_e, v) => field.onChange(v)}
              onBlur={field.onBlur}
              disabled={isBusy}
              fullWidth
              variant={isMobile ? "outlined" : "standard"}
              error={!!fieldState.error}
              helperText={fieldState.error?.message || " "}
            />
          );
        }}
      />
    );
  }, [control, isBusy, isMobile, market?.value]);

  const dateTimeInput = useMemo(() => {
    return (
      <Controller
        name="dateTime"
        control={control}
        render={({ field, fieldState }) => {
          const label = ianaTimeZone
            ? `Date Time (${ianaTimeZone.replace("_", " ")})`
            : "Date Time";

          return (
            <DateTimeZonedPicker
              label={label}
              value={field.value}
              timezone={ianaTimeZone}
              onChange={field.onChange}
              views={["year", "month", "day", "hours", "minutes", "seconds"]}
              disabled={isBusy}
              maxDateTime={maxDateTime}
              slotProps={{
                textField: {
                  fullWidth: true,
                  variant: isMobile ? "outlined" : "standard",
                  error: !!fieldState.error,
                  helperText: fieldState.error?.message || " ",
                  onBlur: field.onBlur,
                },
              }}
              slots={{
                textField: TextInputBase,
              }}
            />
          );
        }}
      />
    );
  }, [control, ianaTimeZone, isBusy, isMobile, maxDateTime]);

  const dateTimePager = useMemo(() => {
    return (
      <Controller
        name="dateTime"
        control={control}
        render={({ field }) => {
          return (
            <DateTimeSearchRangePager
              value={field.value}
              timeZone={ianaTimeZone}
              bounds={cursorRangeBounds}
              maxDate={maxDateTime}
              onChange={field.onChange}
            />
          );
        }}
      />
    );
  }, [control, ianaTimeZone, maxDateTime]);

  const inputElements = useMemo(() => {
    return (
      <Grid container px={{ xs: 0, sm: 2 }} pt={{ xs: 0, sm: 2 }} columnGap={3}>
        <Grid item xs={12} md={true}>
          {marketInput}
        </Grid>
        <Grid item xs={12} md={true}>
          {stationInput}
        </Grid>
        <Grid item xs={12} md={true}>
          {dateTimeInput}
        </Grid>
      </Grid>
    );
  }, [dateTimeInput, marketInput, stationInput]);

  const toolbarElement = useMemo(() => {
    return (
      <Grid container px={2} py={2} columnGap={3}>
        <Grid item xs={12} sm="auto">
          {expandTextSwitch}
        </Grid>

        <Grid item xs={12} sm={true}>
          {dateTimePager}
        </Grid>

        <Grid
          item
          xs={12}
          sm="auto"
          display="flex"
          justifyContent="flex-end"
          gap={2}
        >
          <DateTimeSearchPlayButton isLoading={dateTimeSearch.isFetching} />
          <DateTimeSearchDownloadButton
            isCompact={isLgDown}
            sx={{ width: isLgDown ? "unset" : 140, minWidth: 42 }}
            fetchParams={
              fetchParams && {
                rangeBounds: cursorRangeBounds,
                stationId: `${fetchParams.station?.value}`,
                dateTime: fetchParams.dateTime,
                timeZone: ianaTimeZone,
              }
            }
            disabled={!dateTimeSearch.data?.length || dateTimeSearch.isFetching}
          />
        </Grid>
      </Grid>
    );
  }, [
    dateTimePager,
    dateTimeSearch.data?.length,
    dateTimeSearch.isFetching,
    ianaTimeZone,
    expandTextSwitch,
    fetchParams,
    isLgDown,
  ]);

  // pass slide index from carousel via zustand
  // use it here?
  const currentEvent =
    typeof activeIndex === "number" ? dateTimeSearch.data?.[activeIndex] : null;

  const currentEventStartDateTime = useMemo(() => {
    return currentEvent?.blockStartTime
      ? new Date(currentEvent.blockStartTime)
      : null;
  }, [currentEvent?.blockStartTime]);

  const timeLineDate =
    isPlaying && currentEventStartDateTime && ianaTimeZone
      ? // when playing - combine date with instance play offset
        getActiveInstancePlayDateTime(currentEventStartDateTime, ianaTimeZone)
      : dateTime;

  const gotoClipViewer = () => {
    if (!currentEvent) {
      return;
    }

    dateTimeSearchDetails.mutate({
      body: currentEvent,
    });

    const url = dateTimeSearchClipRoute.makeUrl({});
    navigate(url);
  };

  const timeLineDesktop = useMemo(() => {
    return (
      <TimeLineProgram
        value={timeLineDate}
        timeZone={ianaTimeZone}
        onChange={setCurrentDate}
        schedule={stationProgram.data}
        maxDate={maxDateTime}
        isPlaying={isPlaying}
        station={station?.name ?? "n/a"}
      />
    );
  }, [
    timeLineDate,
    ianaTimeZone,
    maxDateTime,
    setCurrentDate,
    stationProgram.data,
    isPlaying,
    station?.name,
  ]);

  const desktopContentResults = (
    <Fade in>
      <Stack flex={1} width="100%" display="flex" overflow="hidden">
        <PageHeaderDesktop
          title="Date/Time Search"
          toolbar={
            <Stack ml="auto">
              <GlobalSearchDesktopButton />
            </Stack>
          }
          hideBreadcrumbs
          hideBackButton
        />

        <Stack component={Paper} flex={1} overflow="hidden">
          {inputElements}
          {timeLineDesktop}

          {toolbarElement}
          {dataViewElement}
        </Stack>
      </Stack>
    </Fade>
  );

  const mobileContentResults = (
    <Fade in>
      <Stack flex={1} width="100%" display="flex" overflow="hidden">
        <AppTopNavBarMobile
          title="Date/Time Search"
          pr={1}
          toolbar={<DateTimeSearchPlayButton compact />}
        />
        {dataViewElement}
        <TimeLineProgram
          value={timeLineDate}
          onChange={setCurrentDate}
          schedule={stationProgram.data}
          maxDate={maxDateTime}
          station={station?.value ?? "n/a"}
          isPlaying={isPlaying}
          zoomControlPosition="outside"
        />
        <Stack direction="row" columnGap={2} pt={1} pb={1} px={2}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            startIcon={<RemoveRedEye />}
            onClick={gotoClipViewer}
            children="Clip viewer"
            disabled={!currentEvent}
            sx={{
              height: 42,
            }}
          />

          <DateTimeSearchDownloadButton
            disabled={!fetchParams || dateTimeSearch.isFetching}
            fullWidth
            fetchParams={
              fetchParams && {
                rangeBounds: cursorRangeBounds,
                stationId: `${fetchParams.station?.value}`,
                dateTime: fetchParams.dateTime,
                timeZone: ianaTimeZone,
              }
            }
          />
        </Stack>
      </Stack>
    </Fade>
  );

  if (isMobile) {
    return (
      <PageLayoutMobile
        content={
          <Fragment>
            {mobileContentResults}
            <DateTimeSearchCommon />
          </Fragment>
        }
      />
    );
  }

  return (
    <PageLayoutDesktop
      content={
        <Fragment>
          {desktopContentResults}
          <DateTimeSearchCommon />
        </Fragment>
      }
      overflow="hidden"
    />
  );
};
