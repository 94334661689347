import { useState } from "react";
import {
  Box,
  Divider,
  ListItemSecondaryAction,
  Stack,
  Tooltip,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import { DateRangeOutlined } from "@mui/icons-material";
import { ListBaseItem } from "src/components/ListBase/ListBaseItem";
import { TranscriptText } from "src/components/TranscriptText/TranscriptText";
import { KeyValue } from "src/components/KeyValue/KeyValue";
import {
  getPowerSearchResultCountry,
  getPowerSearchResultDateTime,
  getPowerSearchResultMarket,
  getPowerSearchResultEvent,
  getPowerSearchResultSource,
  getPowerSearchResultViews,
  getPowerSearchResultEventType,
  getPowerSearchResultId,
  getPowerSearchResultMediaType,
  getPowerSearchResultTag,
  getPowerSearchResultIndividualTranscript,
  getPowerSearchResultDuplicates,
} from "src/api/usePowerSearchResult.getters";
import { getEventIcon, getEventTooltipTitle } from "src/utils/getEventIcon";
import { AdvChip } from "src/components/AdvChip/AdvChip";
import { TextLineClamp } from "src/components/TextLineClamp/TextLineClamp";
import { TranscriptBlock } from "src/components/TranscriptBlock/TranscriptBlock";
import { powerSearchResultClipRoute } from "src/pages/PowerSearchResultClip/PowerSearchResultClip.route";
import { TileCheckBox } from "src/components/TileCheckBox/TileCheckBox";
import { AppLink } from "src/components/AppLink/AppLink";
import { ClipViewer } from "src/components/ClipViewer/ClipViewer";
import { stripHTMLTags } from "src/utils/stripHTMLTags";
import { getEventTypeLabel } from "src/utils/getEventTypeLabel";
import { useEventSelectionPowerSearchResults } from "src/api/useEventSelectionPowerSearchResults";
import { useEventSelectionDuplicatesDrawer } from "src/api/useEventSelectionDuplicatesDrawer";
import { PowerSearchResultListItemProps } from "./PowerSearchResultListItem.model";
import { PowerSearchResultActions } from "../PowerSearchResultActions/PowerSearchResultActions";
import { DuplicatesChip } from "src/components/DuplicatesChip/DuplicatesChip";
import { TranscriptParser } from "src/components/TranscriptParser/TranscriptParser";

export function PowerSearchResultListItem({
  value,
  isChecked,
  addItemToReport,
  isExpandedTextMode,
  hideMarket,
  showDuplicatesDrawer,
  drawerParentEventId,
}: PowerSearchResultListItemProps) {
  const { palette, spacing } = useTheme();

  const [activeMention, setActiveMention] = useState(0);

  const eventId = getPowerSearchResultId(value);
  const eventType = getPowerSearchResultEventType(value);
  const mediaType = getPowerSearchResultMediaType(value);
  const Icon = getEventIcon(eventType, mediaType);

  const detailsUrl = powerSearchResultClipRoute.makeUrl({ eventId });

  const eventTypeLabel = getEventTypeLabel(eventType);
  const transcript = getPowerSearchResultIndividualTranscript(
    value,
    activeMention,
    isExpandedTextMode
  );
  const transcriptTooltip = stripHTMLTags({ transcript });

  const duplicatesIds = getPowerSearchResultDuplicates(value);
  const duplicatesDrawerEventsState = useEventSelectionDuplicatesDrawer();
  const selectedDuplicatesIds = duplicatesDrawerEventsState.list.map(
    (ev) => ev.id
  );
  const itemSelectedDuplicates = duplicatesIds.filter((id) =>
    selectedDuplicatesIds.includes(id)
  );

  const eventTitle = getPowerSearchResultEvent(value);
  const dateTimeLabel = getPowerSearchResultDateTime(value).toLocaleString();

  const selectionState = useEventSelectionPowerSearchResults();

  const handleSelect = (checked: boolean) => {
    if (checked) {
      selectionState.select(value);
    } else {
      selectionState.unselect(value);
    }
  };

  return (
    <ListBaseItem
      key={eventId}
      sx={{
        minHeight: 208,
        alignItems: "stretch",
        columnGap: 2,
        overflow: "hidden",
        backgroundColor:
          eventId === drawerParentEventId
            ? alpha(palette.secondary.main, 0.08)
            : "inherit",
      }}
    >
      <Box
        position="relative"
        borderRadius={2}
        overflow="hidden"
        border={`1px solid ${palette.action.disabledBackground}`}
        width={215}
        maxHeight={176}
      >
        <ClipViewer
          id={eventId}
          onMentionChange={setActiveMention}
          event={value}
          viewMode="compact"
        />
        <TileCheckBox value={!!isChecked} onChange={handleSelect} zIndex={3} />
        {getPowerSearchResultTag(value).length ? (
          <AdvChip
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          />
        ) : null}
      </Box>

      <Stack justifyContent="space-between" flex={1} overflow="hidden" gap={1}>
        {/* header card */}
        <Stack direction="row" spacing={2} mr={8}>
          <Stack direction="row" alignItems="center" spacing={1} width="40%">
            <Tooltip title={eventTypeLabel}>
              <Box display="flex">
                <Icon />
              </Box>
            </Tooltip>
            <AppLink title={eventTitle} to={detailsUrl}>
              <TextLineClamp
                variant="subtitle1"
                color="primary"
                lineClamp={1}
                height={20}
              >
                {eventTitle}
              </TextLineClamp>
            </AppLink>
          </Stack>

          <Divider orientation="vertical" variant="middle" flexItem />

          <Tooltip title={dateTimeLabel}>
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              width={"60%"}
              minWidth="fit-content"
            >
              <DateRangeOutlined color="action" />
              <TextLineClamp variant="body2" lineClamp={1}>
                {dateTimeLabel}
              </TextLineClamp>
            </Stack>
          </Tooltip>
        </Stack>

        {/* attributes */}

        <Stack direction="row" columnGap={2} justifyContent="space-between">
          <KeyValue
            name="Type"
            value={getEventTooltipTitle(eventType, mediaType)}
          />
          <KeyValue name="Source" value={getPowerSearchResultSource(value)} />
          <KeyValue name="Country" value={getPowerSearchResultCountry(value)} />
          {!hideMarket && (
            <KeyValue name="Market" value={getPowerSearchResultMarket(value)} />
          )}
          <KeyValue
            name="Viewership"
            value={getPowerSearchResultViews(value)}
          />
        </Stack>

        <Tooltip title={transcriptTooltip}>
          <TranscriptBlock px={2} py={1} borderRadius={1}>
            {isExpandedTextMode ? (
              <Typography variant="body1">
                <TranscriptParser
                  highlightColor={palette.primary.main}
                  transcript={transcript}
                />
              </Typography>
            ) : (
              <TranscriptText
                variant="body1"
                transcript={transcript}
                lineClamp={4}
              />
            )}
          </TranscriptBlock>
        </Tooltip>
      </Stack>

      <ListItemSecondaryAction
        sx={{
          top: spacing(1),
          right: 0,
          transform: "none",
          display: "flex",
          alignItems: "center",
        }}
      >
        {!!duplicatesIds.length && (
          <DuplicatesChip
            count={duplicatesIds.length}
            selectedCount={itemSelectedDuplicates.length}
            onClick={() => showDuplicatesDrawer(eventId, duplicatesIds)}
          />
        )}
        <PowerSearchResultActions
          value={value}
          addItemToReport={addItemToReport}
        />
      </ListItemSecondaryAction>
    </ListBaseItem>
  );
}
