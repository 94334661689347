import { useAuth0 } from "@auth0/auth0-react";
import { matchRoutes, useLocation } from "react-router-dom";
import { AppRouteBuilder } from "src/models/AppRoute";
import { useIsUserExpired } from "src/api/useIsUserExpired";
import { AccountExpiredPage } from "src/pages/AccountExpired/AccountExpired.page";
import { PageLoader } from "./PageLoader";

/**
 * Checks for account expiry
 */
export function CheckAccountExpiry({
  element,
  allow,
}: {
  /** Component to show */
  element: JSX.Element;
  /** Allow there routes to be shown for expired accounts */
  allow: AppRouteBuilder<any, any>[];
}) {
  const { user } = useAuth0();
  const isExpired = useIsUserExpired();
  const location = useLocation();
  const matchResult = matchRoutes(allow, location);

  if (!user) return <PageLoader />;
  if (!matchResult?.length && isExpired) return <AccountExpiredPage />;

  return element;
}
