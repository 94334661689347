import { create } from "zustand";
import { queryAdvertisementValues } from "src/models/Advertisement";
import { WatchQueryKeywordFormValues } from "src/components/WatchQueryKeywordForm/WatchQueryKeywordForm.model";

export type WatchTermSharedValues = Pick<
  WatchQueryKeywordFormValues,
  | "sourcesInclude"
  | "sourcesExclude"
  | "advertisement"
  | "programInclude"
  | "programExclude"
>;

export const emptySharedValues = {
  sourcesInclude: {
    list: [],
    logic: "or",
  },
  sourcesExclude: {
    list: [],
    logic: "or",
  },
  advertisement: queryAdvertisementValues.all,
  programInclude: {
    list: [],
    logic: "or",
  },
  programExclude: {
    list: [],
    logic: "or",
  },
};

type WatchTermSharedState = {
  customQueryFromKeyword: string;
  displayNameFromKeyword: string;
  saveCustomQueryFromKeyword: (customQuery: string) => void;
  saveDisplayNameFromKeyword: (displayName: string) => void;
  sharedValues: WatchTermSharedValues;
  saveSharedValue: <T extends keyof WatchTermSharedValues>(
    name: T,
    value: WatchTermSharedValues[T]
  ) => void;
  saveSharedValues: (values: WatchTermSharedValues) => void;
  clearSharedValues: () => void;
};

export const useWatchTermSharedForm = create<WatchTermSharedState>(
  (setState, getState) => ({
    customQueryFromKeyword: "",
    displayNameFromKeyword: "",
    saveCustomQueryFromKeyword: (customQuery) => {
      setState({
        customQueryFromKeyword: customQuery,
      });
    },
    saveDisplayNameFromKeyword: (displayName) => {
      setState({
        displayNameFromKeyword: displayName,
      });
    },
    sharedValues: emptySharedValues,
    saveSharedValue: (name, value) => {
      const prev = getState();
      setState({
        sharedValues: {
          ...prev.sharedValues,
          [name]: value,
        },
      });
    },
    saveSharedValues: (values) => {
      setState({
        sharedValues: values,
      });
    },
    clearSharedValues: () => {
      setState({
        sharedValues: emptySharedValues,
        customQueryFromKeyword: "",
      });
    },
  })
);
