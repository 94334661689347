import { Typography, alpha, useMediaQuery, useTheme } from "@mui/material";
import {
  GRID_TREE_DATA_GROUPING_FIELD,
  GridRenderCellParams,
  gridClasses,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { Fragment, useMemo, useState } from "react";
import { TableBase } from "src/components/TableBase/TableBase";
import { EventsNoResultsOverlay } from "src/components/NoResultsOverlay/NoResultsOverlay";
import { WatchListUserQueryDTO } from "src/models/WatchListUserQueryDTO";
import { useWatchListClusterEditMode } from "../WatchListClusterEditToolbar/hooks/useWatchListClusterEditMode";
import { WatchQueryTableColumnPinMobile } from "./components/WatchQueryTableColumnPinMobile";
import { WatchQueryTableTitleCell } from "./components/WatchQueryTableTitleCell";
import { WatchQueryTableClasses } from "./WatchQueryTable.const";
import { WatchQueryTableProps } from "./WatchQueryTable.model";
import { makeWatchQueryTableColumns } from "./WatchQueryTable.utils";

const defaultPinnedColumns: string[] = [GRID_TREE_DATA_GROUPING_FIELD];

export function WatchQueryTable({
  canCreateQuery,
  ...props
}: WatchQueryTableProps) {
  const clusterEditMode = useWatchListClusterEditMode();
  const [pinnedColumns, setPinnedColumns] =
    useState<string[]>(defaultPinnedColumns);

  const { palette, typography, breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const columns = useMemo(() => {
    return makeWatchQueryTableColumns({
      dateRange: props.dateRange,
      queries: props.queries,
      isMobile,
      canCreateQuery,
    });
  }, [isMobile, props.dateRange, props.queries, canCreateQuery]);

  const apiRef = useGridApiRef();
  const headerTodayColor = isMobile ? palette.info.dark : palette.info.main;

  return (
    <TableBase<WatchListUserQueryDTO>
      isMobile={isMobile}
      pinnedColumns={{
        left: pinnedColumns,
        right: isMobile ? undefined : ["actions"],
      }}
      columns={columns}
      disableRowSelectionOnClick
      disableColumnMenu
      treeData
      getRowId={(row) => `${row.id}`}
      getRowClassName={(params) => {
        const node = apiRef.current.getRowNode(params.id);
        if (node?.type === "group") {
          return WatchQueryTableClasses.rowGroup;
        }

        if (params.row.clusterTitle) {
          return WatchQueryTableClasses.rowGroupData;
        }

        return WatchQueryTableClasses.rowData;
      }}
      rowHeight={56}
      rowSelectionModel={clusterEditMode.queryList}
      onRowSelectionModelChange={clusterEditMode.setQueryList}
      apiRef={apiRef}
      isGroupExpandedByDefault={() => true}
      getTreeDataPath={(row) =>
        row.clusterId
          ? [`${row.clusterId}/${row.clusterTitle}`, row.id]
          : [row.id]
      }
      groupingColDef={(_params) => {
        return {
          field: "title",
          sortable: false,
          headerAlign: "left",
          headerName: "Keyword",
          minWidth: isMobile ? 175 : 240,
          flex: 1,
          disableReorder: true,
          pinnable: true,
          headerClassName: !isMobile
            ? WatchQueryTableClasses.header
            : undefined,
          cellClassName: WatchQueryTableClasses.cellTitle,
          valueGetter({ rowNode, row }) {
            return rowNode.type === "group" ? rowNode.groupingKey : row.title;
          },
          renderHeader({ colDef: { headerName } }) {
            return (
              <Fragment>
                <Typography fontWeight={500} fontSize={14}>
                  {headerName}
                </Typography>
                {isMobile ? (
                  <WatchQueryTableColumnPinMobile
                    value={!!pinnedColumns.length}
                    onChange={() => setPinnedColumns(defaultPinnedColumns)}
                  />
                ) : null}
              </Fragment>
            );
          },
          renderCell(params: GridRenderCellParams) {
            try {
              return (
                <WatchQueryTableTitleCell
                  isEditMode={clusterEditMode.isActive}
                  dateRange={props.dateRange}
                  params={params}
                />
              );
            } catch (e) {
              console.error(`@@ DEBUG: Error: ${params.field}`, params, e);
              return null;
            }
          },
        };
      }}
      {...props}
      slots={{
        noRowsOverlay: EventsNoResultsOverlay,
        noResultsOverlay: EventsNoResultsOverlay,
        ...props.slots,
      }}
      sx={{
        // header styles
        [`.${WatchQueryTableClasses.headerToday}`]: {
          background: alpha(headerTodayColor, 0.04),
          ...typography.subtitle1,
        },
        [`.${WatchQueryTableClasses.headerWeekend}`]: {
          background: palette.background.default,
        },

        // group row cell styles
        [`.${WatchQueryTableClasses.rowGroup}`]: {
          bgcolor: palette.background.default,
        },

        [`.${WatchQueryTableClasses.header}`]: {
          background: alpha(palette.action.hover, 0.04),
        },

        // cell styles
        [`.${WatchQueryTableClasses.cellTitle}`]: {
          fontWeight: 600,
          bgcolor: palette.background.paper,
        },

        [`.${WatchQueryTableClasses.actions}`]: {
          bgcolor: palette.background.paper,
        },
        [`.${gridClasses.pinnedColumnHeaders}`]: {
          background: palette.mode === "dark" ? "rgba(0, 0, 0, 0.04)" : "",
          backgroundImage: "none",
          boxShadow: "none",
        },

        [`.${WatchQueryTableClasses.rowGroup}.Mui-hovered`]: {
          // disabled hover effect
          bgcolor: palette.background.default,
        },

        [`.${gridClasses.treeDataGroupingCellToggle}`]: {
          marginRight: 1,
        },

        [`.${WatchQueryTableClasses.rowGroupData}, .${WatchQueryTableClasses.rowData}`]:
          {
            [`.${gridClasses.treeDataGroupingCellToggle}`]: {
              display: "none",
            },
          },

        // normal row cell styles
        [`.${WatchQueryTableClasses.rowData}, .${WatchQueryTableClasses.rowGroupData}`]:
          {
            [`.${WatchQueryTableClasses.cellDay}`]: {
              fontWeight: 400,
            },

            [`& .${WatchQueryTableClasses.cellToday}`]: {
              fontWeight: 600,
              background: alpha(palette.info.main, 0.12),
            },
            [`& .${WatchQueryTableClasses.cellWeekend}`]: {
              background: palette.background.default,
            },
          },
        ...props.sx,
      }}
    />
  );
}
