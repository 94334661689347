import { Suspense } from "react";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { LicenseInfo } from "@mui/x-license-pro";
import { AppLayout } from "./components/AppLayout";
import { AppRouterRoot } from "./components/AppRouterRoot/AppRouterRoot";
import { PageLoader } from "./components/PageLoader";
import { CheckAccountRegistered } from "./components/CheckAccountRegistered";
import { PageNotFoundPage } from "./pages/PageNotFound/PageNotFound.page";
import { devPlaygroundRoute } from "./pages/DevPlayground/DevPlayground.route";
import { useImpersonateUser } from "./api/useImpersonateUser";
import { useIsDemoMode } from "./api/useIsDemoMode";
import { useIsImpersonatingUser } from "./api/useIsImpersonatingUser";
import { watchListRoute } from "./pages/WatchList/WatchList.route";
import { watchListCreateRoute } from "./pages/WatchListCreate/WatchListCreate.route";
import { watchListUpdateRoute } from "./pages/WatchListUpdate/WatchListUpdate.route";
import { watchListTermResultClipRoute } from "./pages/WatchListTermResultClip/WatchListTermResultClip.route";
import { watchListTermResultsRoute } from "./pages/WatchListTermResults/WatchListTermResults.route";
import { watchListTermResultClipEditorRoute } from "./pages/WatchListTermResultClipEditor/WatchListTermResultClipEditor.route";
import { AnalyticsLiteRoute } from "./pages/ReportAnalysisLite/AnalyticsLite.route";
import { featureGateDemoRoute } from "./pages/FeatureGateDemo/FeatureGateDemo.route";
import { powerSearchRoute } from "./pages/PowerSearch/PowerSearch.route";
import { powerSearchResultRoute } from "./pages/PowerSearchResult/PowerSearchResult.route";
import { powerSearchResultAdvancedRoute } from "./pages/PowerSearchResultAdvanced/PowerSearchResultAdvanced.route";
import { powerSearchResultClipRoute } from "./pages/PowerSearchResultClip/PowerSearchResultClip.route";
import { powerSearchResultClipEditorRoute } from "./pages/PowerSearchResultClipEditor/PowerSearchResultClipEditor.route";
import { reportsRoute } from "./pages/Reports/Reports.route";
import { reportCreateRoute } from "./pages/ReportCreate/ReportCreate.route";
import { reportEditRoute } from "./pages/ReportEdit/ReportEdit.route";
import { reportAnalysisRoute } from "./pages/ReportAnalysis/ReportAnalysis.route";
import { pageNotFoundRoute } from "./pages/PageNotFound/PageNotFound.route";
import { auth0CallbackRoute } from "./pages/Auth0Callback/Auth0Callback.route";
import { AppLayoutPublic } from "./components/AppLayoutPublic";
import { StopImpersonateUserFAB } from "./components/StopImpersonateUserFAB/StopImpersonateUserFAB";
import { publicReportRoute } from "./pages/PublicReport/PublicReport.route";
import { publicReportPDFRoute } from "./pages/PublicReportPDF/PublicReportPDF.route";
import { publicShareEventsRoute } from "./pages/PublicShareEvents/PublicShareEvents.route";
import { publicReportEventRoute } from "./pages/PublicReportEvent/PublicReportEvent.route";
import { publicSharedEventRoute } from "./pages/PublicSharedEvent/PublicSharedEvent.route";
import { publicEventRoute } from "./pages/PublicEvent/PublicEvent.route";
import { publicYoutubePodcastEventRoute } from "./pages/PublicYoutubePodcastEvent/PublicYoutubePodcastEvent.route";
import { publicMediaCenterItemRoute } from "./pages/PublicMediaCenterItem/PublicMediaCenterItem.route";
import { publicFileDownloadRoute } from "./pages/PublicFileDownload/PublicFileDownload.route";
import { publicTermsOfServiceRoute } from "./pages/PublicTermsOfService/PublicTermsOfService.route";
import { unsubscribeAlertsRoute } from "./pages/UnsubscribeAlerts/UnsubscribeAlerts.route";
import { snapshotsRoute } from "./pages/Snapshots/Snapshots.route";
import { SnapshotsClipViewerRoute } from "./pages/SnapshotsClipViewer/SnapshotsClipViewer.route";
import { SnapshotsClipEditorRoute } from "./pages/SnapshotsClipEditor/SnapshotsClipEditor.route";
import { mediaCenterRoute } from "./pages/MediaCenter/MediaCenter.route";
import { settingsRoute } from "./pages/Settings/Settings.route";
import { mediaCenterClipRoute } from "./pages/MediaCenterClip/MediaCenterClip.route";
import { userManagementRoute } from "./pages/UserManagement/UserManagement.route";
import { accountsManagementCreateAccountRoute } from "./pages/AccountsManagementCreateAccount/AccountsManagementCreateAccount.route";
import { accountsManagementRoute } from "./pages/AccountsManagement/AccountsManagement.route";
import { userManagementCreateRoute } from "./pages/UserManagementCreate/UserManagementCreate.route";
import { accountsManagementEditAccountRoute } from "./pages/AccountsManagementEditAccount/AccountsManagementEditAccount.route";
import { accountsManagementAddUserRoute } from "./pages/AccountsManagementAddUser/AccountsManagementAddUser.route";
import { userManagementUpdateRoute } from "./pages/UserManagementUpdate/UserManagementUpdate.route";
import { dateTimeSearchInitialRoute } from "./pages/DateTimeSearchInitial/DateTimeSearchInitial.route";
import { dateTimeSearchResultsRoute } from "./pages/DateTimeSearchResults/DateTimeSearchResults.route";
import { DateTimeSearchRoot } from "./pages/DateTimeSearchRoot/DateTimeSearchRoot.page";
import { dateTimeSearchClipRoute } from "./pages/DateTimeSearchClip/DateTimeSearchClip.route";
import { dateTimeSearchClipEditorRoute } from "./pages/DateTimeSearchClipEditor/DateTimeSearchClipEditor.route";
import { unsubscribeRoute } from "./pages/Unsubscribe/Unsubscribe.route";
import { downloadFileRoute } from "./pages/DownloadFile/DownloadFile.route";
import { DemoModeHandle } from "./components/DemoModeHandle";

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppRouterRoot element={<Outlet />} />,
    children: [
      auth0CallbackRoute,
      {
        path: "/public",
        element: <AppLayoutPublic />,
        children: [
          publicReportRoute,
          publicReportPDFRoute,
          publicShareEventsRoute,
          publicReportEventRoute,
          publicSharedEventRoute,
          publicMediaCenterItemRoute,
          publicEventRoute,
          publicYoutubePodcastEventRoute,
          publicFileDownloadRoute,
          publicTermsOfServiceRoute,
        ],
      },
      {
        path: "/",
        element: <CheckAccountRegistered component={AppLayout} />,
        errorElement: <PageNotFoundPage />,
        children: [
          downloadFileRoute,
          {
            index: true,
            element: <Navigate to={watchListRoute.path} replace />,
          },
          watchListRoute,
          watchListCreateRoute,
          watchListUpdateRoute,
          watchListTermResultsRoute,
          watchListTermResultClipRoute,
          watchListTermResultClipEditorRoute,
          //
          powerSearchRoute,
          powerSearchResultRoute,
          powerSearchResultAdvancedRoute,
          powerSearchResultClipRoute,
          powerSearchResultClipEditorRoute,
          //
          reportsRoute,
          reportCreateRoute,
          reportEditRoute,
          reportAnalysisRoute,
          AnalyticsLiteRoute,
          //
          {
            path: "/date-time-search",
            element: <DateTimeSearchRoot />,
            children: [
              {
                index: true,
                element: (
                  <Navigate to={dateTimeSearchInitialRoute.path} replace />
                ),
              },
              dateTimeSearchInitialRoute,
              dateTimeSearchResultsRoute,
              dateTimeSearchClipRoute,
              dateTimeSearchClipEditorRoute,
            ],
          },

          //
          snapshotsRoute,
          SnapshotsClipViewerRoute,
          SnapshotsClipEditorRoute,
          //
          mediaCenterRoute,
          mediaCenterClipRoute,
          //
          userManagementRoute,
          userManagementCreateRoute,
          userManagementUpdateRoute,
          //
          accountsManagementRoute,
          accountsManagementCreateAccountRoute,
          accountsManagementEditAccountRoute,
          accountsManagementAddUserRoute,
          //
          featureGateDemoRoute,
          //
          devPlaygroundRoute,
          //
          settingsRoute,
        ],
      },
      {
        path: "/unsubscribe",
        errorElement: <PageNotFoundPage isUnregistered />,
        children: [
          unsubscribeRoute,
          {
            path: "/unsubscribe/alerts",
            element: <AppLayoutPublic />,
            children: [unsubscribeAlertsRoute],
          },
        ],
      },
      pageNotFoundRoute,
    ],
  },
]);

const muiLicenseKey = process.env.REACT_APP_MUI_LICENSE_KEY || "";
LicenseInfo.setLicenseKey(muiLicenseKey);

export const App = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const isImpersonatingUser = useIsImpersonatingUser();
  const isDemoMode = useIsDemoMode();
  const impersonateUser = useImpersonateUser({});
  const stopImpersonating = () => {
    impersonateUser.mutate({
      params: {
        path: {
          id: "00000000-0000-0000-0000-000000000000",
        },
      },
    });
  };

  const impersonationFrame = isImpersonatingUser && !isDemoMode && (
    <Box
      sx={{
        position: "fixed",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        border: `4px solid ${theme.palette.error.main}`,
        zIndex: 99999,
        pointerEvents: "none",
      }}
    />
  );

  const demoImpersonationHandle = isDemoMode && <DemoModeHandle />;

  return (
    <Suspense fallback={<PageLoader />}>
      <RouterProvider router={router} fallbackElement={<PageLoader />} />
      {impersonationFrame}
      {demoImpersonationHandle}
      {isImpersonatingUser && !isDemoMode && !isMobile && (
        <StopImpersonateUserFAB
          onClick={stopImpersonating}
          sx={{
            position: "fixed",
            right: "20px",
            bottom: "88px",
            background: theme.palette.error.main,
            color: "#FFF",
            "&:hover": {
              filter: "brightness(85%)",
              background: theme.palette.error.main,
            },
          }}
        />
      )}
    </Suspense>
  );
};
