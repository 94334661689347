import { useEffect } from "react";
import { Controller, FormProvider } from "react-hook-form";
import isEmpty from "lodash/isEmpty";
import { Button, Stack } from "@mui/material";
import { Search } from "@mui/icons-material";
import { CodeEditorSection } from "src/components/CodeEditorSection/CodeEditorSection";
import { DialogBase } from "src/components/DialogBase/DialogBase";
import { useWatchQueryCustomForm } from "src/components/WatchQueryCustomForm/WatchQueryCustomForm.hook";
import { WatchQueryCustomFormSchema } from "src/components/WatchQueryCustomForm/WatchQueryCustomForm.schema";
import { WatchQueryCustomFormValues } from "src/components/WatchQueryCustomForm/WatchQueryCustomForm.model";
import { CustomQueryEditDialogProps } from "./CustomQueryEditDialog.model";
import { usePowerSearchQueryCustom } from "src/api/usePowerSearchQueryCustom";

export function CustomQueryEditDialog({
  onClose,
  initial,
  onSubmit,
  ...props
}: CustomQueryEditDialogProps) {
  const powerSearchQueryCustom = usePowerSearchQueryCustom();

  const formHook = useWatchQueryCustomForm({
    schema: WatchQueryCustomFormSchema,
    initial,
  });

  const { formState, handleSubmit, reset } = formHook;

  useEffect(() => {
    if (!props.open) {
      reset({}, { keepDefaultValues: true });
    } else {
      reset(initial);
    }
  }, [reset, props.open, initial]);

  const submitPowerSearch = handleSubmit(
    async (data: WatchQueryCustomFormValues) => {
      powerSearchQueryCustom.save(data);
      onSubmit();
    },
    (errors) => {
      console.log("@@ DEBUG:submitPowerSearch:error", errors);
    }
  );

  return (
    <FormProvider {...formHook}>
      <DialogBase
        fullWidth
        maxWidth="md"
        onClose={onClose}
        {...props}
        children={
          <Controller
            name="customQuery"
            render={({ field }) => {
              return (
                <Stack mt={3}>
                  <CodeEditorSection
                    value={field.value || ""}
                    onChange={field.onChange}
                  />
                </Stack>
              );
            }}
          />
        }
        footer={
          <Stack direction="row" spacing={2} minHeight={42}>
            <Button
              sx={{ width: 90, height: 42 }}
              onClick={onClose}
              color="error"
            >
              Cancel
            </Button>

            <Button
              startIcon={<Search />}
              variant="contained"
              color="primary"
              disabled={!formState.isValid || !isEmpty(formState.errors)}
              onClick={submitPowerSearch}
              sx={{ width: 164, height: 42 }}
            >
              Search
            </Button>
          </Stack>
        }
      />
    </FormProvider>
  );
}
