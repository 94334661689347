import { PropsWithChildren, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Stack } from "@mui/material";
import {
  emptySharedValues,
  WatchTermSharedValues,
  useWatchTermSharedForm,
} from "src/api/useWatchTermSharedForm";
import { getObjectKeys } from "src/utils/getObjectKeys";
import { WatchQueryCustomFormValues } from "../WatchQueryCustomForm/WatchQueryCustomForm.model";

export const WatchListCreateCustomFormWrapper = ({
  children,
}: PropsWithChildren<{}>) => {
  const { watch, reset, trigger } =
    useFormContext<WatchQueryCustomFormValues>();
  const formValues = watch();

  const {
    sharedValues,
    saveSharedValue,
    customQueryFromKeyword,
    displayNameFromKeyword,
  } = useWatchTermSharedForm();
  const sharedValuesKeys = getObjectKeys(emptySharedValues);

  useEffect(() => {
    let updatedFormValues = {
      ...formValues,
      ...sharedValues,
    };

    if (customQueryFromKeyword) {
      updatedFormValues.customQuery = customQueryFromKeyword;
    }

    if (displayNameFromKeyword) {
      updatedFormValues.displayName = displayNameFromKeyword;
    }

    reset(updatedFormValues);
    trigger(sharedValuesKeys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (type === "change" && name && name in emptySharedValues) {
        const itemValue = value[name as keyof WatchTermSharedValues];
        saveSharedValue(
          name as keyof WatchTermSharedValues,
          itemValue as WatchTermSharedValues[keyof WatchTermSharedValues]
        );
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, saveSharedValue, sharedValuesKeys]);

  return <Stack>{children}</Stack>;
};
