import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "react-query";
import { useSnackbar } from "notistack";
import { paths } from "@tveyes/twosionwebapischema";

const apiPath: keyof paths = "/api/Session";
const method: keyof paths[typeof apiPath] = "delete";

export function useSessionDelete() {
  const { getAccessTokenSilently } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation<void, unknown>({
    mutationFn: async () => {
      const token = await getAccessTokenSilently();
      await fetch(apiPath, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // just in case document is unloaded
        keepalive: true,
      });
    },
    onError: (error) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
  });
}
