import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  useMediaQuery,
  useTheme,
  inputBaseClasses,
  DialogActions,
  Stack,
} from "@mui/material";

import { Close } from "@mui/icons-material";
import { useIsMobile } from "src/utils/useIsMobile";
import { DialogBaseProps } from "./DialogBase.model";
import { ScrollBox } from "../ScrollBox/ScrollBox";
import { IntercomButton } from "../IntercomButton/IntercomButton";

function getMargin(isCompact: boolean, extraMobileMargin?: number) {
  if (!isCompact) return 4;
  if (extraMobileMargin) return extraMobileMargin;
  return 0;
}

export function DialogBase({
  title,
  footer,
  children,
  onClose,
  disableHeaderDivider,
  extraMobileMargin,
  closeOnLeftSide,
  mobileFullHeight,
  hideIntercomButton,
  ...props
}: DialogBaseProps) {
  const { breakpoints, spacing, palette } = useTheme();
  const isMobile = useIsMobile();
  const isCompact = useMediaQuery(breakpoints.down("sm"));
  const margin = getMargin(isCompact, extraMobileMargin);
  const size = `calc(100% - ${spacing(margin)})`;
  const themeBackground =
    palette.mode === "dark"
      ? palette.background.default
      : palette.background.paper;

  const dialogTitle = (
    <DialogTitle
      columnGap={2}
      sx={{
        padding: isCompact
          ? `${spacing(2)} ${spacing(2.5)}`
          : `${spacing(3)} ${spacing(4)}`,
      }}
    >
      {closeOnLeftSide && onClose && (
        <IconButton edge="start" onClick={onClose}>
          <Close />
        </IconButton>
      )}

      {title}

      <Stack direction="row" columnGap={2}>
        {!hideIntercomButton && isMobile && <IntercomButton edge="end" />}

        {!closeOnLeftSide && onClose && (
          <IconButton edge="end" onClick={onClose}>
            <Close />
          </IconButton>
        )}
      </Stack>
    </DialogTitle>
  );

  return (
    <Dialog
      {...props}
      onClose={onClose}
      PaperProps={{
        sx: {
          background: themeBackground,
          width: size,
          maxHeight: size,
          minHeight: mobileFullHeight ? size : undefined,
          borderRadius: mobileFullHeight ? 0 : undefined,
          justifyContent: mobileFullHeight ? "space-between" : undefined,
          margin,
          [`.${inputBaseClasses.root}`]: {
            background:
              palette.mode === "dark"
                ? `${themeBackground}!important`
                : "inherit",
          },
        },
      }}
    >
      {dialogTitle}
      {!disableHeaderDivider && <Divider />}
      {children && (
        // the ScrollBox works in conjunction with DialogContent
        // ba careful with changing styles here as overall padding
        // depend on scrollbar right/bottom padding reservation
        <ScrollBox direction="column" flex={mobileFullHeight ? 1 : undefined}>
          <DialogContent
            children={children}
            sx={{
              padding: isCompact
                ? `0 ${spacing(1.5)} 0 ${spacing(2.5)}`
                : `0 ${spacing(3)} 0 ${spacing(4)}`,
            }}
          />
        </ScrollBox>
      )}
      {footer && (
        <DialogActions
          children={footer}
          sx={{
            justifyContent: "flex-end",
            padding: isCompact
              ? `${spacing(2)} ${spacing(2.5)}`
              : `${spacing(3)} ${spacing(4)}`,
          }}
        />
      )}
    </Dialog>
  );
}
