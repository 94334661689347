import { useMemo, useState } from "react";
import { useQueryParam } from "use-query-params";
import { useNavigate } from "react-router-dom";
import { FormProvider } from "react-hook-form";
import {
  Button,
  Grid,
  Paper,
  Stack,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import { PageLayoutMobile } from "src/components/PageLayoutMobile";
import { PageLayoutDesktop } from "src/components/PageLayoutDesktop";
import { SearchInput } from "src/components/SearchInput";
import { OrderByParameters } from "src/components/SortingMenu/SortingMenu.model";
import { useOpenState } from "src/utils/useOpenState";
import { useSessionContext } from "src/api/useSessionContext";
import { useQueryPagination } from "src/utils/useQueryPagination";
import { ViewModeSwitch } from "src/components/ViewModeSwitch/ViewModeSwitch";
import { ViewMode } from "src/components/ViewModeSwitch/ViewModeSwitch.model";
import { SortingByColumn } from "src/components/SortingByColumn/SortingByColumn";
import {
  RequestAccountsBody,
  defaultAccountsManagementBody,
  useAccountsManagement,
} from "src/api/useAccountsManagement";
import { ViewModeLayout } from "src/components/ViewModeLayout/ViewModeLayout";
import { AccountsManagementFilterDialog } from "./components/AccountsManagementFilterDialog/AccountsManagementFilterDialog";
import { accountsManagementCreateAccountRoute } from "../AccountsManagementCreateAccount/AccountsManagementCreateAccount.route";
import { AccountsManagementTiles } from "./components/AccountsManagementTiles/AccountsManagementTiles";
import { AccountsManagementTable } from "./components/AccountsManagementTable/AccountsManagementTable";
import {
  useAccountsManagementFilter,
  useAccountsManagementFilterForm,
} from "./hooks/useAccountsManagementFilter";
import { AccountManagementFilterButton } from "./components/AccountsManagementFilterButton/AccountsManagementFilterButton";
import { AccountsManagementFilterFormValues } from "./components/AccountsManagementFilterForm/AccountsManagementFilterForm.model";

const viewModeOptions: {
  desktop: ViewMode[];
  mobile: ViewMode[];
} = {
  desktop: [ViewMode.table, ViewMode.tile],
  mobile: [ViewMode.tile, ViewMode.table],
};

const viewModeQueryKey = "tab";

function makeAccountsBody(
  newValues: AccountsManagementFilterFormValues,
  search: string,
  userId?: string
): RequestAccountsBody {
  const accountIds = userId ? [userId] : newValues.accountManager;

  return {
    ...defaultAccountsManagementBody,
    ...newValues,
    accountManager: accountIds ?? null,
    search,
  };
}

export function AccountsManagementPage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [sortBy, setSortBy] = useState("name");
  const [sortType, setSortType] = useState("");
  const [orderBy, setOrderBy] = useState<OrderByParameters>("asc");
  const [filterText, setFilterText] = useState("");
  const [showUserAccounts, setShowUserAccounts] = useState(false);
  const [pagination, setPagination] = useQueryPagination();
  const filterDialog = useOpenState();
  const initialViewMode: ViewMode = isMobile ? ViewMode.tile : ViewMode.list;
  const [viewMode = initialViewMode, setViewMode] =
    useQueryParam<ViewMode>(viewModeQueryKey);
  const navigate = useNavigate();
  const { userId } = useSessionContext();

  const [filterQuery, setFilterQuery, resetFilterQuery] =
    useAccountsManagementFilter();

  const formHook = useAccountsManagementFilterForm({
    initial: filterQuery,
  });

  const { data, isLoading, isFetching } = useAccountsManagement({
    request: {
      params: {
        query: {
          from: pagination.page * pagination.pageSize,
          size: pagination.pageSize,
          sortby: sortBy,
          orderby: orderBy,
        },
      },
      body: makeAccountsBody(
        filterQuery,
        filterText,
        showUserAccounts ? userId : undefined
      ),
    },
  });

  const searchInput = (
    <SearchInput
      placeholder={isMobile ? "Search..." : "Search by text..."}
      onTextChangeThrottled={setFilterText}
      sx={{
        height: isMobile ? 40 : 48,
        justifyContent: "flex-end",
        width: isMobile ? undefined : 200,
        minWidth: "168px",
        paddingRight: "16px",
      }}
      InputProps={{ sx: { height: isMobile ? 40 : 48 } }}
    />
  );

  const newAccountButton = (
    <Stack>
      <Button
        startIcon={<Add />}
        variant="contained"
        color="primary"
        onClick={() => navigate(accountsManagementCreateAccountRoute.path)}
        sx={{
          width: isMobile ? undefined : 178,
          height: isMobile ? 36 : 42,
        }}
      >
        New Account
      </Button>
    </Stack>
  );

  const onSortChange = (column: string, order: OrderByParameters) => {
    setSortBy(column);
    setOrderBy(order);
    if (column === "expirationDate") {
      setSortType("date");
    }
  };

  const sortingMenu = (
    <SortingByColumn
      onChange={onSortChange}
      order={orderBy}
      column={sortBy}
      type={sortType}
      columns={[
        {
          column: "name",
          label: "Name",
        },
        {
          column: "expirationDate",
          label: "Expiration Date ",
          type: "date",
        },
        {
          column: "accountManager",
          label: "Account Manager",
        },
      ]}
    />
  );

  const viewModeSwitch = (
    <ViewModeSwitch
      value={viewMode}
      onChange={setViewMode}
      options={isMobile ? viewModeOptions.mobile : viewModeOptions.desktop}
      size={isMobile ? "small" : "medium"}
    />
  );

  const totalRowCount = data?.total || 0;

  const dataViewElement = useMemo(() => {
    return (
      <ViewModeLayout
        current={viewMode}
        viewMap={{
          table: (
            <AccountsManagementTable
              id="accounts-management-table"
              rows={data?.results || []}
              rowCount={totalRowCount}
              loading={isLoading || isFetching}
              paginationMode="server"
              paginationModel={pagination}
              onPaginationModelChange={setPagination}
              isMobile={isMobile}
            />
          ),
          tile: (
            <AccountsManagementTiles
              filterText={filterText}
              id="accounts-management-tiles"
              rows={data?.results || []}
              rowCount={totalRowCount}
              loading={isLoading || isFetching}
              paginationModel={pagination}
              onPaginationModelChange={setPagination}
            />
          ),
        }}
      />
    );
  }, [
    isLoading,
    data?.results,
    totalRowCount,
    pagination,
    isMobile,
    setPagination,
    viewMode,
    filterText,
    isFetching,
  ]);

  const userAccountsToggle = (
    <Stack direction="row" alignItems={"center"} margin={"auto"} pr={5}>
      <Switch
        checked={showUserAccounts}
        onChange={() => setShowUserAccounts(!showUserAccounts)}
      />
      <Typography variant="body1" pl={0}>
        Show only my accounts
      </Typography>
    </Stack>
  );

  const desktopContent = (
    <>
      <Stack flexWrap="wrap" rowGap={2} direction="row" mb={3}>
        <Typography
          display="inline-flex"
          alignItems="center"
          flexGrow={1}
          variant="h6"
          height={40}
          mr={2}
        >
          Account Management
        </Typography>

        <Stack direction="row" spacing={2}>
          {newAccountButton}
        </Stack>
      </Stack>

      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          height: "100%",
        }}
      >
        <Grid container p={2} pb={2} spacing={2}>
          <Grid
            item
            xs={12}
            sm={6}
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
          >
            {searchInput}
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            gap={1}
          >
            <Stack>{userAccountsToggle}</Stack>
            <Stack direction="row" spacing={1} justifyContent="end">
              <AccountManagementFilterButton
                value={filterQuery}
                onClick={filterDialog.show}
              />
              {sortingMenu}
            </Stack>
            {viewModeSwitch}
          </Grid>
        </Grid>
        {dataViewElement}
      </Paper>
    </>
  );

  const mobileToolbar = (
    <Stack
      direction="row"
      gap={1}
      mt={2}
      alignItems="center"
      justifyContent="space-between"
    >
      {newAccountButton}
      <Stack direction="row" gap={1}>
        <AccountManagementFilterButton
          value={filterQuery}
          onClick={filterDialog.show}
        />
        <Stack flexDirection="row" gap={1}>
          {sortingMenu}
          {viewModeSwitch}
        </Stack>
      </Stack>
    </Stack>
  );

  const mobileContent = (
    <>
      <Stack p={2}>
        <Typography
          display="inline-flex"
          alignItems="center"
          flexGrow={1}
          variant="h6"
          height={40}
          mr={2}
        >
          Account Management
        </Typography>
        {mobileToolbar}
      </Stack>

      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          height: "100%",
        }}
      >
        <Grid container pl={2} spacing={2}>
          <Grid item>
            <Stack>{userAccountsToggle}</Stack>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
          >
            {searchInput}
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            gap={1}
          ></Grid>
        </Grid>
        {dataViewElement}
      </Paper>
    </>
  );

  const commonContent = (
    <AccountsManagementFilterDialog
      title="Filter options"
      open={filterDialog.isOpen}
      onClose={filterDialog.hide}
      initial={filterQuery}
      onSubmit={setFilterQuery}
      showUserAccounts={showUserAccounts}
      onResetForm={resetFilterQuery}
    />
  );

  if (isMobile) {
    return (
      <FormProvider {...formHook}>
        <PageLayoutMobile content={mobileContent} hideGlobalSearchBtn />
        {commonContent}
      </FormProvider>
    );
  }

  return (
    <FormProvider {...formHook}>
      <PageLayoutDesktop content={desktopContent} overflow="hidden" />
      {commonContent}
    </FormProvider>
  );
}
